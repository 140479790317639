import { Card, Col, Form, Row } from "react-bootstrap";

const TunnelTrunksConfig = () => {

  return (
    <>

      <Card className="mt-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">

              <h3 className="fw-bolder mb-2 line-clamp-1">
                Tunnel trunks
              </h3>

              <span className="d-none d-sm-inline-block my-2">
               Les connexions du Tunnel trunks sont sécurisées avec un mot de passe comme couche de protection supplémentaire. Changer le mot de passe ou le port du tunnel nécessite de reprovisionner les applications Ipercom
                </span>
              <Form.Group className="mb-2 mb-sm-0">
                <Form.Label className="text-900">Mot de passe tunnel*</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Password"
                  value="**************"

                />{" "}
                <Form.Control.Feedback type="invalid">
                  This field is required.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

          </Row>
        </Card.Body>
      </Card>


    </>
  );
};

export default TunnelTrunksConfig;

