import { useChatContext } from 'providers/ChatProvider';

const ConversationStarter = () => {
  const { currentConversation } = useChatContext();

  return (
    <div className="d-flex align-items-end justify-content-center text-center h-100">
      <div>
      C'est le début de votre conversation privée avec{' '}
        <a href="#!" className="fw-semi-bold">
          {currentConversation?.user.name}
        </a>
        . Vous disposez de 237 connexions mutuelles.
        <br />
        Dites bonjour à votre nouvel ami maintenant
        <span className="fa-solid fa-paper-plane text-primary ms-1"></span>
      </div>
    </div>
  );
};

export default ConversationStarter;
