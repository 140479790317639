import { faClock, faMessage, faSquarePhone, faVideoCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef } from '@tanstack/react-table';
import classNames from 'classnames';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Avatar from 'components/base/Avatar';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import { CallTableDataType, callTableData } from 'data/iper-x/editor/iperxHistoryCallUserTRUE';
import { usersIperxContactDataType} from 'data/iper-x/ipbx/usersIperx';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import React from 'react';
import { Link } from 'react-router-dom';

export const usersIperxContactColumn: ColumnDef<usersIperxContactDataType>[] = [
  {
    header: 'Nom',
    cell: ({ row: { original } }) => {
      const {
        user: { name, avatar, status, company }
      } = original;
      
      if (avatar === '/static/media/avatar-placeholder.8e19382d3ac76b94f383.webp') {
        return (
          <div className="d-flex align-items-center text-1000">
            <Avatar src={avatar} size="m" className="me-3" />
            <div>
              <h6 className="mb-1 text-1000 fw-bold">{name}</h6>
              <span className="text-800">{company}</span>
            </div>
          </div>
        );
      } else {
        return (
          <div className="d-flex align-items-center text-1000">
            <Avatar src={avatar} size="m" className="me-3" status={status} />
            <div>
              <h6 className="mb-1 text-1000 fw-bold">{name}</h6>
              <span className="text-800">{company}</span>
            </div>
          </div>
        );
      }
    },
    meta: {
      headerProps: {
        style: { width: '20%', minWidth: '100px' },
        className: 'pe-3 ps-0'
      },
      cellProps: { className: 'white-space-nowrap py-2 ps-0' }
    }
  },
  
  {
    accessorKey: 'numeroFixe',
    header: 'Numéro Fixe',
    cell: ({ row: { original } }) => original.numberFixe,
    meta: {
      headerProps: {
        style: { width: '20%', minWidth: '60px' },
        className: 'pe-6'
      },
      cellProps: {
        className: 'white-space-nowrap text-start fw-bold text-700 py-2 pe-6'
      }
    }
  },{
    accessorKey: 'numeroMobile',
    header: 'Numéro Mobile',
    cell: ({ row: { original } }) => original.numberMobile,
    meta: {
      headerProps: {
        style: { width: '20%', minWidth: '60px' },
        className: 'pe-6'
      },
      cellProps: {
        className: 'white-space-nowrap text-start fw-bold text-700 py-2 pe-6'
      }
    }
  },
  {
    accessorKey: 'date',
    header: 'date d\'appel',
    cell: ({ row: { original } }) => original.date,
    meta: {
      headerProps: {
        style: { width: '20%', minWidth: '115px' },
        className: 'text-start'
      },
      cellProps: {
        className: 'white-space-nowrap text-900 text-start'
      }
    }
  },
  {
    accessorKey: 'email',
    header: 'Email',
    cell: ({ row: { original } }) => original.email,
    meta: {
      headerProps: {
        style: { width: '20%', minWidth: '60px' },
        className: 'pe-6'
      },
      cellProps: {
        className: 'white-space-nowrap text-start fw-bold text-700 py-2 pe-6'
      }
    }
  },
  {
    id: 'dealDropdown',
    accessorKey: '',
    cell: ({ row: { original } }) => {
      const {
        user: { name }
      } = original;
      return (
        <div className="d-flex align-items-center justify-content-center flex-1">
          <Link to={`/iperx/utilisateurs/appel/${name}`} className="me-3">
            <FontAwesomeIcon
              icon={faSquarePhone}
              style={{ fontSize: "25px" }}
              transform="shrink-2 up-1" 
            />
          </Link>
          <Link to={`/iperx/utilisateurs/appel/${name}`} className="me-3">
            <FontAwesomeIcon
              icon={faVideoCamera}
              style={{ fontSize: "28px" }}
              transform="shrink-2 up-1" 
            />
          </Link>
          {/* a garder pour plus tard , quand on assignera la discurssion en fonction de l'id et non du name */}
          {/* <Link to={`/iperx/chat/${name}/conversation`} className="me-3"> */}
          <Link to={`/iperx/chat`} className="me-3">
            <FontAwesomeIcon
              icon={faMessage}
              style={{ fontSize: "22px" }}
              transform="shrink-2 up-1" 
            />
          </Link>
          <RevealDropdownTrigger>
            <RevealDropdown>
            <Link to={`/iperx/utilisateurs/details/${name}`} className="d-flex align-items-center text-1000 m-1">
         Modifier
        </Link>
            </RevealDropdown>
          </RevealDropdownTrigger>
        </div>
      );
    },
    meta: {
      headerProps: { style: { width: '15%' }, className: 'text-end' },
      cellProps: { className: 'pe-0 py-2' }
    }
  }
];

const IperxUserContactTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9" }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default IperxUserContactTable;
