export interface ReportsDownload {
  name: string;
  date: string;
  url_report: string;
}

export const reportsDownloadTableData: ReportsDownload[] = [
  {

    name: "IperxReport.zip",
    date: "14/01/2024 00:00:12",
    url_report: "url rapport"

  }


];
