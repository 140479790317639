import { DealDetailsInfoType } from "data/crm/dealDetailsInfo";
import { Col, Row, Table } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { UserDetailsInfoTypeIPBX } from "../../../../data/iper-x/ipbx/userDetailsInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import android from "../../../../assets/img/logos/icons8-android-os-24.png"
import mac from "../../../../assets/img/logos/icons8-mac-os-24.png"
import windows from "../../../../assets/img/logos/icons8-windows-client-24.png"


interface UserDetailsInfoProps {
  data: UserDetailsInfoTypeIPBX[][];
  className?: string;
}

const UserDetailsInfoIPBX = ({ data, className }: UserDetailsInfoProps) => {
  return (
    <div className={classNames("px-xl-4", className)}>
      <Row className="mx-0 mx-sm-3 mx-lg-0 px-lg-0">
        {data.map((category, index) => (
          <Col
            key={index}
            sm={12}
            xxl={6}
            className={classNames("py-3", {
              "col-sm-auto col-xxl-auto border-end-xxl": index === 0,
              "": index === 1,
              "border-end-xxl border-bottom border-bottom-xxl-0 py-3":
                index === 2
            })}
          >
            <InfoCategory category={category} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

const InfoCategory = ({ category }: { category: DealDetailsInfoType[] }) => {
  return (
    <Table borderless className="w-100 table-stats mb-0">
      <thead>
        <tr>
          <th className="p-0"></th>
          <th className="p-0"></th>
          <th className="p-0"></th>
          <th className="p-0"></th>
        </tr>
      </thead>
      <tbody>
        {category.map((item, index) => (
          <InfoItem key={item.id} data={item} index={index} />
        ))}
      </tbody>
    </Table>
  );
};

const InfoItem = ({
  data,
  index
}: {
  data: DealDetailsInfoType;
  index: number;
}) => {
  return (
    <tr>
      <td className="py-2 lh-1">
        <div
          className={classNames("d-inline-flex align-items-center p-0", {
            "d-flex": index == 1
          })}
        >
          <div
            className={`d-flex bg-${data.color}-100 rounded-circle flex-center me-3`}
            style={{ width: "24px", height: "24px" }}
          >
            <FeatherIcon
              icon={data.icon}
              className={`text-${data.color}-600 dark__text-${data.color}-300`}
              width={16}
              height={16}
            />
          </div>
          <p className="fw-bold mb-0">{data.title}</p>
        </div>
      </td>
      <td className="py-2 d-none d-sm-block pe-sm-2">:</td>
      <td className="py-2">
        {data.title === "Email" ? (
          <Link to={`mailto:${data.value}`} className="fw-semi-bold">
            {data.value}
          </Link>
        ) : data.title === "Phone" ? (
          <Link to={`tel:${data.value}`} className="fw-semi-bold">
            {data.value}

          </Link>
        ) : data.title === "Téléphone" && data.value === windows ? (
          <img src={windows} />
        ) : data.title === "Téléphone" && data.value === mac ? (
          <img src={mac} />
        ) : data.title === "Softphone" && data.value === mac ? (
          <img src={mac} />
        ) : data.title === "Softphone" && data.value === android ? (
          <img src={android} />) : (
            <p
              className={classNames("ps-6 ps-sm-0 fw-semi-bold mb-0 py-0 pe-0", {
                "pb-3 pb-sm-0": index === 0
              })}
            >
              {data.value}
            </p>
          )}
      </td>
    </tr>
  );
};

export default UserDetailsInfoIPBX;
