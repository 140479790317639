import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faBell,
  faBullhorn,
  faCalendarXmark,
  faCircleNodes,
  faFileInvoiceDollar,
  faPeace,
  faPieChart,
  faSliders,
  faTruckFast
} from '@fortawesome/free-solid-svg-icons';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const faqBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Pages',
    url: '#!'
  },
  {
    label: 'FAQ',
    active: true
  }
];

export interface Faq {
  id: number;
  title: string;
  details: string;
}

export const faqs: Faq[] = [
  {
    id: 1,
    title: 'Quelle est votre politique de retour ?',
    details: `Au moment de l'expédition, nous garantissons que votre produit sera exempt
	de défauts de matériaux et de fabrication et qu'il sera conforme aux
	spécifications indiquées sur la fiche technique spécifique au lot incluse
	avec le produit. Veuillez contacter notre service de support technique si
	vous rencontrez un problème technique avec un produit : Email :
	<a href="mailto:phoenix@email.com">
		phoenix@support.com.
	</a>
	Si l'équipe conclut que le produit ne répond pas aux
	exigences mentionnées sur la fiche technique, nous fournirons un remplacement gratuit
	ou un remboursement intégral du prix de la facture du produit.`
  },
  {
    id: 2,
    title: `J'ai commandé le mauvais produit. Que dois-je faire ?`,
    details: `Nous envisagerions d'accepter le retour de la marchandise, moyennant des frais de restockage de 20 % plus les frais d'expédition et de manutention. Le client est responsable des frais d'expédition pour le produit retourné et le nouveau produit de remplacement, à la fois vers et depuis notre installation. Tous les retours nécessitent une autorisation préalable de notre part et doivent nous être renvoyés dans les sept jours ouvrables suivant la réception des marchandises. Les produits doivent être retournés dans le même emballage ou équivalent (c.-à-d. froid et isolé) dans lequel ils ont été expédiés (c.-à-d. froid et isolé). Une fois que nous recevons l'article, nous expédierons l'article de remplacement.`
  },
  {
    id: 3,
    title: `Comment puis-je annuler ma commande ?`,
    details: `Si vous devez annuler votre commande, veuillez appeler <a href="tel:+871406-7509">(871) 406-7509</a> Veuillez noter que nous essayons de traiter et d'expédier les commandes le même jour (si reçues avant 13h, heure du Pacifique), et une fois que votre produit a été expédié, notre politique de retour s'appliquera.`
  },
  {
    id: 4,
    title: `Quels sont vos frais de livraison et de manutention ?`,
    details: `Notre frais de manutention est un tarif fixe de 20 $. Les frais d'expédition varient en fonction de votre emplacement et des articles que vous avez achetés. Pour une estimation précise des frais de livraison, veuillez procéder au processus de paiement et entrer vos articles et votre adresse.`
  },
  {
    id: 6,
    title: `Acceptez-vous les bons de commande ?`,
    details: `Oui. Veuillez inclure votre numéro de bon de commande lors du paiement en ligne, ou l'indiquer clairement sur un bon de commande envoyé par télécopie ou par courriel. Veuillez fournir l'adresse e-mail ou postale pertinente pour votre service des comptes créditeurs afin que la facture puisse être envoyée à l'endroit approprié.`
  }
];

export interface FaqType {
  question: string;
  answer: string;
}

export interface FaqCategory {
  id: string;
  name: string;
  icon: IconProp;
  description: string;
  category?: string;
  topFaqs: FaqType[];
  faqs: FaqType[];
}

export const faqCategories: FaqCategory[] = [
  {
    id: 'iper-x-101',
    name: 'Iper-X',
    icon: faPieChart,
    description: 'Répondez aux questions les plus fréquemment posées sur vos produits et services ici.',
    category: 'popular',
    topFaqs: [
      {
        question: 'Comment puis-je acheter vos services ?',
        answer: 'Vous pouvez nous envoyer un email à info@phoenix.template ou visiter notre page de services pour choisir et payer directement les services que nous proposons.'
      },
      {
        question: 'Combien coûtent vos services ?',
        answer: 'Nos services peuvent être obtenus à un coût minimum. Veuillez visiter info.phoenix-tw.com pour obtenir des informations sur les meilleurs plans d’achat.'
      },
      {
        question: 'Offrez-vous une garantie de remboursement ?',
        answer: 'Nous offrons des remboursements aux clients éligibles selon nos conditions générales et nos politiques.'
      }
    ],
    faqs: [
      {
        question: 'Comment puis-je me connecter au Portail Revendeur IPBX Iper-X ?',
        answer: 'Pour vous connecter : 1. Accédez à la page de connexion du portail. 2. Entrez votre adresse email et votre mot de passe. 3. Cliquez sur "Connexion".'
      },
      {
        question: 'Comment puis-je récupérer mon mot de passe oublié ?',
        answer: 'Pour récupérer votre mot de passe : 1. Accédez à la page de connexion. 2. Cliquez sur "Mot de passe oublié". 3. Entrez votre adresse email enregistrée. 4. Suivez les instructions envoyées à votre adresse email pour réinitialiser votre mot de passe.'
      },
      {
        question: 'Comment puis-je gérer les téléphones ?',
        answer: 'Pour gérer les téléphones : 1. Connectez-vous au portail. 2. Accédez à l\'onglet "Téléphones". 3. Ajoutez, modifiez, ou supprimez des téléphones en fonction de vos besoins.'
      },
      {
        question: 'Comment puis-je configurer les trunks ?',
        answer: 'Pour configurer les trunks : 1. Connectez-vous au portail. 2. Accédez à l\'onglet "Trunk". 3. Ajoutez un nouveau trunk en suivant les instructions fournies ou modifiez les trunks existants.'
      },
      {
        question: 'Comment puis-je gérer les utilisateurs ?',
        answer: 'Pour gérer les utilisateurs : 1. Connectez-vous au portail. 2. Accédez à l\'onglet "Utilisateurs". 3. Ajoutez, modifiez, ou supprimez des utilisateurs selon vos besoins.'
      },
      {
        question: 'Comment puis-je configurer les appels entrants ?',
        answer: 'Pour configurer les appels entrants : 1. Connectez-vous au portail. 2. Accédez à l\'onglet "Gestion des appels" puis à "Appels entrants". 3. Configurez les règles de routage, les plans d\'appels, et les groupes d\'appels pour gérer les appels entrants.'
      },
      {
        question: 'Comment puis-je gérer les appels sortants ?',
        answer: 'Pour gérer les appels sortants : 1. Connectez-vous au portail. 2. Accédez à l\'onglet "Gestion des appels" puis à "Appels sortants". 3. Définissez les règles de numérotation et configurez les trunks pour les appels sortants.'
      },
      {
        question: 'Comment puis-je configurer un plan d\'appels ?',
        answer: 'Pour configurer un plan d\'appels : 1. Connectez-vous au portail. 2. Accédez à l\'onglet "Gestion des appels" puis à "Plan d\'appels". 3. Créez ou modifiez un plan d\'appels en définissant les règles et les itinéraires d\'appel.'
      },
      {
        question: 'Comment puis-je configurer le répondeur ?',
        answer: 'Pour configurer le répondeur : 1. Connectez-vous au portail. 2. Accédez à l\'onglet "Gestion des appels" puis à "Répondeur". 3. Paramétrez les messages d\'accueil, les boîtes vocales, et les notifications de messagerie.'
      },
      {
        question: 'Comment puis-je configurer les groupes d\'appels ?',
        answer: 'Pour configurer les groupes d\'appels : 1. Connectez-vous au portail. 2. Accédez à l\'onglet "Gestion des appels" puis à "Groupes d\'appels". 3. Créez ou modifiez des groupes d\'appels pour distribuer les appels entrants parmi plusieurs utilisateurs.'
      },
      {
        question: 'Comment puis-je configurer les files d\'attente ?',
        answer: 'Pour configurer les files d\'attente : 1. Connectez-vous au portail. 2. Accédez à l\'onglet "Gestion des appels" puis à "Files d\'attente". 3. Créez ou modifiez des files d\'attente pour gérer les appels entrants en attente, en définissant les règles de gestion et les notifications.'
      },
      {
        question: 'Comment puis-je ajouter un nouvel utilisateur ?',
        answer: 'Pour ajouter un nouvel utilisateur : 1. Connectez-vous au portail. 2. Accédez à l\'onglet "Utilisateurs". 3. Cliquez sur "Ajouter un utilisateur". 4. Remplissez les informations nécessaires et enregistrez.'
      },
      {
        question: 'Comment puis-je soumettre et suivre des tickets de support ?',
        answer: 'Pour soumettre et suivre des tickets : 1. Connectez-vous au portail. 2. Accédez à l\'onglet "Support" ou "Tickets". 3. Soumettez un nouveau ticket en décrivant votre problème ou demande. 4. Suivez l\'état de vos tickets existants pour voir les réponses et mises à jour.'
      },
      {
        question: 'Comment puis-je contacter le support technique ?',
        answer: 'Le support technique est accessible via : - Email : support@iper-x.com - Téléphone : [Numéro de support] - Chat en direct : Disponible dans la section "Support" du portail.'
      },
      {
        question: 'Comment puis-je mettre à jour mes informations de revendeur ?',
        answer: 'Pour mettre à jour vos informations de revendeur : 1. Connectez-vous au portail. 2. Accédez à la section "Profil" ou "Informations sur le revendeur". 3. Modifiez les informations nécessaires et enregistrez les modifications.'
      }
    ]
  },
  {
    id: 'revendeur-101',
    name: 'Espace revendeur',
    icon: faTruckFast,
    description: 'Répondez à toutes les questions concernant la gestion des comptes revendeurs et des clients.',
    topFaqs: [
      {
        question: 'Comment puis-je consulter mes commandes ?',
        answer: 'Pour consulter vos commandes : 1. Connectez-vous au portail. 2. Accédez à l\'onglet "Commandes". 3. Sélectionnez la commande que vous souhaitez consulter pour voir les détails et le statut.'
      },
      {
        question: 'Comment puis-je consulter et télécharger mes factures ?',
        answer: 'Pour consulter et télécharger vos factures : 1. Connectez-vous au portail. 2. Accédez à l\'onglet "Factures". 3. Sélectionnez la facture que vous souhaitez consulter. 4. Téléchargez la facture en utilisant le bouton de téléchargement.'
      }
    ],
    faqs: [
      {
        question: 'Comment puis-je suivre l\'état de ma commande ?',
        answer: 'Pour suivre l\'état de votre commande : 1. Connectez-vous à votre compte. 2. Accédez à la section "Commandes" de votre tableau de bord. 3. Cliquez sur la commande que vous souhaitez suivre pour voir les mises à jour de statut.'
      },
      {
        question: 'Comment puis-je configurer mon IPBX à partir du site ?',
        answer: 'Pour configurer votre IPBX : 1. Connectez-vous à votre compte. 2. Accédez à la section "Configuration" ou "Paramètres IPBX". 3. Suivez les instructions pour personnaliser les fonctionnalités et les paramètres de votre système IPBX.'
      },
      {
        question: 'Puis-je modifier une commande après l\'avoir passée ?',
        answer: 'Pour modifier une commande après l\'avoir passée, contactez notre service client dès que possible. Vous pouvez nous joindre par : - Email : support@iper-x.com - Téléphone : [Numéro de support] - Chat en direct : Disponible dans la section "Support" du site.'
      },
      {
        question: 'Comment puis-je obtenir une certification ?',
        answer: 'Pour obtenir une certification : 1. Connectez-vous au portail. 2. Accédez à l\'onglet "Certifications". 3. Consultez les certifications disponibles et les exigences. 4. Inscrivez-vous et complétez les modules nécessaires pour obtenir la certification.'
      },
      {
        question: 'Comment puis-je ajouter un nouvel utilisateur ?',
        answer: 'Pour ajouter un nouvel utilisateur : 1. Connectez-vous à votre compte. 2. Accédez à la section "Utilisateurs". 3. Cliquez sur "Ajouter un utilisateur". 4. Remplissez les informations nécessaires et enregistrez.'
      },
      {
        question: 'Comment puis-je soumettre et suivre des tickets de support ?',
        answer: 'Pour soumettre et suivre des tickets : 1. Connectez-vous au portail. 2. Accédez à l\'onglet "Support" ou "Tickets". 3. Soumettez un nouveau ticket en décrivant votre problème ou demande. 4. Suivez l\'état de vos tickets existants pour voir les réponses et mises à jour.'
      },
      {
        question: 'Comment puis-je contacter le support technique ?',
        answer: 'Le support technique est accessible via : - Email : support@iper-x.com - Téléphone : [Numéro de support] - Chat en direct : Disponible dans la section "Support" du portail.'
      },
      {
        question: 'Comment puis-je mettre à jour mes informations de revendeur ?',
        answer: 'Pour mettre à jour vos informations de revendeur : 1. Connectez-vous au portail. 2. Accédez à la section "Profil" ou "Informations sur le revendeur". 3. Modifiez les informations nécessaires et enregistrez les modifications.'
      }
    ]
  },
];

