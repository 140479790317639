export interface CDRConfig {
  name: string;
  example_value: string;
  order: number;
}

export const cdrConfigTableData: CDRConfig[] = [
  {

    name: "Date d'appel",
    example_value: "08/12/2022 17:24:02",
    order: 0

  },

  {

    name: "Numero facture",
    example_value: "33647006803",
    order: 1

  }


];
