import team32 from "assets/img/team/32.webp";
import { BadgeBg } from "components/base/Badge";

export interface PhoneBook {

  user_status: {
    label: string;
    type: BadgeBg;
    icon: string;
  };

  user: {
    avatar?: string;
    name: string;
    placeholder?: boolean;
  };

  email: string;
  mobile: string;
  fix: string;


}

export const phoneBookTableData: PhoneBook[] = [
  {
    user: {
      avatar: team32,
      name: "Carry Anna"
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fix: "0556246219"
  },
  {
    user_status: {
      label: "Absent",
      type: "secondary",
      icon: "x"
    },
    user: {
      name: "Milind Mikuja"
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fix: "0556246219"
  },
  {
    user_status: {
      label: "Absent",
      type: "secondary",
      icon: "x"
    },
    user: {
      name: "Milind Mikuja"
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fix: "0556246219"
  },
  {
    user_status: {
      label: "Absent",
      type: "secondary",
      icon: "x"
    },
    user: {
      name: "Milind Mikuja"
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fix: "0556246219"
  },
  {
    user_status: {
      label: "Absent",
      type: "secondary",
      icon: "x"
    },
    user: {
      name: "Milind Mikuja"
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fix: "0556246219"
  },

  {
    user_status: {
      label: "Absent",
      type: "secondary",
      icon: "x"
    },
    user: {
      name: "Milind Mikuja"
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fix: "0556246219"
  },

  {
    user_status: {
      label: "Absent",
      type: "secondary",
      icon: "x"
    },
    user: {
      name: "Milind Mikuja"
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fix: "0556246219"
  },

  {
    user_status: {
      label: "Absent",
      type: "secondary",
      icon: "x"
    },
    user: {
      name: "Milind Mikuja"
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fix: "0556246219"
  },

  {
    user_status: {
      label: "Absent",
      type: "secondary",
      icon: "x"
    },
    user: {
      name: "Milind Mikuja"
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fix: "0556246219"
  },

  {
    user_status: {
      label: "Absent",
      type: "secondary",
      icon: "x"
    },
    user: {
      name: "Milind Mikuja"
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fix: "0556246219"
  },

  {
    user_status: {
      label: "Absent",
      type: "secondary",
      icon: "x"
    },
    user: {
      name: "Milind Mikuja"
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fix: "0556246219"
  },

  {
    user_status: {
      label: "Absent",
      type: "secondary",
      icon: "x"
    },
    user: {
      name: "Milind Mikuja"
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fix: "0556246219"
  },

  {
    user_status: {
      label: "Absent",
      type: "secondary",
      icon: "x"
    },
    user: {
      name: "Milind Mikuja"
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fix: "0556246219"
  },

  {
    user_status: {
      label: "Absent",
      type: "secondary",
      icon: "x"
    },
    user: {
      name: "Milind Mikuja"
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fix: "0556246219"
  },

  {
    user_status: {
      label: "Absent",
      type: "secondary",
      icon: "x"
    },
    user: {
      name: "Milind Mikuja"
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fix: "0556246219"
  }


];
