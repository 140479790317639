import { Nav } from 'react-bootstrap';
import PageBreadcrumb, {
  PageBreadcrumbItem
} from '../../../../../components/common/PageBreadcrumb';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import GeneralHoldMusicConfig from './hold-music/GeneralHoldMusicConfig';
import PlaylistConfig from './hold-music/PlaylistConfig';

export const holdMusicBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Paramètre',
    url: '/apps/ipbx/parametres'
  },
  {
    label: 'Systéme',
    url: '/apps/ipbx/parametres?selected=system'
  },
  {
    label: 'Musique',
    url: '/apps/ipbx/parametres/hold-music'
  }
];

const HoldMusicConfig = () => {
  const [selectedComponent, setSelectedComponent] =
    useState<string>('general-hold-music');
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const selectedComponentParam = searchParams.get('selected');
    if (selectedComponentParam) {
      setSelectedComponent(selectedComponentParam);
    }
  }, [location]);

  const handleItemClick = (component: string) => {
    setSelectedComponent(component);
    // Update the URL with the selected component
    const searchParams = new URLSearchParams({ selected: component });
    window.history.pushState(
      {},
      '',
      `${location.pathname}?${searchParams.toString()}`
    );
  };

  return (
    <>
      <PageBreadcrumb items={holdMusicBreadcrumbItems} />
      <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
        <h2 className="mb-0">
          <span className="me-3">Paramètres des musiques d'attente</span>
        </h2>
      </div>

      <div>
        <Nav
          variant="underline"
          className="mb-2 mb-3 md-5 pb-3 pt-2 w-100 w-sm-75 w-md-100 mx-auto bg-soft"
        >
          <Nav.Item>
            <Nav.Link
              className={`fw-semi-bold me-3 fs-8 pe-2 text-center ${selectedComponent === 'general-hold-music' ? 'active' : ''}`}
              onClick={() => handleItemClick('general-hold-music')}
            >
              Musiques d’attente général
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={`fw-semi-bold me-3 fs-8 pe-2 text-center ${selectedComponent === 'playlist' ? 'active' : ''}`}
              onClick={() => handleItemClick('playlist')}
            >
              Playlist
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <hr />

        {selectedComponent === 'general-hold-music' && (
          <GeneralHoldMusicConfig />
        )}
        {selectedComponent === 'playlist' && <PlaylistConfig />}
      </div>
    </>
  );
};

export default HoldMusicConfig;
