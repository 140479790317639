import React, { useState } from 'react';
import { Card, Col, Dropdown, Form, Modal, Row } from 'react-bootstrap';
import Avatar from 'components/base/Avatar';
import avatar from 'assets/img/team/72x72/58.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import {
  faCaretDown,
  faCircle,
  faClose,
  faMessage,
  faPlus,
  faSquarePhone,
  faVideoCamera
} from '@fortawesome/free-solid-svg-icons';
import { useAppContext } from 'providers/AppProvider';
import { Link } from 'react-router-dom';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import certification from '../../assets/img/iper-x/certification/technique.png';
import UserDetailsInfoIPBX from 'components/modules/ipbx/users-details/UserDetailsInfoIPBX';
import { userDetailsInfoData } from 'data/iper-x/ipbx/userDetailsInfo';
import UserDetailsInfo from 'pages/apps/retailer/detail/UserDetailsInfo';

const UserProfileCard = ({
  className,
  name = ''
}: {
  className?: string;
  name?: string;
  status?: string;
}) => {
  const {
    config: { isRTL }
  } = useAppContext();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Card className={className}>
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <div className="d-md-flex d-xl-block align-items-center justify-content-between mb-5">
                <div className="d-flex align-items-center mb-3 mb-md-0 mb-xl-3">
                  <Avatar size="xl" src={avatar} className="me-3" />
                  <div className="d-flex align-items-center">
                    <h3 className="me-3">{name}</h3>
                    <img
                      src={certification}
                      alt="certification technique"
                      style={{ width: '28%' }}
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-end flex-1">
                    <Button
                      variant="link"
                      className="px-3"
                      onClick={handleShow}
                    >
                      Modifier
                    </Button>
                  </div>
                </div>
              </div>
              <div className="border-top px-4 px-lg-6 pt-4">
                <UserDetailsInfo data={userDetailsInfoData} className="mb-7" />
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="companyName">
              <Form.Label>Nom du contact</Form.Label>
              <Form.Control type="text" defaultValue="Jackson Pollock" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="partnerID">
              <Form.Label>Rôle</Form.Label>
              <Form.Control type="text" defaultValue="Directeur" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="certificationLevel">
              <Form.Label>Fonction</Form.Label>
              <Form.Control type="text" defaultValue="Technique" />
            </Form.Group>
            <div className="mb-3">
              <Form.Label>Numéro de téléphone mobile</Form.Label>
              <Form.Control type="text" defaultValue="05 54 56 32 97" />
            </div>
            <div className="mb-3">
              <Form.Label>Numéro de téléphone fixe</Form.Label>
              <Form.Control type="text" defaultValue="05 54 56 32 97" />
            </div>
            <div className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" defaultValue="jacksonpol@email.com" />
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Sauvegarder
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserProfileCard;
