import { Row, Col, Form, Card, FormControl, InputGroup } from "react-bootstrap";

const IperxParamTAchangeStatus = () => {
  return (
    <>
      <h2 className="fs-7 mb-3">Modifier le statut</h2>
      <Card className="mb-4">
        <Card.Body>
          <Row>
            <Col>
              <Form.Check
                type='checkbox'
                id='defaultCheckbox'
                label='Passer automatiquement au statut de disponible pendant les heures de bureau'
              />
              <Form.Check
                type='checkbox'
                id='checkedCheckbox'
                label='Cette extension utilise les heures de bureau par défaut'
              />
              <Form.Check
                type='checkbox'
                id='disabledCheckbox'
                label='Cette extension utilise des heures de bureau spécifiques'
              />
              <Form.Check
                type='checkbox'
                id='disabledCheckbox'
                label='Désactiver les appels sortants en dehors des heures de bureau'
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default IperxParamTAchangeStatus;
