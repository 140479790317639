import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import { BlacklistedNumbers } from "../../../data/iper-x/ipbx/blacklistedNumbers";
import RevealDropdown, { RevealDropdownTrigger } from "../../base/RevealDropdown";
import ActionDropdownItems from "../../common/ActionDropdownItems";

export const blacklistedNumbersTableColumns: ColumnDef<BlacklistedNumbers>[] = [
  {
    id: "number",
    header: "Appelant en liste noir",
    accessorFn: ({ number }) => number,
    meta: {
      headerProps: { style: { width: "40%" }, className: "pe-3" }
    }
  },
  {
    id: "description",
    header: "Description",
    accessorFn: ({ description }) => description,
    meta: {
      headerProps: { style: { width: "40%" }, className: "pe-3" }
    }
  },
  {
    id: "createdOn",
    header: "Créer le ",
    accessorFn: ({ createdOn }) => createdOn,
    meta: {
      headerProps: { style: { width: "30%" }, className: "pe-3" }
    }
  },
  {
    id: "leadDropdown",
    cell: () => {
      return (
        <RevealDropdownTrigger>
          <RevealDropdown>
            <ActionDropdownItems />
          </RevealDropdown>
        </RevealDropdownTrigger>
      );
    },
    meta: {
      cellProps: {
        className: "text-end pe-0 ps-4"
      }
    }
  }
];

const BlacklistedNumbersTable = () => {
  return (
    <div>
      <AdvanceTable
        tableProps={{ className: "phoenix-table fs-9" }}
      />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default BlacklistedNumbersTable;
