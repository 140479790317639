import { useState, useCallback, useEffect } from "react";

export interface ScheduleData {
  id: number,
  idUser: number,
  name: string,
  startSlot1: string,
  endSlot1: string,
  startSlot2: string,
  endSlot2: string,
  days: string,
  state: boolean
}

const scheduleTableData: ScheduleData[] = [
  {
    id: 0,
    idUser: 9,
    name: "Example Schedule",
    startSlot1: "08:00:00",
    endSlot1: "12.00:00",
    startSlot2: "13:00:00",
    endSlot2: "17:00:00",
    days: "Lundi-Vendredi",
    state: true
  }
];

const useSchedule = () => {
  const [scheduleData, setScheduleData] = useState(scheduleTableData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    console.log("Fetching new API data...");
    try {
      const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/schedule', {
        method: 'GET',
        headers: {
          'accept': 'text/plain',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setScheduleData(data);
      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données de l'API", error);
      setError('Impossible de récupérer les données.');
      setScheduleData(scheduleTableData);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    scheduleData,
    setScheduleData,
    loading,
error
  };
};

export default useSchedule;
