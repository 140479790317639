import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import Button from 'components/base/Button';

interface AddOrderModalProps {
  show: boolean;
  handleClose: () => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  order: {
    FACTURE: string;
    'BON DE LIVRAISON': string;
    COMMANDE: string;
    TOTAL: string;
    CLIENT: string;
    'STATUT DE PAIEMENT': string;
    'STATUT D\'ACCOMPLISSEMENT': string;
    'TYPE DE LIVRAISON': string;
    'DATE FACTURATION': string;
    'DATE COMMANDE': string;
    'DATE LIVRAISON': string;
  };
}

const AddOrderModal: React.FC<AddOrderModalProps> = ({ show, handleClose, handleChange, handleSubmit, order }) => (
  <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Ajouter une commande</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="FACTURE">
          <Form.Label>NUMÉRO DE FACTURE</Form.Label>
          <Form.Control type="text" name="FACTURE" value={order.FACTURE} onChange={handleChange} required />
        </Form.Group>

        <Form.Group controlId="BON DE LIVRAISON">
          <Form.Label>NUMÉRO DE BON DE LIVRAISON</Form.Label>
          <Form.Control type="text" name="BON DE LIVRAISON" value={order['BON DE LIVRAISON']} onChange={handleChange} required />
        </Form.Group>

        <Form.Group controlId="COMMANDE">
          <Form.Label>NUMÉRO DE COMMANDE</Form.Label>
          <Form.Control type="text" name="COMMANDE" value={order.COMMANDE} onChange={handleChange} required />
        </Form.Group>

        <Form.Group controlId="TOTAL">
          <Form.Label>TOTAL</Form.Label>
          <Form.Control type="text" name="TOTAL" value={order.TOTAL} onChange={handleChange} required />
        </Form.Group>

        <Form.Group controlId="CLIENT">
          <Form.Label>CLIENT</Form.Label>
          <Form.Control type="text" name="CLIENT" value={order.CLIENT} onChange={handleChange} required />
        </Form.Group>

        <Form.Group controlId="STATUT DE PAIEMENT">
          <Form.Label>STATUT DE PAIEMENT</Form.Label>
          <Form.Control type="text" name="STATUT DE PAIEMENT" value={order['STATUT DE PAIEMENT']} onChange={handleChange} required />
        </Form.Group>

        <Form.Group controlId="STATUT D'ACCOMPLISSEMENT">
          <Form.Label>STATUT D'ACCOMPLISSEMENT</Form.Label>
          <Form.Control type="text" name="STATUT D'ACCOMPLISSEMENT" value={order['STATUT D\'ACCOMPLISSEMENT']} onChange={handleChange} required />
        </Form.Group>

        <Form.Group controlId="TYPE DE LIVRAISON">
          <Form.Label>TYPE DE LIVRAISON</Form.Label>
          <Form.Control type="text" name="TYPE DE LIVRAISON" value={order['TYPE DE LIVRAISON']} onChange={handleChange} required />
        </Form.Group>

        <Form.Group controlId="DATE FACTURATION">
          <Form.Label>DATE FACTURATION</Form.Label>
          <Form.Control type="date" name="DATE FACTURATION" value={order['DATE FACTURATION']} onChange={handleChange} required />
        </Form.Group>

        <Form.Group controlId="DATE COMMANDE">
          <Form.Label>DATE COMMANDE</Form.Label>
          <Form.Control type="date" name="DATE COMMANDE" value={order['DATE COMMANDE']} onChange={handleChange} required />
        </Form.Group>

        <Form.Group controlId="DATE LIVRAISON">
          <Form.Label>DATE LIVRAISON</Form.Label>
          <Form.Control type="date" name="DATE LIVRAISON" value={order['DATE LIVRAISON']} onChange={handleChange} required />
        </Form.Group>

        <Button variant="primary" type="submit" className='mt-4 mb-4'>
          Ajouter
        </Button>
      </Form>
    </Modal.Body>
  </Modal>
);

export default AddOrderModal;
