import { useState, useCallback, useEffect } from "react";

export interface SdaData {
  id: number,
  id_user: number,
  sda: string,
  schedule: string,
  destination: string,
  destinationData: string,
  trunkTransfert: string,
  nrDestination: string,
  nrDestinationData: string,
  ringDuration: number,
  nrTrunkTransfert: string,
  state: boolean
}

const sdaTableData: SdaData[] = [
  {
    id: 1,
    id_user: 9,
    sda: "651566505",
    schedule: "Ouverte",
    destination: "Paris",
    destinationData: "63258",
    trunkTransfert: "Mbappé",
    nrDestination: "Maison",
    nrDestinationData: "6996",
    ringDuration: 0,
    nrTrunkTransfert: "Jessie",
    state: false
  }
];

const useSda = () => {
  const [sdaData, setSdaData] = useState(sdaTableData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    try {
      const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/sda', {
        method: 'GET',
        headers: {
          'accept': 'text/plain',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      setSdaData(data);
      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données de l'API", error);
      setError('Impossible de récupérer les données.');
      setSdaData(sdaTableData);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    sdaData,
    setSdaData,
    loading,
    error
  };
};

export default useSda;
