import { PhoneUserData } from 'hooks/api/usePhoneUser';
import React, { useState, ChangeEvent } from 'react';
import { Form, Button } from 'react-bootstrap';


type PhoneUserFormProps = {
  tableData: PhoneUserData[];
  setTableData: React.Dispatch<React.SetStateAction<PhoneUserData[]>>;
  onClose: () => void;
};

const AddPhoneUserForm: React.FC<PhoneUserFormProps> = ({ tableData, setTableData, onClose }) => {
  const [newUser, setNewUser] = useState<PhoneUserData>({
    id: 0,
    idPhoneConfig: 9,
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    phoneUsername: '',
    phonePassword: '',
    avatar: '',
    extension: '',
    mobile: '',
    fixe: '',
    lastActivity: new Date().toISOString(),
    callStatus: ''
  });


  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewUser(prevUser => ({
      ...prevUser,
      [name]: value
    }));
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await fetch('https://rct-backend.ipercom.io/api/Webrtc/phoneuser', {
        method: 'POST',
        headers: {
          'accept': 'text/plain',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newUser)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: PhoneUserData = await response.json();
      setTableData([...tableData, data]);
      onClose();
    } catch (error) {
      console.error("Erreur lors de l'ajout de l'utilisateur", error);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="firstname">
        <Form.Label>Prénom</Form.Label>
        <Form.Control
          type="text"
          name="firstname"
          value={newUser.firstname}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="lastname">
        <Form.Label>Nom</Form.Label>
        <Form.Control
          type="text"
          name="lastname"
          value={newUser.lastname}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="email">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          name="email"
          value={newUser.email}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="password">
        <Form.Label>Mot de passe</Form.Label>
        <Form.Control
          type="password"
          name="password"
          value={newUser.password}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="phoneUsername">
        <Form.Label>Nom d'utilisateur (Téléphone)</Form.Label>
        <Form.Control
          type="text"
          name="phoneUsername"
          value={newUser.phoneUsername}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="phonePassword">
        <Form.Label>Mot de passe (Téléphone)</Form.Label>
        <Form.Control
          type="password"
          name="phonePassword"
          value={newUser.phonePassword}
          onChange={handleChange}
          required
        />
      </Form.Group>

      {/* <Form.Group controlId="avatar">
        <Form.Label>Avatar</Form.Label>
        <Form.Control
          type="text"
          name="avatar"
          value={newUser.avatar}
          onChange={handleChange}
        />
      </Form.Group> */}

      <Form.Group controlId="extension">
        <Form.Label>Extension</Form.Label>
        <Form.Control
          type="text"
          name="extension"
          value={newUser.extension}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group controlId="mobile">
        <Form.Label>Mobile</Form.Label>
        <Form.Control
          type="text"
          name="mobile"
          value={newUser.mobile}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group controlId="fixe">
        <Form.Label>Fixe</Form.Label>
        <Form.Control
          type="text"
          name="fixe"
          value={newUser.fixe}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group controlId="callStatus">
        <Form.Label>Statut d'appel</Form.Label>
        <Form.Control
          as="select"
          className="form-select"
          name="callStatus"
          value={newUser.callStatus}
          onChange={handleChange}
          required
        >
          <option value="">Sélectionner le statut</option>
          <option value="disponible">Disponible</option>
          <option value="absent">Absent</option>
          <option value="dnd">Ne pas déranger</option>
          <option value="lunch">Déjeuner</option>
          <option value="business-trip">En déplacement</option>
        </Form.Control>
      </Form.Group>

      <Button variant="primary" type="submit" className="mt-2">
        Ajouter
      </Button>
    </Form>
  );
};

export default AddPhoneUserForm;
