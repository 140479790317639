import React, { useState, ChangeEvent } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { retailerOrdersAndInvoicesBreadcrumbItems } from 'data/breadcrumbItem';
import { faFileExport, faFileImport, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import FilterTab, { FilterTabItem } from 'components/common/FilterTab';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import OrdersTable, { ordersTableColumns } from 'components/tables/iper-x/retailer/OrdersTable';
import useOrdersData from 'hooks/dolibarr/useOrdersData';
import ExportModal from './ExportModal';
import OrdersForm from './OrdersForm';

interface Order {
  socid: string;
  date: string;
  services: OrderLine[];
}

interface OrderLine {
  fk_product: string;
  qty: string;
  subprice: string;
  tva_tx: string;
  duration?: string;
}

const tabItems: FilterTabItem[] = [
  { label: 'Tout', value: 'all', count: 68817 },
  { label: 'En attente de paiement', value: 'pending_payment', count: 6 },
  { label: 'Non accompli', value: 'unfulfilled', count: 17 },
  { label: 'Complété', value: 'completed', count: 6810 },
  { label: 'Remboursé', value: 'refunded', count: 8 },
  { label: 'Échoué', value: 'failed', count: 2 }
];

const OrderList: React.FC = () => {
  const { orders, error } = useOrdersData();

  // Configure table parameters
  const table = useAdvanceTable({
    data: orders,
    columns: ordersTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const [showExportModal, setShowExportModal] = useState(false);
  const toggleExportModal = () => setShowExportModal(!showExportModal);

  const [exportOptions, setExportOptions] = useState({
    includeInvoice: true,
    includeOrder: true
  });

  const handleExport = () => {
    // Handle export logic here
    toggleExportModal();
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setExportOptions(prevState => ({
      ...prevState,
      [name]: checked
    }));
  };

  const [showAddOrderModal, setShowAddOrderModal] = useState(false);
  const toggleAddOrderModal = () => setShowAddOrderModal(!showAddOrderModal);

  const handleSubmit = (order: Order) => {
    // Handle form submission logic here
    toggleAddOrderModal();
  };

  return (
    <>
      <div>
        <Row className="gy-3 mb-6 justify-content-between">
          <Col md={9} xs="auto">
            <PageBreadcrumb items={retailerOrdersAndInvoicesBreadcrumbItems} />
            <h2 className="mb-2 text-1100">Commandes</h2>
          </Col>
        </Row>
        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex flex-wrap gap-3">
              <SearchBox placeholder="Rechercher des commandes" onChange={handleSearchInputChange} />
              <div className="ms-xxl-auto">
                <Button variant="link" className="text-900 me-4 px-0">
                  <FontAwesomeIcon icon={faFileImport} className="fs-9 me-2" />
                  Importer
                </Button>
                <Button variant="link" className="text-900 me-4 px-0" onClick={toggleExportModal}>
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Exporter
                </Button>
                <Button variant="primary" href={"/portail-revendeur/commandes/ajouter"}>
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Ajouter une commande
                </Button>
              </div>
            </div>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <OrdersTable />
          </div>
        </AdvanceTableProvider>

        <ExportModal
          show={showExportModal}
          handleClose={toggleExportModal}
          handleCheckboxChange={handleCheckboxChange}
          handleExport={handleExport}
          exportOptions={exportOptions}
        />

        <OrdersForm
          show={showAddOrderModal}
          handleClose={toggleAddOrderModal}
          handleSubmit={handleSubmit}
        />
      </div>
    </>
  );
};

export default OrderList;
