import React, { useState, ChangeEvent, FormEvent } from 'react';
import { Button, Form, Modal, Alert } from 'react-bootstrap';

interface ThirdParty {
  name: string;
  ref_ext: string;
  address: string;
  zip: string;
  town: string;
  typent_id: string;
  status: string;
  type: string;
  phone: string;
  email: string;
  fax: string;
  tva_intra: string;
  idprof1: string;
  idprof2: string;
}

interface ThierFormProps {
  show: boolean;
  handleClose: () => void;
  handleSubmit: (thirdParty: ThirdParty) => void;
}

const ThierForm: React.FC<ThierFormProps> = ({ show, handleClose, handleSubmit }) => {
  const [thirdParty, setThirdParty] = useState<ThirdParty>({
    name: '',
    ref_ext: '',
    address: '',
    zip: '',
    town: '',
    typent_id: '',
    status: '1', // Active by default
    type: '0',   // Default type
    phone: '',
    email: '',
    fax: '',
    tva_intra: '',
    idprof1: '',
    idprof2: ''
  });

  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setThirdParty(prevThirdParty => ({
      ...prevThirdParty,
      [name]: value,
    }));
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const DOLAPIKEY = localStorage.getItem('DOLAPIKEY');
      const response = await fetch('https://erp.ipercom.io/api/index.php/thirdparties', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'DOLAPIKEY': DOLAPIKEY || '',
        },
        body: JSON.stringify(thirdParty),
      });

      if (response.ok) {
        setSuccess('Tiers créé avec succès.');
        setError(null);
        setThirdParty({
          name: '',
          ref_ext: '',
          address: '',
          zip: '',
          town: '',
          typent_id: '',
          status: '1',
          type: '0',
          phone: '',
          email: '',
          fax: '',
          tva_intra: '',
          idprof1: '',
          idprof2: ''
        });
        handleSubmit(thirdParty);
      } else {
        const errorResponse = await response.json();
        setError(`Erreur: ${errorResponse.error || "Une erreur s'est produite."}`);
        setSuccess(null);
      }
    } catch (err) {
      if (err instanceof Error) {
        setError(`Erreur: ${err.message}`);
      } else {
        setError('Erreur inconnue');
      }
      setSuccess(null);
    }
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Ajouter un Tiers</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}
        <Form onSubmit={onSubmit}>
          <Form.Group controlId="name">
            <Form.Label>Nom</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={thirdParty.name}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="ref_ext">
            <Form.Label>Référence externe</Form.Label>
            <Form.Control
              type="text"
              name="ref_ext"
              value={thirdParty.ref_ext}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="address">
            <Form.Label>Adresse</Form.Label>
            <Form.Control
              type="text"
              name="address"
              value={thirdParty.address}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="zip">
            <Form.Label>Code postal</Form.Label>
            <Form.Control
              type="text"
              name="zip"
              value={thirdParty.zip}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="town">
            <Form.Label>Ville</Form.Label>
            <Form.Control
              type="text"
              name="town"
              value={thirdParty.town}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="typent_id">
            <Form.Label>Type d'entreprise</Form.Label>
            <Form.Control
              as="select"
              name="typent_id"
              value={thirdParty.typent_id}
              onChange={handleChange}
              required
            >
              <option value="7">Société Anonyme (SA)</option>
              <option value="8">Société à Responsabilité Limitée (SARL)</option>
              <option value="9">Société par Actions Simplifiée (SAS)</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="type">
            <Form.Label>Type de tiers</Form.Label>
            <Form.Control
              as="select"
              name="type"
              value={thirdParty.type}
              onChange={handleChange}
              required
            >
              <option value="1">Client</option>
              <option value="2">Fournisseur</option>
              <option value="3">Les deux</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="phone">
            <Form.Label>Téléphone</Form.Label>
            <Form.Control
              type="text"
              name="phone"
              value={thirdParty.phone}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={thirdParty.email}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="fax">
            <Form.Label>Fax</Form.Label>
            <Form.Control
              type="text"
              name="fax"
              value={thirdParty.fax}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="tva_intra">
            <Form.Label>TVA Intra</Form.Label>
            <Form.Control
              type="text"
              name="tva_intra"
              value={thirdParty.tva_intra}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="idprof1">
            <Form.Label>Numéro SIRET</Form.Label>
            <Form.Control
              type="text"
              name="idprof1"
              value={thirdParty.idprof1}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="idprof2">
            <Form.Label>Numéro SIREN</Form.Label>
            <Form.Control
              type="text"
              name="idprof2"
              value={thirdParty.idprof2}
              onChange={handleChange}
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="mt-4">
            Ajouter
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ThierForm;
