import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import { currencyFormat } from 'helpers/utils';
import { Link } from 'react-router-dom';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Badge from 'components/base/Badge';
import FeatherIcon from 'feather-icons-react';
import { Order, Invoice } from 'data/e-commerce/orders'; // Adjust the import to your data structure

export const ordersInvoicesTableColumns: ColumnDef<Order | Invoice>[] = [
  {
    accessorKey: 'ref',
    header: 'Facture',
    cell: ({ row: { original } }) => {
      const { ref } = original;
      return (
        <Link to="#!" className="fw-semi-bold">
          {ref}
        </Link>
      );
    },
    meta: {
      headerProps: { style: { width: '10%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'commande',
    header: 'Commande',
    cell: ({ row: { original } }) => {
      const { commande } = original;
      return (
        <Link to="#!" className="fw-semi-bold">
          {commande}
        </Link>
      );
    },
    meta: {
      headerProps: { style: { width: '10%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'total_ht',
    header: 'Total',
    cell: ({ row: { original } }) => currencyFormat(original.total_ht),
    meta: {
      headerProps: { style: { width: '10%' }, className: 'text-end' },
      cellProps: { className: 'text-end fw-semi-bold text-1000' }
    }
  },
  {
    accessorKey: 'tiers',
    header: 'Client',
    cell: ({ row: { original } }) => {
      const { tiers } = original;
      return (
        <Link to={`/ipbx/dashboard/${tiers}`} className="d-flex align-items-center">
          <p className="mb-0 ms-3 text-1100 fw-bold">{tiers}</p>
        </Link>
      );
    },
    meta: {
      headerProps: { style: { width: '20%', minWidth: 200 }, className: 'ps-8' },
      cellProps: { className: 'ps-8 py-0 white-space-nowrap' }
    }
  },
  {
    accessorKey: 'payment_status',
    header: 'Statut de paiement',
    cell: ({ row: { original } }) => {
      const { payment_status } = original;
      const badgeType = payment_status === 'completed' ? 'success' : payment_status === 'pending' ? 'warning' : 'danger';
      return (
        <Badge
          bg={badgeType}
          variant="phoenix"
          iconPosition="end"
          className="fs-10"
        >
          {payment_status}
        </Badge>
      );
    },
    meta: {
      headerProps: { style: { width: '15%' }, className: 'pe-3' }
    }
  },
  {
    accessorKey: 'fulfilment_status',
    header: 'Statut d\'accomplissement',
    cell: ({ row: { original } }) => {
      const { fulfilment_status } = original;
      const badgeType = fulfilment_status === 'completed' ? 'success' : fulfilment_status === 'pending' ? 'warning' : 'danger';
      return (
        <Badge
          bg={badgeType}
          variant="phoenix"
          iconPosition="end"
          className="fs-10"
        >
          {fulfilment_status}
        </Badge>
      );
    },
    meta: {
      headerProps: { style: { width: '15%' }, className: 'pe-3' }
    }
  },
  {
    accessorKey: 'type_livraison',
    header: 'Type de livraison',
    meta: {
      headerProps: { style: { width: '20%' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: 'date_facturation',
    header: 'Date facturation',
    cell: ({ row: { original } }) => new Date(original.date_facturation).toLocaleDateString(),
    meta: {
      headerProps: { style: { width: '15%' }, className: 'text-end' },
      cellProps: { className: 'text-end' }
    }
  },
  {
    accessorKey: 'date_commande',
    header: 'Date commande',
    cell: ({ row: { original } }) => new Date(original.date_commande).toLocaleDateString(),
    meta: {
      headerProps: { style: { width: '15%' }, className: 'text-end' },
      cellProps: { className: 'text-end' }
    }
  },
  {
    accessorKey: 'date_livraison',
    header: 'Date livraison',
    cell: ({ row: { original } }) => new Date(original.date_livraison).toLocaleDateString(),
    meta: {
      headerProps: { style: { width: '15%' }, className: 'text-end' },
      cellProps: { className: 'text-end' }
    }
  }
];

const OrdersInvoicesTable: React.FC = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default OrdersInvoicesTable;
