import { Row, Col, Form, Card } from "react-bootstrap";

const IperxParamAudioVideo = () => {
  return (
    <>
      <h2 className="fs-6 mb-3">Audio/Vidéo</h2>

      <Card className="mb-4">
        <Card.Body>
          <h2 className="fs-6 mb-3">Paramètres d'appel</h2>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="audioSettings">
                <Form.Label>Utiliser cet appareil pour les appels</Form.Label>
                <Form.Select defaultValue="Desktop App (127.0.0.1)">
                  <option>Desktop App (127.0.0.1)</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="ringtoneSettings">
                <Form.Label>Sonnerie</Form.Label>
                <Form.Select defaultValue="Default - Haut-parleurs">
                  <option>Default - Haut-parleurs</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="callSettings">
                <Form.Check type="checkbox" label="Remplacer + par" />
                <Form.Control type="number" placeholder="20" className="mb-3" />
                <Form.Check
                  type="checkbox"
                  label="Décrocher automatiquement les appels entrants"
                />
                <Form.Check
                  type="checkbox"
                  label="Autoriser les pop-up d'appel pour attirer l'attention sur les appels entrants"
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mb-4">
        <Card.Body>
          <h2 className="fs-6 mb-3">Audio/Vidéo</h2>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="cameraSettings">
                <Form.Label>Caméra</Form.Label>
                <Form.Select defaultValue="Facetime">
                  <option>Facetime</option>
                  
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="speakerSettings">
                <Form.Label>Haut-parleur</Form.Label>
                <Form.Select defaultValue="Default - Haut-parleurs">
                  <option>Default - Haut-parleurs</option>
                  
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="microphoneSettings">
                <Form.Label>Microphone</Form.Label>
                <Form.Select defaultValue="Default - Microphone">
                  <option>Default - Microphone</option>
                  
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mb-4">
        <Card.Body>
          <h2 className="fs-6 mb-3">Intégration des casques</h2>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="headsetIntegration">
                <Form.Select defaultValue="Aucun">
                  <option>Aucun</option>
                  
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mb-4">
        <Card.Body>
          <h2 className="fs-6 mb-3">Application externe</h2>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="externalAppSettings">
                <Form.Check
                  type="checkbox"
                  label="Lancer une application externe"
                />
                <Form.Control
                  type="text"
                  placeholder="Chemin d'accès à l'application"
                  className="mb-3"
                />
                <Form.Control
                  type="text"
                  placeholder="Paramètres à envoyer"
                  className="mb-3"
                />
                <Form.Control
                  type="text"
                  placeholder="Sonnerie"
                  className="mb-3"
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default IperxParamAudioVideo;
