import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import Avatar from 'components/base/Avatar';
import { UserData } from 'hooks/api/useRetailerUsers';
import AdvanceTable from '../../../../components/base/AdvanceTable';
import AdvanceTableFooter from '../../../../components/base/AdvanceTableFooter';

// Define the table columns, including new columns for address, VAT number, and social security number
export const userAPITableColumns: ColumnDef<UserData>[] = [
  {
    id: 'name',
    header: 'Nom & Prénom',
    cell: ({ row: { original } }) => {
      const id = original?.id ?? '';
      const firstname = original?.firstname ?? '';
      const lastname = original?.lastname ?? '';
      const avatarUrl = `https://ui-avatars.com/api/?name=${firstname}+${lastname}`;
      return (
        <Link
          to={`/portail-revendeur/utilisateurs/details/${id}`}
          className="d-flex align-items-center"
        >
          <Avatar src={avatarUrl} size="m" className="me-3" />
          <p className="mb-0 text-1100 fw-bold">
            {firstname} {lastname}
          </p>
        </Link>
      );
    },
    meta: {
      headerProps: { style: { width: '15%', fontSize: '12px' } },
      cellProps: { className: 'text-900 fs-9 d-flex align-items-center' },
    },
  },
  {
    accessorKey: 'email',
    header: 'Email',
    cell: ({ row: { original } }) => original?.email ?? 'N/A',
    meta: {
      headerProps: { style: { width: '15%', fontSize: '12px' } },
      cellProps: { className: 'text-900 fs-9' },
    },
  },
  {
    accessorKey: 'phone',
    header: 'Téléphone',
    cell: ({ row: { original } }) => original?.office_phone ?? 'N/A',
    meta: {
      headerProps: { style: { width: '15%', fontSize: '12px' } },
      cellProps: { className: 'text-900 fs-9' },
    },
  },
  {
    accessorKey: 'mobile',
    header: 'Mobile',
    cell: ({ row: { original } }) => original?.user_mobile ?? 'N/A',
    meta: {
      headerProps: { style: { width: '15%', fontSize: '12px' } },
      cellProps: { className: 'text-900 fs-9' },
    },
  },

];

const UserTableAPIRetailer = () => {
  return (
    <div>
      <AdvanceTable
        tableProps={{ className: 'phoenix-table fs-9 text-center' }}
        columns={userAPITableColumns} // Pass the updated columns to the table
        data={[]} // This should be populated with the actual user data from your API
      />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default UserTableAPIRetailer;
