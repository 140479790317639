import { Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import PageBreadcrumb, { PageBreadcrumbItem } from "../../../../../components/common/PageBreadcrumb";
import React from "react";
import { codecsBreadcrumbItems } from "./CodecsList";

export const optionsBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Paramètre',
    url: "/apps/ipbx/parametres"
  },
  {
    label: 'Apps',
    url: "/apps/ipbx/parametres?selected=apps"
  },
  {
    label: 'Options',
    url: "/apps/ipbx/parametres/options"
  },
];


const OptionsConfig = () => {

  return (
    <>
      <PageBreadcrumb items={optionsBreadcrumbItems} />
      <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
        <h2 className="mb-0">
          <span className="me-3">Options</span>
        </h2>
      </div>

      <Card className="">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">

              <h3 className="fw-bolder mb-2 line-clamp-1">
                Options générales
              </h3>


              <Form.Check type="checkbox" id="sameAsShipping" className="4">
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Permettre la messagerie instantanée
                </Form.Check.Label>
              </Form.Check>

              <Form.Check type="checkbox" id="sameAsShipping" className="4">
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Masquer les extensions système (SVI, Groupes d'appels, Files d’attente et fax) de l’annuaire d'entreprise des applications IPER-X
                </Form.Check.Label>
              </Form.Check>

              <Form.Check type="checkbox" id="sameAsShipping" className="4">
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Cacher les contacts CRM depuis l'annuaire d'entreprise des applications IPER-X
                </Form.Check.Label>
              </Form.Check>

              <Form.Check type="checkbox" id="sameAsShipping" className="4">
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Cacher l'option de modification des mots de passe pour les applications IPER-X
                </Form.Check.Label>
              </Form.Check>

            </Col>

          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">

              <h3 className="fw-bolder mb-2 line-clamp-1">
                Renommer les statuts Déjeuner et Voyage d'affaires
              </h3>



              <FloatingLabel className="mt-3" controlId="floatingInputAuthMax" label="Lunch">
                <Form.Control type="text" value="Lunch" />
              </FloatingLabel>


              <FloatingLabel className="mt-3" controlId="floatingInputAuthMax" label="Business trip">
                <Form.Control type="text" value="Business trip" />
              </FloatingLabel>

            </Col>

          </Row>
        </Card.Body>
      </Card>



    </>
  );
};

export default OptionsConfig;

