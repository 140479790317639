
export interface Microsoft365Integration {
  name: string;
  mainName: string;
  email: string;
}

export const microsoft365IntegrationsTableData: Microsoft365Integration[] = [
  {
    name: "Ext",
    mainName: "Type",
    email: "MilindMikuja@gmail.com",
  },

];
