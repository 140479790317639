import { useState, useEffect } from 'react';

export interface Assignee {
  id: number;
  name: string;
  avatar?: string;
}

export interface Invoice {
  id: number;
  ref: string;
  total_ht: number;
  total_ttc: number;
  date_creation: number;
  date_validation: number;
  date_modification: number;
  user_creation_id: number;
  user_validation_id: number;
  socid: number;
  tiers: string;
  author: string;
  status: string;
  order_id: number;
}

export interface Order {
  id: number;
  ref: string;
  tiers: string;
  date_commande: number;
  date_livraison: string;
  total_ht: number;
  author: string;
  status: string;
  hasInvoice: boolean;
  invoiceId?: number;
  invoiceStatus?: string;
}

const useOrdersAndInvoicesData = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const DOLAPIKEY = localStorage.getItem('DOLAPIKEY');

    const fetchInvoices = async () => {
      try {
        const response = await fetch(
          'https://erp.ipercom.io/api/index.php/invoices?sortfield=t.rowid&sortorder=ASC&limit=100',
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              DOLAPIKEY: DOLAPIKEY || ''
            }
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        if (!Array.isArray(result)) {
          throw new Error('Invalid data format from API');
        }

        return result;
      } catch (error) {
        if (error instanceof Error) {
          console.error('Error fetching invoices:', error.message);
          throw error;
        } else {
          console.error('Unexpected error:', error);
          throw new Error('Unexpected error fetching invoices');
        }
      }
    };

    const fetchOrders = async () => {
      try {
        const response = await fetch(
          'https://erp.ipercom.io/api/index.php/orders?sortfield=t.rowid&sortorder=ASC&limit=100',
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              DOLAPIKEY: DOLAPIKEY || '',
            }
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        if (!Array.isArray(result)) {
          throw new Error('Invalid data format from API');
        }

        return result;
      } catch (error) {
        if (error instanceof Error) {
          console.error('Error fetching orders:', error.message);
          throw error;
        } else {
          console.error('Unexpected error:', error);
          throw new Error('Unexpected error fetching orders');
        }
      }
    };

    const fetchUserById = async (id: number) => {
      try {
        const response = await fetch(`https://erp.ipercom.io/api/index.php/users/${id}`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            DOLAPIKEY: DOLAPIKEY || '',
          }
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const user = await response.json();
        return { id: user.rowid, name: `${user.firstname} ${user.lastname}`, avatar: user.avatar };
      } catch (error) {
        console.error(`Error fetching user ${id}:`, error);
        throw error;
      }
    };

    const fetchThirdPartyById = async (id: number) => {
      try {
        const response = await fetch(`https://erp.ipercom.io/api/index.php/thirdparties/${id}`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            DOLAPIKEY: DOLAPIKEY || '',
          }
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const thirdParty = await response.json();
        return { id: thirdParty.id, name: thirdParty.name };
      } catch (error) {
        console.error(`Error fetching third party ${id}:`, error);
        throw error;
      }
    };

    const fetchData = async () => {
      try {
        const [invoices, orders] = await Promise.all([fetchInvoices(), fetchOrders()]);

        const usersMap: { [key: number]: Assignee } = {};
        const thirdPartiesMap: { [key: number]: string } = {};

        for (const invoice of invoices) {
          if (invoice.user_creation_id && !usersMap[invoice.user_creation_id]) {
            usersMap[invoice.user_creation_id] = await fetchUserById(invoice.user_creation_id);
          }
          if (invoice.socid && !thirdPartiesMap[invoice.socid]) {
            thirdPartiesMap[invoice.socid] = (await fetchThirdPartyById(invoice.socid)).name;
          }
        }

        for (const order of orders) {
          if (order.user_creation_id && !usersMap[order.user_creation_id]) {
            usersMap[order.user_creation_id] = await fetchUserById(order.user_creation_id);
          }
          if (order.socid && !thirdPartiesMap[order.socid]) {
            thirdPartiesMap[order.socid] = (await fetchThirdPartyById(order.socid)).name;
          }
        }

        const ordersWithDetails: Order[] = orders.map((order: any) => {
          const invoice = invoices.find((invoice: any) => invoice.order_id === order.id);
          return {
            id: Number(order.id),
            ref: order.ref,
            tiers: thirdPartiesMap[order.socid] || 'Unknown Company',
            date_commande: order.date_commande,
            date_livraison: order.delivery_date,
            total_ht: parseFloat(order.total_ht),
            author: usersMap[order.user_creation_id]?.name || 'Unknown Author',
            status: order.statut,
            hasInvoice: !!invoice,
            invoiceId: invoice ? invoice.id : null,
            invoiceStatus: invoice ? invoice.status : null
          };
        });

        const invoicesWithDetails: Invoice[] = invoices.map((invoice: any) => {
          return {
            id: Number(invoice.id),
            ref: invoice.ref,
            total_ht: parseFloat(invoice.total_ht),
            total_ttc: parseFloat(invoice.total_ttc),
            date_creation: invoice.date_creation,
            date_validation: invoice.date_validation,
            date_modification: invoice.date_modification,
            user_creation_id: invoice.user_creation_id,
            user_validation_id: invoice.user_validation_id,
            socid: invoice.socid,
            tiers: thirdPartiesMap[invoice.socid] || 'Unknown Company',
            author: usersMap[invoice.user_creation_id]?.name || 'Unknown Author',
            status: invoice.statut,
            order_id: invoice.order_id
          };
        });

        setOrders(ordersWithDetails);
        setInvoices(invoicesWithDetails);
      } catch (error) {
        setError('Unable to fetch data. Please try again later.');
      }
    };

    fetchData();
  }, []);

  return { orders, invoices, error };
};

export default useOrdersAndInvoicesData;
