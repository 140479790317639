import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';

const IperxParamRepository = () => {
  const annonces = [
    { titre: 'Non assignée', numero: '', nom: '', prenom: '' },
    { titre: 'Non assignée', numero: '', nom: '', prenom: '' },
    { titre: 'Non assignée', numero: '', nom: '', prenom: '' },


    // Add as many as needed
  ];

  const handleAddBLF = () => {
    // Logic to add new BLF (Busy Lamp Field)
  };

  return (
    <Card className="p-4 my-4">
      <Card.Title>BLF</Card.Title>
      {annonces.map((annonce, idx) => (
        <Row key={idx} className="mb-3">
          <Col>
            <Form.Label>Titre</Form.Label>
            <Form.Select defaultValue={annonce.titre}>
              <option>Non assignée</option>

            </Form.Select>
          </Col>
          <Col xs={12} md={3}>
            <Form.Label>Numéro</Form.Label>
            <Form.Control type="text" defaultValue={annonce.numero} />
            <Form.Label>Nom</Form.Label>
            <Form.Control type="text" defaultValue={annonce.nom} />
            <Form.Label>Prénom</Form.Label>
            <Form.Control type="text" defaultValue={annonce.prenom} />
          </Col>
        </Row>
      ))}
      <Col xs="auto">
        <Button variant="outline-primary" onClick={handleAddBLF}>
           <FontAwesomeIcon icon={faPlusCircle} className='me-3'></FontAwesomeIcon>Ajouter plus de BLF
        </Button>
      </Col>
    </Card>
  );
};

export default IperxParamRepository;
