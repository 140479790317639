import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import { OutboundRules } from "../../../data/iper-x/ipbx/outboundRules";
import RevealDropdown, { RevealDropdownTrigger } from "../../base/RevealDropdown";
import ActionDropdownItems from "../../common/ActionDropdownItems";

// Suppose InboundRules has been updated to reflect the new data structure
export const emergencyNumRulesTableColumns: ColumnDef<OutboundRules>[] = [
  // Colonne pour le nom de la règle
  {
    accessorKey: "ruleName",
    header: "Nom ",
    meta: {
      headerProps: { style: { width: "10%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },

  // Colonne pour longueur
  {
    accessorKey: "length",
    header: "Numéros",
    meta: {
      headerProps: { style: { width: "10%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },

  {
    accessorKey: "route1",
    header: "Route 1",
    meta: {
      headerProps: { style: { width: "8%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  // Colonne pour Route 2
  {
    accessorKey: "route2",
    header: "Route 2",
    meta: {
      headerProps: { style: { width: "8%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  // Colonne pour Route 3
  {
    accessorKey: "route3",
    header: "Route 3",
    meta: {
      headerProps: { style: { width: "8%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  // Colonne pour Route 4
  {
    accessorKey: "route4",
    header: "Route 4",
    meta: {
      headerProps: { style: { width: "8%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  // Colonne pour Route 5
  {
    accessorKey: "route5",
    header: "Route 5",
    meta: {
      headerProps: { style: { width: "8%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    id: 'leadDropdown',
    header: "Gérer",
    meta: {
      headerProps: { style: { width: "8%" } },
      cellProps: { className: "text-900 fs-9" }
    },
    cell: () => {
      return (
        <RevealDropdownTrigger>
          <RevealDropdown>
            <ActionDropdownItems />
          </RevealDropdown>
        </RevealDropdownTrigger>
      );
    },
  },

];

const EmergencyNumTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9" }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default EmergencyNumTable;
