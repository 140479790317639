
import React from "react";
import { Button, Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";

const ParamMailServerConfig = () => {
  return (
    <>
      <Card className="">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">

              <h3 className="fw-bolder mb-2 line-clamp-1">
                Paramètres
              </h3>
              <span className="me-3">Spécifiez le serveur mail à utiliser pour envoyer des messageries vocales, fax, notifications d'appels manqués et alertes.</span>


              <Form.Group className="mb-3" controlId="formServeurSMTP">
                <Form.Label>Type de serveur mail</Form.Label>
                <FloatingLabel controlId="floatingSelect" label="Serveur SMTP">
                  <Form.Select aria-label="Floating label select example">
                    <option>Serveur SMTP Iper-x</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>


              <Button variant="btn btn-white border border-1 px-2">
                <FontAwesomeIcon icon={faGear} className="me-2" />
                TEST
              </Button>

            </Col>

          </Row>
        </Card.Body>
      </Card>


    </>
  );
};

export default ParamMailServerConfig;
