import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Badge from "components/base/Badge";
import { GroupData } from "hooks/api/useGroup";
import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Modal, Form } from "react-bootstrap";

export const groupAPITableColumns: ColumnDef<GroupData>[] = [
  {
    accessorKey: "nameGroup",
    header: "Nom du groupe",
    meta: {
      headerProps: { style: { width: "10%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "ringStrategy",
    header: "Stratégie de sonnerie",
    meta: {
      headerProps: { style: { width: "15%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "internalUsers",
    header: "Utilisateurs internes",
    meta: {
      headerProps: { style: { width: "15%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "externalNumber",
    header: "N° externe",
    meta: {
      headerProps: { style: { width: "15%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "trunkExternalNumber",
    header: "Trunk n° externe",
    meta: {
      headerProps: { style: { width: "15%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "priorityExternalInternal",
    header: "Priorité externe/interne",
    meta: {
      headerProps: { style: { width: "15%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "ringDuration",
    header: "Durée de sonnerie",
    meta: {
      headerProps: { style: { width: "10%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "state",
    header: "État",
    cell: ({ row: { original } }) => {
      const { state } = original;
      return (
        <Badge
          bg={state ? "success" : "secondary"}
          variant="phoenix"
          iconPosition="end"
          className="fs-10"
        >
          {state ? "Actif" : "Inactif"}
        </Badge>
      );
    },
    meta: {
      headerProps: { style: { width: "10%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    id: 'leadDropdown',
    header: "Gérer",
    meta: {
      headerProps: { style: { fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    },
    cell: ({ row: { original } }) => {
      const [dropdownOpen, setDropdownOpen] = useState(false);
      const [showDeleteModal, setShowDeleteModal] = useState(false);
      const [showEditModal, setShowEditModal] = useState(false);
      const [selectedGroup, setSelectedGroup] = useState<GroupData | null>(null);
      const [formData, setFormData] = useState<GroupData | null>(null);

      const toggle = () => setDropdownOpen(!dropdownOpen);

      const handleDeleteClick = (group: GroupData) => {
        setSelectedGroup(group);
        setShowDeleteModal(true);
      };

      const handleEditClick = (group: GroupData) => {
        setSelectedGroup(group);
        setFormData(group);
        setShowEditModal(true);
      };

      const handleDeleteConfirm = async () => {
        if (selectedGroup) {
          await handleDelete(selectedGroup.id);
          setShowDeleteModal(false);
        }
      };

      const handleEditConfirm = async () => {
        if (formData) {
          await handleEdit(formData);
          setShowEditModal(false);
        }
      };

      const handleDelete = async (id: number) => {
        try {
          const response = await fetch(`https://rct-backend.ipercom.io/api/Ipbx/group/${id}`, {
            method: 'DELETE',
            headers: {
              'accept': 'text/plain',
            },
          });

          if (response.ok) {
            window.location.reload();
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        } catch (error) {
          console.error("Erreur lors de la suppression du groupe d'appels", error);
        }
      };

      const handleEdit = async (data: GroupData) => {
        try {
          const { id, ...dataWithoutId } = data;
          const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/group', {
            method: 'PUT',
            headers: {
              'accept': 'text/plain',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          });

          if (response.ok) {
            window.location.reload();
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        } catch (error) {
          console.error("Erreur lors de la mise à jour du groupe d'appels", error);
        }
      };

      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => prevData ? {
          ...prevData,
          [name]: type === 'checkbox' ? checked : value
        } : null);
      };

      return (
        <>
          <Dropdown show={dropdownOpen} onToggle={toggle} style={{ display: 'flex', justifyContent: 'center' }}>
            <DropdownToggle as="div" onClick={toggle} style={{ cursor: 'pointer' }}>
              Actions
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => handleEditClick(original)}>Modifier</DropdownItem>
              <DropdownItem onClick={() => handleDeleteClick(original)}>Supprimer</DropdownItem>
            </DropdownMenu>
          </Dropdown>

          <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Confirmation de suppression</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Êtes-vous sûr de vouloir supprimer ce groupe d'appels ?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                Annuler
              </Button>
              <Button variant="danger" onClick={handleDeleteConfirm}>
                Supprimer
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Modifier le groupe d'appels</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="nameGroup">
                  <Form.Label>Nom du groupe</Form.Label>
                  <Form.Control
                    type="text"
                    name="nameGroup"
                    value={formData?.nameGroup || ""}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="ringStrategy">
                  <Form.Label>Stratégie de sonnerie</Form.Label>
                  <Form.Control
                    as="select"
                    className="form-select"
                    name="ringStrategy"
                    value={formData?.ringStrategy || ""}
                    onChange={handleChange}
                    required
                  >
                    <option value="priority">Priorité</option>
                    <option value="all">Tous</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="internalUsers">
                  <Form.Label>Utilisateurs internes</Form.Label>
                  <Form.Control
                    type="text"
                    name="internalUsers"
                    value={formData?.internalUsers || ""}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="externalNumber">
                  <Form.Label>Numéro externe</Form.Label>
                  <Form.Control
                    type="text"
                    name="externalNumber"
                    value={formData?.externalNumber || ""}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="trunkExternalNumber">
                  <Form.Label>Trunk numéro externe</Form.Label>
                  <Form.Control
                    type="text"
                    name="trunkExternalNumber"
                    value={formData?.trunkExternalNumber || ""}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="priorityExternalInternal">
                  <Form.Label>Priorité externe/interne</Form.Label>
                  <Form.Control
                    type="text"
                    name="priorityExternalInternal"
                    value={formData?.priorityExternalInternal || ""}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="ringDuration">
                  <Form.Label>Durée de sonnerie</Form.Label>
                  <Form.Control
                    type="number"
                    name="ringDuration"
                    value={formData?.ringDuration || ""}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="state">
                  <Form.Label>État</Form.Label>
                  <Form.Check
                    type="checkbox"
                    name="state"
                    label="État"
                    checked={formData?.state || false}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Button variant="primary" type="submit" className="mt-2">
                  Ajouter
                </Button>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                Annuler
              </Button>
              <Button variant="primary" onClick={handleEditConfirm}>
                Enregistrer
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    }
  }
];

const GroupTableAPI = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9 text-center" }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default GroupTableAPI;
