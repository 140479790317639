import { useState, useCallback, useEffect } from "react";

export interface UserRole {
  id: number,
  name: string,
  slug: string
}

export interface Company {
  id: number,
  name: string,
  ceoName: string
}

export interface UserData {
  idUsers: number,
  firstname: string,
  lastname: string,
  mail: string,
  phone: string,
  mobile: string,
  avatar: string,
  userRole: UserRole,
  idErpCompanies: number,
  company: Company
}

const userTableData: UserData[] = [
  {
    idUsers: 1,
    firstname: "Stephane",
    lastname: "Degouve",
    mail: "sdegouve@ipercom.com",
    phone: "1234",
    mobile: "1234",
    avatar: "",
    userRole: {
      id: 1,
      name: "Administrateur General",
      slug: "admin"
    },
    idErpCompanies: 0,
    company: {
      id: 1,
      name: "Ipercom",
      ceoName: "Stephane Degouve"
    }
  }
];

const useEditorUser = () => {
  const [userData, setUserData] = useState(userTableData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    console.log("Fetching new API data...");
    try {
      const response = await fetch('https://rct-backend.ipercom.io/api/User', {
        method: 'GET',
        headers: {
          'accept': 'text/plain',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      // console.log("Fetched data: ", data);
      setUserData(data);
      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données de l'API", error);
      setError('Impossible de récupérer les données.');
      setUserData(userTableData);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    userData,
    setUserData,
    loading,
    error
  };
};

export default useEditorUser;
