import React, { useState } from 'react';
import { faArrowLeft, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Card, Row, Col, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { IperxDropdowCallingTaplabels } from 'data/social/IperxDropdowData';
import IperxCallingTapTools from './IperxCallingTapTools';

const IperxCallingTapPage = ({ className }: { className?: string }) => {


  return (
    <>
      <IperxCallingTapTools />
      <Col xs={12} className="text-center py-2" style={{position:'relative', top:'-100px'}}>
        <Link to="/iperx/utilisateurs/appel-waiting/:name" className="text-success">
          <FontAwesomeIcon icon={faPhoneAlt} className="text-success fa-2x" />
        </Link>
      </Col>
    </>
  );
};

export default IperxCallingTapPage;
