import React from "react";
import { Card, Col, Form, Row } from "react-bootstrap";

const continents = [
  "North America",
  "South America",
  "Europe",
  "Middle East",
  "Asia and the Middle East",
  "Africa",
  "Australia",
  "International"
];

const AllowedCountry = () => {

  return (
    <>
      <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
        <h2 className="mb-0">
          <span className="me-3">Codes de pays autorisés</span>
        </h2>
      </div>

      {continents.map((continent, index) => (
        <Card key={index} className="mb-5">
          <Card.Body>
            <Row className="align-items-center g-3">
              <Col sm="auto" className="flex-1">
                <Form.Check type="checkbox" id={`checkbox-${index}`} className="4">
                  <Form.Check.Input type="checkbox" />
                  <Form.Check.Label className="fs-8 fw-normal">
                    {continent}
                  </Form.Check.Label>
                </Form.Check>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ))}
    </>
  );
};

export default AllowedCountry;
