import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';

import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import { usersIperxParamTAexceptionsDataType } from 'data/iper-x/ipbx/usersIperx';
import { Link } from 'react-router-dom';

export const usersIperxParamTAexceptionsColumn: ColumnDef<usersIperxParamTAexceptionsDataType>[] = [
  {
    accessorKey: 'user.name',
    header: 'Nom de la personne',
    cell: ({ row: { original } }) => {
      const {
        user: { name}
      } = original;
        return (
          <Link
            to={`/iperx/utilisateurs/details/${name}`}
            className="d-flex align-items-center text-1000"
          >
            <h6 className="mb-0 text-1000 fw-bold">{name}</h6>
          </Link>
        );
      
    },
    meta: {
      headerProps: {
        style: { width: '30%', minWidth: '100px' },
        className: 'pe-3 ps-0'
      },
      cellProps: { className: 'white-space-nowrap py-2 ps-0' }
    }
  },
  {
    accessorKey: 'extension',
    header: 'Extention',
    cell: ({ row: { original } }) => {
      return (
        <div className="d-flex align-items-center flex-1">
          <span className="fw-bold fs-9 text-900">{original.extension}</span>
        </div>
      );
    },
    meta: {
      headerProps: {
        style: { width: '30%', minWidth: '115px' },
        className: 'ps-0 text-start'
      },
      cellProps: {
        className: 'white-space-nowrap fw-semi-bold text-1000'
      }
    }
  },
  {
    accessorKey: 'statusTraitement',
    header: 'Statuts de traitement',
    cell: ({ row: { original } }) => {
      return (
        <div className="d-flex align-items-center flex-1">
          <span className="fw-bold fs-9 text-900">{original.statusTraitement}</span>
        </div>
      );
    },
    meta: {
      headerProps: {
        style: { width: '30%', minWidth: '115px' },
        className: 'ps-0 text-start'
      },
      cellProps: {
        className: 'white-space-nowrap fw-semi-bold text-1000'
      }
    }
  },
  
  
  {
    id: 'dealDropdown',
    accessorKey: 'à qui',
    cell: ({ row: { original } }) => {
      const {
        user: { name }
      } = original;
      return (
        <div className="d-flex align-items-center justify-content-center flex-1">
           <span className="fw-bold fs-9 text-900">{original.who}</span>
          <RevealDropdownTrigger>
            <RevealDropdown>
            <Link to={`/iperx/utilisateurs/details/${name}`} className="d-flex align-items-center text-1000 m-1">
         Modifier
        </Link>
            </RevealDropdown>
          </RevealDropdownTrigger>
        </div>
      );
    },
    meta: {
      headerProps: { style: { width: '10%' }, className: 'text-center' },
      cellProps: { className: 'pe-0 py-2' }
    }
  }
];

const IperxParamTAexceptionsTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9" }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default IperxParamTAexceptionsTable;
