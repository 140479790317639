import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import RevealDropdown, {
  RevealDropdownTrigger
} from '../../base/RevealDropdown';
import ActionDropdownItems from '../../common/ActionDropdownItems';
import { HoldMusicPlaylist } from '../../../data/iper-x/ipbx/holdMusicPlaylist';
import { CRMIntegration } from "../../../data/iper-x/ipbx/crmIntegrationsInstalled";

export const crmIntegrationsInstalledTableColumns: ColumnDef<CRMIntegration>[] =
  [
    {
      accessorKey: 'name',
      header: 'Nom du plugin',
      meta: {
        headerProps: { style: { width: '60%' } },
        cellProps: { className: 'text-900 fs-9' }
      }
    },
    {
      accessorKey: 'version',
      header: 'Version',
      meta: {
        headerProps: { style: { width: '40%' } },
        cellProps: { className: 'text-900 fs-9' }
      }
    },
    {
      id: 'leadDropdown',
      header: 'Gérer',
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-900 fs-9' }
      },
      cell: () => {
        return (
          <RevealDropdownTrigger>
            <RevealDropdown>
              <ActionDropdownItems />
            </RevealDropdown>
          </RevealDropdownTrigger>
        );
      }
    }
  ];

const CRMIntegrationsInstalledTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default CRMIntegrationsInstalledTable;
