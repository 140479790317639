export interface Devices {
  ext: string;
  manufacturer: string;
  model: string;
  firmware_version: string;
  name: string;
  ip: string;
  mac: string;
}

export const devicesTableData: Devices[] = [
  {
    ext: "10",
    manufacturer: "Fanvil",
    model: "X3U",
    firmware_version: "2.4.12",
    name: "Office",
    ip: "192.168.1.155:5060 via SBC sbcfrenchburgers",
    mac: "0C383E306D5C"
  },
  {
    ext: "10",
    manufacturer: "Fanvil",
    model: "X3U",
    firmware_version: "2.4.12",
    name: "Office",
    ip: "192.168.1.155:5060 via SBC sbcfrenchburgers",
    mac: "0C383E306D5C"
  },
  {
    ext: "10",
    manufacturer: "Fanvil",
    model: "X3U",
    firmware_version: "2.4.12",
    name: "Office",
    ip: "192.168.1.155:5060 via SBC sbcfrenchburgers",
    mac: "0C383E306D5C"
  },
  {
    ext: "10",
    manufacturer: "Fanvil",
    model: "X3U",
    firmware_version: "2.4.12",
    name: "Office",
    ip: "192.168.1.155:5060 via SBC sbcfrenchburgers",
    mac: "0C383E306D5C"
  },
  {
    ext: "10",
    manufacturer: "Fanvil",
    model: "X3U",
    firmware_version: "2.4.12",
    name: "Office",
    ip: "192.168.1.155:5060 via SBC sbcfrenchburgers",
    mac: "0C383E306D5C"
  },
  {
    ext: "10",
    manufacturer: "Fanvil",
    model: "X3U",
    firmware_version: "2.4.12",
    name: "Office",
    ip: "192.168.1.155:5060 via SBC sbcfrenchburgers",
    mac: "0C383E306D5C"
  },
  {
    ext: "10",
    manufacturer: "Fanvil",
    model: "X3U",
    firmware_version: "2.4.12",
    name: "Office",
    ip: "192.168.1.155:5060 via SBC sbcfrenchburgers",
    mac: "0C383E306D5C"
  },
  {
    ext: "10",
    manufacturer: "Fanvil",
    model: "X3U",
    firmware_version: "2.4.12",
    name: "Office",
    ip: "192.168.1.155:5060 via SBC sbcfrenchburgers",
    mac: "0C383E306D5C"
  },
  {
    ext: "10",
    manufacturer: "Fanvil",
    model: "X3U",
    firmware_version: "2.4.12",
    name: "Office",
    ip: "192.168.1.155:5060 via SBC sbcfrenchburgers",
    mac: "0C383E306D5C"
  },
  {
    ext: "10",
    manufacturer: "Fanvil",
    model: "X3U",
    firmware_version: "2.4.12",
    name: "Office",
    ip: "192.168.1.155:5060 via SBC sbcfrenchburgers",
    mac: "0C383E306D5C"
  },
  {
    ext: "10",
    manufacturer: "Fanvil",
    model: "X3U",
    firmware_version: "2.4.12",
    name: "Office",
    ip: "192.168.1.155:5060 via SBC sbcfrenchburgers",
    mac: "0C383E306D5C"
  },
  {
    ext: "10",
    manufacturer: "Fanvil",
    model: "X3U",
    firmware_version: "2.4.12",
    name: "Office",
    ip: "192.168.1.155:5060 via SBC sbcfrenchburgers",
    mac: "0C383E306D5C"
  },
  {
    ext: "10",
    manufacturer: "Fanvil",
    model: "X3U",
    firmware_version: "2.4.12",
    name: "Office",
    ip: "192.168.1.155:5060 via SBC sbcfrenchburgers",
    mac: "0C383E306D5C"
  }

];
