import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import LeadAddressCard from 'pages/apps/retailer/detail/LeadAddressCard';
import PageBreadcrumb, {
  PageBreadcrumbItem
} from 'components/common/PageBreadcrumb';
import { Col, Dropdown, Row } from 'react-bootstrap';
import React, { useState } from 'react';
import LeadDetailsOffcanvas from 'pages/apps/editor/detail_retailer/LeadDetailsOffcanvas';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { dealDetailsInfoData, stats } from 'data/crm/dealDetailsInfo';
import UserDetailsInfo from './UserDetailsInfo';
import RetailerProfileCard from './RetailerProfileCard';
import DealsPrintingDimensionsCard from './DealsPrintingDimensionsCard';
import AboutRetailerCard from './AboutRetailerCard';
import ScrollSpy from '../../../../components/base/ScrollSpy';
import Tasks from '../../../../components/modules/crm/Tasks';
import LeadDeals from '../../../../components/modules/crm/LeadDeals';
import LeadEmails from '../../../../components/modules/crm/LeadEmails';

export const leadsBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Portail Revendeur',
    url: '/portail-revendeur'
  },
  {
    label: 'Détail Revendeur',
    active: true
  }
];
const RetailerDetails = () => {
  const [openOffcanvas, setOpenOffcanvas] = useState(false);

  return (
    <div>
      <PageBreadcrumb items={leadsBreadcrumbItems} />
      <div className="pb-9">
        <Row className="align-items-center justify-content-between g-3 mb-3">
          <Col xs={12} md="auto">
            <h2 className="mb-0">Détail Revendeur</h2>
          </Col>
          <Col xs={12} md="auto">
            <div className="d-flex gap-2">
              <Button variant="phoenix-secondary" className="px-3 px-sm-5">
                <FontAwesomeIcon icon={faEdit} className="me-0 me-sm-2" />
                <span className="d-none d-sm-inline">Modifier</span>
              </Button>
            </div>
          </Col>
        </Row>

        <Row className="g-0 g-md-4 g-xl-6">
          <Col md={5} xl={4} className="d-none d-md-block">
            <div className="sticky-leads-sidebar">
              <div className="lead-details-offcanvas bg-soft scrollbar">
                <div className="d-flex justify-content-between align-items-center mb-2 d-md-none">
                  <h3 className="mb-0">Détails du lead</h3>
                </div>
                <RetailerProfileCard className="mb-3" />

                <LeadAddressCard />
              </div>
            </div>
          </Col>
          <Col md={7} xl={8}>
            <div className="lead-details-container">
              <AboutRetailerCard className="mb-3" />
              <DealsPrintingDimensionsCard stats={stats} className="mb-9" />
              {/* <UserDetailsInfo data={dealDetailsInfoData} className="mb-7" /> */}
              <ScrollSpy>
                {/*<LeadDetailsNavbar />*/}

                <ScrollSpy.Content
                  id="leads"
                  className="lead-details-scrollspy mb-8"
                  minTopValue={150}
                >
                  <LeadDeals />
                </ScrollSpy.Content>

                <ScrollSpy.Content
                  id="emails"
                  className="lead-details-scrollspy mb-8"
                >
                  <LeadEmails />
                </ScrollSpy.Content>
              </ScrollSpy>
            </div>
          </Col>
        </Row>
      </div>
      <LeadDetailsOffcanvas open={openOffcanvas} setOpen={setOpenOffcanvas} />
    </div>
  );
};

export default RetailerDetails;
