import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { Button, Form, Alert, Col, Row, Card, Container, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

interface Order {
  socid: string;
  date: string;
  items: OrderLine[];
}

interface OrderLine {
  fk_product: string;
  product_name: string;
  qty: string;
  subprice: string;
  tva_tx: string;
  licenses: string[];
}

interface Product {
  id: string;
  label: string;
  price: string;
  type: 'product' | 'service';
}

interface Category {
  id: string;
  label: string;
}

const OrderCreate: React.FC = () => {
  const navigate = useNavigate();
  const DOLID = localStorage.getItem('DOLID') || '';
  const [order, setOrder] = useState<Order>({
    socid: DOLID,
    date: new Date().toISOString().split('T')[0],
    items: []
  });
  const [currentItem, setCurrentItem] = useState<OrderLine>({
    fk_product: '',
    product_name: '',
    qty: '1',
    subprice: '',
    tva_tx: '20',
    licenses: []
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [family, setFamily] = useState<string>('IPERX');
  const [simultaneousCalls, setSimultaneousCalls] = useState<string>('4AS');
  const [years, setYears] = useState<string>('5');
  const [itemType, setItemType] = useState<'product' | 'service'>('service');
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    filterItems();
  }, [family, simultaneousCalls, years, categories, itemType]);

  const fetchCategories = async () => {
    try {
      const response = await fetch(
        'https://erp.ipercom.io/api/index.php/categories',
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            DOLAPIKEY: '6VMZqb2oUCF1w9TtZKi4uTn04Ez40ow4'
          }
        }
      );
      const data = await response.json();
      setCategories(data.map((category: any) => ({ id: category.id, label: category.label })));
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchProducts = async (categoryId: string) => {
    try {
      const response = await fetch(
        `https://erp.ipercom.io/api/index.php/products?sortfield=t.ref&sortorder=ASC&limit=100&mode=2&category=${categoryId}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            DOLAPIKEY: '6VMZqb2oUCF1w9TtZKi4uTn04Ez40ow4'
          }
        }
      );
      const data = await response.json();
      const fetchedProducts = data.map((product: any) => ({
        id: product.id,
        label: product.label,
        price: product.price,
        type: product.type
      }));
      setFilteredProducts(fetchedProducts);

      if (fetchedProducts.length === 1) {
        setCurrentItem(prevItem => ({
          ...prevItem,
          fk_product: fetchedProducts[0].id,
          product_name: fetchedProducts[0].label,
          subprice: fetchedProducts[0].price
        }));
      }
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const filterItems = () => {
    const categoryFilter = `${family}${simultaneousCalls}${years}`;
    const category = categories.find(cat => cat.label.includes(categoryFilter));
    if (category) {
      fetchProducts(category.id);
    } else {
      setFilteredProducts([]);
      setCurrentItem(prevItem => ({
        ...prevItem,
        fk_product: '',
        product_name: '',
        subprice: ''
      }));
    }
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    index?: number
  ) => {
    const { name, value } = e.target;
    if (index === undefined) {
      setOrder(prevOrder => ({ ...prevOrder, [name]: value }));
    } else {
      const updatedItems = [...order.items];
      updatedItems[index] = { ...updatedItems[index], [name]: value };
      setOrder(prevOrder => ({ ...prevOrder, items: updatedItems }));
    }

    if (name === 'fk_product') {
      const selectedProduct = filteredProducts.find(product => product.id === value);
      if (selectedProduct) {
        setCurrentItem(prevItem => ({
          ...prevItem,
          fk_product: selectedProduct.id,
          product_name: selectedProduct.label,
          subprice: selectedProduct.price
        }));
      }
    } else if (name === 'qty') {
      const licenses = generateLicenses(parseInt(value));
      setCurrentItem(prevItem => ({ ...prevItem, [name]: value, licenses }));
    } else {
      setCurrentItem(prevItem => ({ ...prevItem, [name]: value }));
    }
  };

  const generateLicenses = (quantity: number) => {
    const licenses = [];
    for (let i = 0; i < quantity; i++) {
      const segment1 = Math.random().toString(36).substring(2, 6).toUpperCase();
      const segment2 = Math.random().toString(36).substring(2, 6).toUpperCase();
      const segment3 = Math.random().toString(36).substring(2, 6).toUpperCase();
      const segment4 = Math.random().toString(36).substring(2, 6).toUpperCase();
      licenses.push([segment1, segment2, segment3, segment4].join('-'));
    }
    return licenses;
  };

  const handleFamilyChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setFamily(e.target.value);
  };

  const handleSimultaneousCallsChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSimultaneousCalls(e.target.value);
  };

  const handleYearsChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setYears(e.target.value);
  };

  const handleAcceptTermsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAcceptedTerms(e.target.checked);
  };

  const handleItemTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setItemType(e.target.value as 'product' | 'service');
    setCurrentItem({
      fk_product: '',
      product_name: '',
      qty: '1',
      subprice: '',
      tva_tx: '20',
      licenses: []
    });
  };

  const addItemToOrder = () => {
    if (!currentItem.fk_product) {
      setError('Vous devez sélectionner un Produit/Service avant de l’ajouter.');
      return;
    }
    if (parseInt(currentItem.qty) <= 0) {
      setError('La quantité doit être supérieure à 0 pour ajouter un élément.');
      return;
    }
    const licenses = generateLicenses(parseInt(currentItem.qty));
    setCurrentItem(prevItem => ({ ...prevItem, licenses }));

    setOrder(prevOrder => ({ ...prevOrder, items: [...prevOrder.items, { ...currentItem }] }));

    setCurrentItem(prevItem => ({ ...prevItem, qty: '1', licenses: [] }));
    setError(null);
  };

  const removeItem = (index: number) => {
    setOrder(prevOrder => ({
      ...prevOrder,
      items: prevOrder.items.filter((_, i) => i !== index)
    }));
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!acceptedTerms) {
      setError('Vous devez accepter les conditions générales de vente.');
      return;
    }

    if (order.items.length === 0) {
      setError('Vous devez ajouter au moins un élément avant de valider la commande.');
      return;
    }

    setLoading(true);

    const updatedLines = order.items.map(item => ({
      ...item,
      array_options: {
        options_licences: item.licenses.join(', ')
      }
    }));

    const DOLAPIKEY = localStorage.getItem('DOLAPIKEY');
    try {
      // Step 1: Create the order
      const response = await fetch(
        'https://erp.ipercom.io/api/index.php/orders',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            DOLAPIKEY: DOLAPIKEY || '',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            socid: order.socid,
            date: new Date(order.date).getTime() / 1000,
            lines: updatedLines,
            status: '1'
          })
        }
      );

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(`Erreur: ${errorResponse.error || "Une erreur s'est produite lors de la création de la commande."}`);
      }

      const orderData = await response.json();
      const orderId = orderData;

      // Step 2: Validate the order
      const validateOrderResponse = await fetch(`https://erp.ipercom.io/api/index.php/orders/${orderId}/validate`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          DOLAPIKEY: DOLAPIKEY || ''
        }
      });

      if (!validateOrderResponse.ok) {
        const validateOrderErrorResponse = await validateOrderResponse.json();
        throw new Error(`Erreur: ${validateOrderErrorResponse.error || "Une erreur s'est produite lors de la validation de la commande."}`);
      }

      // Step 3: Create the invoice
      const invoiceData = {
        socid: order.socid,
        date: Math.floor(Date.now() / 1000),
        type: 0, // Assuming 0 is the type for normal invoices
        lines: updatedLines,
        array_options: {
          options_ref_commande: orderId
        }
      };

      const invoiceResponse = await fetch('https://erp.ipercom.io/api/index.php/invoices', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'DOLAPIKEY': DOLAPIKEY || ''
        },
        body: JSON.stringify(invoiceData)
      });

      if (!invoiceResponse.ok) {
        const invoiceErrorResponse = await invoiceResponse.json();
        throw new Error(`Erreur: ${invoiceErrorResponse.error || "Erreur de création de la facture."}`);
      }

      const invoiceResult = await invoiceResponse.json();
      const invoiceId = invoiceResult;

      // Step 4: Validate the invoice
      const validateInvoiceResponse = await fetch(`https://erp.ipercom.io/api/index.php/invoices/${invoiceId}/validate`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          DOLAPIKEY: DOLAPIKEY || ''
        }
      });

      if (!validateInvoiceResponse.ok) {
        const validateInvoiceErrorResponse = await validateInvoiceResponse.json();
        throw new Error(`Erreur: ${validateInvoiceErrorResponse.error || "Erreur de validation de la facture."}`);
      }

      setSuccess('Commande et facture créées et validées avec succès.');
      setError(null);

      navigate(`/portail-revendeur/commandes/details/${orderId}`);
      setOrder({
        socid: DOLID,
        date: new Date().toISOString().split('T')[0],
        items: []
      });
      setAcceptedTerms(false);
    } catch (err) {
      if (err instanceof Error) {
        setError(`Erreur: ${err.message}`);
      } else {
        setError('Erreur inconnue');
      }
      setSuccess(null);
    } finally {
      setLoading(false);
    }
  };


  return (
    <Container className="mt-5">
      <h2>Ajouter une commande</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      <Form onSubmit={onSubmit}>
        <Card className="mb-3">
          <Card.Header>
            <h5>Ajouter un élément</h5>
          </Card.Header>
          <Card.Body>
            <Form.Group controlId="itemType">
              <Form.Label>Type d'élément</Form.Label>
              <Form.Control
                as="select"
                className='form-select'
                name="itemType"
                value={itemType}
                onChange={handleItemTypeChange}
              >
                <option value="product" disabled>Produit</option>
                <option value="service">Service</option>
              </Form.Control>
            </Form.Group>
            {itemType === 'service' && (
              <>
                <Row>
                  <Col md={4}>
                    <Form.Group controlId="family">
                      <Form.Label>Famille</Form.Label>
                      <Form.Control
                        as="select"
                        className='form-select'
                        name="family"
                        value={family}
                        onChange={handleFamilyChange}
                      >
                        <option value="IPERX">IPERX</option>
                        <option value="IPERCLOUD" disabled>
                          IPERCLOUD
                        </option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="simultaneousCalls">
                      <Form.Label>Appels Simultanés</Form.Label>
                      <Form.Control
                        as="select"
                        className='form-select'
                        name="simultaneousCalls"
                        value={simultaneousCalls}
                        onChange={handleSimultaneousCallsChange}
                      >
                        {[
                          '4',
                          '8',
                          '16',
                          '24',
                          '32',
                          '40',
                          '48',
                          '50',
                          '100',
                          '150',
                          '200',
                          '250',
                          '300',
                          '350',
                          '400',
                          '450',
                          '500',
                          '550',
                          '600',
                          '650',
                          '700',
                          '750',
                          '800',
                          '850',
                          '900',
                          '950',
                          '1000'
                        ].map(call => (
                          <option key={call} value={`${call}AS`}>
                            {call} accès simultanés
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="years">
                      <Form.Label>Années</Form.Label>
                      <Form.Control
                        as="select"
                        className='form-select'
                        name="years"
                        value={years}
                        onChange={handleYearsChange}
                      >
                        <option value="1">1 an</option>
                        <option value="2">2 ans</option>
                        <option value="3">3 ans</option>
                        <option value="4">4 ans</option>
                        <option value="5">5 ans</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}
            <Form.Group controlId="fk_product">
              <Form.Label>Produit/Service</Form.Label>
              <Form.Control
                as="select"
                name="fk_product"
                value={currentItem.fk_product}
                onChange={handleChange}
                required
                disabled
              >
                <option value="">Sélectionnez un produit/service</option>
                {filteredProducts.map(product => (
                  <option key={product.id} value={product.id}>
                    {product.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Row>
              <Col md={4}>
                <Form.Group controlId="qty">
                  <Form.Label>Quantité</Form.Label>
                  <Form.Control
                    type="number"
                    name="qty"
                    value={currentItem.qty}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="subprice">
                  <Form.Label>Prix unitaire</Form.Label>
                  <Form.Control
                    type="number"
                    name="subprice"
                    value={currentItem.subprice}
                    onChange={handleChange}
                    required
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="tva_tx">
                  <Form.Label>TVA (%)</Form.Label>
                  <Form.Control
                    type="number"
                    name="tva_tx"
                    value={currentItem.tva_tx}
                    onChange={handleChange}
                    required
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>

            <Button variant="success" onClick={addItemToOrder} className="mt-3">
              Ajouter un service ou produit
            </Button>
          </Card.Body>
        </Card>

        <Card className="mb-3">
          <Card.Header>
            <h5>Service ou Produit ajoutés</h5>
          </Card.Header>
          <Card.Body>
            <Table striped bordered hover>
              <thead>
              <tr>
                <th>Produit/Service</th>
                <th>Quantité</th>
                <th>Prix unitaire</th>
                <th>TVA (%)</th>
                <th>Total HT</th>
                {/*<th>Licences</th>*/}
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              {order.items.map((item, index) => (
                <tr key={index}>
                  <td>{item.product_name || 'N/A'}</td>
                  <td>{item.qty}</td>
                  <td>{item.subprice}</td>
                  <td>{item.tva_tx}</td>
                  <td>{(parseFloat(item.subprice) * parseFloat(item.qty)).toFixed(2)}</td>
                  {/*<td>{item.licenses.join(', ')}</td>*/}
                  <td>
                    <Button variant="danger" onClick={() => removeItem(index)}>
                      Supprimer
                    </Button>
                  </td>
                </tr>
              ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>

        <Form.Group controlId="acceptedTerms">
          <Form.Check
            type="checkbox"
            label="Accepter les conditions générales de vente"
            checked={acceptedTerms}
            onChange={handleAcceptTermsChange}
            required
          />
        </Form.Group>

        {loading ? (
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Chargement...</span>
            </div>
          </div>
        ) : (
          <Button
            variant="primary"
            type="submit"
            className="mt-4"
            disabled={!acceptedTerms || loading || order.items.length === 0} // Désactive si aucun élément n'est ajouté
          >
            Valider
          </Button>

        )}

      </Form>
    </Container>
  );
};

export default OrderCreate;
