import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';

import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';

import { Link } from 'react-router-dom';

import React from 'react';

import { RetailerClientDataType } from '../../../../data/iper-x/retailer/retailerClientsTableData';
import Avatar from '../../../base/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

export const retailerClientsTableColumns: ColumnDef<RetailerClientDataType>[] =
  [
    {
      accessorKey: 'company',
      header: 'Société',
      cell: ({ row: { original } }) => {
        const {
          name,
          status: { label = 'Unknown', type = 'danger' } = {} // Default to 'secondary' if status is missing
        } = original.company || {};

        return (
          <div className="d-flex align-items-center">
            <div>
              <div className="d-flex justify-content-center">
                {/*<Avatar src="..." size="s" className="me-3" />*/}
                <FontAwesomeIcon
                  transform="shrink-1 down-3"
                  icon={faCircle}
                  className={`me-2 d-inline-block text-${type}`} // Apply status type or default class
                />
                <Link to="/ipbx/dashboard" className="fs-8 fw-bold">
                  {name}
                </Link>
              </div>
            </div>
          </div>
        );
      },
      meta: {
        headerProps: {
          style: { width: '25%' },
          className: 'ps-4 pe-5 border-end'
        },
        cellProps: {
          className: 'white-space-nowrap ps-4 border-end fw-semi-bold text-1000'
        }
      }
    },

    {
      accessorKey: 'product',
      header: 'Produit',
      cell: ({ row: { original } }) => {
        const { name } = original.product;
        return (
          <div className="d-flex align-items-center">
            <p className="text-700 mb-0">
              <p className="mb-0">{name}</p>

              <Link to="#" className="fs-8 fw-bold">
                Augmenter la capacité
              </Link>
            </p>
          </div>
        );
      },
      meta: {
        headerProps: {
          style: { width: '25%' },
          className: 'ps-4 pe-5 border-end'
        },
        cellProps: {
          className: 'white-space-nowrap ps-4 border-end fw-semi-bold text-1000'
        }
      }
    },

    {
      accessorKey: 'responsible',
      header: 'Responsable',
      cell: ({ row: { original } }) => {
        const { name, functionName } = original.responsible;
        return (
          <div className="d-flex align-items-center">
            <p className="text-700 mb-0">
              <p className="mb-0">{name}</p>
              <p className="mb-0">{functionName}</p>
            </p>
          </div>
        );
      },
      meta: {
        headerProps: {
          style: { width: '15%' },
          className: 'ps-4 pe-5 border-end'
        },
        cellProps: {
          className: 'white-space-nowrap ps-4 border-end fw-semi-bold text-1000'
        }
      }
    },

    {
      accessorKey: 'license_number',
      header: 'Numéro de licence',
      cell: ({ row: { original } }) => original.licence,
      meta: {
        headerProps: {
          style: { width: '15%' },
          className: 'ps-4 pe-5 border-end'
        },
        cellProps: {
          className: 'white-space-nowrap ps-4 border-end fw-semi-bold text-1000'
        }
      }
    },

    {
      accessorKey: 'type',
      header: 'Type',
      cell: ({ row: { original } }) => {
        const { name } = original.type;
        return (
          <div className="d-flex align-items-center">
            <p className="text-700 mb-0">
              <p className="mb-0">
                <b>{name}</b>
              </p>
              <p className="mb-0">{name}</p>
            </p>
          </div>
        );
      },
      meta: {
        headerProps: {
          style: { width: '15%' },
          className: 'ps-4 pe-5 border-end'
        },
        cellProps: {
          className: 'white-space-nowrap ps-4 border-end fw-semi-bold text-1000'
        }
      }
    },

    {
      accessorKey: 'date',
      header: 'Date de création',
      cell: ({ row: { original } }) => original.date,
      meta: {
        headerProps: {
          style: { width: '15%' },
          className: 'ps-4 pe-5 border-end'
        },
        cellProps: {
          className: 'white-space-nowrap ps-4 border-end fw-semi-bold text-1000'
        }
      }
    },

    {
      accessorKey: 'date_exp',
      header: 'Expiration',
      cell: ({ row: { original } }) => {
        const { date, status } = original.expiration;
        return (
          <div className="d-flex align-items-center">
            <p className="text-700 mb-0">
              <p className="mb-0">
                <b>{date}</b>
              </p>
              <p className="mb-0">{status}</p>
            </p>
          </div>
        );
      },
      meta: {
        headerProps: {
          style: { width: '15%' },
          className: 'ps-4 pe-5 border-end'
        },
        cellProps: {
          className: 'white-space-nowrap ps-4 border-end fw-semi-bold text-1000'
        }
      }
    },

    {
      id: 'leadDropdown',
      header: 'Administrer',
      cell: () => {
        return (
          <RevealDropdownTrigger>
            <RevealDropdown>
              <ActionDropdownItems />
            </RevealDropdown>
          </RevealDropdownTrigger>
        );
      },
      meta: {
        cellProps: {
          className: 'text-end pe-0 ps-4'
        }
      }
    }
  ];

const RetailerClientsTable = () => {
  return (
    <div className="border-top">
      <AdvanceTable
        tableProps={{ className: 'phoenix-table fs-9' }}
        rowClassName="hover-actions-trigger btn-reveal-trigger"
      />
      <AdvanceTableFooter pagination className="py-4" />
    </div>
  );
};

export default RetailerClientsTable;
