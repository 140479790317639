import React, { useState, ChangeEvent } from 'react';
import { Col, Row, Form, Modal } from 'react-bootstrap';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { retailerOrdersAndInvoicesBreadcrumbItems } from 'data/breadcrumbItem';
import {
  faFileExport,
  faFileImport,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import InvoicesTable, { invoicesTableColumns } from 'components/tables/iper-x/retailer/InvoicesTable';

import {
  exportOptionsInitialState
} from './OrderListUtils';  // Adjusted to keep only export-related logic
import ExportModal from './ExportModal';
import useInvoicesData from 'hooks/dolibarr/useInvoicesData';
import InvoiceCreateModal from './InvoiceCreateModal';  // Assuming Invoice creation is relevant here

const InvoicesList: React.FC = () => {
  const { invoices, error } = useInvoicesData();  // Fetch invoice data

  const table = useAdvanceTable({
    data: invoices,
    columns: invoicesTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);  // Filter invoices by search input
  };

  const [showExportModal, setShowExportModal] = useState(false);
  const toggleExportModal = () => setShowExportModal(!showExportModal);

  const [exportOptions, setExportOptions] = useState(exportOptionsInitialState);

  const handleExport = () => {
    toggleExportModal();  // Show export modal when exporting invoices
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setExportOptions(prevState => ({
      ...prevState,
      [name]: checked
    }));
  };

  const [showAddInvoiceModal, setShowAddInvoiceModal] = useState(false);
  const toggleAddInvoiceModal = () => setShowAddInvoiceModal(!showAddInvoiceModal);

  return (
    <>
      <div>
        <Row className="gy-3 mb-6 justify-content-between">
          <Col md={9} xs="auto">
            <PageBreadcrumb items={retailerOrdersAndInvoicesBreadcrumbItems} />
            <h2 className="mb-2 text-1100">Factures</h2>
          </Col>
        </Row>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex flex-wrap gap-3">
              <SearchBox
                placeholder="Rechercher des factures"
                onChange={handleSearchInputChange}
              />
              <div className="ms-xxl-auto">
                <Button variant="link" className="text-900 me-4 px-0">
                  <FontAwesomeIcon icon={faFileImport} className="fs-9 me-2" />
                  Importer
                </Button>
                <Button
                  variant="link"
                  className="text-900 me-4 px-0"
                  onClick={toggleExportModal}
                >
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Exporter
                </Button>
                <Button variant="primary" href={"/portail-revendeur/commandes/ajouter"}>
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Ajouter une commande
                </Button>
                <Button variant="primary" onClick={toggleAddInvoiceModal}>
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Ajouter une facture
                </Button>
              </div>
            </div>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <InvoicesTable />
          </div>
        </AdvanceTableProvider>

        <ExportModal
          show={showExportModal}
          handleClose={toggleExportModal}
          handleCheckboxChange={handleCheckboxChange}
          handleExport={handleExport}
          exportOptions={exportOptions}
        />
        <InvoiceCreateModal
          show={showAddInvoiceModal}
          handleClose={toggleAddInvoiceModal}
        />
      </div>
    </>
  );
};

export default InvoicesList;
