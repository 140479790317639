import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import IperxCallingTapTools from 'pages/apps/webrtc/IperxCall/IperxCallingTapTools';
import { Link } from 'react-router-dom';

interface Props {
  show: boolean;
  handleClose: () => void;
}

const IperxCallingConferenceModal: React.FC<Props> = ({ show, handleClose }) => {
  

  return (
    <Modal show={show} onHide={handleClose} centered size="lg" dialogClassName="modal-80w">
      <Modal.Header closeButton>
        <Modal.Title>Ajouter à l'appel</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <IperxCallingTapTools />
        <Link to="/iperx/utilisateurs/appel/conference"style={{ position: 'relative', fontSize: 'larger', left: '298px', top: '-80px'}}>
          <FontAwesomeIcon icon={faPlusCircle}  className='me-3' />Ajouter
        </Link>
      </Modal.Body>
    </Modal>
  );
};

export default IperxCallingConferenceModal;
