import React, { useState } from 'react';
import { faClock, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Row, Col } from 'react-bootstrap';
import IperxMeetingCreateForm from './IperxMeetingCreateForm';
import IperxMeetingPlanMeetingForm from './IperxMeetingPlanMeetingForm';
import IperxMeetingParticipateForm from './IperxMeetingParticipateForm';
import Calendar from './calendar/Calendar';

const IperxMeetingPage = () => {
    const [selectedCard, setSelectedCard] = useState('create');
    const [showCreateForm, setShowCreateForm] = useState(true);

    const handleCreateClick = () => {
        setSelectedCard('create');
        setShowCreateForm(true);
    };

    const handlePlanClick = () => {
        setSelectedCard('plan');
        setShowCreateForm(false);
    };

    const handlePartClick = () => {
        setSelectedCard('participate');
        setShowCreateForm(false);
    };

    const handleCalendarClick = () => {
        setSelectedCard('calendar');
        setShowCreateForm(false);
    };

    return (
        <>
            <section>
                <Row>
                    <Col md={3}>
                        <Card className={`mb-3 ${selectedCard === 'create' ? 'selected' : ''}`} onClick={handleCreateClick}>
                            <FontAwesomeIcon icon={faPlusCircle} className="m-3 " size="2x" />
                            <p className="m-0 text-center">Créer</p>
                        </Card>
                        <Card className={`mb-3 ${selectedCard === 'plan' ? 'selected' : ''}`} onClick={handlePlanClick}>
                            <FontAwesomeIcon icon={faClock} className="m-3" size="2x" />
                            <p className="m-0 text-center">Planifier à une réunion</p>
                        </Card>
                        <Card className={`mb-3 rounded-pill ${selectedCard === 'participate' ? 'selected' : ''}`} onClick={handlePartClick}>
                            <p className="m-2 text-center text-uppercase fs-9">Participer à une réunion</p>
                        </Card>
                        <Card className={`mb-3 rounded-pill ${selectedCard === 'calendar' ? 'selected' : ''}`} onClick={handleCalendarClick}>
                            <p className="m-2 text-center text-uppercase fs-9">Afficher les réunions à venir</p>
                        </Card>
                    </Col>
                    <Col md={9}>
                        <Card>
                            <Card.Body>
                                {showCreateForm ? (
                                    selectedCard === 'create' ? <IperxMeetingCreateForm /> : <IperxMeetingPlanMeetingForm />
                                ) : (
                                    selectedCard === 'plan' ? <IperxMeetingPlanMeetingForm /> : (
                                        selectedCard === 'participate' ? <IperxMeetingParticipateForm /> : <Calendar />
                                    )
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </section>
            <style jsx>{`
                .selected {
                    border: 2px solid blue; 
                }
            `}</style>
        </>
    );
};

export default IperxMeetingPage;
