import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Col, Row, Spinner, Alert } from 'react-bootstrap';
import { fetchThierDetails } from 'hooks/dolibarr/useThiersData';

const convertUnixTimestamp = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  return date.toLocaleString(); // e.g., "9/18/2024, 12:30:57 PM"
};

const ThiersDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // Get the ID from the URL parameters
  const [thierData, setThierData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getThierDetails = async () => {
      try {
        setLoading(true);
        const data = await fetchThierDetails(Number(id));
        if (data) {
          setThierData(data);
        } else {
          setError('No data found for this Thier.');
        }
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch details');
        setLoading(false);
      }
    };
    getThierDetails();
  }, [id]);

  if (loading) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  if (!thierData) {
    return <Alert variant="info">No details available for this Thier.</Alert>;
  }

  return (
    <div className="container mt-5">
      <Card className="mb-4">
        <Card.Header>
          <h3>Thier Details: {thierData.name || thierData.name_alias}</h3>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={6}>
              <p><strong>ID:</strong> {thierData.id}</p>
              <p><strong>Nom:</strong> {thierData.name}</p>
              <p><strong>Alias:</strong> {thierData.name_alias || 'N/A'}</p>
              <p><strong>Adresse:</strong> {thierData.address || 'N/A'}</p>
              <p><strong>Ville:</strong> {thierData.town || 'N/A'}</p>
              <p><strong>Code Postal:</strong> {thierData.zip || 'N/A'}</p>
              <p><strong>Pays:</strong> {thierData.country_code || 'N/A'}</p>
              <p><strong>Forme Juridique:</strong> {thierData.forme_juridique || 'N/A'}</p>
              <p><strong>SIRET:</strong> {thierData.idprof2 || 'N/A'}</p>
            </Col>
            <Col md={6}>
              <p><strong>TVA Intracommunautaire:</strong> {thierData.tva_intra || 'N/A'}</p>
              <p><strong>Effectif:</strong> {thierData.effectif || 'N/A'}</p>
              <p><strong>Capital:</strong> {thierData.capital || 'N/A'}</p>
              <p><strong>Mode de Réglement:</strong> {thierData.mode_reglement_id || 'N/A'}</p>
              <p><strong>Code Client:</strong> {thierData.code_client || 'N/A'}</p>
              <p><strong>Statut:</strong> {thierData.status === '1' ? 'Active' : 'Inactive'}</p>
              <p><strong>Date de Création:</strong> {thierData.date_creation} </p>
              <p><strong>Date de Modification:</strong> {thierData.date_modification}</p>
            {/* {convertUnixTimestamp(thierData.date_creation)}   {convertUnixTimestamp(thierData.date_modification)} */}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ThiersDetails;
