import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

export interface Certification {
  id: number;
  datePassage: string;
  status: boolean;
  statusUpdate: string;
  pointScore: number;
  diplomPath: string;
  user: {
    id: number;
    firstname: string;
    lastname: string;
    avatar: string;
  };
  certification: {
    id: number;
    name: string;
  };
  certificationTypeGroup: {
    id: number;
    name: string;
  };
  certificationType: {
    id: number;
    name: string;
  };
}

// Données par défaut pour les certifications
const defaultCertifications: Certification[] = [
  {
    id: 1,
    datePassage: '2024-06-15T10:30:00',
    status: true,
    statusUpdate: 'Completed',
    pointScore: 95,
    diplomPath: 'path/to/diploma',
    user: {
      id: 1,
      firstname: 'Dev',
      lastname: 'Ipercom',
      avatar: 'https://ui-avatars.com/api/?name=Dev+Ipercom'
    },
    certification: {
      id: 1,
      name: 'Certification A'
    },
    certificationTypeGroup: {
      id: 1,
      name: 'Group Editeur'
    },
    certificationType: {
      id: 1,
      name: 'Type Technicien'
    }
  }
];

const useRetailerCertifications = () => {
  const [certifications, setCertifications] = useState<Certification[]>(defaultCertifications);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchCertifications = useCallback(async () => {
    try {
      const response = await axios.get<Certification[]>('https://rct-backend.ipercom.io/api/Certification');
      setCertifications(response.data);
      setError(null); // Reset error on success
    } catch (err) {
      console.error("Erreur lors de la récupération des données de l'API", err);
      setError('Impossible de récupérer les données. Affichage des données par défaut.');
      setCertifications(defaultCertifications); // Use default data on error
    } finally {
      setLoading(false); // Stop loading in all cases
    }
  }, []);

  useEffect(() => {
    fetchCertifications();
  }, [fetchCertifications]);

  return {
    certifications,
    loading,
    error,
    defaultCertifications
  };
};

export default useRetailerCertifications;
