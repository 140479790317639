import { useState, useCallback, useEffect } from 'react';

export interface ModuleFeature {
  idFeature: number;
  idModule: number;
  name: string;
  slug: string;
}

const useModuleFeatures = () => {
  const [moduleFeatures, setModuleFeatures] = useState<ModuleFeature[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    try {
      const response = await fetch('https://rct-backend.ipercom.io/api/ModuleFeature', {
        method: 'GET',
        headers: {
          accept: 'text/plain',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setModuleFeatures(data);
      setError(null);
    } catch (error) {
      console.error('Erreur lors de la récupération des données de l\'API', error);
      setError('Impossible de récupérer les données.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    moduleFeatures,
    loading,
    error,
  };
};

export default useModuleFeatures;
