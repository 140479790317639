import EcomProjectionVsActualChart from 'components/charts/e-charts/EcomProjectionVsActualChart';
import React from 'react';

const EditorGoalAndNumber = () => {
  return (
    <div className="ms-1 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1 p-5">
      <div>
        <h3>Objectif et chiffre</h3>
      </div>
      <EcomProjectionVsActualChart height="300px" width="100%" />
    </div>
  );
};

export default EditorGoalAndNumber;
