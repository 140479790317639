import { useState, useCallback, useEffect } from "react";

export interface RoutingData {
  id: number,
  id_user: number,
  prefixe: string,
  schedule: string,
  trunk: string,
  action: string,
  displayNumber: string,
  state: boolean
}

const routingTableData: RoutingData[] = [
  {
    id: 1,
    id_user: 9,
    prefixe: "6547",
    schedule: "Repos",
    trunk: "Zoe",
    action: "Dormir",
    displayNumber: "258963147859",
    state: false
  }
];

const useRouting = () => {
  const [routingData, setRoutingData] = useState(routingTableData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    console.log("Fetching new API data...");
    try {
      const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/routing', {
        method: 'GET',
        headers: {
          'accept': 'text/plain',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setRoutingData(data);
      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données de l'API", error);
      setError('Impossible de récupérer les données.');
      setRoutingData(routingTableData);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    routingData,
    setRoutingData,
    loading,
    error
  };
};

export default useRouting;
