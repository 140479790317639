export interface BlacklistedNumbers {
  number: string;
  description: string;
  createdOn: string;
}

export const blacklistedNumbersTableData: BlacklistedNumbers[] = [
  {
    number: "06 00 00 00 00",
    description: "bloquer pour cause de ....",
    createdOn: "14/01/2024 00:00:12"
  }

];
