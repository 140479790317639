import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import RevealDropdown, { RevealDropdownTrigger } from "components/base/RevealDropdown";
import ActionDropdownItems from "components/common/ActionDropdownItems";
import { BlacklistedIP } from "data/iper-x/ipbx/blacklistedIP";

export const blacklistedIPTableColumns: ColumnDef<BlacklistedIP>[] = [
  {
    id: "action",
    header: "Action",
    accessorFn: ({ action }) => action,
    meta: {
      headerProps: { style: { width: "15%" }, className: "pe-3" }
    }
  },
  {
    id: "ipAddress",
    header: "Adresse IP",
    accessorFn: ({ ipAddress }) => ipAddress,
    meta: {
      headerProps: { style: { width: "20%" }, className: "pe-3" }
    }
  },
  {
    id: "subnetMask",
    header: "Masque de sous-réseau",
    accessorFn: ({ subnetMask }) => subnetMask,
    meta: {
      headerProps: { style: { width: "20%" }, className: "pe-3" }
    }
  },
  {
    id: "ipRange",
    header: "Plage d’adresse IP",
    accessorFn: ({ ipRange }) => ipRange,
    meta: {
      headerProps: { style: { width: "20%" }, className: "pe-3" }
    }
  },


  {
    id: "description",
    header: "Description",
    accessorFn: ({ description }) => description,
    meta: {
      headerProps: { style: { width: "30%" }, className: "pe-3" }
    }
  },
  {
    id: "expirationDate",
    header: "Date d’expiration",
    accessorFn: ({ expirationDate }) => expirationDate,
    meta: {
      headerProps: { style: { width: "20%" }, className: "pe-3" }
    }
  },
  {
    id: "leadDropdown",
    cell: () => {
      return (
        <RevealDropdownTrigger>
          <RevealDropdown>
            <ActionDropdownItems />
          </RevealDropdown>
        </RevealDropdownTrigger>
      );
    },
    meta: {
      cellProps: {
        className: "text-end pe-0 ps-4"
      }
    }
  }
];

const BlacklistedIPTable = () => {
  return (
    <div>
      <AdvanceTable

        tableProps={{ className: "phoenix-table fs-9" }}
      />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default BlacklistedIPTable;
