// RetailerTicketsList.tsx
import React, { useState, ChangeEvent } from 'react';
import { Button, Card, Col, Row, Alert, Form } from 'react-bootstrap';
import {
  faFileExport,
  faFileImport,
  faFilter,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import EditorUsersAdvanceTable from 'components/base/EditorUsersAdvanceTable';
import { retailerTicketsBreadcrumbItems } from 'data/breadcrumbItem';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import LeadsFilterModal from 'components/modals/LeadsFilterModal';
import TicketForm from './TicketForm';
import useTicketsData from 'hooks/dolibarr/useTicketsData';
import { TicketsTableColumns } from 'pages/apps/editor/ticket/TicketsTable';

export interface User {
  assigne: string;
  startDate: string;
  endDate: string;
  type_code: string;
  category_code: string;
  severity_code: string;
  socid: string;
  projectid: string;
  subject: string;
  message: string;
}

const ETicketsList: React.FC = () => {
  const { data, error } = useTicketsData();
  const table = useAdvanceTable({
    data,
    columns: TicketsTableColumns,
    pageSize: 6,
    pagination: true,
    sortable: true,
    selection: false
  });

  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const handleSubmit = (user: User) => {
    console.log('Ticket ajouté:', user);
  };

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
          <h2 className="mb-0">
            <PageBreadcrumb items={retailerTicketsBreadcrumbItems} />
            <h2 className="mb-2 text-1100">Vos tickets</h2>
          </h2>
        </div>
        <div className="mb-4">
          <Row className="g-3">
            <Col xs="auto">
              <SearchBox
                placeholder="Rechercher"
                onChange={handleSearchInputChange}
              />
            </Col>
            <Col
              xs="auto"
              className="scrollbar overflow-hidden-y flex-grow-1"
            ></Col>
            <Col xs="auto">
              <Button variant="link" className="text-900 me-4 px-0">
                <FontAwesomeIcon icon={faFileImport} className="fs-9 me-2" />
                Importer
              </Button>
              <Button variant="link" className="text-900 me-4 px-0">
                <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                Exporter
              </Button>
              <Button variant="primary" onClick={handleShowModal}>
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Ajouter un ticket
              </Button>
              <Button
                variant="phoenix-secondary"
                className="px-3 ms-2"
                onClick={() => setOpenFilterModal(true)}
              >
                <FontAwesomeIcon
                  icon={faFilter}
                  transform="down-3"
                  className="text-primary"
                />
              </Button>
              <LeadsFilterModal
                show={openFilterModal}
                handleClose={() => setOpenFilterModal(false)}
              />
            </Col>
          </Row>
        </div>
        {error && <Alert variant="danger">{error}</Alert>}
        <Card className="">
          <Card.Body>
            <Row className="align-items-center g-3">
              <Col sm="auto" className="flex-1">
                <EditorUsersAdvanceTable />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </AdvanceTableProvider>

      <TicketForm
        show={showModal}
        handleClose={handleCloseModal}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default ETicketsList;
