import { faFileImport, faFileExport, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchBox from "components/common/SearchBox";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { ChangeEvent, useState } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import useEditorUser from "hooks/api/useEditorUser";
import UserTableAPI, { userAPITableColumns } from "./UserTableAPI";
import AddUserForm from "./AddUserForm";

const UserListAPI = () => {
  const { userData, setUserData, loading, error } = useEditorUser();
  const [showAddClientModal, setShowAddClientModal] = useState(false);
  const table = useAdvanceTable({
    data: userData,
    columns: userAPITableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false,
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
        <h2 className="mb-0">
          <span className="me-3">Utilisateurs</span>
        </h2>
      </div>
      <div className="mb-4">
        <Row className="g-3">
          <Col xs="auto">
            <SearchBox
              className="w-300"
              placeholder="Rechercher"
              onChange={handleSearchInputChange}
            />
          </Col>
          <Col xs="auto" className="scrollbar overflow-hidden-y flex-grow-1"></Col>
          <Col xs="auto">
            <Button variant="link" className="text-900 me-4 px-0">
              <FontAwesomeIcon icon={faFileImport} className="fs-9 me-2" />
              Importer
            </Button>
            <Button variant="link" className="text-900 me-4 px-0">
              <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
              Export
            </Button>
            <Button variant="primary" onClick={() => setShowAddClientModal(true)}>
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Ajouter un utilisateur
            </Button>
          </Col>
        </Row>
      </div>
      {loading && <p>Chargement des données...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {!loading && (
        <AdvanceTableProvider {...table}>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <UserTableAPI />
          </div>
        </AdvanceTableProvider>
      )}
      <Modal show={showAddClientModal} onHide={() => setShowAddClientModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un utilisateur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddUserForm
            tableData={userData}
            setTableData={setUserData}
            onClose={() => setShowAddClientModal(false)}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserListAPI;
