import { Row, Col, Form, Card, FormControl, InputGroup } from "react-bootstrap";

const IperxParamTAdoNotDisturb = () => {
    return (
        <>
            <h2 className="fs-7 mb-3">Ne pas déranger</h2>
            <Card className="mb-4">
                <Card.Body>
                    <Form.Group className="mb-3" controlId="firstName">
                        <Form.Label>Message personnalisé de statut </Form.Label>
                        <Form.Control type="text" placeholder="" />
                    </Form.Group>
                    <Col>
                        <Form.Group className="mb-3" controlId="audioSettings">
                            <Form.Label>Transférer les appels externes à</Form.Label>
                            <Form.Select defaultValue="Messagerie vocal">
                                <option>Messagerie vocal</option>
                            </Form.Select>
                        </Form.Group>
                    </Col><Col>
                        <Form.Group className="mb-3" controlId="audioSettings">
                            <Form.Label>Transférer les appels interne à</Form.Label>
                            <Form.Select defaultValue="Messagerie vocal">
                                <option>Messagerie vocal</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                <Form.Check
                    type='checkbox'
                    id='defaultCheckbox'
                    label='Accepter les notifications Push'
                />
                </Card.Body>
            </Card>

        </>
    );
};

export default IperxParamTAdoNotDisturb;
