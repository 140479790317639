import React from "react";
import { Button, Nav } from "react-bootstrap";
import { faFilm, faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ParamApps = () => {
  return (
    <>
      <div className="mb-5">
        <div className="g-3  row">
          <div className="col-xl-12 gy-3">
            <Nav className="" style={{ width: '90%' }}>
              <Button className="btn bg-white px-10 pt-4 pb-3 fs-8  nav-link "
                      href="/ipbx/parametres/codecs">
                <FontAwesomeIcon
                  icon={faFilm}
                  className="category-icon text-800 fs-6"

                />
                <span className="d-block fs-12 fw-bolder lh-1 text-900 mt-3 mb-2">Codecs</span>
              </Button>

              <Button className="btn bg-white px-10 ms-5 pt-4 pb-3 fs-8  nav-link "
                      href="/ipbx/parametres/options">
                <FontAwesomeIcon
                  icon={faCog}
                  className="category-icon text-800 fs-6"
                />
                <span className="d-block fs-12 fw-bolder lh-1 text-900 mt-3 mb-2">Option</span>
              </Button>
            </Nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParamApps;
