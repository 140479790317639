import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from 'providers/AuthProvider';
import { Spinner } from 'react-bootstrap';

interface ProtectedRouteProps {
  children: React.ReactNode;
  requiredRoles: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, requiredRoles }) => {
  const { isAuthenticated, config, loading } = useAuthContext();

  // Affiche un spinner pendant le chargement
  if (loading) {
    return <Spinner animation="border" />;
  }

  // Redirige vers la page de connexion si l'utilisateur n'est pas authentifié
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  // Vérifie si l'utilisateur a les rôles nécessaires pour accéder à cette page
  const userHasRequiredRole = requiredRoles.includes(config.role);

  // Si l'utilisateur n'a pas les droits nécessaires, redirige immédiatement vers /accessDenied
  if (!userHasRequiredRole) {
    return <Navigate to="/accessDenied" replace />;
  }

  // Si tout est correct, rend le composant enfant
  return <>{children}</>;
};

export default ProtectedRoute;
