import ChatHomepageCard from 'components/cards/ChatHomepageCard';
import ChatSidebar from 'components/modules/chat/ChatSidebar';


const IperxChatModal = () => {
    return (
        <>
            <ChatSidebar />
            <ChatHomepageCard />
        </>
    );
};

export default IperxChatModal;
