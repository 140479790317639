import React, { useState } from "react";
import { Row, Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

import IperxParamGeneral from "./IperxParamGeneral";
import IperxParamAudioVideo from "./IperxParamAudioVideo";
import IperxParamChat from "./IperxParamChat";
import IperxParamVisualiser from "./IperxParamVisualiser";
import IperxParamTransfertAppel from "./Transfert d'appel/IperxParamTransfertAppel";
import IperxParamAnnonce from "./IperxParamAnnonce";
import IperxParamIntegration from "./IperxParamIntegration";
import IperxParamRepository from "./IperxParamRepository";
import IperxParamQRcode from "./IperxParamQRcode";
import IperxParamRaccourci from "./IperxParamRaccourci";

const IperxParam = () => {
    const [selectedComponent, setSelectedComponent] = useState<string>("general");

    const handleItemClick = (component: string) => setSelectedComponent(component);
    
    const selectedMenuStyle: React.CSSProperties = {
        textDecoration: "underline",
        color: "#F72585",
    };

    return (
        <div>
            <Nav
                variant="underline"
                className="mb-2 mb-3 md-5 pb-3 pt-2 w-100 w-sm-75 w-md-100 mx-auto bg-soft"
            >
                <button onClick={() => handleItemClick("general")} className="nav-link fw-bold me-3 fs-8 pe-2 text-center" style={selectedComponent === "general" ? selectedMenuStyle : {}}>
                    Paramètres du compte
                </button>
                <button onClick={() => handleItemClick("audio-video")} className="nav-link fw-bold me-3 fs-8 pe-2 text-center" style={selectedComponent === "audio-video" ? selectedMenuStyle : {}}>
                    Audio/Vidéo
                </button>
                <button onClick={() => handleItemClick("chat")} className="nav-link fw-bold me-3 fs-8 pe-2 text-center" style={selectedComponent === "chat" ? selectedMenuStyle : {}}>
                    Chat
                </button>
                <button onClick={() => handleItemClick("visualiser")} className="nav-link fw-bold me-3 fs-8 pe-2 text-center" style={selectedComponent === "visualiser" ? selectedMenuStyle : {}}>
                    Visualiser
                </button>
                <button onClick={() => handleItemClick("transfert-appels")} className="nav-link fw-bold me-3 fs-8 pe-2 text-center" style={selectedComponent === "transfert-appels" ? selectedMenuStyle : {}}>
                    Transfert d'appels
                </button>
                <button onClick={() => handleItemClick("annonce")} className="nav-link fw-bold me-3 fs-8 pe-2 text-center" style={selectedComponent === "annonce" ? selectedMenuStyle : {}}>
                    Annonces
                </button>
                <button onClick={() => handleItemClick("integration")} className="nav-link fw-bold me-3 fs-8 pe-2 text-center" style={selectedComponent === "integration" ? selectedMenuStyle : {}}>
                    Intégration
                </button>
                <button onClick={() => handleItemClick("qrcode")} className="nav-link fw-bold me-3 fs-8 pe-2 text-center" style={selectedComponent === "qrcode" ? selectedMenuStyle : {}}>
                    Scanner le QR code
                </button>
                <button onClick={() => handleItemClick("repertoire")} className="nav-link fw-bold me-3 fs-8 pe-2 text-center" style={selectedComponent === "repertoire" ? selectedMenuStyle : {}}>
                    BLF
                </button>
                <button onClick={() => handleItemClick("raccourci")} className="nav-link fw-bold me-3 fs-8 pe-2 text-center" style={selectedComponent === "raccourci" ? selectedMenuStyle : {}}>
                    Touches de raccourci
                </button>
            </Nav>

            <hr />

            {selectedComponent === "general" && <IperxParamGeneral />}
            {selectedComponent === "audio-video" && <IperxParamAudioVideo />}
            {selectedComponent === "chat" && <IperxParamChat />}
            {selectedComponent === "visualiser" && <IperxParamVisualiser />}
            {selectedComponent === "transfert-appels" && <IperxParamTransfertAppel />}
            {selectedComponent === "annonce" && <IperxParamAnnonce />}
            {selectedComponent === "integration" && <IperxParamIntegration />}
            {selectedComponent === "qrcode" && <IperxParamQRcode />}
            {selectedComponent === "repertoire" && <IperxParamRepository />}
            {selectedComponent === "raccourci" && <IperxParamRaccourci />}
        </div>
    );
};

export default IperxParam;
