import React from 'react';
import { Nav, Tab } from 'react-bootstrap';
import EditorMapClients from 'components/modules/crm/deals-details/EditorMapClients';
import EditorMapRetailer from 'components/modules/crm/deals-details/EditorMapRetailer';
import DealDetailsCallTable from 'components/tables/old/DealDetailsCallTable';

const EditorMap = () => {

  interface TabItem {
    name: string;
    content: JSX.Element;
  }
  
  const tabitems: TabItem[] = [
    {
      name: 'Revendeur',
      content: <EditorMapRetailer />
    },
    {
      name: 'Client Finaux',
      content: <EditorMapClients />
    },
  ];
  
  return (
    <div className="mb-2 mt-2" style={{ maxHeight: '500px', overflowY: 'auto' }}>
      <h3> Client</h3>
      <Tab.Container defaultActiveKey="revendeur">
        <Nav
          variant="underline"
          className="deal-details scrollbar flex-nowrap w-100 pb-1 mb-2"
        >
          {tabitems.map(item => (
            <Nav.Item key={item.name} className="me-2 text-nowrap">
              <Nav.Link eventKey={item.name.toLowerCase()}>
                {item.name}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
        <Tab.Content>
          {tabitems.map(item => (
            <Tab.Pane key={item.name} eventKey={item.name.toLowerCase()}>
              {item.content}
            </Tab.Pane>
          ))}
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default EditorMap;
