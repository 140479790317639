import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Table, Container, Alert, Row, Col, Button } from "react-bootstrap";

interface Invoice {
  ref: string;
  date: string;
  total_ht: string;
  total_tva: string;
  total_ttc: string;
  socid: string;
  status: string;
  lines: InvoiceLine[];
  array_options: {
    options_ref_commande?: string; // Order reference if available
  };
}

interface InvoiceLine {
  product_ref: string;
  product_label: string;
  qty: string;
  subprice: string;
  tva_tx: string;
  total_ht: string;
  total_ttc: string;
  array_options: {
    options_licences?: string[];
  };
}

const InvoiceDetails: React.FC = () => {
  const { invoiceRef } = useParams<{ invoiceRef: string }>(); // Get the invoice ref or id from the route params
  const [invoice, setInvoice] = useState<Invoice | null>(null); // State to store invoice details
  const [error, setError] = useState<string | null>(null); // State for error handling
  const DOLAPIKEY = localStorage.getItem('DOLAPIKEY');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInvoiceDetails = async () => {
      try {
        // Determine whether it's an ID (numeric) or a reference (non-numeric)
        const isNumeric = /^\d+$/.test(invoiceRef);
        const endpoint = isNumeric
          ? `https://erp.ipercom.io/api/index.php/invoices/${invoiceRef}?contact_list=1`
          : `https://erp.ipercom.io/api/index.php/invoices/ref/${invoiceRef}?contact_list=1`;

        const response = await fetch(endpoint, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'DOLAPIKEY': DOLAPIKEY || '',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch invoice details.');
        }

        const data = await response.json();
        setInvoice(data);
      } catch (error) {
        setError('Failed to load invoice details.');
      }
    };

    fetchInvoiceDetails();
  }, [invoiceRef, DOLAPIKEY]);

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  if (!invoice) {
    return <div>Loading invoice details...</div>;
  }

  return (
    <Container className="mt-5">
      {/* Button for going back */}
      <Button variant="primary" onClick={() => navigate(-1)} className="mb-3">
        ← Retour
      </Button>

      <Card>
        <Card.Header>
          <h4>Invoice Details - {invoice.ref}</h4>
          <p>Date: {new Date(invoice.date).toLocaleDateString()}</p>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <h5>Invoice Information</h5>
              <p><strong>Total HT:</strong> {invoice.total_ht} EUR</p>
              <p><strong>Total TVA:</strong> {invoice.total_tva} EUR</p>
              <p><strong>Total TTC:</strong> {invoice.total_ttc} EUR</p>
              <p><strong>Order Reference:</strong> {invoice.array_options?.options_ref_commande || 'N/A'}</p>
            </Col>
            <Col>
              <h5>Customer Information</h5>
              <p><strong>Customer ID:</strong> {invoice.socid}</p>
              <p><strong>Status:</strong> {invoice.status === '1' ? 'Unpaid' : 'Paid'}</p>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-3">
        <Card.Header>
          <h5>Invoice Lines</h5>
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
            <tr>
              <th>Product Reference</th>
              <th>Product Name</th>
              <th>Quantity</th>
              <th>Price HT</th>
              <th>TVA (%)</th>
              <th>Total HT</th>
              <th>Total TTC</th>
            </tr>
            </thead>
            <tbody>
            {invoice.lines.map((line, index) => (
              <tr key={index}>
                <td>{line.product_ref}</td>
                <td>{line.product_label}</td>
                <td>{line.qty}</td>
                <td>{line.subprice} EUR</td>
                <td>{line.tva_tx}</td>
                <td>{line.total_ht} EUR</td>
                <td>{line.total_ttc} EUR</td>
                <td>
                  {line.array_options?.options_licences // If options_licences exists, display it directly
                    ? String(line.array_options.options_licences) // Convert to string if not already
                    : "No additional options available"}
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default InvoiceDetails;
