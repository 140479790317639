import { Card, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import PageBreadcrumb, {
  PageBreadcrumbItem
} from '../../../../../components/common/PageBreadcrumb';
import React from 'react';

export const optionsBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Paramètre',
    url: '/apps/ipbx/parametres'
  },
  {
    label: 'Avancé',
    url: '/apps/ipbx/parametres?selected=advance'
  },
  {
    label: 'Remplacement 164',
    url: '/apps/ipbx/parametres/replacement-e164'
  }
];

const ReplacementE164Config = () => {
  return (
    <>
      <PageBreadcrumb items={optionsBreadcrumbItems} />
      <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
        <h2 className="mb-0">
          <span className="me-3">Paramètres E164</span>
        </h2>
      </div>

      <Card className="">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <Form.Check type="checkbox" id="sameAsShipping" className="4">
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  <h3 className="fw-bolder mb-2 line-clamp-1">
                    Activer le remplacement du E164 PMS
                  </h3>
                </Form.Check.Label>
              </Form.Check>

              <span className="me-3">
                Si vos applications stockent les numéros au format E164, et que
                ces numéros sont utilisés pour être composés via l'application
                iperx, vous devez pré-modifier ces numéros en remplaçant le signe
                + par un préfixe.
              </span>

              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Choisir un pays</Form.Label>
                <FloatingLabel controlId="floatingSelect" label="Region">
                  <Form.Select aria-label="Floating label select example">
                    <option>France (+33)</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>

              <Form.Check type="checkbox" id="sameAsShipping" className="4">
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Supprimer si le pays est le même
                </Form.Check.Label>
              </Form.Check>

              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Code numérotation internationale</Form.Label>
                <FloatingLabel controlId="floatingSelect" label="Region">
                  <Form.Select aria-label="Floating label select example">
                    <option>00</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Code de zone</Form.Label>
                <FloatingLabel controlId="floatingSelect" label="Region">
                  <Form.Select aria-label="Floating label select example">
                    <option>Code de zone</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>

              <Form.Check type="checkbox" id="sameAsShipping" className="4">
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Supprimer si la zone est la même
                </Form.Check.Label>
              </Form.Check>

              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Code de numérotation nationale</Form.Label>
                <FloatingLabel controlId="floatingSelect" label="Region">
                  <Form.Select aria-label="Floating label select example">
                    <option>Code de numérotation nationale</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Ajouter un préfixe</Form.Label>
                <FloatingLabel controlId="floatingSelect" label="Region">
                  <Form.Select aria-label="Floating label select example">
                    <option>Ajouter un préfixe</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default ReplacementE164Config;
