import React, { useState } from 'react';
import classNames from 'classnames';
import { Card, Col, Form, ProgressBar, Row, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import Button from '../../base/Button';
import Avatar from 'components/base/Avatar';

const isRTL = '';
const name = '';
const avatar = '';
const RetailerLeadProfileCard = ({ className }: { className?: string }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Card className={classNames(className)}>
      <Card.Body>
        <Row className="align-items-center g-3 text-center text-xxl-start">
          <Col xs={12} sm="auto" className="flex-1">
            <div className="d-flex align-items-center mb-2">
              <h3 className="fw-bolder mb-2">Pigma communications</h3>
              <Button variant="link" className="px-3" onClick={handleShow}>
                Modifier
              </Button>
            </div>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Modifier le profil du lead</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group className="mb-3" controlId="companyName">
                    <Form.Label>Nom de l'entreprise</Form.Label>
                    <Form.Control type="text" defaultValue="Pigma communications" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="avatar">
                    <Form.Label>Avatar</Form.Label>
                    <Form.Control type="text" defaultValue="Link to Avatar Image" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="employeeName">
                    <Form.Label>Nom de l'employé</Form.Label>
                    <Form.Control type="text" defaultValue="Ansolo Lazinato " />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="employeePosition">
                    <Form.Label>Poste de l'employé</Form.Label>
                    <Form.Control type="text" defaultValue="PDG, Siège Sociale" />
                  </Form.Group>
                  {/* You can add more form fields as needed */}
                </Form>

              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Annuler
                </Button>
                <Button variant="primary" onClick={handleClose}>
                  Sauvegarder
                </Button>
              </Modal.Footer>
            </Modal>

            <div className="d-md-flex d-xl-block align-items-center justify-content-between mb-2 pt-2">
              <div className="d-flex align-items-center mb-3 mb-md-0 mb-xl-3">
                <Avatar size="xl" src={avatar} className="me-3" />
                <div>
                  <h5>Ansolo Lazinato Pigma communications</h5>
                  <p className="text-800">PDG, Siège Sociale</p>
                </div>
              </div>
            </div>

            <ProgressBar variant="primary-200" now={40} className="mb-2" />
            <div className="d-flex align-items-center justify-content-between">
              <p className="mb-0">Progression du lead</p>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default RetailerLeadProfileCard;
