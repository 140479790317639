import { faFileExport, faPencil, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeadsFilterModal from 'components/modals/LeadsFilterModal';
import React from 'react';
import { Button, Card, ListGroup, Row, Col, Form } from 'react-bootstrap';

const IperxParamRaccourci = () => {

  const shortcuts = [
    { action: 'Ouvrir le numéroteur', keybind: 'Nom défini' },
    { action: 'Copier depuis le presse-papiers', keybind: 'Nom défini' },
    { action: 'Répondre', keybind: 'Nom défini' },
    { action: 'Transfert Aveugle', keybind: 'Nom défini' },
    { action: 'Transfert Supervisé', keybind: 'Nom défini' },
    { action: 'Attente/Reprendre', keybind: 'Nom défini' },
    { action: 'Renvoyer l\'appel entrant vers la messagerie vocale', keybind: 'Nom défini' },
    { action: 'Raccroché', keybind: 'Nom défini' },

  ];

  return (
    <>
      <h2 className="fs-6 mb-3">Touches de raccourci</h2>
      <Col xs="auto" className='m-4'>
        <Button variant="link" className="text-900 me-4 px-0">
          <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
          Exporter
        </Button>
        <Button variant="primary">
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          Ajouter une touche
        </Button>
      </Col>
      <Card>
        <Card.Header>
        </Card.Header>
        <ListGroup variant="flush">
          {shortcuts.map((shortcut, index) => (
            <ListGroup.Item key={index} className='border-0'>
              <Row className="align-items-end flex-nowrap" >
                <Form.Group controlId="firstName" style={{width:'80%'}}>
                  <Form.Label>{shortcut.action}</Form.Label>
                  <Form.Control type="text" placeholder={shortcut.keybind} />
                </Form.Group>
                <Row>
                <Col>
                  <Button variant="outline-primary" className="me-2"><FontAwesomeIcon icon={faPencil} className="fs-9 me-2" />
                    Modifier
                  </Button>
                  <Button variant="outline-danger"><FontAwesomeIcon icon={faXmark} className="fs-9 me-2" />
                    Supprimer
                  </Button>
                </Col>
                </Row>
              </Row>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card>
    </>
  );
};

export default IperxParamRaccourci;
