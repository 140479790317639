import { useState, useEffect } from 'react';
import axios from 'axios';
import { RetailerClientDataType } from 'data/iper-x/retailer/retailerClientsTableData';

// ID de l'entreprise en dur
const COMPANY_ID = 1;

const useRetailerClients = () => {
  const [tableData, setTableData] = useState<RetailerClientDataType[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = async () => {
    try {
      const response = await axios.get(`https://rct-backend.ipercom.io/api/Company/${COMPANY_ID}/licences`, {
        headers: {
          // Ajoutez ici les headers nécessaires si besoin
        }
      });

      // Mapper les données reçues en format adapté au tableau
      const mappedData = response.data.map((item: any) => ({
        id: item.idLicences,
        company: {
          name: item.responsible ? `${item.responsible.firstname} ${item.responsible.lastname}` : 'Inconnu',
          status: { label: 'Actif', type: 'success' }
        },
        product: { name: item.licenceCatalog ? item.licenceCatalog.name : 'N/A' },
        responsible: {
          name: item.responsible ? `${item.responsible.firstname} ${item.responsible.lastname}` : 'N/A',
          functionName: 'Fonction Indéterminée'
        },
        licence: item.key,
        type: { name: item.licenceTypeHost ? item.licenceTypeHost.name : 'N/A', status: 'active' },
        date: item.subscriptionDate,
        expiration: { date: item.expirationDate, status: 'Valide' }
      }));

      setTableData(mappedData);
      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données de l'API", error);
      setError('Impossible de récupérer les données. Affichage des données par défaut.');
      setTableData([]); // Utiliser un tableau vide en cas d'erreur
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    tableData,
    setTableData,
    fetchData,
    loading,
    error
  };
};

export default useRetailerClients;
