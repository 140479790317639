import { Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import PageBreadcrumb, { PageBreadcrumbItem } from "../../../../../components/common/PageBreadcrumb";
import React from "react";

export const shortcutCodesBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Paramètre',
    url: "/apps/ipbx/parametres"
  },
  {
    label: 'Systéme',
    url: "/apps/ipbx/parametres?selected=system"
  },
  {
    label: 'Codes de raccourcis',
    url: "/apps/ipbx/parametres/shortcut-codes"
  },
];


const ShortcutCodesConfig = () => {

  return (
    <>
      <PageBreadcrumb items={shortcutCodesBreadcrumbItems} />
      <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
        <h2 className="mb-0">
          <span className="me-3">Paramètres des codes de raccourcis</span>
        </h2>
      </div>

      <Card className="">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">

              <h3 className="fw-bolder mb-2 line-clamp-1">
                Codes de raccourcis
              </h3>


              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Parquer un appel</Form.Label>
                <FloatingLabel controlId="floatingSelect" label="Region">
                  <Form.Select aria-label="Floating label select example">
                    <option>*1</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Reprendre un appel parqué</Form.Label>
                <FloatingLabel controlId="floatingSelect" label="Region">
                  <Form.Select aria-label="Floating label select example">
                    <option>*1</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Intercepter un appel</Form.Label>
                <FloatingLabel controlId="floatingSelect" label="Region">
                  <Form.Select aria-label="Floating label select example">
                    <option>*1</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Changer de statut</Form.Label>
                <FloatingLabel controlId="floatingSelect" label="Region">
                  <Form.Select aria-label="Floating label select example">
                    <option>*1</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Connecter à la messagerie vocale de l'extension</Form.Label>
                <FloatingLabel controlId="floatingSelect" label="Region">
                  <Form.Select aria-label="Floating label select example">
                    <option>*1</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Connecter l'extension aux files</Form.Label>
                <FloatingLabel controlId="floatingSelect" label="Region">
                  <Form.Select aria-label="Floating label select example">
                    <option>*1</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Déconnecter l'extension des files</Form.Label>
                <FloatingLabel controlId="floatingSelect" label="Region">
                  <Form.Select aria-label="Floating label select example">
                    <option>*1</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Annonce sans décrocher</Form.Label>
                <FloatingLabel controlId="floatingSelect" label="Region">
                  <Form.Select aria-label="Floating label select example">
                    <option>*1</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Préfixe de facturation</Form.Label>
                <FloatingLabel controlId="floatingSelect" label="Region">
                  <Form.Select aria-label="Floating label select example">
                    <option>*1</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Forcer le Iper-x Phone System EN ou HORS heures bureau</Form.Label>
                <FloatingLabel controlId="floatingSelect" label="Region">
                  <Form.Select aria-label="Floating label select example">
                    <option>*1</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Sortir en numéro anonyme</Form.Label>
                <FloatingLabel controlId="floatingSelect" label="Region">
                  <Form.Select aria-label="Floating label select example">
                    <option>*1</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Hotdesking (Disponible en Professionnel et Entreprise)</Form.Label>
                <FloatingLabel controlId="floatingSelect" label="Region">
                  <Form.Select aria-label="Floating label select example">
                    <option>*1</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Codes de statut des chambres (utilisé pour les services hôteliers) Tapez le code de numérotation suivi du code de statut approprié pour changer le statut de la chambre. Réveil téléphonique, un SVI est nécessaire pour que cette fonctionnalité soit activée. Cliquer ici pour plus d'informations.</Form.Label>
                <FloatingLabel controlId="floatingSelect" label="Region">
                  <Form.Select aria-label="Floating label select example">
                    <option>*1</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
            </Col>

          </Row>
        </Card.Body>
      </Card>


    </>
  );
};

export default ShortcutCodesConfig;

