import { useState, useCallback, useEffect } from "react";

export interface AppModule {
  idModule: number;
  idApps: number;
  name: string;
  slug: string;
}

const appModuleData: AppModule[] = [
  // Initial data or mock data
];

const useAppModules = () => {
  const [appModules, setAppModules] = useState(appModuleData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    console.log("Fetching new API data...");
    try {
      const response = await fetch("https://rct-backend.ipercom.io/api/AppModule", {
        method: "GET",
        headers: {
          accept: "text/plain",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      // console.log("Fetched data: ", data);
      setAppModules(data);
      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données de l'API", error);
      setError("Impossible de récupérer les données.");
      setAppModules(appModuleData);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    appModules,
    loading,
    error,
  };
};

export default useAppModules;
