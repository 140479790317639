import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";

import AdvanceTableFooter from "components/base/AdvanceTableFooter";

import { Reports } from "../../../data/iper-x/ipbx/reports";

export const reprtsTableColumns: ColumnDef<Reports>[] = [

  {
    id: "name",
    header: "Nom",
    accessorFn: ({ name }) => name,
    meta: {
      headerProps: { style: { width: "40%" }, className: "pe-3" }
    }
  },
  {
    id: "frequency",
    header: "fréquence",
    accessorFn: ({ frequency }) => frequency,
    meta: {
      headerProps: { style: { width: "40%" }, className: "pe-3" }
    }
  },
  {
    id: "status",
    header: "Statut",
    accessorFn: ({ status }) => status,
    meta: {
      headerProps: { style: { width: "40%" }, className: "pe-3" }
    }
  }

];

const ReportsTable = () => {
  return (
    <div>
      <AdvanceTable
        tableProps={{ className: "phoenix-table fs-9" }}
      />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default ReportsTable;
