import { FilterTabItem } from 'components/common/FilterTab';

export const tabItems: FilterTabItem[] = [
  { label: 'Tout', value: 'all', count: 68817 },
  { label: 'En attente de paiement', value: 'pending_payment', count: 6 },
  { label: 'Non accompli', value: 'unfulfilled', count: 17 },
  { label: 'Complété', value: 'completed', count: 6810 },
  { label: 'Remboursé', value: 'refunded', count: 8 },
  { label: 'Échoué', value: 'failed', count: 2 }
];

export const exportOptionsInitialState = {
  includeInvoice: true,
  includeOrder: true,
};

export const defaultOrder = {
  FACTURE: '',
  'BON DE LIVRAISON': '',
  COMMANDE: '',
  TOTAL: '',
  CLIENT: '',
  'STATUT DE PAIEMENT': '',
  'STATUT D\'ACCOMPLISSEMENT': '',
  'TYPE DE LIVRAISON': '',
  'DATE FACTURATION': '',
  'DATE COMMANDE': '',
  'DATE LIVRAISON': ''
};
