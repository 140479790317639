import { Col, Row } from "react-bootstrap";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import { editorUserRightsBreadcrumbItems } from "data/breadcrumbItem";
import { useParams } from "react-router-dom";
import DealProfileCard from "components/cards/DealProfileCard";


const UsersRightsDashboardInEditor = () => {
  const { name } = useParams();

  const updatedBreadcrumbItems = editorUserRightsBreadcrumbItems.map((item, index) => {
    if (index === editorUserRightsBreadcrumbItems.length - 1) {
      return {
        ...item,
        label: `Gestion des droits utilisateurs de ${name}`
      };
    }
    return item;
  });

  return (
    <>
      <Row className="gy-3 mb-6 justify-content-between">
        <Col md={9} xs="auto">
          <PageBreadcrumb items={updatedBreadcrumbItems} />
          <h2 className="mb-2 text-1100">Gestion des droits utilisateur de {name}</h2>
        </Col>
      </Row>
      <Row className="g-4 g-xl-6">
        <Col xl={5} xxl={4}>
          <div className="sticky-leads-sidebar">
            <DealProfileCard className="mb-3" />
          </div>
        </Col>
      </Row>
    </>
  );
};


export default UsersRightsDashboardInEditor;
