import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import { CallLogs } from "../../../data/iper-x/ipbx/callLogs";

export const callLogsTableColumns: ColumnDef<CallLogs>[] = [
  {
    id: "dateTime",
    header: "Date/heure",
    accessorFn: ({ dateTime }) => dateTime,
    meta: {
      headerProps: { style: { width: "30%" }, className: "pe-3" }
    }
  },
  {
    id: "from",
    header: "De",
    accessorFn: ({ from }) => from,
    meta: {
      headerProps: { style: { width: "40%" }, className: "pe-3" }
    }
  },
  {
    id: "to",
    header: "A",
    accessorFn: ({ to }) => to,
    meta: {
      headerProps: { style: { width: "20%" }, className: "pe-3" }
    }
  },
  {
    id: "conversation",
    header: "Conversation",
    accessorFn: ({ conversation }) => conversation,
    meta: {
      headerProps: { style: { width: "30%" }, className: "pe-3" }
    }
  }
];

const CallLogsTable = () => {
  return (
    <div>
      <AdvanceTable
        tableProps={{ className: "phoenix-table fs-9" }}
      />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default CallLogsTable;
