import React from "react";
import { Card } from "react-bootstrap";
import useSWR from "swr";
import QRCode from "react-qr-code"; 


const IperxParamQRcode = () => {
  const { data, error } = useSWR("/api/user", fetch);

  if (error) return <div>failed to load</div>;
  if (!data) return <div>loading...</div>;

  return (
    <>
      <h2 className="fs-6 mb-3">Installez l'application mobile</h2>
      <Card className="mb-4">
        <Card.Body>
          <Card.Text>1. Installez l'application iOS ou Android</Card.Text>
          <Card.Text>
            2. Ouvrez l'application et pointez votre téléphone vers cet
            écran, ou téléchargez alternativement le fichier de configuration
            pour provisionner l'application.
          </Card.Text>
          <QRCode value="as;dfj;adsf" size={250} className="ms-5" />
        </Card.Body>
      </Card>
    </>
  );
};

export default IperxParamQRcode;
