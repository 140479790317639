import OutboundRulesList from "../../../pages/apps/ipbx/gestion-des-appels/OutboundRulesList";

export interface OutboundRules {
  ruleName: string; // Nom de la règle
  extCalls: string; // Appels de l’ext
  prefix: string; // Préfixe
  length: string; // Longueur
  extensionGroup: string; // Groupe d’extension
  route1: string; // Route 1
  route2: string; // Route 2
  route3: string; // Route 3
  route4: string; // Route 4
  route5: string; // Route 5
}

export const outboundRulesTableData: OutboundRules[] = [
  {
    ruleName: "NDI",
    extCalls: "SORTANT",
    prefix: "Unyc",
    length: "0556246219",
    extensionGroup: "10 Bureau",
    route1: "10 Bureau",
    route2: "10 Bureau",
    route3: "10 Bureau",
    route4: "10 Bureau",
    route5: "10 Bureau"
  },

  {
    ruleName: "NDI",
    extCalls: "SORTANT",
    prefix: "Unyc",
    length: "0556246219",
    extensionGroup: "10 Bureau",
    route1: "10 Bureau",
    route2: "10 Bureau",
    route3: "10 Bureau",
    route4: "10 Bureau",
    route5: "10 Bureau"
  },
  {
    ruleName: "NDI",
    extCalls: "SORTANT",
    prefix: "Unyc",
    length: "0556246219",
    extensionGroup: "10 Bureau",
    route1: "10 Bureau",
    route2: "10 Bureau",
    route3: "10 Bureau",
    route4: "10 Bureau",
    route5: "10 Bureau"
  },
  {
    ruleName: "NDI",
    extCalls: "SORTANT",
    prefix: "Unyc",
    length: "0556246219",
    extensionGroup: "10 Bureau",
    route1: "10 Bureau",
    route2: "10 Bureau",
    route3: "10 Bureau",
    route4: "10 Bureau",
    route5: "10 Bureau"
  },
  {
    ruleName: "NDI",
    extCalls: "SORTANT",
    prefix: "Unyc",
    length: "0556246219",
    extensionGroup: "10 Bureau",
    route1: "10 Bureau",
    route2: "10 Bureau",
    route3: "10 Bureau",
    route4: "10 Bureau",
    route5: "10 Bureau"
  },
  {
    ruleName: "NDI",
    extCalls: "SORTANT",
    prefix: "Unyc",
    length: "0556246219",
    extensionGroup: "10 Bureau",
    route1: "10 Bureau",
    route2: "10 Bureau",
    route3: "10 Bureau",
    route4: "10 Bureau",
    route5: "10 Bureau"
  },
  {
    ruleName: "NDI",
    extCalls: "SORTANT",
    prefix: "Unyc",
    length: "0556246219",
    extensionGroup: "10 Bureau",
    route1: "10 Bureau",
    route2: "10 Bureau",
    route3: "10 Bureau",
    route4: "10 Bureau",
    route5: "10 Bureau"
  },
  {
    ruleName: "NDI",
    extCalls: "SORTANT",
    prefix: "Unyc",
    length: "0556246219",
    extensionGroup: "10 Bureau",
    route1: "10 Bureau",
    route2: "10 Bureau",
    route3: "10 Bureau",
    route4: "10 Bureau",
    route5: "10 Bureau"
  },
  {
    ruleName: "NDI",
    extCalls: "SORTANT",
    prefix: "Unyc",
    length: "0556246219",
    extensionGroup: "10 Bureau",
    route1: "10 Bureau",
    route2: "10 Bureau",
    route3: "10 Bureau",
    route4: "10 Bureau",
    route5: "10 Bureau"
  },
  {
    ruleName: "NDI",
    extCalls: "SORTANT",
    prefix: "Unyc",
    length: "0556246219",
    extensionGroup: "10 Bureau",
    route1: "10 Bureau",
    route2: "10 Bureau",
    route3: "10 Bureau",
    route4: "10 Bureau",
    route5: "10 Bureau"
  },
  {
    ruleName: "NDI",
    extCalls: "SORTANT",
    prefix: "Unyc",
    length: "0556246219",
    extensionGroup: "10 Bureau",
    route1: "10 Bureau",
    route2: "10 Bureau",
    route3: "10 Bureau",
    route4: "10 Bureau",
    route5: "10 Bureau"
  },
  {
    ruleName: "NDI",
    extCalls: "SORTANT",
    prefix: "Unyc",
    length: "0556246219",
    extensionGroup: "10 Bureau",
    route1: "10 Bureau",
    route2: "10 Bureau",
    route3: "10 Bureau",
    route4: "10 Bureau",
    route5: "10 Bureau"
  },
  {
    ruleName: "NDI",
    extCalls: "SORTANT",
    prefix: "Unyc",
    length: "0556246219",
    extensionGroup: "10 Bureau",
    route1: "10 Bureau",
    route2: "10 Bureau",
    route3: "10 Bureau",
    route4: "10 Bureau",
    route5: "10 Bureau"
  },
  {
    ruleName: "NDI",
    extCalls: "SORTANT",
    prefix: "Unyc",
    length: "0556246219",
    extensionGroup: "10 Bureau",
    route1: "10 Bureau",
    route2: "10 Bureau",
    route3: "10 Bureau",
    route4: "10 Bureau",
    route5: "10 Bureau"
  },
  {
    ruleName: "NDI",
    extCalls: "SORTANT",
    prefix: "Unyc",
    length: "0556246219",
    extensionGroup: "10 Bureau",
    route1: "10 Bureau",
    route2: "10 Bureau",
    route3: "10 Bureau",
    route4: "10 Bureau",
    route5: "10 Bureau"
  },
  {
    ruleName: "NDI",
    extCalls: "SORTANT",
    prefix: "Unyc",
    length: "0556246219",
    extensionGroup: "10 Bureau",
    route1: "10 Bureau",
    route2: "10 Bureau",
    route3: "10 Bureau",
    route4: "10 Bureau",
    route5: "10 Bureau"
  },
  {
    ruleName: "NDI",
    extCalls: "SORTANT",
    prefix: "Unyc",
    length: "0556246219",
    extensionGroup: "10 Bureau",
    route1: "10 Bureau",
    route2: "10 Bureau",
    route3: "10 Bureau",
    route4: "10 Bureau",
    route5: "10 Bureau"
  },

  // Plus d'objets OutboundRules peuvent être ajoutés ici
];
