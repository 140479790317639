import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import { Link } from 'react-router-dom';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { FinalClientDeal } from 'data/crm/leadsData';
import Avatar from 'components/base/Avatar';


export const FinalClientcolumns: ColumnDef<FinalClientDeal>[] = [
  {
    accessorKey: 'name',
    header: 'Compagnie',
    cell: ({ row: { original } }) => {
      const { name, avatar } = original;
      return (
        <Link
          // to={`/iperx/utilisateurs/details/${name}`}
          to={`/ipbx/dashboard`}
          className=" d-flex align-items-center text-900 text-hover-1000"
        >
          <Avatar src={avatar} size="m" />
          <p className="mb-0 ms-3 text-1100 fw-semi-bold">{name}</p>
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: '15%', minWidth: '200px' },
        className: 'px-1'
      },
      cellProps: { className: 'align-middle white-space-nowrap py-2' }
    }
  },
  {
    accessorKey: 'manager',
    header: 'responsable',
    meta: {
      headerProps: { style: { width: '20%' } },
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'email',
    header: 'email',
    meta: {
      headerProps: { style: { width: '20%' } },
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'phone',
    header: 'numéro de téléphone',
    meta: {
      headerProps: { style: { width: '20%' } },
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'city',
    header: 'Ville',
    meta: {
      headerProps: { style: { width: '20%' } },
      cellProps: { className: 'text-900' }
    }
  },
];

const FinalClientTable = () => {

  return (
    <div className="border-top">
    <AdvanceTable
      tableProps={{ className: 'phoenix-table fs-9' }}
      rowClassName="hover-actions-trigger btn-reveal-trigger"
    />
    <AdvanceTableFooter pagination className="py-4" />
  </div>
  );
};

export default FinalClientTable;
