import { Card, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import PageBreadcrumb, {
  PageBreadcrumbItem
} from '../../../../../components/common/PageBreadcrumb';
import React from 'react';

export const optionsBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Paramètre',
    url: '/apps/ipbx/parametres'
  },
  {
    label: 'Systéme',
    url: '/apps/ipbx/parametres?selected=system'
  },
  {
    label: "Paramètres du parking d'appels",
    url: '/apps/ipbx/parametres/call-parking-config'
  }
];

const CallParkingConfig = () => {
  return (
    <>
      <PageBreadcrumb items={optionsBreadcrumbItems} />
      <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
        <h2 className="mb-0">
          <span className="me-3">Paramètres du parking d'appels</span>
        </h2>
      </div>

      <Card className="">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">
                Configuration du parking d'appels
              </h3>

              <Form.Group controlId="formNum" className="mt-3 mb-3">
                <Form.Label>
                  Sélectionner le nombre de places de parking
                </Form.Label>
                <FloatingLabel controlId="floatingInputAuthMax" label="Nombre">
                  <Form.Control type="text" value="10" />
                </FloatingLabel>
              </Form.Group>

              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>
                  Musique d'attente à jouer pour les appels parqués : Format WAV
                  (PCM, 8 kHz, 16 bit, Mono, taille max : 100MB)
                </Form.Label>
                <Form.Control type="file" />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <Form.Check type="checkbox" id="sameAsShipping" className="4">
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  <h3 className="fw-bolder mb-2 line-clamp-1">
                    Sortir les appels parqués oubliés automatiquement
                  </h3>
                </Form.Check.Label>
              </Form.Check>

              <Form.Group
                controlId="formNum"
                className="mt-3 mb-3 d-flex align-items-center"
              >
                <Form.Check.Label className="fs-8 fw-normal me-2">
                  Si un appel est parqué pour plus de :
                </Form.Check.Label>
                <div className="me-2">
                  <FloatingLabel
                    controlId="floatingInputAuthMax"
                    label="seconde"
                  >
                    <Form.Control type="text" value="5" />
                  </FloatingLabel>
                </div>
                <Form.Check.Label className="fs-8 fw-normal">
                  secondes, alors :
                </Form.Check.Label>
              </Form.Group>

              <FloatingLabel controlId="floatingSelect" label="Choisir le mode">
                <Form.Select aria-label="Floating label select example">
                  <option>Un seul fichier pour tous les appels</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </FloatingLabel>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default CallParkingConfig;
