import { faCircle, faClock, faPlusCircle, faTimes, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Row, Col, Form, Button } from "react-bootstrap";

const IperxMeetingCreateForm = () => {

    return (
        <>
                <Col className="m-4 mb-1">
                    <h1 className="fs-6">Nouvelle conférence</h1>
                    <p className="fs-9">Autoriser les utilisateurs à se connecter au client web et à la console d'administration (« Autoriser l'accès à la console d'administration IPERX » doit être activé)</p>
                </Col>
                <Col className="ms-4 mt-1 d-flex">
                    <p className="me-2">Type :</p>
                    <Form.Check
                        inline
                        type='radio'
                        id='item1Radio'
                        label='Audio'
                        name='inline-radio'
                    />
                    <Form.Check
                        inline
                        type='radio'
                        id='item2Radio'
                        label='Video'             
                        name='inline-radio'
                    />
                    <Form.Check
                        inline
                        type='radio'
                        id='item3Radio'
                        label='webinaire'
                        name='inline-radio'
                    />
                </Col>
                <Col className="ms-4 mt-1 d-flex">
                    <p className="me-2">Tag :</p>
                    <Form.Check
                        inline
                        type='checkbox'
                        id='item1Radio'
                        label={<>Urgence <FontAwesomeIcon icon={faCircle} className="ms-2" style={{ color: 'red' }} /></>}
                        name='inline-radio'
                    />

                    <Form.Check
                        inline
                        type='checkbox'
                        id='item2Check'
                        label={<>Debrief <FontAwesomeIcon icon={faCircle} className="ms-2" style={{ color: 'green' }} /></>}
                    
                    />
                    <Form.Check
                        inline
                        type='checkbox'
                        id='item3Check'
                        label={<>Préparation évènement <FontAwesomeIcon icon={faCircle} className="ms-2" style={{ color: 'blue' }} /></>}
                    
                    />
                    <Form.Check
                        inline
                        type='checkbox'
                        id='item3Check'
                        label={<>Briefing <FontAwesomeIcon icon={faCircle} className="ms-2" style={{ color: 'orange' }} /></>}
                   
                    />
                </Col>
                <Col className="ms-4 mt-1 me-4">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="fs-12">Sujet*</Form.Label>
                        <Form.Control type="text" placeholder="" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label className="fs-12">Note au participants</Form.Label>
                        <Form.Control as="textarea" rows={3} />
                    </Form.Group>
                </Col>
                <Col className="ms-4 mt-1 me-4">
                    <p className="m-2 fs-9">Sélectionner un email/calendrier à ajouter</p>
                    <Form.Select>
                        <option className="fw-bold">Sélectionner un email/calendrier à ajouter</option>
                        <option value="1" className="fw-bold">Google</option>
                        <option value="2" className="fw-bold">Google</option>
                        <option value="3" className="fw-bold">Google</option>
                    </Form.Select>
                </Col>
                <Col className="ms-4 mt-1 me-4">
                    <p className="m-2 fs-9">Ajouter des personnes</p>
                    <Form.Select>
                        <option value="1" className="fw-bold">👤 Paul FRANCISE Commerciale</option>
                        <option value="2" className="fw-bold">👤 Paul FRANCISE Commerciale</option>
                        <option value="3" className="fw-bold">👤 Paul FRANCISE Commerciale</option>
                    </Form.Select>
                </Col>
                <Col className="m-4 d-flex">
                    <Button variant="primary" className="me-2">
                        <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
                        Créer une réunion
                    </Button>
                    <Button variant="danger">
                        <FontAwesomeIcon icon={faTimes} className="me-2" />
                        Annuler
                    </Button>
                </Col>
       
        </>
    );
};

export default IperxMeetingCreateForm;
