import { VoicemailData } from 'hooks/api/useVoicemail';
import React, { useState, ChangeEvent } from 'react';
import { Form, Button } from 'react-bootstrap';

type VoicemailFormProps = {
  tableData: VoicemailData[];
  setTableData: React.Dispatch<React.SetStateAction<VoicemailData[]>>;
  onClose: () => void;
};

const AddVoicemailForm: React.FC<VoicemailFormProps> = ({ tableData, setTableData, onClose }) => {
  const [newVoicemail, setNewVoicemail] = useState<VoicemailData>({
    id: 0,
    id_user: 0,
    nameUser: "",
    emailUser: "",
    password: "",
    voicemailNumber: "",
    state: false
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewVoicemail(prevVoicemail => ({
      ...prevVoicemail,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/voicemail', {
        method: 'POST',
        headers: {
          'accept': 'text/plain',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newVoicemail)
      });

      if (response.ok) {
        window.location.reload();
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: VoicemailData = await response.json();
      setTableData([...tableData, data]);
      onClose();
    } catch (error) {
      console.error("Erreur lors de l'ajout d'un répondeur numérique", error);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="nameUser">
        <Form.Label>Nom d'utilisateur</Form.Label>
        <Form.Control
          type="text"
          name="nameUser"
          value={newVoicemail.nameUser}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="emailUser">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          name="emailUser"
          value={newVoicemail.emailUser}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="password">
        <Form.Label>Mot de passe</Form.Label>
        <Form.Control
          type="password"
          name="password"
          value={newVoicemail.password}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="voicemailNumber">
        <Form.Label>Numéro de répondeur</Form.Label>
        <Form.Control
          type="text"
          name="voicemailNumber"
          value={newVoicemail.voicemailNumber}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="state">
        <Form.Label>État</Form.Label>
        <Form.Check
          type="checkbox"
          name="state"
          checked={newVoicemail.state}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setNewVoicemail({
              ...newVoicemail,
              state: e.target.checked
            })
          }
        />
      </Form.Group>

      <Button variant="primary" type="submit" className="mt-2">
        Ajouter
      </Button>
    </Form>
  );
};

export default AddVoicemailForm;
