import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { OutboundRules } from '../../../data/iper-x/ipbx/outboundRules';
import RevealDropdown, {
  RevealDropdownTrigger
} from '../../base/RevealDropdown';
import ActionDropdownItems from '../../common/ActionDropdownItems';
import { HoldMusicPlaylist } from "../../../data/iper-x/ipbx/holdMusicPlaylist";

export const holdMusicPlaylistTableColumns: ColumnDef<HoldMusicPlaylist>[] = [
  {
    accessorKey: 'name',
    header: 'Nom',
    meta: {
      headerProps: { style: { width: '10%' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: 'playlistLink',
    header: 'Lien vers playlist',
    meta: {
      headerProps: { style: { width: '10%' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: 'volume',
    header: 'Volume %',
    meta: {
      headerProps: { style: { width: '8%' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: 'gain',
    header: 'Gain automatique',
    meta: {
      headerProps: { style: { width: '8%' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: 'random',
    header: 'Aléatoire',
    meta: {
      headerProps: { style: { width: '8%' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    id: 'leadDropdown',
    header: 'Gérer',
    meta: {
      headerProps: { style: { width: '8%' } },
      cellProps: { className: 'text-900 fs-9' }
    },
    cell: () => {
      return (
        <RevealDropdownTrigger>
          <RevealDropdown>
            <ActionDropdownItems />
          </RevealDropdown>
        </RevealDropdownTrigger>
      );
    }
  }
];

const HoldMusicPlaylistTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default HoldMusicPlaylistTable;
