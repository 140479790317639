import { Col, Row } from 'react-bootstrap';
import { StatType } from 'data/crm/stats';
import Unicon from 'components/base/Unicon';
import classNames from 'classnames';

const AnalyticsStatsEditor = ({ stats }: { stats: StatType[] }) => {
  return (
    <Row className="justify-content-between m-4">
      {stats.map((stat,index) => (
        <Col
          key={stat.id}
          xs={6}
          md={4}
          xxl={4}
          className={classNames('text-center border-end-xxl border-bottom-xxl p-4', {
            'border-bottom-xxl-0': index === 7 || index === 8 || index === 6,
            'border-end-xxl-0': index === 2 || index === 5 || index === 8,
          })}
        >
          <Stat data={stat} />
        </Col>
      ))}
    </Row>
  );
};

const Stat = ({ data }: { data: StatType }) => {
  return (
    <>
      <Unicon
        icon={data.icon}
        className={`text-${data.iconColor} mb-1`}
        size={31.25}
      />
      <h1 className="fs-5 mt-3">{data.emailCount}</h1>
      <p className="fs-9 mb-0">{data.title}</p>
    </>
  );
};

export default AnalyticsStatsEditor;