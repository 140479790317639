import React, { useState, useEffect } from 'react';
import CoverUpload from 'components/common/CoverUpload';
import AvatarUpload from 'components/common/AvatarUpload';
import { Button, Card, Col, Form, Row, Modal } from "react-bootstrap";
import { CSSProperties } from "react";
import coverImage from 'assets/img/iper-x/profile-banner/2.png';
import avatarr from 'assets/img/iper-x/profile-banner/profile.png';

interface SocialProfileCardProps {
  showAbout?: boolean;
  avatar: string;
  className?: string;
}

interface CompanyData {
  name: string;
  code_client: string;
  address: string;
  status_prospect_label: string;
  siret: string;
  presidentName: string;
  representativeName: string;
  representativeTitle: string;
  representativeEmail: string;
  representativePhone: string;
}

const SocialProfileCard = ({
                             showAbout,
                             avatar,
                             className
                           }: SocialProfileCardProps) => {
  const [show, setShow] = useState(false);
  const [companyData, setCompanyData] = useState<CompanyData | null>(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const DOLID = localStorage.getItem('DOLID');
    const fetchCompanyData = async () => {

      try {
        // Récupérer les informations de la société
        const companyResponse = await fetch('https://erp.ipercom.io/api/index.php/thirdparties/'+DOLID, {
          headers: {
            'Accept': 'application/json',
            'DOLAPIKEY': 'VPnBn591lOztE1g8H7p06aHR2Akn1ABl',
          },
        });

        if (companyResponse.ok) {
          const companyData = await companyResponse.json();

          // Récupérer les contacts associés à la société
          const contactsResponse = await fetch('https://erp.ipercom.io/api/index.php/contacts?sortfield=t.rowid&sortorder=ASC&limit=100&thirdparty_ids='+DOLID, {
            headers: {
              'Accept': 'application/json',
              'DOLAPIKEY': 'VPnBn591lOztE1g8H7p06aHR2Akn1ABl',
            },
          });

          let presidentName = "Jean Dupont";
          if (contactsResponse.ok) {
            const contactsData = await contactsResponse.json();
            const presidentContact = contactsData.find((contact: any) => contact.poste === 'Présidente');
            presidentName = presidentContact ? `${presidentContact.lastname} ${presidentContact.firstname}` : presidentName;
          }

          // Récupérer les informations du représentant commercial
          const representativeResponse = await fetch('https://erp.ipercom.io/api/index.php/thirdparties/'+DOLID+'/representatives', {
            headers: {
              'Accept': 'application/json',
              'DOLAPIKEY': 'VPnBn591lOztE1g8H7p06aHR2Akn1ABl',
            },
          });

          let representativeData = {
            name: 'Benoit LEFEVRE',
            title: 'Directeur Commercial',
            email: 'commercial@ipercom.com',
            phone: '0644390949',
          };

          if (representativeResponse.ok) {
            const representativeArray = await representativeResponse.json();
            const representative = representativeArray[0]; // Utiliser le premier représentant s'il y en a plusieurs
            representativeData = {
              name: `${representative.lastname} ${representative.firstname}`,
              title: representative.statutLabel || '',
              email: representative.email || '',
              phone: representative.phone_mobile || '',
            };
          }

          setCompanyData({
            name: companyData.name,
            code_client: companyData.code_client,
            address: `${companyData.address}, ${companyData.zip} ${companyData.town}`,
            status_prospect_label: companyData.status_prospect_label,
            siret: companyData.idprof1 || "",
            presidentName,
            representativeName: representativeData.name,
            representativeTitle: representativeData.title,
            representativeEmail: representativeData.email,
            representativePhone: representativeData.phone,
          });
        } else {
          console.error('Erreur lors de la récupération des données de la société');
        }
      } catch (error) {
        console.error('Erreur:', error);
      }
    };

    fetchCompanyData();
  }, []);

  return (
    <Card className={className}>
      <Card.Header
        className="position-relative mb-6"
        style={{ minHeight: '130px' }}
      >
        <CoverUpload
          src={coverImage}
          gradient={
            'linear-gradient(0deg, #000000 -3%, rgba(0, 0, 0, 0) 83%)' as CSSProperties
          }
        />
        <AvatarUpload
          size="4xl"
          src={avatar}
          status="ivoire"
          thumbnail
          className="feed-avatar-profile"
        />
      </Card.Header>
      <Card.Body>
        <Row>
          <Col xs={12}>
            <div className="d-flex flex-wrap mb-2 align-items-center">
              <div className="d-flex align-items-center mb-3">
                <h3 className="fw-bolder mb-2">{companyData ? companyData.name : ''}</h3>
                <Button variant="link" className="px-3" onClick={handleShow}>
                  Modifier
                </Button>
              </div>
              <div className="mt-0">
                <p className="mb-0">PDG : {companyData?.presidentName || ''}</p>
                <p className="mb-0">Siret : {companyData?.siret || ''}</p>
                <p className="mb-0">Adresse : {companyData?.address || ''}</p>
              </div>
            </div>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Modification</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group className="mb-3" controlId="companyName">
                    <Form.Label>Nom de l'entreprise</Form.Label>
                    <Form.Control type="text" placeholder={companyData?.name || ""} value={companyData?.name || ""}  />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="ceoName">
                    <Form.Label>PDG</Form.Label>
                    <Form.Control type="text" placeholder={companyData?.presidentName || ""} value={companyData?.presidentName || ""} />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="companySiret">
                    <Form.Label>Siret de l'entreprise</Form.Label>
                    <Form.Control type="text" placeholder={companyData?.siret || ""} value={companyData?.siret || ""} />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="companyAddress">
                    <Form.Label>Adresse de l'entreprise</Form.Label>
                    <Form.Control type="text" placeholder={companyData?.address || ""} value={companyData?.siret || ""}/>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="certificationLevel">
                    <Form.Label>Niveau de certification</Form.Label>
                    <Form.Control type="text" placeholder="Avancé" />
                  </Form.Group>


                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Annuler
                </Button>
                <Button variant="primary" onClick={handleClose}>
                  Sauvegarder
                </Button>
              </Modal.Footer>
            </Modal>

            <div className="mt-4">
              <p className="fw-semi-bold mb-0">
                <b>ID partenaire :</b> {companyData?.code_client || "pas defini"}
              </p>
            </div>
            <div className="mt-4">
              <p className="fw-semi-bold mb-0">
                <b>Niveau de certification :</b> En attente
              </p>
            </div>

            <div className="mt-4">
              <p className="fw-semi-bold mb-0">
                <b>Votre distributeur</b>
              </p>
              <p className="mb-0">ipercom</p>
              <p className="mb-0">Service Commercial</p>
              <p className="mb-0">+33 5 18 22 22 00</p>
              <p className="mb-0">adv@ipercom.com</p>
            </div>
            <div className="mt-4">
              <p className="fw-semi-bold mb-0">
                <b>Votre représentant commercial</b>
              </p>
              <p className="text-700 mb-0">
                <p className="mb-0">{companyData?.representativeName || ""}</p>
                <p className="mb-0">{companyData?.representativeTitle || ""}</p>
                <p className="mb-0">{companyData?.representativeEmail || ""}</p>
                <p className="mb-0">{companyData?.representativePhone || ""}</p>
              </p>
            </div>
            <div className="mt-4">
              <p className="fw-semi-bold mb-0">
                <b>Conditions de règlements</b>
              </p>
              <p className="text-700 mb-0">
                <p className="mb-0">Prélèvement automatique</p>
              </p>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default SocialProfileCard;
