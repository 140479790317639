// src/components/tables/iper-x/retailer/OrdersTable.tsx

import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { currencyFormat } from 'utils/currencyFormat';

export interface Order {
  id: number;
  ref: string;
  tiers: string;
  date_commande: number;
  date_livraison: string;
  total_ht: number;
  author: string;
  status: string;
}

export const ordersTableColumns: ColumnDef<Order>[] = [
  {
    accessorKey: 'ref',
    header: 'Commande.',
    cell: ({ row: { original } }) => (
      <a
        href={`/portail-revendeur/commandes/details/${original.id}`}
      >
        {original.ref}
      </a>
    ),
    meta: {
      headerProps: { style: { width: '10%' } },
      cellProps: { className: 'ps-0' }
    }
  },

  {
    accessorKey: 'ref',
    header: 'Facture.',
    cell: ({ row: { original } }) => (
      <a
        href={`https://erp.ipercom.io/document.php?modulepart=commande&attachment=0&file=${original.ref}/${original.ref}.pdf&&entity=1`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {original.ref}
      </a>
    ),
    meta: {
      headerProps: { style: { width: '10%' } },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'tiers',
    header: 'Tiers',
    meta: {
      headerProps: { style: { width: '25%' } },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'date_commande',
    header: 'Date de commande',
    cell: ({ getValue }) => {
      const value = getValue<number>();
      return new Date(value * 1000).toLocaleDateString();
    },
    meta: {
      headerProps: { style: { width: '15%' } },
      cellProps: { className: 'ps-0' }
    }
  },

  {
    accessorKey: 'total_ht',
    header: 'Montant HT',
    cell: ({ row: { original } }) => currencyFormat(original.total_ht),
    meta: {
      headerProps: { style: { width: '10%' }, className: 'text-end' },
      cellProps: { className: 'text-end fw-semi-bold text-1000' }
    }
  },
  {
    accessorKey: 'author',
    header: 'Auteur',
    meta: {
      headerProps: { style: { width: '10%' } },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'status',
    header: 'État',
    cell: ({ getValue }) => {
      const value = getValue<string>();

      let badgeClass = '';
      let badgeText = '';

      switch (value) {
        case '0': // Brouillon
          badgeClass = 'badge bg-secondary';
          badgeText = 'Brouillon';
          break;
        case '1': // Validée
          badgeClass = 'badge bg-success';
          badgeText = 'Validée';
          break;
        case '2': // Acceptée
          badgeClass = 'badge bg-primary';
          badgeText = 'Acceptée';
          break;
        case '3': // Expédiée
          badgeClass = 'badge bg-success';
          badgeText = 'Expédiée';
          break;
        case '4': // Facturée
          badgeClass = 'badge bg-warning';
          badgeText = 'Facturée';
          break;
        case '5': // Annulée
          badgeClass = 'badge bg-danger';
          badgeText = 'Annulée';
          break;
        default:
          badgeClass = 'badge bg-dark';
          badgeText = 'Inconnu';
      }

      return (
        <span className={badgeClass}>
        {badgeText}
      </span>
      );
    },
    meta: {
      headerProps: { style: { width: '10%' } },
      cellProps: { className: 'ps-0' }
    }
  }

];

const OrderPreviewLink: React.FC<{ order: Order }> = ({ order }) => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <>
      <Link to="#" onClick={handleShow} className="fw-semi-bold">
        {order.ref}
      </Link>

      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Preview for {order.ref}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="dialogforpopup" style={{ width: "auto", minHeight: "0px", maxHeight: "none", height: "706.012px" }} className="ui-dialog-content ui-widget-content">
            <embed
              src={`https://erp.ipercom.io/document.php?modulepart=commande&attachment=0&file=${order.ref}/${order.ref}.pdf&&entity=1`}
             type="application/pdf"
             width="100%"
             height="98%"/>

          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const OrdersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9" }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default OrdersTable;
