import { faArrowDown, faArrowUp, faFileExport, faFilter, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchBox from "components/common/SearchBox";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import React, { ChangeEvent } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import CDRConfigTable, { cdrConifgTableColumns } from "../../../../components/tables/ipbx/CDRConfigTable";
import { cdrConfigTableData } from "../../../../data/iper-x/ipbx/cdrConfig";

const CDRConfig = () => {


  const table = useAdvanceTable({
    data: cdrConfigTableData,
    columns: cdrConifgTableColumns,
    pageSize: 5,
    pagination: true,
    sortable: true,
    selection: true
  });


  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };
  return (
    <>

      <Card className="">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">

              <h3 className="fw-bolder mb-2 line-clamp-1">
                Générer les CDR
              </h3>

              <span
                className="d-none d-sm-inline-block my-2">Choisir comment les logs seront générés</span>

              <Form.Select as="select" className="form-select">
                <option value="test">Un seul fichier pour tous les appels</option>

              </Form.Select>
              <Form.Check type="checkbox" id="sameAsShipping" className="mt-2">
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Supprimer les délimiteurs à virgule
                </Form.Check.Label>
              </Form.Check>

            </Col>

          </Row>
        </Card.Body>
      </Card>


      <div className="mt-5">
        {/*<PageBreadcrumb items={editorUserBreadcrumbItems} />*/}
        <AdvanceTableProvider {...table}>
          <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
            <h2 className="mb-0">
              <span className="me-3">Configurer les champs de la sortie CDR</span>
            </h2>

            <Link className="btn btn-white border border-2 px-2" to="/">
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Ajouter CDR
            </Link>
            <Link className="btn btn-white border border-2 px-2" to="/">
              <FontAwesomeIcon icon={faArrowUp} className="me-2" />
              Monter
            </Link>
            <Link className="btn btn-white border border-2 px-2" to="/">
              <FontAwesomeIcon icon={faArrowDown} className="me-2" />
              Descendre
            </Link>


          </div>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox className="w-300"
                           placeholder="Rechercher"
                           onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                <Button variant="link" className="text-900 me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Exporter
                </Button>
                <Button variant="primary">
                  <FontAwesomeIcon icon={faFilter} />
                </Button>


              </Col>
            </Row>
          </div>
          <div
            className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <CDRConfigTable />
          </div>
        </AdvanceTableProvider>
      </div>

    </>
  );
};

export default CDRConfig;

