import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const ipbxBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Nom de l’utilisateur',
    url: '/dashboard/portail-ipbx'
  }
];

export const retailerBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Portail Revendeur',
    url: '/dashboard/revendeur'
  }
];
export const retailerOrdersAndInvoicesBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Portail Revendeur',
    url: '/dashboard/revendeur'
  },
  {
    label: 'Commandes et factures',
    url: '/portail-revendeur/commandes-et-factures'
  }
];
export const retailerTicketsBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Portail-revendeur',
    url: '/dashboard/revendeur'
  },
  {
    label: 'Vos tickets',
    url: '/portail-revendeur/vos-tickets'
  }
];

export const editorBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Portail éditeur',
    url: '/dashboard/editeur'
  }
];

export const editorUserBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Portail éditeur',
    url: '/dashboard/editeur'
  },
  {
    label: 'Utilisateur',
    url: '/portail-editeur/utilisateurs'
  }
];
export const editorTicketsBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Portail éditeur',
    url: '/dashboard/editeur'
  },
  {
    label: 'Vos tickets',
    url: '/portail-editeur/vos-tickets'
  }
];

export const editorOrdersAndInvoicesBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Portail éditeur',
    url: '/dashboard/editeur'
  },
  {
    label: 'Commandes et factures',
    url: '/portail-editeur/commandes-et-factures'
  }
];
export const editorUserRightsBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Portail éditeur',
    url: '/dashboard/editeur'
  },
  {
    label: 'Utilisateur',
    url: '/portail-editeur/utilisateurs'
  },
  {
    label: ''
  }
];
export const editorDetailsLeadBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Portail éditeur',
    url: '/dashboard/editeur'
  },
  {
    label: ''
  }
];
export const editorDetailsRetailerBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Portail éditeur',
    url: '/dashboard/editeur'
  },
  {
    label: ''
  }
];
