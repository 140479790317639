import useSchedule from 'hooks/api/useSchedule';
import { SdaData } from 'hooks/api/useSda';
import React, { useState, ChangeEvent } from 'react';
import { Form, Button } from 'react-bootstrap';

type SdaFormProps = {
  tableData: SdaData[];
  setTableData: React.Dispatch<React.SetStateAction<SdaData[]>>;
  onClose: () => void;
};

const AddSdaForm: React.FC<SdaFormProps> = ({ tableData, setTableData, onClose }) => {
  const { scheduleData, loading, error } = useSchedule();

  const [newSda, setNewSda] = useState<SdaData>({
    id: 0,
    id_user: 0,
    sda: "",
    schedule: "",
    destination: "",
    destinationData: "",
    trunkTransfert: "",
    nrDestination: "",
    nrDestinationData: "",
    ringDuration: 0,
    nrTrunkTransfert: "",
    state: false
  });
  

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewSda(prevSda => ({
      ...prevSda,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/sda', {
        method: 'POST',
        headers: {
          'accept': 'text/plain',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newSda)
      });

      if (response.ok) {
        window.location.reload();
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: SdaData = await response.json();
      setTableData([...tableData, data]);
      onClose();
    } catch (error) {
      console.error("Erreur lors de l'ajout d'un sda", error);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="sda">
        <Form.Label>Sda</Form.Label>
        <Form.Control
          type="text"
          name="sda"
          value={newSda.sda}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <Form.Group controlId="schedule">
        <Form.Label>Horaire</Form.Label>
        <Form.Control
          as="select"
          name="schedule"
          className="form-select"
          value={newSda.schedule}
          onChange={handleChange}
          required
          disabled={loading}
        >
          <option value="">Sélectionnez un horaire</option>
          {scheduleData.map((schedule) => (
            <option key={schedule.id} value={schedule.name}>
              {schedule.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      <Form.Group controlId="destination">
        <Form.Label>Destination</Form.Label>
        <Form.Control
          as="select"
          className="form-select"
          name="destination"
          value={newSda.destination}
          onChange={handleChange}
          required
        >
          <option value="post_interne">Post Interne</option>
          <option value="queue">Queue</option>
          <option value="IVR">IVR</option>
          <option value="voicemail">Voicemail</option>
          <option value="messageVocal">Message Vocal</option>
          <option value="transfer_exterieur">Transfert Exterieur</option>
          <option value="groupe">Groupe</option>
        </Form.Control>
      </Form.Group>


      <Form.Group controlId="destinationData">
        <Form.Label>Données de destination</Form.Label>
        <Form.Control
          type="text"
          name="destinationData"
          value={newSda.destinationData}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="trunkTransfert">
        <Form.Label>Transfert de trunk</Form.Label>
        <Form.Control
          type="text"
          name="trunkTransfert"
          value={newSda.trunkTransfert}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="nrDestination">
        <Form.Label>Destination NR</Form.Label>
        <Form.Control
          as="select"
          className="form-select"
          name="nrDestination"
          value={newSda.nrDestination}
          onChange={handleChange}
          required
        >
          <option value="post_interne">Post Interne</option>
          <option value="queue">Queue</option>
          <option value="IVR">IVR</option>
          <option value="voicemail">Voicemail</option>
          <option value="messageVocal">Message Vocal</option>
          <option value="transfer_exterieur">Transfert Exterieur</option>
          <option value="groupe">Groupe</option>
        </Form.Control>
      </Form.Group>


      <Form.Group controlId="nrDestinationData">
        <Form.Label>Données de destination NR</Form.Label>
        <Form.Control
          type="text"
          name="nrDestinationData"
          value={newSda.nrDestinationData}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="ringDuration">
        <Form.Label>Durée de sonnerie</Form.Label>
        <Form.Control
          type="number"
          name="ringDuration"
          value={newSda.ringDuration}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="nrTrunkTransfert">
        <Form.Label>Transfert de trunk NR</Form.Label>
        <Form.Control
          type="text"
          name="nrTrunkTransfert"
          value={newSda.nrTrunkTransfert}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="state">
        <Form.Label>État</Form.Label>
        <Form.Check
          type="checkbox"
          name="state"
          checked={newSda.state}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setNewSda({
              ...newSda,
              state: e.target.checked
            })
          }
        />
      </Form.Group>

      <Button variant="primary" type="submit" className="mt-2">
        Ajouter
      </Button>
    </Form>
  );
};

export default AddSdaForm;
