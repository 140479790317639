import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { Member } from 'data/iper-x/editor/editorRetailerMembersInfo';
import Avatar from 'components/base/Avatar';
import { Link } from 'react-router-dom';

export const RetailerUsersColumn: ColumnDef<Member>[] = [
  {
    accessorKey: 'name',
    header: 'Compagnie',
    cell: ({ row: { original } }) => {
      const { name, avatar } = original;
      return (
        <Link
          to={`/portail-editeur/revendeur/${name}`}
          className="d-flex align-items-center text-900 text-hover-1000"
        >
          <Avatar src={avatar} size="m" />
          <p className="mb-0 ms-3 text-1100 fw-semi-bold">{name}</p>
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: '15%', minWidth: '200px' },
        className: 'px-1'
      },
      cellProps: { className: 'align-middle white-space-nowrap py-2' }
    }
  },
  {
    accessorKey: 'email',
    header: 'Email',
    cell: ({ row: { original } }) => {
      const { email } = original;
      return (
        <Link to={`mailto:${email}`} className="fw-semi-bold">
          {email}
        </Link>
      );
    },
    meta: {
      headerProps: { style: { width: '15%', minWidth: '200px' } },
      cellProps: { className: 'white-space-nowrap' }
    }
  }
  // other columns...
];

const RetailerUsersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default RetailerUsersTable;
