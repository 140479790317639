export interface Records {
  dateTime: string;
  name: string;
  sda: string;
  from: string;
  to: string;
  conversation: string;
}

export const recordsTableData: Records[] = [
  {
    dateTime: "15/01/2024 09:06:26",
    name: "Enregistrements",
    sda: "Enregistrements",
    from: "+33424020008:restaurant (+33424020008)",
    to: "IVR (80) ",
    conversation: "00:00:06"
  },
  {
    dateTime: "15/01/2024 09:06:26",
    name: "Enregistrements",
    sda: "Enregistrements",
    from: "+33424020008:restaurant (+33424020008)",
    to: "IVR (80) ",
    conversation: "00:00:06"
  },
  {
    dateTime: "15/01/2024 09:06:26",
    name: "Enregistrements",
    sda: "Enregistrements",
    from: "+33424020008:restaurant (+33424020008)",
    to: "IVR (80) ",
    conversation: "00:00:06"
  },
  {
    dateTime: "15/01/2024 09:06:26",
    name: "Enregistrements",
    sda: "Enregistrements",
    from: "+33424020008:restaurant (+33424020008)",
    to: "IVR (80) ",
    conversation: "00:00:06"
  },
  {
    dateTime: "15/01/2024 09:06:26",
    name: "Enregistrements",
    sda: "Enregistrements",
    from: "+33424020008:restaurant (+33424020008)",
    to: "IVR (80) ",
    conversation: "00:00:06"
  },
  {
    dateTime: "15/01/2024 09:06:26",
    name: "Enregistrements",
    sda: "Enregistrements",
    from: "+33424020008:restaurant (+33424020008)",
    to: "IVR (80) ",
    conversation: "00:00:06"
  },
  {
    dateTime: "15/01/2024 09:06:26",
    name: "Enregistrements",
    sda: "Enregistrements",
    from: "+33424020008:restaurant (+33424020008)",
    to: "IVR (80) ",
    conversation: "00:00:06"
  },
  {
    dateTime: "15/01/2024 09:06:26",
    name: "Enregistrements",
    sda: "Enregistrements",
    from: "+33424020008:restaurant (+33424020008)",
    to: "IVR (80) ",
    conversation: "00:00:06"
  },
  {
    dateTime: "15/01/2024 09:06:26",
    name: "Enregistrements",
    sda: "Enregistrements",
    from: "+33424020008:restaurant (+33424020008)",
    to: "IVR (80) ",
    conversation: "00:00:06"
  },
  {
    dateTime: "15/01/2024 09:06:26",
    name: "Enregistrements",
    sda: "Enregistrements",
    from: "+33424020008:restaurant (+33424020008)",
    to: "IVR (80) ",
    conversation: "00:00:06"
  },
  {
    dateTime: "15/01/2024 09:06:26",
    name: "Enregistrements",
    sda: "Enregistrements",
    from: "+33424020008:restaurant (+33424020008)",
    to: "IVR (80) ",
    conversation: "00:00:06"
  },
  {
    dateTime: "15/01/2024 09:06:26",
    name: "Enregistrements",
    sda: "Enregistrements",
    from: "+33424020008:restaurant (+33424020008)",
    to: "IVR (80) ",
    conversation: "00:00:06"
  },
  {
    dateTime: "15/01/2024 09:06:26",
    name: "Enregistrements",
    sda: "Enregistrements",
    from: "+33424020008:restaurant (+33424020008)",
    to: "IVR (80) ",
    conversation: "00:00:06"
  },
  {
    dateTime: "15/01/2024 09:06:26",
    name: "Enregistrements",
    sda: "Enregistrements",
    from: "+33424020008:restaurant (+33424020008)",
    to: "IVR (80) ",
    conversation: "00:00:06"
  },
  {
    dateTime: "15/01/2024 09:06:26",
    name: "Enregistrements",
    sda: "Enregistrements",
    from: "+33424020008:restaurant (+33424020008)",
    to: "IVR (80) ",
    conversation: "00:00:06"
  },
  {
    dateTime: "15/01/2024 09:06:26",
    name: "Enregistrements",
    sda: "Enregistrements",
    from: "+33424020008:restaurant (+33424020008)",
    to: "IVR (80) ",
    conversation: "00:00:06"
  }


];
