import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Badge from "components/base/Badge";
import { ScheduleData } from "hooks/api/useSchedule";
import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Modal, Form } from "react-bootstrap";

// Convertir la chaîne "Lundi-Mardi" en tableau ["Lundi", "Mardi"]
const convertDaysStringToArray = (daysString: string): string[] => {
  return daysString ? daysString.split('-').map(day => day.trim()) : [];
};

// Convertir le tableau ["Lundi", "Mardi"] en chaîne "Lundi-Mardi"
const convertDaysArrayToString = (daysArray: string[]): string => {
  return daysArray.join('-');
};

export const scheduleAPITableColumns: ColumnDef<ScheduleData>[] = [
  {
    accessorKey: "name",
    header: "Nom",
    meta: {
      headerProps: { style: { width: "20%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "startSlot1",
    header: "Créneau horaire 1 ouverture",
    meta: {
      headerProps: { style: { width: "20%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "endSlot1",
    header: "Créneau horaire 1 fermeture",
    meta: {
      headerProps: { style: { width: "20%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "startSlot2",
    header: "Créneau horaire 2 ouverture",
    meta: {
      headerProps: { style: { width: "20%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "endSlot2",
    header: "Créneau horaire 2 fermeture",
    meta: {
      headerProps: { style: { width: "20%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "days",
    header: "Jours",
    meta: {
      headerProps: { style: { width: "20%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "state",
    header: "État",
    cell: ({ row: { original } }) => {
      const { state } = original;
      return (
        <Badge
          bg={state ? "success" : "secondary"}
          variant="phoenix"
          iconPosition="end"
          className="fs-10"
        >
          {state ? "Actif" : "Inactif"}
        </Badge>
      );
    },
    meta: {
      headerProps: { style: { width: "10%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    id: 'leadDropdown',
    header: "Gérer",
    meta: {
      headerProps: { style: { fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    },
    cell: ({ row: { original } }) => {
      const [dropdownOpen, setDropdownOpen] = useState(false);
      const [showDeleteModal, setShowDeleteModal] = useState(false);
      const [showEditModal, setShowEditModal] = useState(false);
      const [selectedSchedule, setSelectedSchedule] = useState<ScheduleData | null>(null);
      const [formData, setFormData] = useState<ScheduleData | null>(null);
      const [selectedDays, setSelectedDays] = useState<string[]>([]);

      const toggle = () => setDropdownOpen(!dropdownOpen);

      const handleDeleteClick = (schedule: ScheduleData) => {
        setSelectedSchedule(schedule);
        setShowDeleteModal(true);
      };

      const handleEditClick = (schedule: ScheduleData) => {
        setSelectedSchedule(schedule);
        setFormData(schedule);
        setSelectedDays(convertDaysStringToArray(schedule.days));
        setShowEditModal(true);
      };

      const handleDeleteConfirm = async () => {
        if (selectedSchedule) {
          await handleDelete(selectedSchedule.id);
          setShowDeleteModal(false);
        }
      };

      const handleEditConfirm = async () => {
        if (formData) {
          const updatedData = { 
            ...formData, 
            days: convertDaysArrayToString(selectedDays)
          };
          await handleEdit(updatedData);
          setShowEditModal(false);
        }
      };

      const handleDelete = async (id: number) => {
        try {
          const response = await fetch(`https://rct-backend.ipercom.io/api/Ipbx/schedule/${id}`, {
            method: 'DELETE',
            headers: {
              'accept': 'text/plain',
            },
          });

          if (response.ok) {
            window.location.reload();
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        } catch (error) {
          console.error("Erreur lors de la suppression du plan d'appel", error);
        }
      };

      const handleEdit = async (data: ScheduleData) => {
        try {
          const { id, ...dataWithoutId } = data;
          const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/schedule', {
            method: 'PUT',
            headers: {
              'accept': 'text/plain',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          });

          if (response.ok) {
            window.location.reload();
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        } catch (error) {
          console.error("Erreur lors de la mise à jour du plan d'appel", error);
        }
      };

      const handleDaysChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = e.target;

        setSelectedDays((prevDays) =>
          checked ? [...prevDays, value] : prevDays.filter((day) => day !== value)
        );
      };

      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;

        const formattedValue = (name.startsWith('startSlot') || name.startsWith('endSlot')) && value.length === 5
          ? `${value}:00`
          : value;

        setFormData((prevData) =>
          prevData
            ? {
                ...prevData,
                [name]: type === 'checkbox' ? checked : formattedValue,
              }
            : null
        );
      };

      return (
        <>
          <Dropdown show={dropdownOpen} onToggle={toggle} style={{ display: 'flex', justifyContent: 'center' }}>
            <DropdownToggle as="div" onClick={toggle} style={{ cursor: 'pointer' }}>
              Actions
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => handleEditClick(original)}>Modifier</DropdownItem>
              <DropdownItem onClick={() => handleDeleteClick(original)}>Supprimer</DropdownItem>
            </DropdownMenu>
          </Dropdown>

          <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Confirmation de suppression</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Êtes-vous sûr de vouloir supprimer ce plan d'appel ?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                Annuler
              </Button>
              <Button variant="danger" onClick={handleDeleteConfirm}>
                Supprimer
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Modifier le plan d'appel</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                {formData && (
                  <>
                    <Form.Group controlId="formName">
                      <Form.Label>Nom</Form.Label>
                      <Form.Control type="text" name="name" value={formData.name} onChange={handleChange} />
                    </Form.Group>

                    <Form.Group controlId="formStartSlot1">
                      <Form.Label>Créneau horaire 1 ouverture</Form.Label>
                      <Form.Control
                        type="time"
                        name="startSlot1"
                        value={formData.startSlot1.slice(0, 5)}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group controlId="formEndSlot1">
                      <Form.Label>Créneau horaire 1 fermeture</Form.Label>
                      <Form.Control
                        type="time"
                        name="endSlot1"
                        value={formData.endSlot1.slice(0, 5)}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group controlId="formStartSlot2">
                      <Form.Label>Créneau horaire 2 ouverture</Form.Label>
                      <Form.Control
                        type="time"
                        name="startSlot2"
                        value={formData.startSlot2.slice(0, 5)}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group controlId="formEndSlot2">
                      <Form.Label>Créneau horaire 2 fermeture</Form.Label>
                      <Form.Control
                        type="time"
                        name="endSlot2"
                        value={formData.endSlot2.slice(0, 5)}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group controlId="days">
                      <Form.Label>Jours</Form.Label>
                      <div>
                        {["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"].map((day) => (
                          <Form.Check
                            inline
                            type="checkbox"
                            label={day}
                            name="days"
                            value={day}
                            checked={selectedDays.includes(day)}
                            onChange={handleDaysChange}
                            key={day}
                          />
                        ))}
                      </div>
                    </Form.Group>

                    <Form.Group controlId="formState">
                      <Form.Label>État</Form.Label>
                      <Form.Check type="checkbox" name="state" checked={formData.state} onChange={handleChange} />
                    </Form.Group>
                  </>
                )}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                Annuler
              </Button>
              <Button variant="primary" onClick={handleEditConfirm}>
                Enregistrer
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    },
  },
];

const ScheduleTableAPI = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9 text-center" }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default ScheduleTableAPI;
