import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

const IperxContactPageForm = () => {
  const [contact, setContact] = useState({
    NOM: '',
    'NUMÉRO FIXE': '',
    'NUMÉRO MOBILE': '',
    'DATE D\'APPEL': '',
    EMAIL: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContact(prevContact => ({
      ...prevContact,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Votre logique de soumission ici
  };

  return (
    <div>
      <h2>Ajouter un contact</h2>
      <Form onSubmit={handleSubmit} style={{width:'30%'}}>
        <Form.Group controlId="NOM">
          <Form.Label>NOM</Form.Label>
          <Form.Control type="text" name="NOM" value={contact.NOM} onChange={handleChange} required />
        </Form.Group>

        <Form.Group controlId="NUMÉRO FIXE">
          <Form.Label>NUMÉRO FIXE</Form.Label>
          <Form.Control type="text" name="NUMÉRO FIXE" value={contact['NUMÉRO FIXE']} onChange={handleChange} required />
        </Form.Group>

        <Form.Group controlId="NUMÉRO MOBILE">
          <Form.Label>NUMÉRO MOBILE</Form.Label>
          <Form.Control type="text" name="NUMÉRO MOBILE" value={contact['NUMÉRO MOBILE']} onChange={handleChange} required />
        </Form.Group>

        <Form.Group controlId="DATE D'APPEL">
          <Form.Label>DATE D'APPEL</Form.Label>
          <Form.Control type="date" name="DATE D'APPEL" value={contact['DATE D\'APPEL']} onChange={handleChange} required />
        </Form.Group>

        <Form.Group controlId="EMAIL">
          <Form.Label>EMAIL</Form.Label>
          <Form.Control type="email" name="EMAIL" value={contact.EMAIL} onChange={handleChange} required />
        </Form.Group>

        <Button variant="primary" type="submit" className='mt-4'>
          Ajouter
        </Button>
      </Form>
    </div>
  );
};

export default IperxContactPageForm;
