import { useState, useEffect } from 'react';

interface GroupMember {
  id: number;
  firstname: string;
  lastname: string;
  email?: string;
}

interface GroupInfo {
  id: number;
  name: string;
  members: GroupMember[];
}

const useRetailerGroupMembers = () => {
  const [group, setGroup] = useState<GroupInfo | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const DOLAPIKEY = localStorage.getItem('DOLAPIKEY') || 'UX8gfqaOcVv8692NNw11fkvE6Sh3DJ5M';

    const fetchGroupMembers = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch('https://erp.ipercom.io/api/index.php/users/groups/5', {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            DOLAPIKEY: DOLAPIKEY,
          },
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch: ${response.status}`);
        }

        const groupData = await response.json();

        // Assuming groupData has a `members` property with an array of users
        if (groupData && Array.isArray(groupData.members)) {
          const formattedMembers = groupData.members.map((member: any) => ({
            id: member.id,
            firstname: member.firstname,
            lastname: member.lastname,
            email: member.email || 'No email provided',
          }));

          setGroup({
            id: groupData.id,
            name: groupData.nom,  // Assuming 'nom' contains the group name
            members: formattedMembers,
          });
        } else {
          throw new Error('Invalid group data format');
        }

      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError('An unexpected error occurred');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchGroupMembers();
  }, []);

  return { group, loading, error };
};

export default useRetailerGroupMembers;
