import { faMicrophone, faMicrophoneAltSlash, faUsers, faMessage, faVideo, faShareSquare, faPhoneAlt, faPhoneFlip, faVideoSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IperxProfileWaitingCalling from "components/cards/ipbx/IperxProfileWaitingCalling";
import IperxNavigationCallingPage from "components/list-items/IperxNavigationCallingPage";
import { conversations } from "data/chat";
import ChatConversationDefault from "pages/apps/old/chat/iperx/ChatConversationDefault";
import ChatProvider from "providers/ChatProvider";
import { useState } from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import IperxCallingConferenceButton from './IperxCallingConferenceModal';
import profileImage from 'assets/img/team/59.webp';


const IperxCallingWaitingPage: React.FC<{ show: boolean }> = ({ show }) => {
  const [isActive, setIsActive] = useState(false);
  const [icon, setIcon] = useState(faMicrophone);
  const [showModal, setShowModal] = useState(false);
  const [showChat, setShowChat] = useState(false);

  const handleMuteMicrophone = () => {
    setIsActive(prevState => !prevState);
    setIcon(prevState => (prevState === faMicrophone ? faMicrophoneAltSlash : faMicrophone));
  };

  const toggleChatVisibility = () => {
    setShowChat(prevState => !prevState);
  };

  return (
    <>
      <Card>
        <div className="d-flex justify-content-end m-2 me-4 pb-2 gap-3 gap-sm-6 gap-xxl-2 align-items-center border-bottom">
          <Link to="#" onClick={() => setShowModal(true)}>
            <FontAwesomeIcon icon={faUsers} className="me-5" />
          </Link>
          <Link to="#" onClick={toggleChatVisibility}>
            <FontAwesomeIcon icon={faMessage} className="me-5" />
          </Link>
          <span className="me-5">|</span>
          <Link to="/iperx/utilisateurs/appel-waiting/:name/video">
            <FontAwesomeIcon icon={faVideo} className="me-5" />
          </Link>
          <Link to="#" onClick={handleMuteMicrophone}>
            <FontAwesomeIcon icon={icon} className="me-5" />
          </Link>
          <Link to="#">
            <FontAwesomeIcon icon={faShareSquare} className="me-5" />
          </Link>
          <Link to="/iperx/utilisateurs/appel">
            <Button variant="danger">Arrêter</Button>
          </Link>
        </div>
        <IperxCallingConferenceButton show={showModal} handleClose={() => setShowModal(false)} />

      <div className="mt-0 mb-2 text-center d-flex">
      <Row className="g-2 flex-column align-items-center" style={{width: showChat ? '77%' : '100%'}}>
        <Col className="d-none d-lg-block">
                <IperxProfileWaitingCalling
                  avatar={profileImage}
                />
              </Col>
          <Col className="d-none d-lg-block">
            <div>
            <Link to="/iperx/utilisateurs/appel/:name" className="text-success">
                    <FontAwesomeIcon icon={faPhoneAlt} className="me-15 text-success fa-2x" />
                  </Link>
                  <Link to="/iperx/utilisateurs/appel" className="text-error">
                    <FontAwesomeIcon icon={faPhoneFlip} className="text-danger fa-2x"  style={{transform: 'rotate(225deg)'}}/>
                  </Link>
            </div>
          </Col>
          <Col xs={12} lg={5} className="mb-2 mt-0 m-3">
            <IperxNavigationCallingPage />
          </Col>
        </Row>
        {showChat && (
          <Row className="align-items-end" style={{width: '33%'}}>
            <ChatProvider conversations={conversations}>
              <Col xs={2} sm={5} md={10} xl={12} className="border-start chat">
                <ChatConversationDefault />
              </Col>
            </ChatProvider>
          </Row>
        )}
      </div>
      </Card>
    </>
  );
};

export default IperxCallingWaitingPage;
