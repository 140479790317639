import { MsgVocalData } from 'hooks/api/useMsgVocal';
import React, { useState, ChangeEvent } from 'react';
import { Form, Button } from 'react-bootstrap';

type MsgVocalFormProps = {
  tableData: MsgVocalData[];
  setTableData: React.Dispatch<React.SetStateAction<MsgVocalData[]>>;
  onClose: () => void;
};

const AddMsgVocalForm: React.FC<MsgVocalFormProps> = ({ tableData, setTableData, onClose }) => {
  const [newMsgVocal, setNewMsgVocal] = useState<MsgVocalData>({
    id: 0,
    id_user: 0,
    name: "",
    url: "",
    date: new Date().toISOString(),
    state: false
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewMsgVocal(prevMsgVocal => ({
      ...prevMsgVocal,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/msgvocal', {
        method: 'POST',
        headers: {
          'accept': 'text/plain',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newMsgVocal)
      });

      if (response.ok) {
        window.location.reload();
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: MsgVocalData = await response.json();
      setTableData([...tableData, data]);
      onClose();
    } catch (error) {
      console.error("Erreur lors de l'ajout d'un message vocal", error);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="name">
        <Form.Label>Nom</Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={newMsgVocal.name}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="url">
        <Form.Label>URL</Form.Label>
        <Form.Control
          type="text"
          name="url"
          value={newMsgVocal.url}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="date">
        <Form.Label>Date</Form.Label>
        <Form.Control
          type="datetime-local"
          name="date"
          value={newMsgVocal.date}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="state">
        <Form.Label>État</Form.Label>
        <Form.Check
          type="checkbox"
          name="state"
          checked={newMsgVocal.state}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setNewMsgVocal({
              ...newMsgVocal,
              state: e.target.checked
            })
          }
        />
      </Form.Group>

      <Button variant="primary" type="submit" className="mt-2">
        Ajouter
      </Button>
    </Form>
  );
};

export default AddMsgVocalForm;
