import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Badge from "components/base/Badge";
import { RoutingData } from "hooks/api/useRouting";
import useSchedule from "hooks/api/useSchedule";
import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Modal, Form } from "react-bootstrap";

export const routingAPITableColumns: ColumnDef<RoutingData>[] = [
  {
    accessorKey: "prefixe",
    header: "Préfixe",
    meta: {
      headerProps: { style: { width: "10%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "schedule",
    header: "Horaire",
    meta: {
      headerProps: { style: { width: "10%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "trunk",
    header: "Trunk",
    meta: {
      headerProps: { style: { width: "10%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "action",
    header: "Action",
    meta: {
      headerProps: { style: { width: "10%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "displayNumber",
    header: "Numéro d'affichage",
    meta: {
      headerProps: { style: { width: "15%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "state",
    header: "État",
    cell: ({ row: { original } }) => {
      const { state } = original;
      return (
        <Badge
          bg={state ? "success" : "secondary"}
          variant="phoenix"
          iconPosition="end"
          className="fs-10"
        >
          {state ? "Actif" : "Inactif"}
        </Badge>
      );
    },
    meta: {
      headerProps: { style: { width: "10%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    id: 'leadDropdown',
    header: "Gérer",
    meta: {
      headerProps: { style: { fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    },
    cell: ({ row: { original } }) => {
      const [dropdownOpen, setDropdownOpen] = useState(false);
      const [showDeleteModal, setShowDeleteModal] = useState(false);
      const [showEditModal, setShowEditModal] = useState(false);
      const [selectedRouting, setSelectedRouting] = useState<RoutingData | null>(null);
      const [formData, setFormData] = useState<RoutingData | null>(null);

      const toggle = () => setDropdownOpen(!dropdownOpen);

      const handleDeleteClick = (routing: RoutingData) => {
        setSelectedRouting(routing);
        setShowDeleteModal(true);
      };

      const handleEditClick = (routing: RoutingData) => {
        setSelectedRouting(routing);
        setFormData(routing);
        setShowEditModal(true);
      };

      const handleDeleteConfirm = async () => {
        if (selectedRouting) {
          await handleDelete(selectedRouting.id);
          setShowDeleteModal(false);
        }
      };
      const { scheduleData, loading, error } = useSchedule();

      const handleEditConfirm = async () => {
        if (formData) {
          await handleEdit(formData);
          setShowEditModal(false);
        }
      };

      const handleDelete = async (id: number) => {
        try {
          const response = await fetch(`https://rct-backend.ipercom.io/api/Ipbx/routing/${id}`, {
            method: 'DELETE',
            headers: {
              'accept': 'text/plain',
            },
          });

          if (response.ok) {
            window.location.reload();
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        } catch (error) {
          console.error("Erreur lors de la suppression de la règle de routage", error);
        }
      };

      const handleEdit = async (data: RoutingData) => {
        try {
          const { id, ...dataWithoutId } = data;
          const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/routing', {
            method: 'PUT',
            headers: {
              'accept': 'text/plain',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          });

          if (response.ok) {
            window.location.reload();
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        } catch (error) {
          console.error("Erreur lors de la mise à jour de la règle de routage", error);
        }
      };

      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => prevData ? {
          ...prevData,
          [name]: type === 'checkbox' ? checked : value
        } : null);
      };

      return (
        <>
          <Dropdown show={dropdownOpen} onToggle={toggle} style={{ display: 'flex', justifyContent: 'center' }}>
            <DropdownToggle as="div" onClick={toggle} style={{ cursor: 'pointer' }}>
              Actions
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => handleEditClick(original)}>Modifier</DropdownItem>
              <DropdownItem onClick={() => handleDeleteClick(original)}>Supprimer</DropdownItem>
            </DropdownMenu>
          </Dropdown>

          <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Confirmation de suppression</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Êtes-vous sûr de vouloir supprimer cette règle sortante ?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                Annuler
              </Button>
              <Button variant="danger" onClick={handleDeleteConfirm}>
                Supprimer
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Modifier la règle sortante</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="prefixe">
                  <Form.Label>Préfixe</Form.Label>
                  <Form.Control
                    type="text"
                    name="prefixe"
                    value={formData?.prefixe || ""}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="schedule">
                  <Form.Label>Horaire</Form.Label>
                  <Form.Control
                    as="select"
                    name="schedule"
                    className="form-select"
                    value={formData?.schedule || ""}
                    onChange={handleChange}
                    required
                    disabled={loading}
                  >
                    <option value="">Sélectionnez un horaire</option>
                    {scheduleData.map((schedule) => (
                      <option key={schedule.id} value={schedule.name}>
                        {schedule.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="trunk">
                  <Form.Label>Trunk</Form.Label>
                  <Form.Control
                    type="text"
                    name="trunk"
                    value={formData?.trunk || ""}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="action">
                  <Form.Label>Action</Form.Label>
                  <Form.Control
                    as="select"
                    className="form-select"
                    name="action"
                    value={formData?.action || ""}
                    onChange={handleChange}
                    required
                  >
                    <option value="post_interne">Authorized</option>
                    <option value="queue">Blocked</option>
         
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="displayNumber">
                  <Form.Label>Numéro d'affichage</Form.Label>
                  <Form.Control
                    type="text"
                    name="displayNumber"
                    value={formData?.displayNumber || ""}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="state">
                  <Form.Check
                    type="checkbox"
                    name="state"
                    label="État"
                    checked={formData?.state || false}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                Annuler
              </Button>
              <Button variant="primary" onClick={handleEditConfirm}>
                Enregistrer
              </Button>
            </Modal.Footer>
          </Modal>

        </>
      );
    }
  }
];

const RoutingTableAPI = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9 text-center" }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default RoutingTableAPI;
