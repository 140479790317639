import MainLayout from 'layouts/MainLayout';
import { createBrowserRouter, RouteObject } from 'react-router-dom';
import MainLayoutProvider from 'providers/MainLayoutProvider';
import Error404 from 'pages/error/Error404';
import App from 'App';
import React, { lazy } from 'react';
import DashboardRetailer from './pages/apps/retailer/DashboardRetailer';
import DashboardEditor from 'pages/apps/editor/DashboardEditor';
import TicketsList from 'pages/apps/editor/ticket/ETicketsList';
import DetailsLeadDashboardInEditor from 'pages/apps/editor/DetailsLeadDashboardInEditor';
import UsersRightsDashboardInEditor from 'pages/apps/editor/UsersRightsDashboardInEditor copy';
import UsersList from './pages/apps/ipbx/UsersList';
import DevicesList from './pages/apps/ipbx/DevicesList';
import DetailsRetailerDashboardInEditor from 'pages/apps/editor/detail_retailer/DetailsRetailerDashboardInEditor';
import BackupsList from './pages/apps/ipbx/BackupsList';
import ContactsList from './pages/apps/ipbx/ContactsList';
import CallLogsList from './pages/apps/ipbx/report/CallLogsList';
import UsersProfile from './pages/apps/ipbx/UsersProfile';
import RecordsList from './pages/apps/ipbx/report/RecordsList';
import ReportsList from './pages/apps/ipbx/report/ReportsList';
import CDRConfig from './pages/apps/ipbx/report/CDRConfig';
import FaqIpbx from './pages/apps/ipbx/FaqIpbx';
import SecurityConfig from './pages/apps/ipbx/security/SecurityConfig';
import AllowedCountry from './pages/apps/ipbx/security/AllowedCountry';
import RootCredentialsConfig from './pages/apps/ipbx/security/RootCredentialsConfig';
import BlacklistedNumbersList from './pages/apps/ipbx/security/BlacklistedNumbersList';
import BlacklistedIPList from './pages/apps/ipbx/security/BlacklistedIPList';
import SecureSIPConfig from './pages/apps/ipbx/security/SecureSIPConfig';
import TunnelTrunksConfig from './pages/apps/ipbx/security/TunnelTrunksConfig';
import PhoneBookList from './pages/apps/ipbx/advance/PhoneBookList';

import RetailerCertificationsHistoryList from './pages/apps/retailer/RetailerCertificationsHistoryList';
import DownloadClients from './pages/apps/retailer/DownloadClients';
import DashboardIpbx from './pages/apps/ipbx/DashboardIpbx';
import SignIn from './pages/auth/SignIn';
import AddExtensionDeviceForm from './pages/apps/ipbx/extension/AddExtensionDeviceForm';
import Settings from './pages/apps/ipbx/settings/Settings';
import ChatConversation from 'pages/apps/old/chat/ChatConversation';
import ChatHomepage from 'pages/apps/old/chat/ChatHomepage';
import IperxMessagerie from 'pages/apps/webrtc/IperxMessagerie';
import IperxParamChat from 'pages/apps/webrtc/IperxParam/IperxParamChat';
import IperxParamAnnonce from 'pages/apps/webrtc/IperxParam/IperxParamAnnonce';
import IperxParamIntegration from 'pages/apps/webrtc/IperxParam/IperxParamIntegration';
import IperxParamQRcode from 'pages/apps/webrtc/IperxParam/IperxParamQRcode';
import IperxParamRaccourci from 'pages/apps/webrtc/IperxParam/IperxParamRaccourci';
import IperxParamRepository from 'pages/apps/webrtc/IperxParam/IperxParamRepository';
import IperxParamTransfertAppel from "pages/apps/webrtc/IperxParam/Transfert d'appel/IperxParamTransfertAppel";
import IperxParamVisualiser from 'pages/apps/webrtc/IperxParam/IperxParamVisualiser';
import IperxHistoryUserCalls from 'pages/apps/webrtc/IperxHistoryUserCalls';
import IperxCallingPage from 'pages/apps/webrtc/IperxCall/IperxCallingPage';
import IperxMessagePage from 'pages/apps/webrtc/IperxChat';
import IperxChat from 'pages/apps/webrtc/IperxChat';
import IperxMeetingPage from 'pages/apps/webrtc/IperxMeetingFiles/IperxMeetingPage';
import IperxUsersProfile from 'pages/apps/ipbx/IperxUsersProfile';
import IperxContactPage from 'pages/apps/webrtc/IperxContactPage';
import IperxCallingWaitingPage from 'pages/apps/webrtc/IperxCall/IperxCallingWaitingPage';
import IperxCallingTapPage from 'pages/apps/webrtc/IperxCall/IperxCallingTapPage';
import IperxParamAudioVideo from 'pages/apps/webrtc/IperxParam/IperxParamAudioVideo';
import IperxParam from 'pages/apps/webrtc/IperxParam/IperxParam';
import CodecsList from './pages/apps/ipbx/settings/apps/CodecsList';
import OptionsConfig from './pages/apps/ipbx/settings/apps/OptionsConfig';
import CallParkingConfig from './pages/apps/ipbx/settings/system/CallParkingConfig';
import ConferenceConfig from './pages/apps/ipbx/settings/system/ConferenceConfig';
import IperxCalendarCreateAnEvent from 'pages/apps/webrtc/IperxMeetingFiles/calendar/events/IperxCalendarCreateAnEvent';
import IperxCalendarEventDetail from 'pages/apps/webrtc/IperxMeetingFiles/calendar/events/IperxCalendarEventDetail';
import ShortcutCodesConfig from 'pages/apps/ipbx/settings/system/ShortcutCodesConfig';
import EmailConfig from './pages/apps/ipbx/settings/system/EmailConfig';
import GeneralConfig from './pages/apps/ipbx/settings/system/general/GeneralConfig';
import HoldMusicConfig from './pages/apps/ipbx/settings/system/HoldMusicConfig';
import VoiceInstructionsConfig from './pages/apps/ipbx/settings/system/VoiceInstructionsConfig';
import IperxCallingConference from 'pages/apps/webrtc/IperxCall/IperxCallingConference';
import IperxCallingConferenceVideo from 'pages/apps/webrtc/IperxCall/IperxCallingConferenceVideo';
import IperxMeetingCall from 'pages/apps/webrtc/IperxMeetingFiles/IperxMeetingCall';
import IperxCallingPageVideo from 'pages/apps/webrtc/IperxCall/IperxCallingPageVideo';
import CRMIntegrationConfig from './pages/apps/ipbx/settings/integration/crm/CRMIntegrationConfig';
import IperxChatModal from 'pages/apps/webrtc/IperxParam/IperxChatModal';
import GoogleIntegrationConfig from './pages/apps/ipbx/settings/integration/google/GoogleIntegrationConfig';
import IperxCallingWaitingPageVideo from 'pages/apps/webrtc/IperxCall/IperxCallingWaitingPageVideo';
import HotelServicesConfig from './pages/apps/ipbx/settings/integration/hotel-services/HotelServicesConfig';
import Microsoft365IntegrationConfig from './pages/apps/ipbx/settings/integration/microsoft/Microsoft365IntegrationConfig';
import ReplacementE164Config from './pages/apps/ipbx/settings/advance/ReplacementE164Config';
import LanguageOfficeHoursConfig from 'pages/apps/ipbx/settings/advance/LanguageOfficeHoursConfig';
import UpdateConfig from './pages/apps/ipbx/settings/advance/UpdateConfig';
import RetailerLeadDetails from './pages/apps/retailer/leads/RetailerLeadDetails';
import RetailerDetails from './pages/apps/retailer/detail/RetailerDetails';
import IperxUsers from 'pages/apps/webrtc/IperxUsers';
import IperxContactPageForm from 'pages/apps/webrtc/IperxContactPageForm';
import OrderInvoicesList from 'pages/apps/retailer/orders_invoices/OrderInvoicesList';
import IpbxUsersProfile from 'pages/apps/ipbx/IpbxUsersProfile';
import DashboardMain from './pages/dashboard/DashboardMain';
import Logout from './pages/auth/Logout';
import TicketDetail from "./pages/apps/retailer/tickets/TicketDetail";
import OrderList from "./pages/apps/retailer/orders_invoices/OrderList";
import InvoicesList from 'pages/apps/retailer/orders_invoices/InvoicesList';
import OrderCreate from "./pages/apps/retailer/orders_invoices/OrderCreate";
import TrunkListAPI from 'pages/apps/ipbx/trunk-sip/TrunkListAPI';
import SdaListAPI from 'pages/apps/ipbx/sda/SdaListAPI';
import RoutingListAPI from 'pages/apps/ipbx/routing/RoutingListAPI';
import GroupListAPI from 'pages/apps/ipbx/group/GroupListAPI';
import QueueListAPI from 'pages/apps/ipbx/queue/QueueListApi';
import MsgVocalListAPI from 'pages/apps/ipbx/msgvoc/MsgVocalListApi';
import VoicemailListAPI from 'pages/apps/ipbx/voicemail/VoicemailListAPI';
import ScheduleListAPI from 'pages/apps/ipbx/schedule/ScheduleListAPI';
import UserListAPI from 'pages/apps/editor/user/UserListAPI';
import UserListAPIRetailer from 'pages/apps/retailer/users/UserListAPIRetailer';
import OrderInvoicesListEditor from 'pages/apps/editor/order_invoices/OrderInvoicesListEditor';
import ProtectedRoute from 'pages/ProtectedRoute';
import AccessDenied from 'pages/error/AccessDenied';
import OrderDetails from "./pages/apps/retailer/orders_invoices/OrderDetails";
import InvoiceDetails from "./pages/apps/retailer/orders_invoices/InvoiceDetails";
import UserPhoneListAPI from "./pages/apps/ipbx/users/UserPhoneListAPI";
import UserDetails from "./pages/apps/retailer/users/UserDetails";
import ETicketsList from "pages/apps/editor/ticket/ETicketsList";
import ThiersList from "./pages/apps/editor/thiers/ThiersList";
import RetailerCreate from "./pages/apps/retailer/users/RetailerCreate";
import UserCreate from "./pages/apps/editor/retailer/UserCreate";
import ThiersDetails from "./pages/apps/editor/thiers/ThiersDetails";

const FontAwesomeExample = lazy(
  () => import('pages/modules/components/FontAwesomeExample')
);

const FeatherIconsExample = lazy(
  () => import('pages/modules/components/FeatherIconsExample')
);

const UniconsExample = lazy(
  () => import('pages/modules/components/UniconsExample')
);

const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: (
          <MainLayoutProvider>
            <MainLayout />
          </MainLayoutProvider>
        ),
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute requiredRoles={['admin', 'editeur', 'dev', 'revendeur', 'com']}>
                <DashboardMain />
              </ProtectedRoute>
            ),
          },
          {
            path: 'dashboard/revendeur',
            element: (
              <ProtectedRoute requiredRoles={['admin', 'dev', 'revendeur', 'com']}>
                <DashboardRetailer />
              </ProtectedRoute>
            ),
          },
          {
            path: 'dashboard/editeur',
            element: (
              <ProtectedRoute requiredRoles={['admin', 'editeur', 'dev', 'com']}>
                <DashboardEditor />
              </ProtectedRoute>
            ),
          },
          {
            path: '/portail-revendeur',
            children: [
              {
                path: 'commandes-et-factures',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'revendeur', 'com']}>
                    <OrderInvoicesList />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'commandes',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'revendeur', 'com']}>
                    <OrderList />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'commandes/details/:orderId',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'revendeur', 'com']}>
                    <OrderDetails />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'commandes/ajouter',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'revendeur', 'com']}>
                    <OrderCreate />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'factures',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'revendeur', 'com']}>
                    <InvoicesList />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'factures/details/:invoiceRef',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'revendeur', 'com']}>
                    <InvoiceDetails />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'utilisateurs',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'revendeur', 'com']}>
                    <UserListAPIRetailer />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'utilisateurs/create',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'revendeur', 'com']}>
                    <RetailerCreate />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'utilisateurs/details/:id',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'revendeur', 'com']}>
                    <UserDetails />
                    {/*<UsersProfile />*/}
                  </ProtectedRoute>
                ),
              },
              {
                path: 'vos-tickets',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'revendeur', 'com']}>
                    <TicketsList />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'vos-tickets/:id',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'revendeur', 'com']}>
                    <TicketDetail />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'gestion-des-droits-utilisateurs',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'revendeur', 'com']}>
                    <UserListAPIRetailer />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'certifications',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'revendeur', 'com']}>
                    <RetailerCertificationsHistoryList />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'téléchargements',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'revendeur', 'com']}>
                    <DownloadClients />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'parametres',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'revendeur', 'com']}>
                    <UserListAPIRetailer />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'lead/:name',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'revendeur', 'com']}>
                    <RetailerLeadDetails />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'revendeur/:name',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'revendeur', 'com']}>
                    <RetailerDetails />
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            path: '/portail-editeur',
            children: [
              {
                path: 'utilisateurs',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'editeur','dev', 'com']}>
                    <UserListAPI />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'utilisateurs/details/:name',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'editeur', 'dev', 'com']}>
                    <UsersProfile />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'vos-tickets',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'editeur', 'dev', 'com']}>
                    <ETicketsList />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'vos-thiers',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'editeur', 'dev', 'com']}>
                    <ThiersList />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'vos-thiers/details/:id',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'editeur', 'dev', 'com']}>
                    <ThiersDetails />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'create-thiers',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'editeur', 'dev', 'com']}>
                    <RetailerCreate  show/>
                  </ProtectedRoute>
                ),
              },

              {
                path: 'create-user',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'editeur', 'dev', 'com']}>
                    <UserCreate />
                  </ProtectedRoute>
                ),
              },

              {
                path: 'commandes-et-factures',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'editeur', 'dev', 'com']}>
                    <OrderInvoicesListEditor />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'utilisateurs/gestion-des-droits-utlisateurs/:name',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'editeur', 'dev', 'com']}>
                    <UsersRightsDashboardInEditor />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'leads/:name',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'editeur', 'dev', 'com']}>
                    <DetailsLeadDashboardInEditor />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'revendeur/:name',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'editeur', 'dev', 'com']}>
                    <DetailsRetailerDashboardInEditor />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'parametres',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'editeur', 'dev', 'com']}>
                    <UserListAPIRetailer />
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            path: 'ipbx',
            children: [
              {
                path: 'dashboard',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur']}>
                    <DashboardIpbx />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'utilisateurs',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur']}>
                    <UserPhoneListAPI />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'utilisateurs/details/:name',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur']}>
                    <IpbxUsersProfile />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'téléphones',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur']}>
                    <DevicesList />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'téléphones/ajouter',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur']}>
                    <AddExtensionDeviceForm />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'trunk-sip',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <TrunkListAPI />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'gestion-des-appels/editor',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <ScheduleListAPI />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'gestion-des-appels/regles-entrantes',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <SdaListAPI />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'gestion-des-appels/regles-sortantes',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <RoutingListAPI />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'gestion-des-appels/repondeurs-numeriques',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <VoicemailListAPI />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'gestion-des-appels/groupes-d-appels',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <GroupListAPI />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'gestion-des-appels/files-d-attente',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <QueueListAPI />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'gestion-des-appels/musique-d-attente',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <MsgVocalListAPI />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'sauvegardes',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <BackupsList />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'contacts',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <ContactsList />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'rapport/journaux-appels',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <CallLogsList />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'rapport/parametres-cdr',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <CDRConfig />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'rapport/rapports',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <ReportsList />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'rapport/enregistrements',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <RecordsList />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'securite/anti-piratage',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <SecurityConfig />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'securite/codes-de-pays-autorises',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <AllowedCountry />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'securite/identifiants-racine',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <RootCredentialsConfig />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'securite/numeros-en-liste-noire',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <BlacklistedNumbersList />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'securite/liste-noire-IP-adresses',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <BlacklistedIPList />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'securite/SIP-sécurisé',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <SecureSIPConfig />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'securite/tunnel-trunks',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <TunnelTrunksConfig />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'avancés/annuaire',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <PhoneBookList />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'faq',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <FaqIpbx />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'parametres',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <Settings />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'parametres/codecs',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <CodecsList />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'parametres/options',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <OptionsConfig />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'parametres/call-parking-config',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <CallParkingConfig />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'parametres/conference',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <ConferenceConfig />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'parametres/shortcut-codes',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <ShortcutCodesConfig />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'parametres/email',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <EmailConfig />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'parametres/general',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <GeneralConfig />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'parametres/hold-music',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <HoldMusicConfig />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'parametres/voice-instructions',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <VoiceInstructionsConfig />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'parametres/voice-messaging',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <MsgVocalListAPI />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'parametres/crm-integration',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <CRMIntegrationConfig />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'parametres/google-integration',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <GoogleIntegrationConfig />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'parametres/hotel-services',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <HotelServicesConfig />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'parametres/microsoft-integration',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <Microsoft365IntegrationConfig />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'parametres/replacement-e164',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <ReplacementE164Config />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'parametres/language-office-hours',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <LanguageOfficeHoursConfig />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'parametres/update',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <UpdateConfig />
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            path: 'webrtc',
            children: [
              {
                path: 'utilisateurs',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <IperxUsers />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'utilisateurs/details/:name',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <IperxUsersProfile />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'chat',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <IperxChat />
                  </ProtectedRoute>
                ),
                children: [
                  {
                    index: true,
                    element: <ChatHomepage />,
                  },
                  {
                    path: ':userId/conversation',
                    element: <ChatConversation />,
                  },
                  {
                    path: ':userId/appel/conversation',
                    element: <IperxChatModal />,
                  },
                ],
              },
              {
                path: 'reunion',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <IperxMeetingPage />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'reunion/appel',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <IperxMeetingCall />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'reunion-events',
                children: [
                  {
                    path: 'create-an-event',
                    element: (
                      <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                        <IperxCalendarCreateAnEvent />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: 'event-detail',
                    element: (
                      <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                        <IperxCalendarEventDetail />
                      </ProtectedRoute>
                    ),
                  },
                ],
              },
              {
                path: 'historique-appel',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <IperxHistoryUserCalls />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'utilisateurs/appel',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <IperxCallingTapPage />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'utilisateurs/appel-waiting/:name',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <IperxCallingWaitingPage show={false} />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'utilisateurs/appel-waiting/:name/video',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <IperxCallingWaitingPageVideo show={false} />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'utilisateurs/appel/:name',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <IperxCallingPage show={false} />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'utilisateurs/appel/:name/video',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <IperxCallingPageVideo show={false} />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'utilisateurs/appel/conference',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <IperxCallingConference show={false} />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'utilisateurs/appel/conference/video',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <IperxCallingConferenceVideo show={false} />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'utilisateurs/message/:name',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <IperxMessagePage />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'contact',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <IperxContactPage />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'contact/formulaire',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <IperxContactPageForm />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'messagerie',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <IperxMessagerie />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'faq',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <FaqIpbx />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'parametres',
                element: (
                  <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                    <IperxParam />
                  </ProtectedRoute>
                ),
                children: [
                  {
                    path: 'annonce',
                    element: (
                      <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                        <IperxParamAnnonce />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: 'audio-video',
                    element: (
                      <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                        <IperxParamAudioVideo />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: 'chat',
                    element: (
                      <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                        <IperxParamChat />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: 'integration',
                    element: (
                      <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                        <IperxParamIntegration />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: 'qrcode',
                    element: (
                      <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                        <IperxParamQRcode />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: 'raccourci',
                    element: (
                      <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                        <IperxParamRaccourci />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: 'repertoire',
                    element: (
                      <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                        <IperxParamRepository />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: 'transfert-appel',
                    element: (
                      <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                        <IperxParamTransfertAppel />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: 'visualiser',
                    element: (
                      <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                        <IperxParamVisualiser />
                      </ProtectedRoute>
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: 'faq',
            element: (
              <ProtectedRoute requiredRoles={['admin', 'dev', 'com','editeur','revendeur',]}>
                <FaqIpbx />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: 'login',
        element: <SignIn />,
      },
      {
        path: '/logout',
        element: <Logout />,
      },
      {
        path: '*',
        element: <Error404 />,
      },
      {
        path: 'accessDenied',
        element: <AccessDenied />,
      },
    ],
  },
];


export const router = createBrowserRouter(routes);

export default routes;
