import React from 'react';
import { Card, Col, FloatingLabel, Form, Row } from 'react-bootstrap';

const ParamNotificationsConfig = () => {
  return (
    <>
      <Card className="">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">
                Adresse email pour les notifications
              </h3>
              <span className="me-3">
                Spécifiez le serveur mail à utiliser pour envoyer des
                messageries vocales, fax, notifications d'appels manqués et
                alertes.
              </span>

              <Form.Group className="mb-3" controlId="formServeurSMTP">
                <Form.Label>
                  Entrez une ou plusieurs adresses email séparées par des
                  virgules.
                </Form.Label>
                <FloatingLabel controlId="floatingSelect" label="Serveur SMTP">
                  <Form.Select aria-label="Floating label select example">
                    <option>support@pigma.fr</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-5 mb-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">Événements</h3>
              <span className="me-3">
                Envoyer une alerte email lorsque les évènements suivant
                surviennent :
              </span>

              <Form.Check type="checkbox" id="emergencyCall" className="mt-2">
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Un numéro d'urgence a été composé
                </Form.Check.Label>
              </Form.Check>

              <Form.Check
                type="checkbox"
                id="trunkSBCStatusChange"
                className="mt-2"
              >
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Quand le statut d'un trunk / SBC change
                </Form.Check.Label>
              </Form.Check>

              <Form.Check
                type="checkbox"
                id="trunkFailoverMaxCalls"
                className="mt-2"
              >
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Un failover de trunks a eu lieu ou le nombre max d'appels
                  disponibles sur un trunk a été dépassé
                </Form.Check.Label>
              </Form.Check>

              <Form.Check
                type="checkbox"
                id="trunkOperatorError"
                className="mt-2"
              >
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Le trunk/opérateur répond aux requêtes avec code d'erreur
                </Form.Check.Label>
              </Form.Check>

              <Form.Check type="checkbox" id="ipBlacklist" className="mt-2">
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Une IP a été mise en liste noire
                </Form.Check.Label>
              </Form.Check>

              <Form.Check
                type="checkbox"
                id="antiHackingRejections"
                className="mt-2"
              >
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Des requêtes ont été rejetées/bloquées par le module
                  AntiHacking en raison d'abus
                </Form.Check.Label>
              </Form.Check>

              <Form.Check
                type="checkbox"
                id="dnsNetworkFailure"
                className="mt-2"
              >
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Résolution DNS/Panne réseau
                </Form.Check.Label>
              </Form.Check>

              <Form.Check
                type="checkbox"
                id="stunResolutionFailure"
                className="mt-2"
              >
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Échec de la résolution STUN
                </Form.Check.Label>
              </Form.Check>

              <Form.Check
                type="checkbox"
                id="diskCapacityReached"
                className="mt-2"
              >
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Le disque dur a atteint ou presque atteint sa capacité
                  maximale
                </Form.Check.Label>
              </Form.Check>

              <Form.Check
                type="checkbox"
                id="blockedCountryCall"
                className="mt-2"
              >
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Appel vers un pays bloqué
                </Form.Check.Label>
              </Form.Check>

              <Form.Check
                type="checkbox"
                id="adminConsoleAccess"
                className="mt-2"
              >
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Connexion à la console d'administration Iper-x
                </Form.Check.Label>
              </Form.Check>

              <Form.Check
                type="checkbox"
                id="extensionAddedNotification"
                className="mt-2"
              >
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Notifier l'utilisateur lorsqu'une extension est ajoutée
                </Form.Check.Label>
              </Form.Check>

              <Form.Check type="checkbox" id="serviceFailure" className="mt-2">
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Les services Iper-x sont stoppés ou échouent
                </Form.Check.Label>
              </Form.Check>
            </Col>
          </Row>
        </Card.Body>
      </Card>

    </>
  );
};

export default ParamNotificationsConfig;
