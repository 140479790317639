import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { OutboundRules } from '../../../data/iper-x/ipbx/outboundRules';
import RevealDropdown, {
  RevealDropdownTrigger
} from '../../base/RevealDropdown';
import ActionDropdownItems from '../../common/ActionDropdownItems';
import { HoldMusicPlaylist } from "../../../data/iper-x/ipbx/holdMusicPlaylist";
import VoiceInstructionsConfig from "../../../pages/apps/ipbx/settings/system/VoiceInstructionsConfig";
import { VoiceInstructions } from "../../../data/iper-x/ipbx/voiceInstructions";

export const voiceInstructionsTableColumns: ColumnDef<VoiceInstructions>[] = [
  {
    accessorKey: 'id',
    header: 'ID directive',
    meta: {
      headerProps: { style: { width: '25%' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: 'fileName',
    header: 'Fichier',
    meta: {
      headerProps: { style: { width: '35%' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: 'desc',
    header: 'Description',
    meta: {
      headerProps: { style: { width: '35%' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    id: 'leadDropdown',
    header: 'Gérer',
    meta: {
      headerProps: { style: { width: '25%' } },
      cellProps: { className: 'text-900 fs-9' }
    },
    cell: () => {
      return (
        <RevealDropdownTrigger>
          <RevealDropdown>
            <ActionDropdownItems />
          </RevealDropdown>
        </RevealDropdownTrigger>
      );
    }
  }
];

const VoiceInstructionsTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default VoiceInstructionsTable;
