import ChatContent from 'components/modules/iperx/chat/chat-content';
import { useBreakpoints } from 'providers/BreakpointsProvider';
import { useChatContext } from 'providers/ChatProvider';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SET_CURRENT_CONVERSATION } from 'reducers/ChatReducer';

const ChatConversationDefault = () => {
  const { userId } = useParams();

  // Set userId to '1' by default if not provided
  const defaultUserId = userId || '1';

  const { chatDispatch, conversations } = useChatContext();

  const { breakpoints } = useBreakpoints();
  useEffect(() => {
    chatDispatch({
      type: SET_CURRENT_CONVERSATION,
      payload: {
        userId: defaultUserId
      }
    });
  }, [userId, conversations]);

  return (
    <>
      {/*{breakpoints.up('sm') && <ChatSidebar />}*/}
      <ChatContent />
    </>
  );
};

export default ChatConversationDefault;
