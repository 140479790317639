import { ProgressBar } from 'react-bootstrap';

const RetailerRanksProgressBar = () => {
  return (
    <div className="w-100">
      <div className="d-flex flex-start">
        <div style={{ width: '20.72%' }}>
          <p className="mb-2 text-700 fw-semi-bold fs-9">IVOIRE</p>
        </div>
        <div style={{ width: '35.76%' }}>
          <p className="mb-2 text-700 fw-semi-bold fs-9">SILVER</p>
        </div>
        <div style={{ width: '25.38%' }}>
          <p className="mb-2 text-700 fw-semi-bold fs-9">GOLD</p>
        </div>
        <div style={{ width: '25.14%' }}>
          <p className="mb-2 text-700 fw-semi-bold fs-9">PLATINIUM</p>
        </div>
      </div>

      <ProgressBar className="mb-3 rounded-3" style={{ height: '10px' }}>
        <ProgressBar
          className="bg-warning border-end border-white border-2"
          now={20.72}
          key={1}
        />
        <ProgressBar
          className="bg-success border-end border-white border-2"
          now={35.76}
          key={2}
        />
        <ProgressBar
          className="bg-success border-end border-white border-2"
          now={25.38}
          key={3}
        />
        <ProgressBar
            className="bg-white border border-gray border-1"
           now={25.14}
           key={4}
        />
      </ProgressBar>
    </div>
  );
};

export default RetailerRanksProgressBar;
