import IperxParamTAmenu from "./IperxParamTAmenu"

const IperxParamTransfertAppel = () => {

  return (
    <>
    <h2 className="fs-6 mb-3">Transfert d'appels</h2>
    <IperxParamTAmenu />
    </>
  );
};


export default IperxParamTransfertAppel;
