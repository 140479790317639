import React, { useEffect } from 'react';

const Logout: React.FC = () => {
  useEffect(() => {
    // Clear localStorage
    localStorage.clear();
    window.location.href = '/login';
  }, []);

  return <div>Logged out successfully!</div>;
};

export default Logout;
