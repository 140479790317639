import React from 'react';
import { Button, Nav } from 'react-bootstrap';
import {
  faFilm,
  faBriefcase,
  faSearch,
  faDesktop
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ipercom from 'assets/img/logos/ipercom.png'

const ParamIntegration = () => {
  return (
    <>
      <div className="mb-5">
        <div className="g-3 row">
          <div className="col-xl-12 gy-3">
            <Nav className="" style={{ width: '90%' }}>
              <Button
                className="btn bg-white px-5 pt-4 pb-3 fs-8  nav-link "
                href="/ipbx/parametres/crm-integration"
              >
                <FontAwesomeIcon
                  icon={faFilm}
                  className="category-icon text-800 fs-6"
                />
             <span className="d-block fs-12 fw-bolder lh-1 text-900 mt-3 mb-2">
                  CRM
                </span>
              </Button>

              <Button
                className="btn bg-white px-5 ms-5 pt-4 pb-3 fs-8  nav-link "
                href="/ipbx/parametres/google-integration"
              >
                <FontAwesomeIcon
                  icon={faSearch}
                  className="category-icon text-800 fs-6"
                />
                <span className="d-block fs-12 fw-bolder lh-1 text-900 mt-3 mb-2">
                  Google
                </span>
              </Button>

              <Button
                className="btn bg-white px-5 ms-5 pt-4 pb-3 fs-8  nav-link "
                href="/ipbx/parametres/hotel-services"
              >
                <FontAwesomeIcon
                  icon={faBriefcase}
                  className="category-icon text-800 fs-6"
                />
                <span className="d-block fs-12 fw-bolder lh-1 text-900 mt-3 mb-2">
                  Services hôtel
                </span>
              </Button>

              <Button
                className="btn bg-white px-5 ms-5 pt-4 pb-3 fs-8  nav-link "
                href="/ipbx/parametres/microsoft-integration"
              >
                <FontAwesomeIcon
                  icon={faDesktop}
                  className="category-icon text-800 fs-6"
                />
                <span className="d-block fs-12 fw-bolder lh-1 text-900 mt-3 mb-2">
                  Microsoft
                </span>
              </Button>
              <Button
                className="btn bg-white ms-5 pt-4 pb-3 fs-8  nav-link "
                href="https://www.ipercom.com/"
              >
                <img src={ipercom} alt="logo ipercom" style={{width:'60%'}} />
                <span className="d-block fs-12 fw-bolder lh-1 text-900 mt-3 mb-2">
                  ERP Ipercom
                </span>
              </Button>
            </Nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParamIntegration;
