import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { Card, Dropdown } from 'react-bootstrap';
import { useChatContext } from 'providers/ChatProvider';
import classNames from 'classnames';
import { useMemo } from 'react';
import {
  faChevronDown,
  faChevronLeft,
  faCircle,
  faEllipsisVertical,
  faPhone,
  faVideo
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const ChatContentHeader = () => {
  const {
    currentConversation,
    setShowConversationDetails,
    setShowUserListOffcanvas
  } = useChatContext();

  const firstName = useMemo(() => {
    return currentConversation?.user.name.split(' ')[0] || '';
  }, [currentConversation]);

  return (
    <>
      {currentConversation && (
        <>
          <Card.Header className="p-3 p-md-4 d-flex flex-between-center">
            <div className="d-flex align-items-center">

              <div  className="fs-7 fw-semi-bold text-1100 d-flex align-items-center p-0 me-3 text-start btn">
                <span>Chat</span>
              </div>



            </div>
          </Card.Header>
        </>
      )}
    </>
  );
};

export default ChatContentHeader;
