import { useState, useCallback, useEffect } from "react";

export interface VoicemailData {
  id: number,
  id_user: number,
  nameUser: string,
  emailUser: string,
  password: string,
  voicemailNumber: string,
  state: boolean
}

const voicemailTableData: VoicemailData[] = [
  {
    id: 1,
    id_user: 9,
    nameUser: "Jhon",
    emailUser: "jhon@cocombre.fr",
    password: "27l96e4!",
    voicemailNumber: "75888",
    state: true
  }
];

const useVoicemail = () => {
  const [voicemailData, setVoicemailData] = useState(voicemailTableData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    console.log("Fetching new API data...");
    try {
      const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/voicemail', {
        method: 'GET',
        headers: {
          'accept': 'text/plain',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setVoicemailData(data);
      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données de l'API", error);
      setError('Impossible de récupérer les données.');
      setVoicemailData(voicemailTableData);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    voicemailData,
    setVoicemailData,
    loading,
    error
  };
};

export default useVoicemail;
