import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import useTicketsData, { Ticket } from '../../../../hooks/dolibarr/useTicketsData'; // Adjust the import path as needed

const TicketDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useTicketsData();
  const [ticket, setTicket] = useState<Ticket | null>(null);

  useEffect(() => {
    const foundTicket = data.find(ticket => ticket.id === Number(id));
    setTicket(foundTicket || null);
  }, [data, id]);

  if (!ticket) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2>Ticket Detail</h2>
      <p><strong>Author:</strong> {ticket.author}</p>
      <p><strong>Subject:</strong> {ticket.subject}</p>
      <p><strong>Type:</strong> {ticket.type}</p>
      <p><strong>Severity:</strong> {ticket.severity}</p>
      <p><strong>Company:</strong> {ticket.company}</p>
      <p><strong>Assignee:</strong> {ticket.assignee}</p>
      <p><strong>Creation Date:</strong> {ticket.creationDate}</p>
      <p><strong>Status:</strong> {ticket.status.label}</p>
      <p><strong>Assigned to:</strong> {ticket.assigness.map(a => a.name).join(', ')}</p>
      <p><strong>Progress:</strong> {ticket.progress?.min} / {ticket.progress?.max}</p>
      <div>
        <Button variant="primary" onClick={() => alert('Edit action')}>Edit</Button>
        <Button variant="danger" onClick={() => alert('Delete action')}>Delete</Button>
        <Button variant="success" onClick={() => alert('Complete action')}>Complete</Button>
      </div>
    </div>
  );
};

export default TicketDetail;
