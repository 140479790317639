import VoiceInstructionsConfig from "../../../pages/apps/ipbx/settings/system/VoiceInstructionsConfig";

export interface VoiceInstructions {
  id: string;
  fileName: string;
  desc: string;
}

export const voiceInstructionsTableData: VoiceInstructions[] = [
  {
    id: "ACCDND",
    fileName: "access_denied.wav",
    desc: "Accès refusé.Intonation de refus",
  },
  {
    id: "ACCDND",
    fileName: "access_denied.wav",
    desc: "Accès refusé.Intonation de refus",
  },
  {
    id: "ACCDND",
    fileName: "access_denied.wav",
    desc: "Accès refusé.Intonation de refus",
  },
  {
    id: "ACCDND",
    fileName: "access_denied.wav",
    desc: "Accès refusé.Intonation de refus",
  },
  {
    id: "ACCDND",
    fileName: "access_denied.wav",
    desc: "Accès refusé.Intonation de refus",
  },
  {
    id: "ACCDND",
    fileName: "access_denied.wav",
    desc: "Accès refusé.Intonation de refus",
  },
  {
    id: "ACCDND",
    fileName: "access_denied.wav",
    desc: "Accès refusé.Intonation de refus",
  },
  {
    id: "ACCDND",
    fileName: "access_denied.wav",
    desc: "Accès refusé.Intonation de refus",
  },
  {
    id: "ACCDND",
    fileName: "access_denied.wav",
    desc: "Accès refusé.Intonation de refus",
  },
  {
    id: "ACCDND",
    fileName: "access_denied.wav",
    desc: "Accès refusé.Intonation de refus",
  },
  {
    id: "ACCDND",
    fileName: "access_denied.wav",
    desc: "Accès refusé.Intonation de refus",
  },
  {
    id: "ACCDND",
    fileName: "access_denied.wav",
    desc: "Accès refusé.Intonation de refus",
  },


];
