import React from 'react';
import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  InputGroup,
  Row
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCirclePlus,
  faCog,
  faSave,
  faTrash
} from '@fortawesome/free-solid-svg-icons';

const GoogleClientConfig = () => {
  return (
    <>
      <Card className="">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <Form.Check type="checkbox" id="sameAsShipping" className="4">
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  <h3 className="fw-bolder mb-2 line-clamp-1">
                    Administrator Sign in
                  </h3>
                </Form.Check.Label>
              </Form.Check>

              <span className="me-3">
                Autoriser les utilisateurs à se connecter en tant
                qu'administrateur du standard téléphonique IPER-X
              </span>

              <Button variant="btn btn-white border border-1 px-2">
                <FontAwesomeIcon icon={faCirclePlus} className="me-2" />
                Ajouter
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <Form.Check type="checkbox" id="sameAsShipping" className="4">
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  <h3 className="fw-bolder mb-2 line-clamp-1">
                    Sélection aléatoire
                  </h3>
                </Form.Check.Label>
              </Form.Check>

              <span className="me-3 mb-2">
                Autoriser les utilisateurs à se connecter au client web et à la
                console d'administration (« Autoriser l'accès à la console
                d'administration iperx » doit être activé)
              </span>

              <Form.Group className="mb-1" controlId="personalAccount">
                <Form.Check
                  inline
                  type="radio"
                  name="accountType"
                  id="personalAccount"
                  className="me-2"
                  defaultChecked
                />
                <Form.Label className="mb-0">
                  Tous les utilisateurs
                </Form.Label>
              </Form.Group>

              <Form.Group className="mb-1" controlId="personalAccount">
                <Form.Check
                  inline
                  type="radio"
                  name="accountType"
                  id="personalAccount"
                  className="me-2"
                  defaultChecked
                />
                <Form.Label className="mb-0">
                  Tous les utilisateurs sauf ceux-ci :
                </Form.Label>
              </Form.Group>

              <Form.Group className="mb-2" controlId="personalAccount">
                <Form.Check
                  inline
                  type="radio"
                  name="accountType"
                  id="personalAccount"
                  className="me-2"
                  defaultChecked
                />
                <Form.Label className="mb-0">
                  Seulement ces utilisateurs :
                </Form.Label>
              </Form.Group>

              <Button variant="btn btn-white border border-1 px-2">
                <FontAwesomeIcon icon={faCirclePlus} className="me-2" />
                Ajouter
              </Button>

              <Button variant="btn btn-white border border-1 px-2 ms-2">
                <FontAwesomeIcon icon={faTrash} className="me-2" />
                Supprimer
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default GoogleClientConfig;
