import classNames from 'classnames';
import Avatar from 'components/base/Avatar';
import Badge from 'components/base/Badge';
import { Conversation } from 'data/chat'; // Import des données de conversation
import { useChatContext } from 'providers/ChatProvider'; // Import du contexte de chat
import React, { useMemo } from 'react'; // Import de React et de la fonction useMemo
import { Nav } from 'react-bootstrap'; // Import du composant de navigation de react-bootstrap
import { Link } from 'react-router-dom'; // Import du composant de lien de react-router-dom
import { MARKED_AS_READ } from 'reducers/ChatReducer'; // Import de l'action MARKED_AS_READ du reducer ChatReducer

// Composant représentant un élément de la liste des utilisateurs
const UserListItem = ({ conversation }: { conversation: Conversation }) => {
  const { currentConversation, chatDispatch } = useChatContext(); // Utilisation du contexte de chat

  // Utilisation de useMemo pour calculer le dernier message et le nombre de messages non lus de manière efficace
  const lastMessage = useMemo(
    () => conversation.messages[conversation.messages.length - 1],
    [conversation]
  );
  const unseenMessageCount = useMemo(
    () =>
      conversation.messages.filter(
        message => message.type === 'received' && !message.readAt
      ).length,
    [conversation]
  );

  // Fonction pour marquer la conversation comme lue
  const markedAsRead = () => {
    chatDispatch({
      type: MARKED_AS_READ,
      payload: { conversationId: conversation.id }
    });
  };

  return (
    <Nav.Item
      key={conversation.id}
      className={unseenMessageCount > 0 ? 'read' : 'unread'} // Applique une classe CSS en fonction du nombre de messages non lus
    >
      <Nav.Link
        as={Link}
        to={`/iperx/chat/${conversation.user.id}/conversation`} // Lien vers la conversation avec l'utilisateur
        onClick={markedAsRead} // Appelle la fonction pour marquer comme lu lors du clic
        className={classNames( // Gère les classes CSS dynamiquement
          'd-flex align-items-center justify-content-center p-2',
          {
            unread: unseenMessageCount > 0, // Applique la classe "unread" si des messages non lus sont présents
            active: currentConversation?.user.id === conversation.user.id // Applique la classe "active" si la conversation est la conversation actuelle
          }
        )}
      >
        <div className="position-relative me-2 me-sm-0 me-xl-2">
          <Avatar
            src={conversation.user.avatar} // Affiche l'avatar de l'utilisateur
            size="xl"
            className="d-block"
            imageClassName="border border-2 border-white"
          />
          {unseenMessageCount > 0 && ( // Affiche le badge indiquant le nombre de messages non lus
            <span
              className="bg-primary rounded-circle top-0 end-0 position-absolute text-white d-flex flex-center fs-10 fw-semi-bold d-none d-sm-flex d-xl-none lh-1"
              style={{ height: '1rem', width: '1rem' }}
            >
              {unseenMessageCount}
            </span>
          )}
        </div>
        <div className="flex-1 d-sm-none d-xl-block">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="text-900 fw-normal name text-nowrap">
              {conversation.user.name} {/* Affiche le nom de l'utilisateur */}
            </h5>
            <p className="fs-10 text-600 mb-0 text-nowrap">
              {lastMessage.time} {/* Affiche l'heure du dernier message */}
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <p className="fs-9 mb-0 line-clamp-1 text-600 message">
              {lastMessage.message} {/* Affiche le dernier message */}
            </p>
            {unseenMessageCount > 0 && ( // Affiche le badge avec le nombre de messages non lus
              <Badge
                variant="phoenix"
                bg="primary"
                className="px-1 unread-badge ms-1"
              >
                {unseenMessageCount}
              </Badge>
            )}
          </div>
        </div>
      </Nav.Link>
    </Nav.Item>
  );
};

// Composant représentant la liste des utilisateurs avec lesquels l'utilisateur a des conversations en cours
const UserList = ({ conversations }: { conversations: Conversation[] }) => {
  return (
    <div className="scrollbar">
      <Nav className="chat-conversation-tab flex-column">
        {conversations.map(conversation => (
          <UserListItem conversation={conversation} key={conversation.id} />
        ))}
      </Nav>
    </div>
  );
};

export default UserList; // Export du composant UserList
