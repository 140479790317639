import Avatar from 'components/base/Avatar';
import { Card, Col, Dropdown, Form, ProgressBar, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import Button from '../../../../components/base/Button';
import React from 'react';
import FeatherIcon from "feather-icons-react";
import Badge from "../../../../components/base/Badge";

const isRTL = '';
const name = '';
const avatar = '';
const RetailerProfileCard = ({ className }: { className?: string }) => {
  return (
    <Card className={classNames(className)}>
      <Card.Body>
        <Row className="align-items-center g-3 text-center text-xxl-start">
          <Col xs={12} sm="auto" className="flex-1">
            <h3 className="fw-bolder mb-2">GEKOM</h3>

            <div className="d-md-flex d-xl-block align-items-center justify-content-between mb-5 pt-3">
              <div className="d-flex align-items-center mb-3 mb-md-0 mb-xl-3">
                <Avatar size="xl" src={avatar} className="me-3  status-gold" />
                <div>
                  <h5>Milind Mikuja GEKOM</h5>
                  <p className="text-800">PDG, Siège Sociale</p>
                </div>
              </div>
              <div>
              <Badge variant="phoenix" bg="success" className="me-2">
                  Succès
                </Badge>
                <Badge variant="phoenix" bg="danger" className="me-2">
                  Perdu
                </Badge>
                <Badge variant="phoenix" bg="secondary">
                  Fermer
                </Badge>
              </div>
            </div>
            <ProgressBar variant="primary-200" now={40} className="mb-2" />
            <div className="d-flex align-items-center justify-content-between">
              <p className="mb-0">New</p>
              <div>
                <FeatherIcon
                  icon="clock"
                  className="lh-sm me-1"
                  width={16}
                  height={16}
                />
                <span className="d-inline-block lh-sm">Dec 15, 05:00AM</span>
              </div>

            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default RetailerProfileCard;
