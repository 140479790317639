import { faDownload, faPlay, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Form, Card, FormControl, InputGroup, Button } from "react-bootstrap";

const IperxParamAnnonce = () => {
  return (
    <>
      <h2 className="fs-7 mb-3">Annonces</h2>
      <Card className="p-4 my-4">
        <Card.Text>Sélectionner ou télécharger une annonce de bienvenue personnalisée pour la jouer pour chaque statut</Card.Text>
        <Form>
          <InputGroup className="align-items-center">
            <Form.Check
              type='checkbox'
              id='defaultCheckbox'
              label='Enregistrer une annonce'
              className="justify-content-end ml-auto"
            />
          </InputGroup>
          {["Défaut", "Disponible", "Absent", "Ne pas déranger", "Lunch", "Business Trip"].map((status, idx) => (
            <Row key={idx} className="mb-3 align-items-end">
              <Col>
                <Form.Label>{status}</Form.Label>
                <Form.Select aria-label="Default select example">
                  <option>Jouer le son par défaut</option>
                  <option value="1">Jouer le son par défaut</option>
                  <option value="2">Jouer le son par défaut</option>
                  <option value="3">Jouer le son par défaut</option>
                </Form.Select>
              </Col>
              <Col>
                <Button variant="outline-secondary" className="me-2"><FontAwesomeIcon icon={faUpload} className="fs-8 me-2" />Uploader</Button>
                <Button variant="outline-secondary" className="me-2"><FontAwesomeIcon icon={faDownload} className="fs-8 me-2" />Télécharger</Button>
                <Button variant="outline-secondary"><FontAwesomeIcon icon={faPlay} className="fs-8 me-2" />Écouter</Button>
              </Col>
            </Row>
          ))}
        </Form>
      </Card>
    </>
  );
};

export default IperxParamAnnonce;
