import CoverUpload from 'components/common/CoverUpload';
import coverImage from 'assets/img/generic/59.png';
import AvatarUpload from 'components/common/AvatarUpload';
import { Card, Col, Row } from 'react-bootstrap';
import { CSSProperties } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import {
  faPencil,
} from '@fortawesome/free-solid-svg-icons';

interface SocialProfileCardProps {
  showAbout?: boolean;
  avatar: string;
  className?: string;
}

const IperxSocialProfileCard = ({
  showAbout,
  avatar,
  className
}: SocialProfileCardProps) => {
  return (
    <Card className={className}>
      <Card.Header
        className="position-relative mb-6"
        style={{ minHeight: '130px' }}
      >
        <CoverUpload
          src={coverImage}
          gradient={
            'linear-gradient(0deg, #000000 -3%, rgba(0, 0, 0, 0) 83%)' as CSSProperties
          }
        />
        <AvatarUpload
          size="4xl"
          src={avatar}
          status="do-not-disturb"
          thumbnail
          className="feed-avatar-profile"
        />

      </Card.Header>
      <Card.Body>
        <Row>
          <Col xs={12}>
            <div className="d-flex flex-wrap mb-2 align-items-center">
              <h3 className="me-0">PIGMA COMMUNICATION</h3>
            </div>

            <div className="mt-4">
              <p className="fw-semi-bold mb-0">
                Commerciale
                <Link to="#!">
                  <FontAwesomeIcon
                      icon={faPencil}
                      className="fs-10 text-500 ms-3"
                  />
                </Link>
              </p>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default IperxSocialProfileCard;
