export interface Reports {
  name: string;
  type: string;
  frequency: string;
  status: string;

}

export const reportsTableData: Reports[] = [
  {

    name: "HOL",
    type: "Réunion",
    frequency: "Standard",
    status: "End Call"

  },

  {

    name: "HOL",
    type: "Réunion",
    frequency: "Standard",
    status: "End Call"

  },

  {

    name: "HOL",
    type: "Réunion",
    frequency: "Standard",
    status: "End Call"

  },

  {

    name: "HOL",
    type: "Réunion",
    frequency: "Standard",
    status: "End Call"

  },

  {

    name: "HOL",
    type: "Réunion",
    frequency: "Standard",
    status: "End Call"

  },

  {

    name: "HOL",
    type: "Réunion",
    frequency: "Standard",
    status: "End Call"

  }


];
