import { BadgeBg } from "components/base/Badge";
import generic40 from "assets/img/generic/40.png";

interface Badge {
  label: string;
  bg: BadgeBg;
}

export interface InfoItem {
  task: string;
  date: string;
  time: string;
  attachment?: number;
  badge?: Badge;
  listitems?: number;
  completed?: boolean;
}

export interface SubTaskItem {
  task: string;
}

export interface Attachment {
  name: string;
  mimeType: string;
  size: string;
  user: string;
  date: string;
  thumbnail?: string;
}

export const statusList: InfoItem[] = [
  {
    task: "Trunks enregistrés",
    badge: {
      label: "3/3",
      bg: "warning"
    },
    attachment: 2,
    date: "12 Nov, 2021",
    time: "12:00 PM"
  },
  {
    task: "Extensions enregistrées",
    badge: {
      label: "3/3",
      bg: "warning"
    },
    attachment: 2,
    date: "12 Nov, 2021",
    time: "12:00 PM"
  },
  {
    task: "Nombre d'appels en cours",
    badge: {
      label: "3/3",
      bg: "warning"
    },
    attachment: 2,
    date: "12 Nov, 2021",
    time: "12:00 PM"
  },
  {
    task: "IPs en liste noire",
    badge: {
      label: "5",
      bg: "warning"
    },
    attachment: 2,
    date: "12 Nov, 2021",
    time: "12:00 PM"
  },
  {
    task: "Historique d'appels",
    badge: {
      label: "Purger 2K + appels",
      bg: "warning"
    },
    attachment: 2,
    date: "12 Nov, 2021",
    time: "12:00 PM"
  },
  {
    task: "Logs du chat",
    badge: {
      label: "Désactiver",
      bg: "warning"
    },
    attachment: 2,
    date: "12 Nov, 2021",
    time: "12:00 PM"
  },

  {
    task: "Journal d’audit",
    badge: {
      label: "On 07/24/2024 00:00:13",
      bg: "warning"
    },
    attachment: 2,
    date: "12 Nov, 2021",
    time: "12:00 PM"
  },

  {
    task: "Sauvegardes automatiques",
    badge: {
      label: "-/8.0GB",
      bg: "warning"
    },
    attachment: 2,
    date: "12 Nov, 2021",
    time: "12:00 PM"
  },
  {
    task: "Enregistrement",
    badge: {
      label: "3/3",
      bg: "warning"
    },
    attachment: 2,
    date: "12 Nov, 2021",
    time: "12:00 PM"
  }


];

export const infoList: InfoItem[] = [
  {
    task: "Licence",
    badge: {
      label: "KO3M-Q8I4-4MIV-VB4R",
      bg: "warning"
    },
    attachment: 2,
    date: "12 Nov, 2021",
    time: "12:00 PM"
  },
  {
    task: "Nom d'instance serveur",
    badge: {
      label: "ipbx-4da35.ipercom.eu",
      bg: "warning"
    },
    attachment: 2,
    date: "12 Nov, 2021",
    time: "12:00 PM"
  },
  {
    task: "Produit",
    badge: {
      label: "Professionnelle annuelle",
      bg: "warning"
    },
    attachment: 2,
    date: "12 Nov, 2021",
    time: "12:00 PM"
  },
  {
    task: "Appels simultanés",
    badge: {
      label: "4",
      bg: "warning"
    },
    attachment: 2,
    date: "12 Nov, 2021",
    time: "12:00 PM"
  },
  {
    task: "Places en réunion",
    badge: {
      label: "100",
      bg: "warning"
    },
    attachment: 2,
    date: "12 Nov, 2021",
    time: "12:00 PM"
  },
  {
    task: "Date d'expiration",
    badge: {
      label: "Date 30/04/2024",
      bg: "warning"
    },
    attachment: 2,
    date: "12 Nov, 2021",
    time: "12:00 PM"
  },
  {
    task: "Version",
    badge: {
      label: "18.0 Mise à jour 9 (Build 20)",
      bg: "warning"
    },
    attachment: 2,
    date: "12 Nov, 2021",
    time: "12:00 PM"
  },

  {
    task: "Mon partenaire",
    badge: {
      label: "PIGMA COMMUNICATIONS",
      bg: "warning"
    },
    attachment: 2,
    date: "12 Nov, 2021",
    time: "12:00 PM"
  },

  {
    task: "FQDN",
    badge: {
      label: "-/8.0GB",
      bg: "warning"
    },
    attachment: 2,
    date: "12 Nov, 2021",
    time: "12:00 PM"
  },
  {
    task: "IPv4",
    badge: {
      label: "3/3",
      bg: "warning"
    },
    attachment: 2,
    date: "12 Nov, 2021",
    time: "12:00 PM"
  },
  {
    task: "IPv6",
    badge: {
      label: "3/3",
      bg: "warning"
    },
    attachment: 2,
    date: "12 Nov, 2021",
    time: "12:00 PM"
  },
  {
    task: "Règles sortantes",
    badge: {
      label: "3/3",
      bg: "warning"
    },
    attachment: 2,
    date: "12 Nov, 2021",
    time: "12:00 PM"
  }
];

export const subTasks: SubTaskItem[] = [
  {
    task: "Study Dragons"
  },
  {
    task: "Procrastinate a bit"
  },
  {
    task: "Staring at the notebook for 5 mins"
  }
];

export const attachments: Attachment[] = [
  {
    name: "Silly_sight_1.png",
    mimeType: "image",
    size: "768kb",
    user: "Shantinan Mekalan",
    date: "21st Dec, 12:56 PM",
    thumbnail: generic40
  },
  {
    name: "All_images.zip",
    mimeType: "zip",
    size: "12.8 mb",
    user: "Yves Tanguy",
    date: "19th Dec, 08:56 PM"
  },
  {
    name: "Project.txt",
    mimeType: "txt",
    size: "123 kb",
    user: "Shantinan Mekalan",
    date: "12th Dec, 12:56 PM"
  }
];
