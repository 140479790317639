import { Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";

const SecureSIPConfig = () => {

  return (
    <>


      <Card className="">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">

              <h3 className="fw-bolder mb-2 line-clamp-1">
                SIP sécurisé
              </h3>


              <span className="d-none d-sm-inline-block my-2">
                       Pour utiliser le Secure SIP vous devrez créer une clé et un certificat pour chaque interface réseau. Les téléphones ne sont pas tous supportés et les services doivent être redémarrés ! Cliquer ici pour plus d'informations.
                </span>


              <Form.Label>Certificat</Form.Label>
              <Col xs={12} className="gy-6 mb-5">

                <FloatingLabel
                  controlId="floatingProjectOverview"
                  label="Certificat"
                >
                  <Form.Control
                    as="textarea"
                    value="-----BEGIN CERTIFICATE-----
MIIE+TCCA+GgAwIBAgISA1ZWgGQao/J7eEUBam5zG5x9MA0GCSqGSIb3DQEBCwUA
MDIxCzAJBgNVBAYTAlVTMRYwFAYDVQQKEw1MZXQncyBFbmNyeXB0MQswCQYDVQQD
EwJSMzAeFw0yMzEyMjQwMTU1MjBaFw0yNDAzMjMwMTU1MTlaMCExHzAdBgNVBAMT
FmZyZW5jaGJ1cmdlcnMub24zY3guZnIwggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAw
ggEKAoIBAQD3RUSNe72VGZ/wFiXMSj5PPg1kkMdYx0Y+2J36B6BuNPxkFCf1VsqD
me8fsByUI6VOBFfIdLA6o/IwR1cEhsTzPQ6ipKz4HMvv/5FSxaNzSbUiiCdwmXVV
vHXEcO3WKZOifh/M9laFMnLhfmkoepxP4eglLBlh3zhlYC5Zg0OXo9Q9TwWmuehB
HERQlJwL4b9dVBcccohSNt+PhRYYD4QKFdvevFdZy2LZLlVJ67iLa1JmD3IADQEf
P2tTZZ7e0hvfAY3vtugNXndQ2lUeYXse9BcIS3Hwjk0Ok+QugP
Wjp0AAABjJm/nEsAAAQDAEgwRgIhAIWWxMQc/nXlbqy0PYhFSH7qsjjuvj9IGRCu
gQ4GmZ9XAiEAukjepiNOxEkoCfkOqge85HHBTxzLK3f4Qw4c8zYG0xQwDQYJKoZI
hvcNAQELBQADggEBADobU3SUO/zXOSnbs+KXpq85skOSzW5vG2xIAua2lj+y+Vb5
h+gOF3r1md++8M6pNAIHT6qKgVW+7nwMRYura5+m6BC5yPlz67GIMoaEdo1w1idH
jBh59v2U6/kBq7h1csAvj/AuqDv2xBjo0zP4GJpaUMHXjQFIGSQx1FQDb3VZoiaS
VGJ9ZkQ2H4rgLwvBtuXmT9k+LsyAechYNYSwwzY6/Cl9LXjyVkmJPIrlfrYD6/Z0
xt992encNvyREmsiiPPu5rmkj7d3ycY+nkQzWrr37T/YbIOI2hm13fjIeuSMNDn6
FhAUS0oWC5QDq0y/rOG9jwfPI47UsiWCVNHRfgQ=
-----END CERTIFICATE-----
"
                    style={{ height: "340px" }}
                  />
                </FloatingLabel>
              </Col>

              <Form.Label>Clé privée</Form.Label>
              <Col xs={12} className="gy-6 mb-5">

                <FloatingLabel
                  controlId="floatingProjectOverview"
                  label="Clé privée"
                >
                  <Form.Control
                    as="textarea"
                    value="-----BEGIN CERTIFICATE-----
MIIE+TCCA+GgAwIBAgISA1ZWgGQao/J7eEUBam5zG5x9MA0GCSqGSIb3DQEBCwUA
MDIxCzAJBgNVBAYTAlVTMRYwFAYDVQQKEw1MZXQncyBFbmNyeXB0MQswCQYDVQQD
EwJSMzAeFw0yMzEyMjQwMTU1MjBaFw0yNDAzMjMwMTU1MTlaMCExHzAdBgNVBAMT
FmZyZW5jaGJ1cmdlcnMub24zY3guZnIwggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAw
ggEKAoIBAQD3RUSNe72VGZ/wFiXMSj5PPg1kkMdYx0Y+2J36B6BuNPxkFCf1VsqD
me8fsByUI6VOBFfIdLA6o/IwR1cEhsTzPQ6ipKz4HMvv/5FSxaNzSbUiiCdwmXVV
vHXEcO3WKZOifh/M9laFMnLhfmkoepxP4eglLBlh3zhlYC5Zg0OXo9Q9TwWmuehB
HERQlJwL4b9dVBcccohSNt+PhRYYD4QKFdvevFdZy2LZLlVJ67iLa1JmD3IADQEf
P2tTZZ7e0hvfAY3vtugNXndQ2lUeYXse9BcIS3Hwjk0Ok+QugP
Wjp0AAABjJm/nEsAAAQDAEgwRgIhAIWWxMQc/nXlbqy0PYhFSH7qsjjuvj9IGRCu
gQ4GmZ9XAiEAukjepiNOxEkoCfkOqge85HHBTxzLK3f4Qw4c8zYG0xQwDQYJKoZI
hvcNAQELBQADggEBADobU3SUO/zXOSnbs+KXpq85skOSzW5vG2xIAua2lj+y+Vb5
h+gOF3r1md++8M6pNAIHT6qKgVW+7nwMRYura5+m6BC5yPlz67GIMoaEdo1w1idH
jBh59v2U6/kBq7h1csAvj/AuqDv2xBjo0zP4GJpaUMHXjQFIGSQx1FQDb3VZoiaS
VGJ9ZkQ2H4rgLwvBtuXmT9k+LsyAechYNYSwwzY6/Cl9LXjyVkmJPIrlfrYD6/Z0
xt992encNvyREmsiiPPu5rmkj7d3ycY+nkQzWrr37T/YbIOI2hm13fjIeuSMNDn6
FhAUS0oWC5QDq0y/rOG9jwfPI47UsiWCVNHRfgQ=
-----END CERTIFICATE-----"
                    style={{ height: "340px" }}
                  />
                </FloatingLabel>
              </Col>


            </Col>

          </Row>
        </Card.Body>
      </Card>


    </>
  );
};

export default SecureSIPConfig;

