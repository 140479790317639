import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Badge from 'components/base/Badge';
import { PhoneUserData } from 'hooks/api/usePhoneUser';
import React, { useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Modal,
  Form
} from 'react-bootstrap';
import { formatDistanceToNow, isBefore, subMinutes } from 'date-fns';
import { fr } from 'date-fns/locale';

import Avatar from 'components/base/Avatar';

export const phoneUserTableColumns: ColumnDef<PhoneUserData>[] = [
  {
    accessorFn: ({ lastActivity }) => lastActivity,
    id: 'status',
    header: 'Utilisateur',
    cell: ({ row: { original } }) => {
      const { lastActivity, firstname, lastname, extension } = original;

      const isOnline = isBefore(
        subMinutes(new Date(), 5),
        new Date(lastActivity)
      );

      const avatar = `https://ui-avatars.com/api/?name=${encodeURIComponent(firstname)}+${encodeURIComponent(lastname)}&rounded=true&size=32`;

      return (
        <div className="d-flex align-items-center">
          <Avatar
            src={avatar}
            size="m"
            className="me-2"
            status={isOnline ? "online" : "offline"}
          />
          <div className="d-flex flex-column ms-2">
            {/* Centre l'élément à la verticale dans la colonne */}
            <p className="mb-0 fw-bold text-dark">
              {firstname} {lastname}
            </p>
            {/* Texte de l'extension aligné à gauche */}
            <small className="text-muted" style={{ textAlign: "left", display: "block" }}>
              {extension}
            </small>
          </div>
        </div>
      );
    },
    meta: {
      headerProps: {
        style: { width: "10%", minWidth: 250 }
      },
      cellProps: { className: "ps-3" }
    }
  },

  {
    accessorKey: "email",
    header: "Email",
    meta: {
      headerProps: { style: { width: "20%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "mobile",
    header: "Mobile",
    cell: ({ getValue }) => {
      const mobile = getValue();

      // Gestion si le numéro de mobile est non défini
      if (!mobile) {
        return null
      }

      // Click-to-call pour les numéros définis
      return <a href={`tel:${mobile}`}>{mobile}</a>;
    },
    meta: {
      headerProps: { style: { width: "10%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "fixe",
    header: 'Fixe',
    cell: ({ getValue }) => {
      const fixe = getValue();

      // Gestion si le numéro de fixe est non défini
      if (!fixe) {
        return null
      }

      // Click-to-call pour les numéros définis
      return <a href={`tel:${fixe}`}>{fixe}</a>;
    },
    meta: {
      headerProps: { style: { width: '10%', fontSize: '12px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },

  {
    accessorKey: "lastActivity",
    header: 'Dernière Activité',
    cell: ({ getValue }) => {
      const lastActivity = getValue();
      const formattedTimeAgo = formatDistanceToNow(new Date(lastActivity), { addSuffix: true, locale: fr });

      return <span>{formattedTimeAgo}</span>;
    },
    meta: {
      headerProps: { style: { width: '10%', fontSize: '12px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: 'callStatus',
    header: "Statut d'appel",
    cell: ({ row: { original } }) => {
      const { callStatus } = original;

      const statusMapping = {
        disponible: { color: 'success', label: 'Disponible' },
        absent: { color: 'danger', label: 'Absent' },
        dnd: { color: 'dark', label: 'Ne pas déranger' },
        lunch: { color: 'warning', label: 'Déjeuner' },
        'business-trip': { color: 'info', label: 'En déplacement' }
      };
      

      const { color, label } = statusMapping[callStatus] || {
        color: 'secondary',
        label: 'Inconnu'
      };

      return (
        <Badge bg={color} className="text-uppercase">
          {label}
        </Badge>
      );
    },
    meta: {
      headerProps: { style: { width: '10%', fontSize: '12px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    id: 'leadDropdown',
    header: 'Gérer',
    meta: {
      headerProps: { style: { fontSize: '12px' } },
      cellProps: { className: 'text-900 fs-9' }
    },
    cell: ({ row: { original } }) => {
      const [dropdownOpen, setDropdownOpen] = useState(false);
      const [showDeleteModal, setShowDeleteModal] = useState(false);
      const [showEditModal, setShowEditModal] = useState(false);
      const [selectedUser, setSelectedUser] = useState<PhoneUserData | null>(
        null
      );
      const [formData, setFormData] = useState<PhoneUserData | null>(null);

      const toggle = () => setDropdownOpen(!dropdownOpen);

      const handleDeleteClick = (user: PhoneUserData) => {
        setSelectedUser(user);
        setShowDeleteModal(true);
      };

      const handleEditClick = (user: PhoneUserData) => {
        setSelectedUser(user);
        setFormData(user);
        setShowEditModal(true);
      };

      const handleDeleteConfirm = async () => {
        if (selectedUser) {
          await handleDelete(selectedUser.id);
          setShowDeleteModal(false);
        }
      };

      const handleEditConfirm = async () => {
        if (formData) {
          await handleEdit(formData);
          setShowEditModal(false);
        }
      };

      const handleDelete = async (id: number) => {
        try {
          const response = await fetch(
            `https://rct-backend.ipercom.io/api/Webrtc/phoneuser/${id}`,
            {
              method: 'DELETE',
              headers: {
                accept: 'text/plain'
              }
            }
          );

          if (response.ok) {
            window.location.reload();
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        } catch (error) {
          console.error(
            "Erreur lors de la suppression de l'utilisateur",
            error
          );
        }
      };

      const handleEdit = async (data: PhoneUserData) => {
        try {
          const response = await fetch(
            `https://rct-backend.ipercom.io/api/Webrtc/phoneuser`,
            {
              method: 'PUT',
              headers: {
                accept: 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(data)
            }
          );

          if (response.ok) {
            window.location.reload();
          } else {
            const errorData = await response.json();
            console.error('Error updating user:', errorData);
            alert('There was an error updating the user.');
          }
        } catch (error) {
          console.error(
            "Erreur lors de la mise à jour de l'utilisateur",
            error
          );
        }
      };

      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => prevData ? {
          ...prevData,
          [name]: type === 'checkbox' ? checked : value
        } : null);
      };

      return (
        <>
          <Dropdown
            show={dropdownOpen}
            onToggle={toggle}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <DropdownToggle
              as="div"
              onClick={toggle}
              style={{ cursor: 'pointer' }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => handleEditClick(original)}>
                Modifier
              </DropdownItem>
              <DropdownItem onClick={() => handleDeleteClick(original)}>
                Supprimer
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>

          {/* Delete Modal */}
          <Modal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirmation de suppression</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Êtes-vous sûr de vouloir supprimer cet utilisateur ?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowDeleteModal(false)}
              >
                Annuler
              </Button>
              <Button variant="danger" onClick={handleDeleteConfirm}>
                Supprimer
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Edit Modal */}
          <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Modifier l'utilisateur</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="firstname">
                  <Form.Label>Prénom</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstname"
                    value={formData?.firstname || ''}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="lastname">
                  <Form.Label>Nom</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastname"
                    value={formData?.lastname || ''}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData?.email || ''}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="password">
                  <Form.Label>Mot de passe</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={formData?.password || ''}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="phoneUsername">
                  <Form.Label>Nom d'utilisateur (Téléphone)</Form.Label>
                  <Form.Control
                    type="text"
                    name="phoneUsername"
                    value={formData?.phoneUsername || ''}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="phonePassword">
                  <Form.Label>Mot de passe (Téléphone)</Form.Label>
                  <Form.Control
                    type="password"
                    name="phonePassword"
                    value={formData?.phonePassword || ''}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="extension">
                  <Form.Label>Extension</Form.Label>
                  <Form.Control
                    type="text"
                    name="extension"
                    value={formData?.extension || ''}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="mobile">
                  <Form.Label>Mobile</Form.Label>
                  <Form.Control
                    type="text"
                    name="mobile"
                    value={formData?.mobile || ''}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="fixe">
                  <Form.Label>Fixe</Form.Label>
                  <Form.Control
                    type="text"
                    name="fixe"
                    value={formData?.fixe || ''}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="callStatus">
                  <Form.Label>Statut d'appel</Form.Label>
                  <Form.Control
                    as="select"
                    className="form-select"
                    name="callStatus"
                    value={formData?.callStatus || ""}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Sélectionner le statut</option>
                    <option value="disponible">Disponible</option>
                    <option value="absent">Absent</option>
                    <option value="dnd">Ne pas déranger</option>
                    <option value="lunch">Déjeuner</option>
                    <option value="business-trip">En déplacement</option>
                  </Form.Control>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowEditModal(false)}
              >
                Annuler
              </Button>
              <Button variant="primary" onClick={handleEditConfirm}>
                Enregistrer
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    }
  }
];

const UserPhoneTableAPI = () => {
  return (
    <div>
      <AdvanceTable
        tableProps={{ className: 'phoenix-table fs-9 text-center' }}
      />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default UserPhoneTableAPI;
