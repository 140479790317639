import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import { Link } from "react-router-dom";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Avatar from "components/base/Avatar";

import Badge from "components/base/Badge";
import { Contacts } from "../../../data/iper-x/ipbx/contacts";
import { useState } from "react";
import { Button, Dropdown, Form, Modal } from 'react-bootstrap';
import RevealDropdown, { RevealDropdownTrigger } from "components/base/RevealDropdown";

export const contactsTableColumns: ColumnDef<Contacts>[] = [

  // {
  //   id: "user_status",
  //   accessorFn: ({ user_status }) => user_status.label,
  //   header: "Status",
  //   cell: ({ row: { original } }) => {
  //     const { user_status } = original;
  //     return (
  //       <Badge
  //         bg={user_status.type}
  //         variant="phoenix"
  //         iconPosition="end"
  //         className="fs-10"
  //         // icon={
  //         //   <FeatherIcon
  //         //       icon={user_status.icon}
  //         //       size={12.8}
  //         //       className="ms-1"
  //         //   />
  //         // }
  //       >
  //         {user_status.label}
  //       </Badge>
  //     );
  //   },
  //   meta: {
  //     headerProps: { style: { width: "10%" }, className: "pe-3" }
  //   }
  // },
  // {
  //   id: "user",
  //   accessorFn: ({ user }) => user.name,
  //   header: "Nom d’utilisateur",
  //   cell: ({ row: { original } }) => {
  //     const { name, avatar } = original.user;
  //     return (
  //       <Link
  //         to="/apps/e-commerce/admin/customer-details"
  //         className="d-flex align-items-center"
  //       >
  //         <Avatar src={avatar} size="m" />
  //         <p className="mb-0 ms-3 text-1100 fw-bold">{name}</p>
  //       </Link>
  //     );
  //   },
  //   meta: {
  //     headerProps: {
  //       style: { width: "18%", minWidth: 250 },
  //       className: "ps-8"
  //     },
  //     cellProps: { className: "ps-8 py-0 white-space-nowrap" }
  //   }
  // },
  {
    id: "user",
    accessorFn: ({ user }) => user.name,
    header: "Nom d’utilisateur",
    cell: ({ row: { original } }) => {
      const { name, avatar, status} = original.user;
      return (
        <Link
          to={`/ipbx/utilisateurs/details/${name}`}
          className="d-flex align-items-center"
        >
          <Avatar src={avatar} size="m" className="me-3" status={status} />
          <p className="mb-0 ms-3 text-1100 fw-bold">{name}</p>
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: "5%" },
        className: "ps-8"
      },
      cellProps: { className: "ps-8 py-0 white-space-nowrap" }
    }
  },

  {
    accessorKey: "email",
    header: "Email",
    meta: {
      headerProps: { style: { width: "5%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "mobile",
    header: "Mobile",
    meta: {
      headerProps: { style: { width: "5%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "fix",
    header: "Fix",
    meta: {
      headerProps: { style: { width: "5%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    id: 'leadDropdown',
    header: 'Gérer',
    cell: ({ row }) => {
      const [showForm, setShowForm] = useState(false);

      const toggleForm = () => {
        setShowForm(!showForm);
      };

      return (
        <>
          <RevealDropdownTrigger>
            <RevealDropdown>
              <Dropdown.Item eventKey="1" onClick={toggleForm}>
                Modifier
              </Dropdown.Item>
            </RevealDropdown>
          </RevealDropdownTrigger>
          {showForm && (
            <Modal show={showForm} onHide={toggleForm}>
              <Modal.Header closeButton>
                <Modal.Title>Modifier le contact</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group controlId="username">
                    <Form.Label>NOM D'UTILISATEUR</Form.Label>
                    <Form.Control type="text" name="username" required/>
                  </Form.Group>

                  <Form.Group controlId="email">
                    <Form.Label>EMAIL</Form.Label>
                    <Form.Control type="text" name="email" required />
                  </Form.Group>

                  <Form.Group controlId="mobile">
                    <Form.Label>MOBILE</Form.Label>
                    <Form.Control type="text" name="mobile" required />
                  </Form.Group>

                  <Form.Group controlId="fixed">
                    <Form.Label>FIX</Form.Label>
                    <Form.Control type="text" name="fixed" required />
                  </Form.Group>

                  <Button variant="primary" type="submit" className='mt-4'>
                    Ajouter
                  </Button>
                </Form>
              </Modal.Body>


            </Modal>
          )}
        </>
      );
    },
    meta: {
      headerProps: { style: { width: '1%' } },
      cellProps: {
        className: 'text-start',
      },
    },
  },


];

const ContactsTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9" }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default ContactsTable;
