import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, Navigate } from 'react-router-dom';
import { AuthProvider, useAuthContext } from "./providers/AuthProvider";
import SettingsToggle from './components/settings-panel/SettingsToggle';
import SettingsPanel from './components/settings-panel/SettingsPanel';
import { useSettingsPanelContext } from './providers/SettingsPanelProvider';
import useToggleStyle from './hooks/useToggleStyle';
import axios from "axios";
import { Spinner } from "react-bootstrap";

const App = () => {
  const [clientIP, setClientIP] = useState('');
  const [ipCheckLoading, setIpCheckLoading] = useState(true); // New loading state for IP check
  const exemptedIP = '217.128.32.10';

  const { isStylesheetLoaded } = useToggleStyle();
  const { pathname } = useLocation();
  const {
    settingsPanelConfig: { showSettingPanelButton },
    setSettingsPanelConfig
  } = useSettingsPanelContext();
  const {
    isAuthenticated,
    loading,
    config: { theme, isRTL }
  } = useAuthContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setSettingsPanelConfig({
      openSettingPanel: false
    });
  }, [isRTL]);

  // useEffect(() => {
  //   // Fetch client IP address
  //   axios.get('https://api.ipify.org?format=json')
  //     .then(response => {
  //       console.log("Client IP:", response.data.ip); // Log for debugging
  //       setClientIP(response.data.ip);
  //       setIpCheckLoading(false); // Set loading to false after getting IP
  //     })
  //     .catch(error => {
  //       console.error('Error fetching IP address:', error);
  //       setIpCheckLoading(false); // Ensure loading state is updated even if there's an error
  //     });
  // }, []);

  // if (loading || ipCheckLoading) { // Wait for both auth and IP check to complete
  //   return (
  //     <Spinner animation="border" role="status">
  //       <span className="visually-hidden">Loading...</span>
  //     </Spinner>
  //   );
  // }
//&& clientIP !== exemptedIP

  if (!isAuthenticated && pathname !== '/login') {
    console.log("Redirecting to login, IP not matched"); // Log for debugging
    return <Navigate to="/login" />;
  }

  return (
    <>
      {!isStylesheetLoaded ? (
        <div
          style={{
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: theme === 'dark' ? '#000' : '#fff'
          }}
        />
      ) : (
        <>
          <Outlet />
          {showSettingPanelButton && (
            <>
              <SettingsToggle />
              <SettingsPanel />
            </>
          )}
        </>
      )}
    </>
  );
};

const AppWithAuthProvider = () => {
  return (
    <AuthProvider> {/* Wrap the entire application with AuthProvider */}
      <App />
    </AuthProvider>
  );
};

export default AppWithAuthProvider;
