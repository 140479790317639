import Badge from "components/base/Badge";
import React, { useCallback, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { ToDoItem } from "data/project-management/todoListData";
import classNames from "classnames";

type Breakpoints = "md" | "lg" | "xl" | "xxl";

interface TodoListItemInterface {
  todo: ToDoItem;
  className?: string;
  labelClassName?: string;
  halfLayoutBreakpoints?: Breakpoints[];
  fullLayoutBreakpoints?: Breakpoints[];
  onClick?: (item: ToDoItem) => void;
}

type FullLayoutBreakpoints = {
  [key in Breakpoints]?: "auto";
};
type HalfLayoutBreakpoints = {
  [key in Breakpoints]?: 12;
};

const InfoListItem = ({
                        todo,
                        className,
                        labelClassName,
                        halfLayoutBreakpoints = [],
                        fullLayoutBreakpoints = [],
                        onClick
                      }: TodoListItemInterface) => {
  const [selected, setSelected] = useState(todo.completed);


  const handleClick = (item: ToDoItem) => {
    if (onClick) {
      onClick(item);
    } else {
      setSelected(!selected);
    }
  };

  const getBreakpointClasses = useCallback(
    (className: string, values: (number | string)[]) =>
      [
        ...halfLayoutBreakpoints.map(
          breakpoint => `${className}-${breakpoint}-${values[0]}`
        ),
        ...fullLayoutBreakpoints.map(
          breakpoint => `${className}-${breakpoint}-${values[1]}`
        )
      ].join(" "),
    [halfLayoutBreakpoints, fullLayoutBreakpoints]
  );

  return (
    <>
      <div
        className={classNames(
          className,
          "d-flex align-items-center hover-actions-trigger border-bottom gap-2 todolist-item "
        )}
      >

        <Row
          className="justify-content-between align-items-center btn-reveal-trigger border-200 gx-0 flex-1 gy-1 ">
          <Col
            xs={12}
            {...fullLayoutBreakpoints?.reduce(
              (acc: FullLayoutBreakpoints, val: Breakpoints) => {
                acc[val] = "auto";
                return acc;
              },
              {}
            )}
            {...halfLayoutBreakpoints?.reduce(
              (acc: HalfLayoutBreakpoints, val: Breakpoints) => {
                acc[val] = 12;
                return acc;
              },
              {}
            )}
          >
            <div className="d-flex align-items-center lh-1 gap-2">
              <h5
                className={classNames(
                  labelClassName,
                  "mb-0 line-clamp-1 fw-semi-bold text-700 cursor-pointer",
                  {
                    "text-decoration-line-through": selected
                  }
                )}
                onClick={() => handleClick(todo)}
              >
                {todo.task}
              </h5>

            </div>
          </Col>
          <Col
            xs={12}
            {...fullLayoutBreakpoints?.reduce(
              (acc: FullLayoutBreakpoints, val: Breakpoints) => {
                acc[val] = "auto";
                return acc;
              },
              {}
            )}
            {...halfLayoutBreakpoints?.reduce(
              (acc: HalfLayoutBreakpoints, val: Breakpoints) => {
                acc[val] = 12;
                return acc;
              },
              {}
            )}
          >


          </Col>
        </Row>
        <div
          className={classNames(
            getBreakpointClasses("d", ["none", "block"]),
            "d-none end-0 position-absolute"
          )}
          style={{ top: "23%" }}
        >


          {todo.badge && (
            <Badge
              variant="phoenix"
              bg={todo.badge.bg}
              className="fs-10 ms-auto"
            >
              {todo.badge.label}
            </Badge>
          )}


        </div>
      </div>
    </>
  );
};

export default InfoListItem;
