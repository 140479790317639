import { useState, useEffect, useCallback } from 'react';

export interface PhoneUserData {
  id: number;
  idPhoneConfig: number;
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  phoneUsername: string;
  phonePassword: string;
  avatar: string;
  extension: string;
  mobile: string;
  fixe: string;
  lastActivity: string;
  callStatus: string;
}

const phoneUserTableData: PhoneUserData[] = [
  {
    id: 0,
    idPhoneConfig: 0,
    firstname: "John",
    lastname: "Doe",
    email: "john.doe@example.com",
    password: "password",
    phoneUsername: "jdoe",
    phonePassword: "phonepass",
    avatar: "https://example.com/avatar.jpg",
    extension: "1001",
    mobile: "123456789",
    fixe: "987654321",
    lastActivity: "2024-09-09T13:03:00.455Z",
    callStatus: "idle"
  }
];

const usePhoneUser = () => {
  const [phoneUserData, setPhoneUserData] = useState(phoneUserTableData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchPhoneUsers = useCallback(async () => {
    try {
      const response = await fetch('https://rct-backend.ipercom.io/api/Webrtc/phoneuser', {
        method: 'GET',
        headers: {
          'accept': 'text/plain',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setPhoneUserData(data);
      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données de l'API", error);
      setError('Impossible de récupérer les données.');
      setPhoneUserData(phoneUserTableData);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchPhoneUsers();
  }, [fetchPhoneUsers]);

  return {
    phoneUserData,
    setPhoneUserData,
    loading,
    error
  };
};

export default usePhoneUser;
