import {
  faFileExport,
  faFileImport,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import React, { ChangeEvent } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';

import certComm from 'assets/img/iper-x/certification/commerciale.png';
import certTech from 'assets/img/iper-x/certification/technique.png';
import classNames from 'classnames';
import FeatherIcon from 'feather-icons-react';

import CertificationsHistoryTable, {
  certificationsTableColumns
} from '../../../components/tables/iper-x/retailer/CertificationsHistoryTable';
import useRetailerCertifications from '../../../hooks/api/useRetailerCertifications';

const RetailerCertificationsHistoryList: React.FC = () => {
  const { certifications, loading, error, defaultCertifications } =
    useRetailerCertifications();

  // Configurer les paramètres de la table
  const table = useAdvanceTable({
    data: certifications,
    columns: certificationsTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <div className="d-flex flex-wrap gap-3 flex-between-end mb-5">
        <div>
          <h2 className="mb-2">Obtenir la certification</h2>
          <h5 className="text-700 fw-semi-bold">
            Les certifications sont gratuites et ouvertes à tous. Elles prouvent
            que vous avez les connaissances pour installer, configurer,
            administrer et dépanner IPER-X
          </h5>
        </div>
      </div>

      <Row className="g-5 g-xl-5 mb-1">
        <Col xl={2}>
          <div className="avatar avatar-5xl mb-5">
            <img
              src={certComm}
              alt=""
              className="rounded-circle mb-4 d-dark-none"
              width={160}
              height={160}
              style={{ objectFit: 'contain' }}
            />
          </div>
        </Col>
        <Col xl={2}>
          <div className="avatar avatar-5xl mb-5">
            <img
              src={certTech}
              alt=""
              className=" rounded-circle mb-4 d-dark-none"
              width={160}
              height={160}
              style={{ objectFit: 'contain' }}
            />
          </div>
        </Col>
      </Row>

      <Card className="mb-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">
                Préparez-vous pour le test
              </h3>

              <span className="d-none d-sm-inline-block my-2">
                Étudiez tous nos matériaux pédagogiques gratuits avant de passer
                votre test. Ne manquez pas nos webinaires techniques IPER-X pour
                des démonstrations en direct et des sessions de
                questions-réponses !
              </span>

              <Row className="g-4 g-xl-1 g-xxl-3 justify-content-between mt-2">
                <Col sm="auto">
                  <div
                    className={classNames(
                      'd-sm-block d-inline-flex d-md-flex flex-xl-column flex-xxl-row align-items-center align-items-xl-start align-items-xxl-center',
                      ' ps-sm-3 px-12'
                    )}
                  >
                    <div
                      className={`d-flex bg-primary-100 rounded flex-center me-3 mb-sm-3 mb-md-0 mb-xl-3 mb-xxl-0`}
                      style={{ width: '32px', height: '32px' }}
                    >
                      <FeatherIcon
                        icon="video"
                        className={`text-primary-600 dark__text-info-300`}
                      />
                    </div>
                    <div>
                      <h4 className="fw-bolder text-nowrap">Webinaires</h4>
                    </div>
                  </div>
                </Col>

                <Col sm="auto">
                  <div
                    className={classNames(
                      'd-sm-block d-inline-flex d-md-flex flex-xl-column flex-xxl-row align-items-center align-items-xl-start align-items-xxl-center',
                      'border-start-sm ps-sm-3 px-12'
                    )}
                  >
                    <div
                      className={`d-flex bg-primary-100 rounded flex-center me-3 mb-sm-3 mb-md-0 mb-xl-3 mb-xxl-0`}
                      style={{ width: '32px', height: '32px' }}
                    >
                      <FeatherIcon
                        icon="book"
                        className={`text-primary-600 dark__text-info-300`}
                      />
                    </div>
                    <div>
                      <h4 className="fw-bolder text-nowrap">
                        Manuel utilisateur
                      </h4>
                    </div>
                  </div>
                </Col>

                <Col sm="auto">
                  <div
                    className={classNames(
                      'd-sm-block d-inline-flex d-md-flex flex-xl-column flex-xxl-row align-items-center align-items-xl-start align-items-xxl-center',
                      'border-start-sm ps-sm-3 px-12'
                    )}
                  >
                    <div
                      className={`d-flex bg-primary-100 rounded flex-center me-3 mb-sm-3 mb-md-0 mb-xl-3 mb-xxl-0`}
                      style={{ width: '32px', height: '32px' }}
                    >
                      <FeatherIcon
                        icon="monitor"
                        className={`text-primary-600 dark__text-info-300`}
                      />
                    </div>
                    <div>
                      <h4 className="fw-bolder text-nowrap">Académie</h4>
                    </div>
                  </div>
                </Col>

                <Col sm="auto">
                  <div
                    className={classNames(
                      'd-sm-block d-inline-flex d-md-flex flex-xl-column flex-xxl-row align-items-center align-items-xl-start align-items-xxl-center',
                      'border-start-sm ps-sm-3 px-12'
                    )}
                  >
                    <div
                      className={`d-flex bg-primary-100 rounded flex-center me-3 mb-sm-3 mb-md-0 mb-xl-3 mb-xxl-0`}
                      style={{ width: '32px', height: '32px' }}
                    >
                      <FeatherIcon
                        icon="settings"
                        className={`text-primary-600 dark__text-info-300`}
                      />
                    </div>
                    <div>
                      <h4 className="fw-bolder text-nowrap">
                        Manuel administrateur
                      </h4>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-5 mb-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">Passez le test</h3>

              <span className="d-none d-sm-inline-block my-2 ">
                <FeatherIcon
                  icon="check"
                  className={`text-primary-600 dark__text-info-300`}
                />
                30 questions de type QCM vous seront posées. Vous avez besoin de
                25 réponses correctes pour réussir.
              </span>

              <span className="d-none d-sm-inline-block my-2 ">
                <FeatherIcon
                  icon="check"
                  className={`text-primary-600 dark__text-info-300`}
                />
                Vous avez 60 minutes pour terminer le test. Si vous êtes
                déconnecté ou ne pouvez pas passer à la prochaine question,
                rafraîchissez la page et appuyez sur le bouton Reprendre le
                test.
              </span>

              <span className="d-none d-sm-inline-block my-2 ">
                <FeatherIcon
                  icon="check"
                  className={`text-primary-600 dark__text-info-300`}
                />
                Si vous échouez, ne vous inquiétez pas. Vous pouvez recommencer
                le test après 30 jours.
              </span>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <div className="d-flex flex-wrap gap-3 flex-between-end mb-5">
        <div>
          <h2 className="mb-2">Historique</h2>
        </div>
      </div>
      <div className="mb-4">
        <Row className="g-3">
          <Col xs="auto">
            <SearchBox
              className="w-300"
              placeholder="Rechercher"
              onChange={handleSearchInputChange}
            />
          </Col>
          <Col
            xs="auto"
            className="scrollbar overflow-hidden-y flex-grow-1"
          ></Col>
          <Col xs="auto">
            <Button variant="link" className="text-900 me-4 px-0">
              <FontAwesomeIcon icon={faFileImport} className="fs-9 me-2" />
              Importer
            </Button>
            <Button variant="link" className="text-900 me-4 px-0">
              <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
              Export
            </Button>
            <Button variant="primary">
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Ajouter un utilisateur
            </Button>
          </Col>
        </Row>
      </div>

      {loading && <p>Chargement des données...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {!loading && (
        <AdvanceTableProvider {...table}>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <CertificationsHistoryTable />
          </div>
        </AdvanceTableProvider>
      )}
    </div>
  );
};

export default RetailerCertificationsHistoryList;
