import { Button, Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";

import React from 'react';
import PageBreadcrumb, { PageBreadcrumbItem } from "../../../../../../components/common/PageBreadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";

export const optionsBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Paramètre',
    url: '/apps/ipbx/parametres'
  },
  {
    label: 'Intégration',
    url: "/apps/ipbx/parametres?selected=integration"
  },
  {
    label: "Services Hotel",
    url: '/apps/ipbx/parametres/hotel-services'
  }
];

const HotelServicesConfig = () => {
  return (
    <>
      <PageBreadcrumb items={optionsBreadcrumbItems} />
      <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
        <h2 className="mb-0">
          <span className="me-3">Services Hotel</span>
        </h2>
      </div>

      <Card className="">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <Form.Check type="checkbox" id="sameAsShipping" className="4">
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  <h3 className="fw-bolder mb-2 line-clamp-1">
                    Intégration PMS
                  </h3>
                </Form.Check.Label>
              </Form.Check>

              <span className="me-3">Installe les services d'intégration PMS qui transmettent les coûts des appels et permettent le check in / check out d'extensions.</span>

              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Type d'intégration PMS</Form.Label>
                <FloatingLabel controlId="floatingSelect" label="Region">
                  <Form.Select aria-label="Floating label select example">
                    <option>Mitel SX2000</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>

              <Form.Group controlId="formNum" className="mt-3 mb-3">
                <Form.Label>
                  Adresse IP PMS
                </Form.Label>
                <FloatingLabel controlId="floatingInputAuthMax" label="ip">
                  <Form.Control type="text" value="127.0.0.1" />
                </FloatingLabel>
              </Form.Group>

              <span className="me-3">Groupes d'extensions des chambres d'hôtel
Spécifiez les groupes qui incluent des chambres d'hôtel. Les messages PMS seront envoyés seulement aux extensions qui font partie de ces groupes.</span>

              <Button variant="btn btn-white border border-1 px-2">
                <FontAwesomeIcon icon={faCirclePlus} className="me-2" />
                Ajouter
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-5">
        <Card.Body>
          <Row className="align-items-center g-3">
          <Col sm="auto" className="flex-1">

              <h3 className="fw-bolder mb-2 line-clamp-1">
                Réveils téléphoniques non répondus
              </h3>


              <Form.Group
                controlId="formNum"
                className="mt-3 mb-3 d-flex align-items-center"
              >
                <Form.Check.Label className="fs-8 fw-normal me-2">
                  Si le réveil téléphonique n'est pas répondu dans les
                </Form.Check.Label>
                <div className="me-2">
                  <FloatingLabel
                    controlId="floatingInputAuthMax"
                    label="seconde"
                  >
                    <Form.Control type="text" value="5" />
                  </FloatingLabel>
                </div>
                <Form.Check.Label className="fs-8 fw-normal">
                  secondes, transférer l'appel à :
                </Form.Check.Label>
              </Form.Group>

              <FloatingLabel controlId="floatingSelect" label="Choisir le mode">
                <Form.Select aria-label="Floating label select example">
                  <option>Terminer l’appel</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </FloatingLabel>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default HotelServicesConfig;
