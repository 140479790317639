import React, { useState } from 'react';
import classNames from 'classnames';
import { Card, Modal, Form, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  UilBuilding,
  UilCheckCircle,
  UilClock,
  UilDollarAlt,
  UilEnvelopeAlt,
  UilFileCheckAlt,
  UilGlobe,
  UilInfo,
  UilInfoCircle,
  UilMoneybag,
  UilPhone,
  UilPostcard
} from "@iconscout/react-unicons";
import Button from 'components/base/Button';
import LeadInfoItem from 'components/info-items/LeadInfoItem';

const AboutRetailerCard = ({ className }: { className?: string }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Card className={classNames(className)}>
      <Card.Body>
        <div className="d-flex align-items-center justify-content-between mb-5">
          <h3>À propos</h3>
          <Button variant="link" className="px-3" onClick={handleShow}>
            Modifier
          </Button>
        </div>

        <div className="d-flex flex-wrap gap-4 p-4  ">
          <LeadInfoItem className="mb-4 me-4" label="Email" icon={UilEnvelopeAlt}>
            <Link to="mailto:ansolo5@jeemail.com">ansolo5@jeemail.com</Link>
          </LeadInfoItem>
          <LeadInfoItem className="mb-4 me-4" label="Téléphone" icon={UilPhone}>
            <a href="tel:+1234567890">+1234567890</a>
          </LeadInfoItem>
          <LeadInfoItem className="mb-4 me-4" label="Téléphone fixe" icon={UilPhone}>
            <a href="tel:+1234567890">+1234567890</a>
          </LeadInfoItem>
          <LeadInfoItem className="mb-4 me-4" label="Website" icon={UilGlobe}>
            <a href="#!">www.bb.ru.com</a>
          </LeadInfoItem>
          <LeadInfoItem className="mb-4 me-4" label="Nombre d’employés" icon={UilPostcard}>
            <p className="mb-0 text-800">126</p>
          </LeadInfoItem>
          <LeadInfoItem className="mb-4 me-4" label="Revenu annuel" icon={UilMoneybag}>
            <p className="mb-0 text-800">12 000 €</p>
          </LeadInfoItem>
          <LeadInfoItem className="mb-4 me-4" label="SIRET" icon={UilInfoCircle}>
            <p className="mb-0 text-800">123 568 941 00056</p>
          </LeadInfoItem>
          <LeadInfoItem className="mb-4 me-4" label="NAF" icon={UilInfoCircle}>
            <p className="mb-0 text-800">0111Z</p>
          </LeadInfoItem>
          <LeadInfoItem className="mb-4 me-4" label="Dernier contacté" icon={UilClock}>
            <p className="mb-0 text-800">12 November 2021, 10:54 AM</p>
          </LeadInfoItem>
          <LeadInfoItem className="mb-4 me-4" label="Source principale" icon={UilFileCheckAlt}>
            <p className="mb-0 text-800">Publicité</p>
          </LeadInfoItem>
          <LeadInfoItem className="mb-4 me-4" label="Statut" icon={UilCheckCircle}>
            <p className="mb-0 text-800">Gold</p>
          </LeadInfoItem>
        </div>


        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Modifier les informations du détaillant</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="companyName">
                    <Form.Label>Nom de l'entreprise</Form.Label>
                    <Form.Control type="text" defaultValue="Pigma communications" />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" defaultValue="ansolo5@jeemail.com" />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="phoneNumber">
                    <Form.Label>Téléphone</Form.Label>
                    <Form.Control type="text" defaultValue="+1234567890" />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="landline">
                    <Form.Label>Téléphone fixe</Form.Label>
                    <Form.Control type="text" defaultValue="+1234567890" />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="website">
                    <Form.Label>Website</Form.Label>
                    <Form.Control type="text" defaultValue="www.bb.ru.com" />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="employeeCount">
                    <Form.Label>Nombre d’employés</Form.Label>
                    <Form.Control type="text" defaultValue="126" />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="annualRevenue">
                    <Form.Label>Revenu annuel</Form.Label>
                    <Form.Control type="text" defaultValue="12 000 €" />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="siret">
                    <Form.Label>SIRET</Form.Label>
                    <Form.Control type="text" defaultValue="123 568 941 00056" />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="naf">
                    <Form.Label>NAF</Form.Label>
                    <Form.Control type="text" defaultValue="0111Z" />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="lastContacted">
                    <Form.Label>Dernier contacté</Form.Label>
                    <Form.Control type="text" defaultValue="12 November 2021, 10:54 AM" />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="mainSource">
                    <Form.Label>Source principale</Form.Label>
                    <Form.Control type="text" defaultValue="Publicité" />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="status">
                    <Form.Label>Statut</Form.Label>
                    <Form.Control type="text" defaultValue="Gold" />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Annuler
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Sauvegarder
            </Button>
          </Modal.Footer>
        </Modal>

      </Card.Body>
    </Card>
  );
};

export default AboutRetailerCard;
