import { faCircle, faClock, faPlusCircle, faTimes, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Row, Col, Form, Button } from "react-bootstrap";

const IperxParamChat = () => {

  return (
    <>
     <h2 className="fs-6 mb-3">Chat</h2>
      <Card className="mb-4">
        <Col className="ms-4 mt-4">
          <Form.Check
            type='radio'
            id='item1Radio'
            label='Afficher la pop-up du chat'
          />
          <Form.Check
            type='radio'
            id='item2Radio'
            label='Jouer la notification du chat'

          />
        </Col>
        <Col className="ms-4 mt-1 me-4 mb-3">
          <Form.Select>
            <option value="1" className="fw-bold">Défault</option>
            <option value="2" className="fw-bold">Défault</option>
            <option value="3" className="fw-bold">Défault</option>
          </Form.Select>
        </Col>
      </Card>
    </>
  );
};

export default IperxParamChat;
