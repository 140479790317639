
export interface HoldMusicPlaylist {
  name: string;
  playlistLink: string;
  volume: string;
  gain: string;
  random: string;

}

export const holdMusicPlaylistTableData: HoldMusicPlaylist[] = [
  {
    name: "Fermeture",
    playlistLink: "Lien",
    volume: "50",
    gain: "10",
    random: "805E0CE84976",
  },
  {
    name: "Fermeture",
    playlistLink: "Lien",
    volume: "50",
    gain: "10",
    random: "805E0CE84976",
  },
  {
    name: "Fermeture",
    playlistLink: "Lien",
    volume: "50",
    gain: "10",
    random: "805E0CE84976",
  },
  {
    name: "Fermeture",
    playlistLink: "Lien",
    volume: "50",
    gain: "10",
    random: "805E0CE84976",
  },
  {
    name: "Fermeture",
    playlistLink: "Lien",
    volume: "50",
    gain: "10",
    random: "805E0CE84976",
  },
  {
    name: "Fermeture",
    playlistLink: "Lien",
    volume: "50",
    gain: "10",
    random: "805E0CE84976",
  },  {
    name: "Fermeture",
    playlistLink: "Lien",
    volume: "50",
    gain: "10",
    random: "805E0CE84976",
  },
  {
    name: "Fermeture",
    playlistLink: "Lien",
    volume: "50",
    gain: "10",
    random: "805E0CE84976",
  },
  {
    name: "Fermeture",
    playlistLink: "Lien",
    volume: "50",
    gain: "10",
    random: "805E0CE84976",
  },
  {
    name: "Fermeture",
    playlistLink: "Lien",
    volume: "50",
    gain: "10",
    random: "805E0CE84976",
  },
  {
    name: "Fermeture",
    playlistLink: "Lien",
    volume: "50",
    gain: "10",
    random: "805E0CE84976",
  },
  {
    name: "Fermeture",
    playlistLink: "Lien",
    volume: "50",
    gain: "10",
    random: "805E0CE84976",
  },

];
