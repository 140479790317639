import React, { useState, ChangeEvent, FormEvent } from 'react';
import { Col, Row, Form, Modal } from 'react-bootstrap';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { retailerOrdersAndInvoicesBreadcrumbItems } from 'data/breadcrumbItem';
import { faFileExport, faFileImport, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import FilterTab from 'components/common/FilterTab';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import OrdersInvoicesTable, { ordersInvoicesTableColumns } from 'components/tables/iper-x/retailer/OrdersInvoicesTable';
import useOrdersAndInvoicesData from 'hooks/dolibarr/useOrdersAndInvoicesData';
import ExportModal from './ExportModal';
import AddOrderModal from './AddOrderModal';
import { tabItems, exportOptionsInitialState, defaultOrder } from './OrderListUtils';

const OrderInvoicesListEditor: React.FC = () => {
  const { orders, invoices, error } = useOrdersAndInvoicesData();

  const table = useAdvanceTable({
    data: orders,
    columns: ordersInvoicesTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const [showExportModal, setShowExportModal] = useState(false);
  const toggleExportModal = () => setShowExportModal(!showExportModal);

  const [exportOptions, setExportOptions] = useState(exportOptionsInitialState);

  const handleExport = () => {
    // Export logic here
    toggleExportModal();
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setExportOptions(prevState => ({
      ...prevState,
      [name]: checked
    }));
  };

  const [showAddOrderModal, setShowAddOrderModal] = useState(false);
  const toggleAddOrderModal = () => setShowAddOrderModal(!showAddOrderModal);

  const [order, setOrder] = useState(defaultOrder);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setOrder(prevOrder => ({
      ...prevOrder,
      [name]: value
    }));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Submit form logic here
    toggleAddOrderModal();
  };

  return (
    <>
      <div>
        <Row className="gy-3 mb-6 justify-content-between">
          <Col md={9} xs="auto">
            <PageBreadcrumb items={retailerOrdersAndInvoicesBreadcrumbItems} />
            <h2 className="mb-2 text-1100">Commandes et factures</h2>
          </Col>
        </Row>
        <FilterTab tabItems={tabItems} className="mb-2" />

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex flex-wrap gap-3">
              <SearchBox
                placeholder="Rechercher des commandes"
                onChange={handleSearchInputChange}
              />
              <div className="ms-xxl-auto">
                <Button variant="link" className="text-900 me-4 px-0">
                  <FontAwesomeIcon icon={faFileImport} className="fs-9 me-2" />
                  Importer
                </Button>
                <Button variant="link" className="text-900 me-4 px-0" onClick={toggleExportModal}>
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Exporter
                </Button>
                <Button variant="primary" onClick={toggleAddOrderModal}>
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Ajouter une commande
                </Button>
              </div>
            </div>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <OrdersInvoicesTable />
          </div>
        </AdvanceTableProvider>

        <ExportModal
          show={showExportModal}
          handleClose={toggleExportModal}
          handleCheckboxChange={handleCheckboxChange}
          handleExport={handleExport}
          exportOptions={exportOptions}
        />
        <AddOrderModal
          show={showAddOrderModal}
          handleClose={toggleAddOrderModal}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          order={order}
        />
      </div>
    </>
  );
};

export default OrderInvoicesListEditor;
