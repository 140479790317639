import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import { Backups } from "../../../data/iper-x/ipbx/backups";

export const backupsTableColumns: ColumnDef<Backups>[] = [
  {
    id: "fileName",
    header: "Nom de fichier",
    accessorFn: ({ fileName }) => fileName,
    meta: {
      headerProps: { style: { width: "40%" }, className: "pe-3" }
    }
  },
  {
    id: "createdOn",
    header: "Crée le",
    accessorFn: ({ createdOn }) => createdOn,
    meta: {
      headerProps: { style: { width: "40%" }, className: "pe-3" }
    }
  },
  {
    id: "size",
    header: "taille",
    accessorFn: ({ size }) => size,
    meta: {
      headerProps: { style: { width: "30%" }, className: "pe-3" }
    }
  }
];

const BackupsTable = () => {
  return (
    <div>
      <AdvanceTable
        tableProps={{ className: "phoenix-table fs-9" }}
      />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default BackupsTable;
