export interface BlacklistedIP {
  ipAddress: string;
  subnetMask: string;
  action: string;
  expirationDate: string;
  ipRange: string;
  description: string;
}

export const blacklistedIPTableData: BlacklistedIP[] = [
  {
    ipAddress: "31.204.85.228",
    subnetMask: "255.255.255.0", // Update with actual subnet mask
    action: "Autoriser",
    expirationDate: "31/01/2043 15:47:15",
    ipRange: "31.204.85.228", // Update with actual IP range
    description: "Created by PBX Express"
  }
];

