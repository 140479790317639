import React, { useEffect, useState } from 'react';
import Avatar from 'components/base/Avatar';
import { Card, Dropdown, Nav } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import Scrollbar from 'components/base/Scrollbar';
import classNames from 'classnames';
import axios from 'axios';
import avatar from 'assets/img/team/40x40/avatar.webp';

export interface NavItem {
  label: string;
  icon: string;
  path: string;
}

export const navItems: NavItem[] = [
  { label: 'Dashboard', icon: 'pie-chart', path: '/dashboard' },
  { label: 'FAQ', icon: 'help-circle', path: '/faq' },
  { label: 'Langue', icon: 'globe', path: '/language' }
];

interface UserData {
  firstname: string;
  lastname: string;
  avatar: string;
}

const fallbackData: UserData = {
  firstname: 'undefined',
  lastname: 'undefined',
  avatar: 'https://ui-avatars.com/api/?name=undefined'
};

const ProfileDropdownMenu = ({ className }: { className?: string }) => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      axios.get('https://rct-backend.ipercom.io/api/Account/info', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          const data: UserData = response.data;
          if (!data.avatar) {
            data.avatar = `https://ui-avatars.com/api/?name=${data.firstname}+${data.lastname}`;
          }
          setUserData(data);
          setError(false);
        })
        .catch(() => {
          setUserData(fallbackData);
          setError(true);
        });
    } else {
      // setUserData(fallbackData);
    }
  }, []);

  return (
    <Dropdown.Menu
      align="end"
      className={classNames(
        className,
        'navbar-top-dropdown-menu navbar-dropdown-caret py-0 dropdown-profile shadow border border-300'
      )}
    >
      <Card className="position-relative border-0">
        <Card.Body className="p-0">
          <div className="d-flex flex-column align-items-center justify-content-center gap-2 pt-4 pb-3">
            <Avatar src={userData ? userData.avatar : avatar} size="xl" />
            <h6 className="text-black">{userData ? `${userData.firstname} ${userData.lastname}` : "Fallback User"}</h6>
          </div>

          <div style={{ height: "6rem" }}>
            <Scrollbar>
              <Nav className="nav flex-column mb-2 pb-1">
                {navItems.map((item: NavItem) => (
                  <Nav.Item key={item.label}>
                    <Nav.Link href={item.path} className="px-3">
                      <FeatherIcon
                        icon={item.icon}
                        size={16}
                        className="me-2 text-900"
                      />
                      <span className="text-1000">{item.label}</span>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Scrollbar>
          </div>
        </Card.Body>
        <Card.Footer className="p-0 border-top">
          <div className="px-3 my-3">
            <Link
              to="/logout"
              className="btn btn-phoenix-secondary d-flex flex-center w-100"
            >
              <FeatherIcon icon="log-out" className="me-2" size={16} />
              Se déconnecter
            </Link>
          </div>
          <div className="my-2 text-center fw-bold fs-10 text-600">
            <Link className="text-600 me-1" to="#!">
              Politique de confidentialité
            </Link>
            •
            <Link className="text-600 mx-1" to="#!">
              Terms
            </Link>
            •
            <Link className="text-600 ms-1" to="#!">
              Cookies
            </Link>
          </div>
        </Card.Footer>
      </Card>
    </Dropdown.Menu>
  );
};

export default ProfileDropdownMenu;
