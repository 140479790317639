import { Col, Form, Row, Button } from "react-bootstrap";
import profileImage from "assets/img/team/59.webp";
import IperxSocialProfileCard from "components/cards/ipbx/IperxSocialProfileCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faLinkedinIn, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";

const IperxParamGeneral = () => {
  return (
    <>
    <h2 className="fs-6 mb-3">Paramètres du compte</h2>
    <Row>
      <Col lg={6} xl={5}>
        <IperxSocialProfileCard
          showAbout={true}
          avatar={profileImage}
          className="mb-4"
        />
      </Col>
      <Col lg={6} xl={7}>
        <h2 className="fs-6">Informations personnelles</h2>
        <Form>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="firstName">
                <Form.Label>Prénom</Form.Label>
                <Form.Control type="text" placeholder="Anselo" />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="lastName">
                <Form.Label>Nom</Form.Label>
                <Form.Control type="text" placeholder="Lazinatov" />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="hansolo@falconreact.tw" />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="telephone">
                <Form.Label>Téléphone</Form.Label>
                <Form.Control type="text" placeholder="+00 11 123 2345" />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <h2 className="fs-6">Informations d'entreprise</h2>
              <Form.Group className="mb-3" controlId="companyName">
                <Form.Label>Nom de l'entreprise</Form.Label>
                <Form.Control type="text" placeholder="Pigma communicatijons" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="website">
                <Form.Label>Site Web</Form.Label>
                <Form.Control type="text" placeholder="Pigma.fr" />
              </Form.Group>
            </Col>

            <Col lg={6}>
              <h2 className="fs-6">Changer le mot de passe</h2>
              <Form.Group className="mb-3" controlId="oldPassword">
                <Form.Label>Ancien mot de passe</Form.Label>
                <Form.Control type="password" placeholder="Ancien mot de passe" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="newPassword">
                <Form.Label>Nouveau mot de passe</Form.Label>
                <Form.Control type="password" placeholder="Nouveau mot de passe" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="confirmNewPassword">
                <Form.Label>Confirmer le nouveau mot de passe</Form.Label>
                <Form.Control type="password" placeholder="Confirmer le nouveau mot de passe" />
              </Form.Group>
              <Button variant="primary" className="mb-3">Confirmer</Button>
              <a href="#" className="d-block">Renvoyer les identifiants par email</a>
            </Col>
          </Row>
        </Form>
        <h2 className="fs-6">Social</h2>
      <Form>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="facebook">
              <Form.Label><FontAwesomeIcon icon={faFacebook} className="me-3"></FontAwesomeIcon>Facebook</Form.Label>
              <Form.Control type="text" placeholder="/Phoenix" />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="twitter">
              <Form.Label><FontAwesomeIcon icon={faTwitter} className="me-3"></FontAwesomeIcon>Twitter</Form.Label>
              <Form.Control type="text" placeholder="@phoenixadmin" />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="linkedin">
              <Form.Label><FontAwesomeIcon icon={faLinkedinIn} className="me-3"></FontAwesomeIcon>LinkedIn</Form.Label>
              <Form.Control type="text" placeholder="/PhoenixThemeWagon" />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="youtube">
              <Form.Label><FontAwesomeIcon icon={faYoutube} className="me-3"></FontAwesomeIcon>YouTube</Form.Label>
              <Form.Control type="text" placeholder="/phoenixadmin" />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="artstation">
              <Form.Label>ArtStation</Form.Label>
              <Form.Control type="text" placeholder="/Phoenixfire" />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="behance">
              <Form.Label>Behance</Form.Label>
              <Form.Control type="text" placeholder="/PhoenixTheme" />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button variant="secondary" className="me-2">Annuler les modifications</Button>
            <Button variant="primary">Enregistrer les informations</Button>
          </Col>
        </Row>
      </Form>
      
      <hr />

      <Row className="mb-3">
        <Col md={6}>
          <h2 className="fs-6">Transfert de propriété</h2>
          <p>Transférez ce compte à une autre personne ou vers les référentiels d'une entreprise.</p>
          <Button variant="info">Transfert</Button>
        </Col>
        <Col md={6}>
          <h2 className="fs-6">Suppression de compte</h2>
          <p>Transférez ce compte à une autre personne ou vers les référentiels d'une entreprise.</p>
          <Button variant="danger">Supprimer le compte</Button>
        </Col>
      </Row>
      </Col>
    </Row>
    </>
  );
};

export default IperxParamGeneral;
