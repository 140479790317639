import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import { Devices } from "../../../data/iper-x/ipbx/devices";
import ActionDropdownItems from "../../common/ActionDropdownItems";
import { Dropdown } from "react-bootstrap";
import DevicesDropdownItems from "../../common/DevicesDropdownItems";
import RevealDropdown, { RevealDropdownTrigger } from "components/base/RevealDropdown";
import React from "react";

export const devicesTableColumns: ColumnDef<Devices>[] = [

  {
    accessorKey: "ext",
    header: "EXT",
    meta: {
      headerProps: { style: { width: "10%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "manufacturer",
    header: "Constructeur",
    meta: {
      headerProps: { style: { width: "15%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "model",
    header: "Modèle",
    meta: {
      headerProps: { style: { width: "15%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "firmware_version",
    header: "Version du firmware",
    meta: {
      headerProps: { style: { width: "20%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "name",
    header: "NOM",
    meta: {
      headerProps: { style: { width: "15%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },

  {
    accessorKey: "ip",
    header: "IP",
    meta: {
      headerProps: { style: { width: "30%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },

  {
    accessorKey: "mac",
    header: "MAC",
    meta: {
      headerProps: { style: { width: "30%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    id: 'leadDropdown',
    header: "Gérer",
    cell: () => {
      return (
        <RevealDropdownTrigger>
          <RevealDropdown>
          <ActionDropdownItems />
          {/*<DevicesDropdownItems />*/}

          </RevealDropdown>
        </RevealDropdownTrigger>
      );
    },
    meta: {
      headerProps: { style: { width: "30%" } },
      cellProps: {
        className: 'text-end pe-0 ps-4'
      }
    }
  },


];

const DevicesTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9" }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default DevicesTable;
