import {
  faChevronLeft,
  faChevronRight,
  faFilter,
  faSearch
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Button from 'components/base/Button';
import { CalendarView, useCalendarContext } from 'providers/CalendarProvider';
import { ButtonGroup, Col, Row } from 'react-bootstrap';
import { SET_CALENDAR_STATE } from 'reducers/CalendarReducer';

const CalendarHeader = () => {
  const { calendarApi, title, view, calendarDispatch } = useCalendarContext();

  const handleCalendarView = (viewType: CalendarView) => {
    if (calendarApi) {
      calendarApi.changeView(viewType);
      calendarDispatch({
        type: SET_CALENDAR_STATE,
        payload: {
          view: viewType,
          title: calendarApi.view.title
        }
      });
    }
  };

  const handleCalendarUpdate = (actionType: string) => {
    if (calendarApi) {
      actionType === 'next'
        ? calendarApi.next()
        : actionType === 'prev'
        ? calendarApi.prev()
        : calendarApi.today();
      calendarDispatch({
        type: SET_CALENDAR_STATE,
        payload: {
          title: calendarApi.view.title
        }
      });
    }
  };

  return (
    <div className="mx-n4 px-4 mx-lg-n4 px-lg-6 border-y border-200">
      <Row className="py-3 gy-3 gx-0 justify-content-between">
      <Col xs={6} md="auto" className="order-1 d-flex justify-content-end">
          <ButtonGroup size="sm">
          <Button
              variant="phoenix-secondary"
              className="me-2"
            >
              5 tâches aujourd'hui
            </Button>
            <Button
              onClick={() => console.log('Filtrer')}
              variant="phoenix-secondary"
              className="me-2"
            >
              <FontAwesomeIcon icon={faFilter} className="me-1" />
              Filtrer
            </Button>
            <Button
              onClick={() => console.log('Rechercher')}
              variant="phoenix-secondary"
            >
              <FontAwesomeIcon icon={faSearch} className="me-1" />
              Rechercher
            </Button>
          </ButtonGroup>
        </Col>
        <Col
          xs={12}
          md="auto"
          className="order-md-1 d-flex align-items-center justify-content-center"
        >
          <Button
            onClick={() => handleCalendarUpdate('prev')}
            className="icon-item icon-item-sm shadow-none text-1100 p-0"
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </Button>
          {calendarApi && (
            <h3 className="px-3 text-1100 fw-semi-bold mb-0">
              {title || calendarApi.view.title}
            </h3>
          )}
          <Button
            onClick={() => handleCalendarUpdate('next')}
            className="icon-item icon-item-sm shadow-none text-1100 p-0"
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </Button>
        </Col>
        <Col xs={6} md="auto" className="order-1 d-flex justify-content-end">
          <ButtonGroup size="sm">
            
            <Button
              onClick={() => handleCalendarView('dayGridMonth')}
              variant="phoenix-secondary"
              className={classNames({
                active: view === 'dayGridMonth'
              })}
            >
              Mois
            </Button>
            <Button
              onClick={() => handleCalendarView('timeGridWeek')}
              variant="phoenix-secondary"
              className={classNames({
                active: view === 'timeGridWeek'
              })}
            >
              Semaine
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </div>
  );
};

export default CalendarHeader;
