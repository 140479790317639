import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import IperxUserProfileCard from "components/cards/ipbx/IperxUserProfileCard";


const IperxUsersProfile = () => {
  const { name } = useParams();


  return (
    <>

      <Row className="g-4 g-xl-6">
        <Col xl={5} xxl={12}>
          <div className="sticky-leads-sidebar">
            <IperxUserProfileCard className="mb-3" name={name} />
          </div>
        </Col>
      </Row>


    </>
  );
};


export default IperxUsersProfile;
