import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Badge from "components/base/Badge";
import { Voicemail } from "../../../data/iper-x/ipbx/voicemail"; // Assurez-vous que ce type est correct et contient les bonnes propriétés
import RevealDropdown, { RevealDropdownTrigger } from "../../base/RevealDropdown";
import ActionDropdownItems from "../../common/ActionDropdownItems";

export const voicemailTableColumns: ColumnDef<Voicemail>[] = [
  // Colonne pour Ext.
  {
    accessorKey: "extension",
    header: "Ext.",
    meta: {
      headerProps: { style: { width: "10%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  // Colonne pour Nom
  {
    accessorKey: "name",
    header: "Nom",
    meta: {
      headerProps: { style: { width: "10%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  // Colonne pour Type
  {
    accessorKey: "type",
    header: "Type",
    meta: {
      headerProps: { style: { width: "10%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  // Colonne pour Directive
  {
    accessorKey: "directive",
    header: "Directive",
    meta: {
      headerProps: { style: { width: "15%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  // Colonne pour Pas de saisie
  {
    accessorKey: "noInputAction",
    header: "Pas de saisie",
    meta: {
      headerProps: { style: { width: "15%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  // ...autres colonnes si nécessaire
];

const VoicemailTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9" }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default VoicemailTable;
