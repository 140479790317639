import team32 from "assets/img/team/32.webp";
import unknown from 'assets/img/team/avatar-placeholder.webp'
import { Status } from "components/base/Avatar";
import { BadgeBg } from "components/base/Badge";
import member4 from 'assets/img/team/35.webp';


export interface usersIperxMessagingDataType {

  user_status: {
    label: string;
    type: BadgeBg;
    icon: string;
  };

  user: {
    avatar?: string;
    name: string;
    status: Status;
    placeholder?: boolean;
  };
  date: string;
  number: string;
  email: string;
  calling_id: string;
  sync_time: string;
}
export const usersIperxMessagingTableData: usersIperxMessagingDataType[] = [
  {
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    date: 'Dec 29, 2021 14h00',
    number: '012',
    email: "MilindMikuja@gmail.com",
    calling_id: "0556246219",
    sync_time: "34 minute"
  }, {
    user: {
      avatar: unknown,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    date: 'Dec 29, 2021 14h00',
    number: '012',
    email: "MilindMikuja@gmail.com",
    calling_id: "0556246219",
    sync_time: "34 minute"
  }, {
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    date: 'Dec 29, 2021 14h00',
    number: '012',
    email: "MilindMikuja@gmail.com",
    calling_id: "0556246219",
    sync_time: "34 minute"
  }, {
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    date: 'Dec 29, 2021 14h00',
    number: '012',
    email: "MilindMikuja@gmail.com",
    calling_id: "0556246219",
    sync_time: "34 minute"
  }, {
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    date: 'Dec 29, 2021 14h00',
    number: '012',
    email: "MilindMikuja@gmail.com",
    calling_id: "0556246219",
    sync_time: "34 minute"
  }, {
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    date: 'Dec 29, 2021 14h00',
    number: '012',
    email: "MilindMikuja@gmail.com",
    calling_id: "0556246219",
    sync_time: "34 minute"
  }, {
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    date: 'Dec 29, 2021 14h00',
    number: '012',
    email: "MilindMikuja@gmail.com",
    calling_id: "0556246219",
    sync_time: "34 minute"
  }, {
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    date: 'Dec 29, 2021 14h00',
    number: '012',
    email: "MilindMikuja@gmail.com",
    calling_id: "0556246219",
    sync_time: "34 minute"
  }, {
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    date: 'Dec 29, 2021 14h00',
    number: '012',
    email: "MilindMikuja@gmail.com",
    calling_id: "0556246219",
    sync_time: "34 minute"
  }, {
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    date: 'Dec 29, 2021 14h00',
    number: '012',
    email: "MilindMikuja@gmail.com",
    calling_id: "0556246219",
    sync_time: "34 minute"
  }, {
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    date: 'Dec 29, 2021 14h00',
    number: '012',
    email: "MilindMikuja@gmail.com",
    calling_id: "0556246219",
    sync_time: "34 minute"
  }, {
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    date: 'Dec 29, 2021 14h00',
    number: '012',
    email: "MilindMikuja@gmail.com",
    calling_id: "0556246219",
    sync_time: "34 minute"
  }, {
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    date: 'Dec 29, 2021 14h00',
    number: '012',
    email: "MilindMikuja@gmail.com",
    calling_id: "0556246219",
    sync_time: "34 minute"
  }, {
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    date: 'Dec 29, 2021 14h00',
    number: '012',
    email: "MilindMikuja@gmail.com",
    calling_id: "0556246219",
    sync_time: "34 minute"
  }, {
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    date: 'Dec 29, 2021 14h00',
    number: '012',
    email: "MilindMikuja@gmail.com",
    calling_id: "0556246219",
    sync_time: "34 minute"
  }, {
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    date: 'Dec 29, 2021 14h00',
    number: '012',
    email: "MilindMikuja@gmail.com",
    calling_id: "0556246219",
    sync_time: "34 minute"
  }, {
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    date: 'Dec 29, 2021 14h00',
    number: '012',
    email: "MilindMikuja@gmail.com",
    calling_id: "0556246219",
    sync_time: "34 minute"
  }, {
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    date: 'Dec 29, 2021 14h00',
    number: '012',
    email: "MilindMikuja@gmail.com",
    calling_id: "0556246219",
    sync_time: "34 minute"
  }, {
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    date: 'Dec 29, 2021 14h00',
    number: '012',
    email: "MilindMikuja@gmail.com",
    calling_id: "0556246219",
    sync_time: "34 minute"
  }, {
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    date: 'Dec 29, 2021 14h00',
    number: '012',
    email: "MilindMikuja@gmail.com",
    calling_id: "0556246219",
    sync_time: "34 minute"
  },
]
export interface UsersIperx {

  user_status: {
    label: string;
    type: BadgeBg;
    icon: string;
  };

  user: {
    avatar?: string;
    name: string;
    status: Status;
    placeholder?: boolean;
    
  };
  numberPoste: string;
  email: string;
  mobile: string;
  calling_id: string;

  sync_time: string;
}
export const usersIperxTableData: UsersIperx[] = [
  {

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    numberPoste: '10',
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    calling_id: "0556246219",

    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    numberPoste: '10',
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    calling_id: "0556246219",

    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    numberPoste: '10',
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    calling_id: "0556246219",

    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    numberPoste: '10',
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    calling_id: "0556246219",

    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    numberPoste: '10',
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    calling_id: "0556246219",

    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    numberPoste: '10',
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    calling_id: "0556246219",

    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    numberPoste: '10',
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    calling_id: "0556246219",

    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    numberPoste: '10',
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    calling_id: "0556246219",

    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    numberPoste: '10',
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    calling_id: "0556246219",

    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    numberPoste: '10',
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    calling_id: "0556246219",

    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    numberPoste: '10',
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    calling_id: "0556246219",

    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    numberPoste: '10',
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    calling_id: "0556246219",

    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    numberPoste: '10',
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    calling_id: "0556246219",

    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    numberPoste: '10',
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    calling_id: "0556246219",

    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    numberPoste: '10',
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    calling_id: "0556246219",

    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    numberPoste: '10',
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    calling_id: "0556246219",

    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    numberPoste: '10',
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    calling_id: "0556246219",

    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    numberPoste: '10',
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    calling_id: "0556246219",

    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    numberPoste: '10',
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    calling_id: "0556246219",

    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    numberPoste: '10',
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    calling_id: "0556246219",

    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    numberPoste: '10',
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    calling_id: "0556246219",

    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    numberPoste: '10',
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    calling_id: "0556246219",

    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    numberPoste: '10',
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    calling_id: "0556246219",

    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    numberPoste: '10',
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    calling_id: "0556246219",

    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    numberPoste: '10',
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    calling_id: "0556246219",

    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    numberPoste: '10',
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    calling_id: "0556246219",

    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    numberPoste: '10',
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    calling_id: "0556246219",

    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    numberPoste: '10',
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    calling_id: "0556246219",

    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: 'offline'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    numberPoste: '10',
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    calling_id: "0556246219",

    sync_time: "34 minute"
  },
];
export interface usersIperxHistoryCallDataType {
  user: {
    avatar: string;
    name: string;
    status: Status;

  };
  numberPoste: string;
  number: string;
  date: string;
  creatBy: string;
  activity: string;
}
export const usersIperxHistoryCallTableData: usersIperxHistoryCallDataType[] = [
  {
    user: {
      avatar: member4,
      name: 'Ansolo Lazinatov',
      status: 'online',
    },
    numberPoste: '10',
    number: '06 10 52 15 43',
    date: 'Dec 29, 2021 14h00',
    creatBy: 'Ansolo Lazinarov',
    activity: 'Active'
  },{
    user: {
      avatar: member4,
      name: 'Ansolo Lazinatov',
      status: 'online',
    },
    numberPoste: '10',
    number: '06 10 52 15 43',
    date: 'Dec 29, 2021 14h00',
    creatBy: 'Ansolo Lazinarov',
    activity: 'Active'
  },{
    user: {
      avatar: member4,
      name: 'Ansolo Lazinatov',
      status: 'online',
    },
    numberPoste: '10',
    number: '06 10 52 15 43',
    date: 'Dec 29, 2021 14h00',
    creatBy: 'Ansolo Lazinarov',
    activity: 'Active'
  },{
    user: {
      avatar: member4,
      name: 'Ansolo Lazinatov',
      status: 'online',
    },
    numberPoste: '10',
    number: '06 10 52 15 43',
    date: 'Dec 29, 2021 14h00',
    creatBy: 'Ansolo Lazinarov',
    activity: 'Active'
  },{
    user: {
      avatar: member4,
      name: 'Ansolo Lazinatov',
      status: 'online',
    },
    numberPoste: '10',
    number: '06 10 52 15 43',
    date: 'Dec 29, 2021 14h00',
    creatBy: 'Ansolo Lazinarov',
    activity: 'Active'
  },{
    user: {
      avatar: member4,
      name: 'Ansolo Lazinatov',
      status: 'online',
    },
    numberPoste: '10',
    number: '06 10 52 15 43',
    date: 'Dec 29, 2021 14h00',
    creatBy: 'Ansolo Lazinarov',
    activity: 'Active'
  },{
    user: {
      avatar: member4,
      name: 'Ansolo Lazinatov',
      status: 'online',
    },
    numberPoste: '10',
    number: '06 10 52 15 43',
    date: 'Dec 29, 2021 14h00',
    creatBy: 'Ansolo Lazinarov',
    activity: 'Active'
  },{
    user: {
      avatar: member4,
      name: 'Ansolo Lazinatov',
      status: 'online',
    },
    numberPoste: '10',
    number: '06 10 52 15 43',
    date: 'Dec 29, 2021 14h00',
    creatBy: 'Ansolo Lazinarov',
    activity: 'Active'
  },{
    user: {
      avatar: member4,
      name: 'Ansolo Lazinatov',
      status: 'online',
    },
    numberPoste: '10',
    number: '06 10 52 15 43',
    date: 'Dec 29, 2021 14h00',
    creatBy: 'Ansolo Lazinarov',
    activity: 'Active'
  },{
    user: {
      avatar: member4,
      name: 'Ansolo Lazinatov',
      status: 'online',
    },
    numberPoste: '10',
    number: '06 10 52 15 43',
    date: 'Dec 29, 2021 14h00',
    creatBy: 'Ansolo Lazinarov',
    activity: 'Active'
  },{
    user: {
      avatar: member4,
      name: 'Ansolo Lazinatov',
      status: 'online',
    },
    numberPoste: '10',
    number: '06 10 52 15 43',
    date: 'Dec 29, 2021 14h00',
    creatBy: 'Ansolo Lazinarov',
    activity: 'Active'
  },{
    user: {
      avatar: member4,
      name: 'Ansolo Lazinatov',
      status: 'online',
    },
    numberPoste: '10',
    number: '06 10 52 15 43',
    date: 'Dec 29, 2021 14h00',
    creatBy: 'Ansolo Lazinarov',
    activity: 'Active'
  },{
    user: {
      avatar: member4,
      name: 'Ansolo Lazinatov',
      status: 'online',
    },
    numberPoste: '10',
    number: '06 10 52 15 43',
    date: 'Dec 29, 2021 14h00',
    creatBy: 'Ansolo Lazinarov',
    activity: 'Active'
  },{
    user: {
      avatar: member4,
      name: 'Ansolo Lazinatov',
      status: 'online',
    },
    numberPoste: '10',
    number: '06 10 52 15 43',
    date: 'Dec 29, 2021 14h00',
    creatBy: 'Ansolo Lazinarov',
    activity: 'Active'
  },
]
export interface usersIperxContactDataType {
  user: {
    avatar: string;
    name: string;
    status: Status;
    company: string;
  };
  numberFixe: string;
  numberMobile: string;
  email: string;
  date: string;
  creatBy: string;
  activity: string;
}
export const usersIperxContactTableData: usersIperxContactDataType[] = [
  {
    user: {
      avatar: member4,
      name: 'Ansolo Lazi',
      status: 'offline',
      company: 'Ipercom'
    },
    email: 'dev@ipercom.com',
    numberFixe: '06 10 52 15 43',
    numberMobile: '06 12 00 45 78',
    date: 'Dec 29, 2021 14h00',
    creatBy: 'Ansolo Lazinarov',
    activity: 'Active'
  }, {
    user: {
      avatar: member4,
      name: 'Ansolo Lazi',
      status: 'offline',
      company: 'Ipercom'
    },
    email: 'dev@ipercom.com',
    numberFixe: '06 10 52 15 43',
    numberMobile: '06 12 00 45 78',
    date: 'Dec 29, 2021 14h00',
    creatBy: 'Ansolo Lazinarov',
    activity: 'Active'
  }, {
    user: {
      avatar: member4,
      name: 'Ansolo Lazi',
      status: 'offline',
      company: 'Ipercom'
    },
    email: 'dev@ipercom.com',
    numberFixe: '06 10 52 15 43',
    numberMobile: '06 12 00 45 78',
    date: 'Dec 29, 2021 14h00',
    creatBy: 'Ansolo Lazinarov',
    activity: 'Active'
  }, {
    user: {
      avatar: member4,
      name: 'Ansolo Lazi',
      status: 'offline',
      company: 'Ipercom'
    },
    email: 'dev@ipercom.com',
    numberFixe: '06 10 52 15 43',
    numberMobile: '06 12 00 45 78',
    date: 'Dec 29, 2021 14h00',
    creatBy: 'Ansolo Lazinarov',
    activity: 'Active'
  }, {
    user: {
      avatar: member4,
      name: 'Ansolo Lazi',
      status: 'offline',
      company: 'Ipercom'
    },
    email: 'dev@ipercom.com',
    numberFixe: '06 10 52 15 43',
    numberMobile: '06 12 00 45 78',
    date: 'Dec 29, 2021 14h00',
    creatBy: 'Ansolo Lazinarov',
    activity: 'Active'
  }, {
    user: {
      avatar: member4,
      name: 'Ansolo Lazi',
      status: 'offline',
      company: 'Ipercom'
    },
    email: 'dev@ipercom.com',
    numberFixe: '06 10 52 15 43',
    numberMobile: '06 12 00 45 78',
    date: 'Dec 29, 2021 14h00',
    creatBy: 'Ansolo Lazinarov',
    activity: 'Active'
  }, {
    user: {
      avatar: member4,
      name: 'Ansolo Lazi',
      status: 'offline',
      company: 'Ipercom'
    },
    email: 'dev@ipercom.com',
    numberFixe: '06 10 52 15 43',
    numberMobile: '06 12 00 45 78',
    date: 'Dec 29, 2021 14h00',
    creatBy: 'Ansolo Lazinarov',
    activity: 'Active'
  }, {
    user: {
      avatar: member4,
      name: 'Ansolo Lazi',
      status: 'offline',
      company: 'Ipercom'
    },
    email: 'dev@ipercom.com',
    numberFixe: '06 10 52 15 43',
    numberMobile: '06 12 00 45 78',
    date: 'Dec 29, 2021 14h00',
    creatBy: 'Ansolo Lazinarov',
    activity: 'Active'
  }, {
    user: {
      avatar: member4,
      name: 'Ansolo Lazi',
      status: 'offline',
      company: 'Ipercom'
    },
    email: 'dev@ipercom.com',
    numberFixe: '06 10 52 15 43',
    numberMobile: '06 12 00 45 78',
    date: 'Dec 29, 2021 14h00',
    creatBy: 'Ansolo Lazinarov',
    activity: 'Active'
  }, {
    user: {
      avatar: member4,
      name: 'Ansolo Lazi',
      status: 'offline',
      company: 'Ipercom'
    },
    email: 'dev@ipercom.com',
    numberFixe: '06 10 52 15 43',
    numberMobile: '06 12 00 45 78',
    date: 'Dec 29, 2021 14h00',
    creatBy: 'Ansolo Lazinarov',
    activity: 'Active'
  }, {
    user: {
      avatar: member4,
      name: 'Ansolo Lazi',
      status: 'offline',
      company: 'Ipercom'
    },
    email: 'dev@ipercom.com',
    numberFixe: '06 10 52 15 43',
    numberMobile: '06 12 00 45 78',
    date: 'Dec 29, 2021 14h00',
    creatBy: 'Ansolo Lazinarov',
    activity: 'Active'
  }, {
    user: {
      avatar: member4,
      name: 'Ansolo Lazi',
      status: 'offline',
      company: 'Ipercom'
    },
    email: 'dev@ipercom.com',
    numberFixe: '06 10 52 15 43',
    numberMobile: '06 12 00 45 78',
    date: 'Dec 29, 2021 14h00',
    creatBy: 'Ansolo Lazinarov',
    activity: 'Active'
  }, {
    user: {
      avatar: member4,
      name: 'Ansolo Lazi',
      status: 'offline',
      company: 'Ipercom'
    },
    email: 'dev@ipercom.com',
    numberFixe: '06 10 52 15 43',
    numberMobile: '06 12 00 45 78',
    date: 'Dec 29, 2021 14h00',
    creatBy: 'Ansolo Lazinarov',
    activity: 'Active'
  }, {
    user: {
      avatar: member4,
      name: 'Ansolo Lazi',
      status: 'offline',
      company: 'Ipercom'
    },
    email: 'dev@ipercom.com',
    numberFixe: '06 10 52 15 43',
    numberMobile: '06 12 00 45 78',
    date: 'Dec 29, 2021 14h00',
    creatBy: 'Ansolo Lazinarov',
    activity: 'Active'
  }, {
    user: {
      avatar: member4,
      name: 'Ansolo Lazi',
      status: 'offline',
      company: 'Ipercom'
    },
    email: 'dev@ipercom.com',
    numberFixe: '06 10 52 15 43',
    numberMobile: '06 12 00 45 78',
    date: 'Dec 29, 2021 14h00',
    creatBy: 'Ansolo Lazinarov',
    activity: 'Active'
  },
]
export interface usersIperxParamTAexceptionsDataType {
  user: {
    name: string;
  };
  extension: string;
  statusTraitement: string;
  who: string;
}
export const usersIperxParamTAexceptionsTableData: usersIperxParamTAexceptionsDataType[] = [
  {
    user: {
      name: 'Jean MARC',
    },
    extension: 'Interne',
    statusTraitement: 'Transférer',
    who: 'ADV'
  },{
    user: {
      name: 'Jean MARC',
    },
    extension: 'Interne',
    statusTraitement: 'Transférer',
    who: 'ADV'
  },{
    user: {
      name: 'Jean MARC',
    },
    extension: 'Interne',
    statusTraitement: 'Transférer',
    who: 'ADV'
  },{
    user: {
      name: 'Jean MARC',
    },
    extension: 'Interne',
    statusTraitement: 'Transférer',
    who: 'ADV'
  },{
    user: {
      name: 'Jean MARC',
    },
    extension: 'Interne',
    statusTraitement: 'Transférer',
    who: 'ADV'
  },{
    user: {
      name: 'Jean MARC',
    },
    extension: 'Interne',
    statusTraitement: 'Transférer',
    who: 'ADV'
  },{
    user: {
      name: 'Jean MARC',
    },
    extension: 'Interne',
    statusTraitement: 'Transférer',
    who: 'ADV'
  },{
    user: {
      name: 'Jean MARC',
    },
    extension: 'Interne',
    statusTraitement: 'Transférer',
    who: 'ADV'
  },{
    user: {
      name: 'Jean MARC',
    },
    extension: 'Interne',
    statusTraitement: 'Transférer',
    who: 'ADV'
  },{
    user: {
      name: 'Jean MARC',
    },
    extension: 'Interne',
    statusTraitement: 'Transférer',
    who: 'ADV'
  },{
    user: {
      name: 'Jean MARC',
    },
    extension: 'Interne',
    statusTraitement: 'Transférer',
    who: 'ADV'
  },{
    user: {
      name: 'Jean MARC',
    },
    extension: 'Interne',
    statusTraitement: 'Transférer',
    who: 'ADV'
  },{
    user: {
      name: 'Jean MARC',
    },
    extension: 'Interne',
    statusTraitement: 'Transférer',
    who: 'ADV'
  },{
    user: {
      name: 'Jean MARC',
    },
    extension: 'Interne',
    statusTraitement: 'Transférer',
    who: 'ADV'
  },{
    user: {
      name: 'Jean MARC',
    },
    extension: 'Interne',
    statusTraitement: 'Transférer',
    who: 'ADV'
  },{
    user: {
      name: 'Jean MARC',
    },
    extension: 'Interne',
    statusTraitement: 'Transférer',
    who: 'ADV'
  },{
    user: {
      name: 'Jean MARC',
    },
    extension: 'Interne',
    statusTraitement: 'Transférer',
    who: 'ADV'
  },
]




