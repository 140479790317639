import team1 from "assets/img/team/32.webp";
import team2 from "assets/img/team/33.webp";
import { BadgeBg } from "components/base/Badge";


export interface Status {
  ongoing: number;
  critical: number;
  inactive: number;
  completed: number;
}


export interface EditorLeadDataType {
  id: number;
  prospect: {
    avatar: string;
    name: string;
    designation: string;
    date: string;
    status: {
      label: string;
      type: BadgeBg;
    };
  };

  devices_number: string;
  access_number: string;
  progress: {
    min: number;
    max: number;
  };
  statusProgress: Status;
  status: {
    label: string;
    type: BadgeBg;
  };
  contact: {
    avatar: string;
    name: string;
  };
  date: string;
}

export const editorLeadsTableData: EditorLeadDataType[] = [
  {
    id: 1,
    prospect: {
      avatar: team1,
      name: "Pigma communications",
      designation: "Anthoney Michael PDG",
      date: `12/04/2024`,
      status: {
        label: "new lead",
        type: "info"
      }
    },

    devices_number: "36",
    access_number: "21",
    progress: {
      min: 145,
      max: 145
    },
    statusProgress: {
      ongoing: 30,
      critical: 5,
      inactive: 45,
      completed: 15
    },

    status: {
      label: "completed",
      type: "success"
    },


    contact: {
      avatar: team2,
      name: `Milind Mikuja`
    },

    date: `Jan 01, 12:56 PM`
  },

  {
    id: 2,
    prospect: {
      avatar: team1,
      name: "Pigma communications",
      designation: "Anthoney Michael PDG",
      date: `12/04/2024`,
      status: {
        label: "new lead",
        type: "info"
      }
    },

    devices_number: "36",
    access_number: "21",
    progress: {
      min: 145,
      max: 145
    },
    statusProgress: {
      ongoing: 30,
      critical: 5,
      inactive: 45,
      completed: 15
    },

    status: {
      label: "completed",
      type: "success"
    },
    contact: {
      avatar: "",
      name: ""
    },

    date: `Jan 01, 12:56 PM`
  },

  {
    id: 3,
    prospect: {
      avatar: team1,
      name: "Pigma communications",
      designation: "Anthoney Michael PDG",
      date: `12/04/2024`,
      status: {
        label: "new lead",
        type: "info"
      }
    },

    devices_number: "36",
    access_number: "21",
    progress: {
      min: 145,
      max: 145
    },
    statusProgress: {
      ongoing: 30,
      critical: 5,
      inactive: 45,
      completed: 15
    },

    status: {
      label: "completed",
      type: "success"
    },
    contact: {
      avatar: team2,
      name: `Milind Mikuja`
    },

    date: `Jan 01, 12:56 PM`
  },

  {
    id: 4,
    prospect: {
      avatar: team1,
      name: "Pigma communications",
      designation: "Anthoney Michael PDG",
      date: `12/04/2024`,
      status: {
        label: "new lead",
        type: "info"
      }
    },

    devices_number: "36",
    access_number: "21",
    progress: {
      min: 145,
      max: 145
    },
    statusProgress: {
      ongoing: 30,
      critical: 5,
      inactive: 45,
      completed: 15
    },

    status: {
      label: "completed",
      type: "success"
    },
    contact: {
      avatar: team2,
      name: `Milind Mikuja`
    },

    date: `Jan 01, 12:56 PM`
  },
  {
    id: 5,
    prospect: {
      avatar: team1,
      name: "Pigma communications",
      designation: "Anthoney Michael PDG",
      date: `12/04/2024`,
      status: {
        label: "new lead",
        type: "info"
      }
    },

    devices_number: "36",
    access_number: "21",
    progress: {
      min: 145,
      max: 145
    },
    statusProgress: {
      ongoing: 30,
      critical: 5,
      inactive: 45,
      completed: 15
    },

    status: {
      label: "completed",
      type: "success"
    },

    contact: {
      avatar: team2,
      name: `Milind Mikuja`
    },

    date: `Jan 01, 12:56 PM`
  },
  {
    id: 6,
    prospect: {
      avatar: team1,
      name: "Pigma communications",
      designation: "Anthoney Michael PDG",
      date: `12/04/2024`,
      status: {
        label: "new lead",
        type: "info"
      }
    },

    devices_number: "36",
    access_number: "21",
    progress: {
      min: 145,
      max: 145
    },
    statusProgress: {
      ongoing: 30,
      critical: 5,
      inactive: 45,
      completed: 15
    },

    status: {
      label: "completed",
      type: "success"
    },

    contact: {
      avatar: team2,
      name: `Milind Mikuja`
    },

    date: `Jan 01, 12:56 PM`
  }


];
