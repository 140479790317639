import React, { useState, ChangeEvent } from 'react';
import { Button, Card, Col, Row, Alert } from 'react-bootstrap';
import {
  faFileExport,
  faFileImport,
  faFilter,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import EditorUsersAdvanceTable from 'components/base/EditorUsersAdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import LeadsFilterModal from 'components/modals/LeadsFilterModal';

import useThiersData from 'hooks/dolibarr/useThiersData';
import { ThiersTableColumns } from './ThiersTable';
import ThierForm from "./ThierForm";

// import ThierForm from './ThierForm';

const ThiersList: React.FC = () => {
  const { data, error } = useThiersData();
  const table = useAdvanceTable({
    data,
    columns: ThiersTableColumns,
    pageSize: 6,
    pagination: true,
    sortable: true,
    selection: false
  });

  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [thierModalVisible, setThierModalVisible] = useState(false);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 align-items-center">
          <h2 className="mb-0">
            {/*<PageBreadcrumb items={retailerThiersBreadcrumbItems} />*/}
            <h2 className="mb-2 text-1100">Your Third Parties</h2>
          </h2>
        </div>
        <div className="mb-4">
          <Row className="g-3">
            <Col xs="auto">
              <SearchBox
                placeholder="Search"
                onChange={handleSearchInputChange}
              />
            </Col>
            <Col
              xs="auto"
              className="scrollbar overflow-hidden-y flex-grow-1"
            ></Col>
            <Col xs="auto">
              <Button variant="link" className="text-900 me-4 px-0">
                <FontAwesomeIcon icon={faFileImport} className="fs-9 me-2" />
                Import
              </Button>
              <Button variant="link" className="text-900 me-4 px-0">
                <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                Export
              </Button>
              <Button
                variant="primary"
                onClick={() => setThierModalVisible(true)}
              >
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Add Thier
              </Button>
              <Button
                variant="phoenix-secondary"
                className="px-3 ms-2"
                onClick={() => setFilterModalVisible(true)}
              >
                <FontAwesomeIcon
                  icon={faFilter}
                  transform="down-3"
                  className="text-primary"
                />
              </Button>
              <LeadsFilterModal
                show={filterModalVisible}
                handleClose={() => setFilterModalVisible(false)}
              />
            </Col>
          </Row>
        </div>
        {error ? (
          <Alert variant="danger">{error}</Alert>
        ) : (
          <EditorUsersAdvanceTable />
        )}
      </AdvanceTableProvider>
      <ThierForm
        show={thierModalVisible}
        handleClose={() => setThierModalVisible(false)}
      />
    </div>
  );
};

export default ThiersList;
