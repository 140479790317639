export interface Backups {
  fileName: string;
  createdOn: string;
  size: string;
}

export const backupsTableData: Backups[] = [
  {
    fileName: "IperxBackup.zip",
    createdOn: "14/01/2024 00:00:12",
    size: "15.2 MB"
  },
  {
    fileName: "IperxBackup.zip",
    createdOn: "14/01/2024 00:00:12",
    size: "15.2 MB"
  },
  {
    fileName: "IperxBackup.zip",
    createdOn: "14/01/2024 00:00:12",
    size: "15.2 MB"
  },
  {
    fileName: "IperxBackup.zip",
    createdOn: "14/01/2024 00:00:12",
    size: "15.2 MB"
  },
  {
    fileName: "IperxBackup.zip",
    createdOn: "14/01/2024 00:00:12",
    size: "15.2 MB"
  },
  {
    fileName: "IperxBackup.zip",
    createdOn: "14/01/2024 00:00:12",
    size: "15.2 MB"
  },
  {
    fileName: "IperxBackup.zip",
    createdOn: "14/01/2024 00:00:12",
    size: "15.2 MB"
  },
  {
    fileName: "IperxBackup.zip",
    createdOn: "14/01/2024 00:00:12",
    size: "15.2 MB"
  },
  {
    fileName: "IperxBackup.zip",
    createdOn: "14/01/2024 00:00:12",
    size: "15.2 MB"
  },
  {
    fileName: "IperxBackup.zip",
    createdOn: "14/01/2024 00:00:12",
    size: "15.2 MB"
  },
  {
    fileName: "IperxBackup.zip",
    createdOn: "14/01/2024 00:00:12",
    size: "15.2 MB"
  },
  {
    fileName: "IperxBackup.zip",
    createdOn: "14/01/2024 00:00:12",
    size: "15.2 MB"
  },
  {
    fileName: "IperxBackup.zip",
    createdOn: "14/01/2024 00:00:12",
    size: "15.2 MB"
  },
  {
    fileName: "IperxBackup.zip",
    createdOn: "14/01/2024 00:00:12",
    size: "15.2 MB"
  },
  {
    fileName: "IperxBackup.zip",
    createdOn: "14/01/2024 00:00:12",
    size: "15.2 MB"
  },
  {
    fileName: "IperxBackup.zip",
    createdOn: "14/01/2024 00:00:12",
    size: "15.2 MB"
  },
  {
    fileName: "IperxBackup.zip",
    createdOn: "14/01/2024 00:00:12",
    size: "15.2 MB"
  }

];
