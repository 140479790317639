import { TrunkData } from 'hooks/api/useTrunk';
import React, { useState, ChangeEvent } from 'react';
import { Form, Button } from 'react-bootstrap';

type TrunkFormProps = {
  tableData: TrunkData[];
  setTableData: React.Dispatch<React.SetStateAction<TrunkData[]>>;
  onClose: () => void;
};

const AddTrunkSIPForm: React.FC<TrunkFormProps> = ({ tableData, setTableData, onClose }) => {
  const [newTrunk, setNewTrunk] = useState<TrunkData>({
    id: 0,
    id_user: 0,
    name: '',
    username: '',
    password: '',
    server: '',
    clientid: '',
    nameInstance: '',
    subdomainInstance: '',
    caps: 0,
    currentCall: 0,
    displayNumber: '',
    prefixe: '',
    ringDuration: 0,
    withRegistration: true,
    sdaRegistration: '',
    nbLiveCalls: 0,
    state: true
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewTrunk(prevTrunk => ({
      ...prevTrunk,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/trunk', {
        method: 'POST',
        headers: {
          'accept': 'text/plain',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newTrunk)
      });
      if (response.ok) {
        window. location. reload();
      }
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: TrunkData = await response.json();
      setTableData([...tableData, data]);
      onClose();
    } catch (error) {
      console.error("Erreur lors de l'ajout du trunk", error);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="name">
        <Form.Label>Nom</Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={newTrunk.name}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="username">
        <Form.Label>Nom d'utilisateur</Form.Label>
        <Form.Control
          type="text"
          name="username"
          value={newTrunk.username}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="password">
        <Form.Label>Mot de passe</Form.Label>
        <Form.Control
          type="password"
          name="password"
          value={newTrunk.password}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="server">
        <Form.Label>Serveur</Form.Label>
        <Form.Control
          type="text"
          name="server"
          value={newTrunk.server}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="clientid">
        <Form.Label>ID Client</Form.Label>
        <Form.Control
          type="text"
          name="clientid"
          value={newTrunk.clientid}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="nameInstance">
        <Form.Label>Nom de l'instance</Form.Label>
        <Form.Control
          type="text"
          name="nameInstance"
          value={newTrunk.nameInstance}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="subdomainInstance">
        <Form.Label>Sous-domaine de l'instance</Form.Label>
        <Form.Control
          type="text"
          name="subdomainInstance"
          value={newTrunk.subdomainInstance}
          onChange={handleChange}
          required
        />
      </Form.Group>
{/* 
      <Form.Group controlId="caps">
        <Form.Label>Caps</Form.Label>
        <Form.Control
          type="number"
          name="caps"
          value={newTrunk.caps}
          onChange={handleChange}
          required
        />
      </Form.Group> */}

      <Form.Group controlId="currentCall">
        <Form.Label>Appel en cours</Form.Label>
        <Form.Control
          type="number"
          name="currentCall"
          value={newTrunk.currentCall}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="displayNumber">
        <Form.Label>Numéro affiché</Form.Label>
        <Form.Control
          type="text"
          name="displayNumber"
          value={newTrunk.displayNumber}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="prefixe">
        <Form.Label>Préfixe</Form.Label>
        <Form.Control
          type="text"
          name="prefixe"
          value={newTrunk.prefixe}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <Form.Group controlId="ringDuration">
        <Form.Label>Durée de sonnerie</Form.Label>
        <Form.Control
          type="number"
          name="ringDuration"
          value={newTrunk.ringDuration}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="withRegistration">
        <Form.Label>Avec enregistrement</Form.Label>
        <Form.Check
          type="checkbox"
          name="withRegistration"
          checked={newTrunk.withRegistration}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setNewTrunk({
              ...newTrunk,
              withRegistration: e.target.checked
            })
          }
        />
      </Form.Group>

      <Form.Group controlId="sdaRegistration">
        <Form.Label>Enregistrement SDA</Form.Label>
        <Form.Control
          type="text"
          name="sdaRegistration"
          value={newTrunk.sdaRegistration}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="nbLiveCalls">
        <Form.Label>Nombre d'appels en direct</Form.Label>
        <Form.Control
          type="number"
          name="nbLiveCalls"
          value={newTrunk.nbLiveCalls}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="state">
        <Form.Label>État</Form.Label>
        <Form.Check
          type="checkbox"
          name="state"
          checked={newTrunk.state}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setNewTrunk({
              ...newTrunk,
              state: e.target.checked
            })
          }
        />
      </Form.Group>

      <Button variant="primary" type="submit" className="mt-2">
        Ajouter
      </Button>
    </Form>
  );
};

export default AddTrunkSIPForm;
