import ChatHomepageCard from 'components/cards/ChatHomepageCard';
import ChatSidebar from 'components/modules/chat/ChatSidebar';
import { conversations } from 'data/chat';
import ChatProvider from 'providers/ChatProvider';
import { Outlet } from 'react-router-dom';

const IperxChat = () => {
  return (
    <ChatProvider conversations={conversations}>
      <div className="chat d-flex gap-3">
        <Outlet />
      </div>
    </ChatProvider>
  );
};

export default IperxChat;
