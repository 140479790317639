import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import { CDRConfig } from "../../../data/iper-x/ipbx/cdrConfig";

export const cdrConifgTableColumns: ColumnDef<CDRConfig>[] = [

  {
    id: "name",
    header: "Nom du champ",
    accessorFn: ({ name }) => name,
    meta: {
      headerProps: { style: { width: "40%" }, className: "pe-3" }
    }
  },
  {
    id: "date",
    header: "Valeur exemple",
    accessorFn: ({ example_value }) => example_value,
    meta: {
      headerProps: { style: { width: "40%" }, className: "pe-3" }
    }
  },
  {
    id: "order",
    header: "Position du champ",
    accessorFn: ({ order }) => order,
    meta: {
      headerProps: { style: { width: "40%" }, className: "pe-3" }
    }
  }

];

const CDRConfigTable = () => {
  return (
    <div>
      <AdvanceTable
        tableProps={{ className: "phoenix-table fs-9" }}
      />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default CDRConfigTable;