import { Card, Col, FloatingLabel, Form, Nav, Row } from 'react-bootstrap';
import PageBreadcrumb, {
  PageBreadcrumbItem
} from '../../../../../../components/common/PageBreadcrumb';
import React, { useEffect, useState } from 'react';
import ParamApps from '../../apps/ParamApps';
import ParamSystem from '../../system/ParamSystem';
import ParamIntegration from '../ParamIntegration';
import ParamAdvance from '../../advance/ParamAdvance';
import ParamVideo from '../../system/video/ParamVideo';
import ParamAudio from '../../system/audio/ParamAudio';
import { useLocation } from 'react-router-dom';
import CRMClientSideConfig from '../crm/CRMClientSideConfig';
import CRMServerSideConfig from '../crm/CRMServerSideConfig';
import GoogleConfig from './GoogleConfig';
import GoogleClientConfig from "./GoogleClientConfig";

// import { useLocation } from "react-router-dom";

export const crmIntegrationBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Paramètre',
    url: '/apps/ipbx/parametres'
  },
  {
    label: 'Intégration',
    url: '/apps/ipbx/parametres?selected=integration'
  },
  {
    label: 'Google',
    url: '/apps/ipbx/parametres/google-integration'
  }
];

const GoogleIntegrationConfig = () => {
  const [selectedComponent, setSelectedComponent] = useState<string>('config');
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const selectedComponentParam = searchParams.get('selected');
    if (selectedComponentParam) {
      setSelectedComponent(selectedComponentParam);
    }
  }, [location]);

  const handleItemClick = (component: string) => {
    setSelectedComponent(component);
    // Update the URL with the selected component
    const searchParams = new URLSearchParams({ selected: component });
    window.history.pushState(
      {},
      '',
      `${location.pathname}?${searchParams.toString()}`
    );
  };

  return (
    <>
      <PageBreadcrumb items={crmIntegrationBreadcrumbItems} />
      <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
        <h2 className="mb-0">
          <span className="me-3">Intégration Google</span>
        </h2>
      </div>

      <div>
        <Nav
          variant="underline"
          className="mb-2 mb-3 md-5 pb-3 pt-2 w-100 w-sm-75 w-md-100 mx-auto bg-soft"
        >
          <Nav.Item>
            <Nav.Link
              className={`fw-semi-bold me-3 fs-8 pe-2 text-center ${selectedComponent === 'config' ? 'active' : ''}`}
              onClick={() => handleItemClick('config')}
            >
              Configurer
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={`fw-semi-bold me-3 fs-8 pe-2 text-center ${selectedComponent === 'client' ? 'active' : ''}`}
              onClick={() => handleItemClick('client')}
            >
              Côté client (ancien)
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <hr />

        {selectedComponent === 'config' && <GoogleConfig />}
        {selectedComponent === 'client' && <GoogleClientConfig />}
      </div>
    </>
  );
};

export default GoogleIntegrationConfig;
