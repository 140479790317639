import { Button, Col, Row } from 'react-bootstrap';
import PageBreadcrumb, {
  PageBreadcrumbItem
} from '../../../../../components/common/PageBreadcrumb';
import React, { ChangeEvent, useState } from 'react';
import SearchBox from '../../../../../components/common/SearchBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faFileExport,
  faFileImport,
  faFilter,
  faFolderOpen,
  faMicrophoneAlt,
  faMobileAlt,
  faPlus,
  faSave
} from '@fortawesome/free-solid-svg-icons';
import LeadsFilterModal from '../../../../../components/modals/LeadsFilterModal';
import AdvanceTableProvider from '../../../../../providers/AdvanceTableProvider';
import useAdvanceTable from '../../../../../hooks/useAdvanceTable';
import VoiceInstructionsTable, {
  voiceInstructionsTableColumns
} from '../../../../../components/tables/ipbx/VoiceInstructionsTable';
import { voiceInstructionsTableData } from '../../../../../data/iper-x/ipbx/voiceInstructions';

export const optionsBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Paramètre',
    url: '/apps/ipbx/parametres'
  },
  {
    label: 'Systéme',
    url: '/apps/ipbx/parametres?selected=system'
  },
  {
    label: 'Directives vocales',
    url: '/apps/ipbx/parametres/voice-instructions'
  }
];

const VoiceInstructionsConfig = () => {
  const table = useAdvanceTable({
    data: voiceInstructionsTableData,
    columns: voiceInstructionsTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  const [openFilterModal, setOpenFilterModal] = useState(false);
  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <>
      <PageBreadcrumb items={optionsBreadcrumbItems} />
      <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
        <h2 className="mb-0">
          <span className="me-3">
            Paramètres des directives vocales (18 mise à jour)
          </span>
        </h2>
      </div>

      <div className="mb-4">
        <Row className="g-3">
          <Col xs="auto">
            <SearchBox
              className="w-300"
              placeholder="Rechercher"
              onChange={handleSearchInputChange}
            />
          </Col>
          <Col
            xs="auto"
            className="scrollbar overflow-hidden-y flex-grow-1"
          ></Col>
          <Col xs="auto">
            <button className="btn btn-link text-900 me-4 px-0">
              <FontAwesomeIcon icon={faDownload} className="fs-9 me-2" />
              Télécharger
            </button>
            <button className="btn btn-link text-900 me-4 px-0">
              <FontAwesomeIcon icon={faMobileAlt} className="fs-9 me-2" />
              Jouer sur téléphone
            </button>
            <button className="btn btn-link text-900 me-4 px-0">
              <FontAwesomeIcon icon={faSave} className="fs-9 me-2" />
              Enregistrer
            </button>
            <button className="btn btn-link text-900 me-4 px-0">
              <FontAwesomeIcon icon={faFolderOpen} className="fs-9 me-2" />
              Parcourir
            </button>
            <button className="btn btn-link text-900 me-4 px-0">
              <FontAwesomeIcon icon={faMicrophoneAlt} className="fs-9 me-2" />
              Gérer les directives vocales
            </button>
            <Button variant="link" className="text-900 me-4 px-0">
              <FontAwesomeIcon icon={faFileImport} className="fs-9 me-2" />
              Importer
            </Button>
            <Button variant="link" className="text-900 me-4 px-0">
              <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
              Exporter
            </Button>
            <Button variant="primary">
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Ajouter une directive vocale
            </Button>
            <Button
              variant="phoenix-secondary"
              className="px-3 ms-2"
              onClick={() => setOpenFilterModal(true)}
            >
              <FontAwesomeIcon
                icon={faFilter}
                transform="down-3"
                className="text-primary"
              />
            </Button>
            <LeadsFilterModal
              show={openFilterModal}
              handleClose={() => setOpenFilterModal(false)}
            />
          </Col>
        </Row>
      </div>

      <AdvanceTableProvider {...table}>
        <div className="mx-n4 pt-5 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <div className="mb-4">
            <h3 className="mb-0">
              <span className="me-3">
                Fichiers de musiques d'attentes : Format WAV (PCM, 8 kHz, 16
                bit, Mono, taille max : 100MB)
              </span>
            </h3>
          </div>

          <VoiceInstructionsTable />
        </div>
      </AdvanceTableProvider>
    </>
  );
};

export default VoiceInstructionsConfig;
