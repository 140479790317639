import React, { useState, useEffect } from 'react';
import DashboardEditor from '../apps/editor/DashboardEditor';
import DashboardRetailer from '../apps/retailer/DashboardRetailer';
import DashboardTest from "./DashboardTest";

const DashboardMain = () => {
  // Ajout de 'all' dans les types possibles
  const [userGroup, setUserGroup] = useState<'editor' | 'retailer' | 'all' | null>(null);

  useEffect(() => {
    // Lire le groupe utilisateur depuis le localStorage
    const storedUserGroup = localStorage.getItem('userGroup');

    // Définir l'état avec le groupe utilisateur lu
    if (storedUserGroup === 'editor' || storedUserGroup === 'retailer' || storedUserGroup === 'all') {
      setUserGroup(storedUserGroup);
    } else {
      console.error('Groupe utilisateur non reconnu ou non défini');
      // Vous pouvez gérer des actions supplémentaires ici
    }
  }, []);

  // Rendu conditionnel basé sur l'état userGroup
  return (
    <>
      {userGroup === 'editor' && <DashboardEditor />}
      {userGroup === 'all' && (
        <>
          {/*<DashboardTest />*/}
          <DashboardEditor />
        </>
      )}
      {userGroup === 'retailer' && <DashboardRetailer />}
      {userGroup === null && <p>Chargement...</p>} {/* Message de chargement ou état par défaut */}
    </>
  );
};

export default DashboardMain;
