import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { Badge, Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook

export const ThiersTableColumns: ColumnDef<any>[] = [
  {
    accessorKey: 'name',
    header: 'Name',
    cell: ({ row }) => {
      const navigate = useNavigate(); // Initialize navigate

      // Get the Thier ID from the row and navigate to the details page
      const handleNameClick = () => {
        const thierId = row.original.id; // Assuming each Thier row has an `id` field
        navigate(`/portail-editeur/vos-thiers/details/${thierId}`);
      };

      return (
        <Button variant="link" onClick={handleNameClick}>
          {row.original.name}
        </Button>
      );
    },
  },
  {
    accessorKey: 'ref',
    header: 'Reference',
  },
  {
    accessorKey: 'phone',
    header: 'Phone',
  },
  {
    accessorKey: 'phone_mobile',
    header: 'Mobile Phone',
  },
  {
    accessorKey: 'email',
    header: 'Email',
  },
  {
    accessorKey: 'address',
    header: 'Address',
  },
  {
    accessorKey: 'town',
    header: 'Town',
  },
  {
    accessorKey: 'zip',
    header: 'ZIP',
  },
  {
    accessorKey: 'tva_assuj',
    header: 'TVA Subjected',
    cell: ({ row }) => (
      <Badge bg={row.original.tva_assuj === 'Yes' ? 'success' : 'danger'}>
        {row.original.tva_assuj}
      </Badge>
    ),
  },
  {
    accessorKey: 'url',
    header: 'Website',
    cell: ({ getValue }) => {
      const url = getValue() as string;
      return url !== 'No website' ? <a href={url} target="_blank" rel="noopener noreferrer">{url}</a> : 'N/A';
    },
  },
  {
    accessorKey: 'date_creation',
    header: 'Creation Date',
    cell: ({ getValue }) => new Date(getValue() as string).toLocaleDateString(),
  },
  {
    accessorKey: 'date_modification',
    header: 'Last Modification',
    cell: ({ getValue }) => new Date(getValue() as string).toLocaleDateString(),
  },
];

const ThiersTable = () => {
  return (
    <div className="border-bottom">
      <EditorUsersAdvanceTable tableProps={{ className: 'phoenix-table border-top border-200 fs-9' }} />
    </div>
  );
};

export default ThiersTable;
