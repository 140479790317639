import CoverUpload from 'components/common/CoverUpload';
import AvatarUpload from 'components/common/AvatarUpload';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from 'react-router-dom';
import { faPencil, faUserCheck, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import IperxAvatarCalling from 'components/common/IperxAvatarCalling';

interface SocialProfileCardProps {
  avatar: string;
  className?: string;
}
const IperxProfileWaitingCalling = ({ avatar, className }: SocialProfileCardProps) => {
  const { name } = useParams<{ name: string }>();
  
  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className={className}>
        <p className="me-0"> Appel en attente...</p>
        <div className="position-relative" style={{ width: '200px', height: '200px' }}>
          <IperxAvatarCalling size="8xl" src={avatar} />
        </div>
        <div>
          <Row>
            <Col xs={12}>
              <div className="d-flex flex-wrap mb-2 align-items-center justify-content-center">
                <div className="mt-4">
                  <h3 className="me-0">{name}</h3>
                  <p className="fw-semi-bold mb-0">Commercial</p>
                  <p className="fw-semi-bold mb-0">012</p>
                  <p className="fw-bold mb-0" style={{ fontSize: '40px' }}>01:23</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default IperxProfileWaitingCalling;
