import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import AboutLeadCard from 'components/cards/AboutLeadCard';
import LeadAddressCard from 'pages/apps/retailer/detail/LeadAddressCard';
import PageBreadcrumb, {
  PageBreadcrumbItem
} from 'components/common/PageBreadcrumb';
import { Col, Dropdown, Row } from 'react-bootstrap';
import React, { useState } from 'react';
import LeadDetailsOffcanvas from 'pages/apps/editor/detail_retailer/LeadDetailsOffcanvas';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';

import { faEdit } from '@fortawesome/free-regular-svg-icons';

import { dealDetailsInfoData } from 'data/crm/dealDetailsInfo';
import RetailerLeadProfileCard from '../../../components/cards/iperx/RetailerLeadProfileCard';
import UserDetailsInfo from '../retailer/detail/UserDetailsInfo';

export const leadsBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Portail éditeur',
    url: '/portail-revendeur'
  },
  {
    label: 'Détail revendeur',
    active: true
  }
];
const EditorDetails = () => {
  const [openOffcanvas, setOpenOffcanvas] = useState(false);

  return (
    <div>
      <PageBreadcrumb items={leadsBreadcrumbItems} />
      <div className="pb-9">
        <Row className="align-items-center justify-content-between g-3 mb-3">
          <Col xs={12} md="auto">
            <h2 className="mb-0">Détail revendeur</h2>
          </Col>
          <Col xs={12} md="auto">
            <div className="d-flex gap-2">
              <Button variant="phoenix-secondary" className="px-3 px-sm-5">
                <FontAwesomeIcon icon={faEdit} className="me-0 me-sm-2" />
                <span className="d-none d-sm-inline">Modifier</span>
              </Button>

              <Dropdown>
                <Dropdown.Toggle
                  variant="phoenix-secondary"
                  className="dropdown-caret-none px-3"
                >
                  <FontAwesomeIcon icon={faEllipsis} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#!">Modifer</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Col>
        </Row>

        <Row className="g-0 g-md-4 g-xl-6">
          <Col md={5} xl={4} className="d-none d-md-block">
            <div className="sticky-leads-sidebar">
              <div className="lead-details-offcanvas bg-soft scrollbar">
                <div className="d-flex justify-content-between align-items-center mb-2 d-md-none">
                  <h3 className="mb-0">Lead Details</h3>
                </div>
                <RetailerLeadProfileCard className="mb-3" />
                <AboutLeadCard className="mb-3" />
                <LeadAddressCard />
              </div>
            </div>
          </Col>
          <Col md={7} xl={8}>
            <div className="lead-details-container">
              <UserDetailsInfo data={dealDetailsInfoData} className="mb-7" />
            </div>
          </Col>
        </Row>
      </div>
      <LeadDetailsOffcanvas open={openOffcanvas} setOpen={setOpenOffcanvas} />
    </div>
  );
};

export default EditorDetails;
