import android from "../../../assets/img/logos/icons8-android-os-24.png"
import mac from "../../../assets/img/logos/icons8-mac-os-24.png"
import windows from "../../../assets/img/logos/icons8-windows-client-24.png"

export interface UserDetailsInfoType {
  id: number;
  title: string;
  value: string;
  icon: string;
  color: string;
}

export const userDetailsInfoData: UserDetailsInfoType[][] = [
  [
    {
      id: 1,
      title: "Nom du contact",
      value: "Jackson Pollock",
      icon: "user",
      color: "success"
    }
    ,
    {
      id: 2,
      title: "Rôle",
      value: "Directeur",
      icon: "user",
      color: "info"
    }
    ,
    {
      id: 3,
      title: "Fonction",
      value: "Technique",
      icon: "user",
      color: "info"
    },
  ],
  [
    {
      id: 4,
      title: "Numéro de téléphone mobile",
      value: "05 54 56 32 97",
      icon: "phone",
      color: "primary"
    },
    {
      id: 5,
      title: "Numéro de téléphone fixe",
      value: "05 54 56 32 97",
      icon: "phone",
      color: "primary"
    },
    {
      id: 6,
      title: "Email",
      value: "jacksonpol@email.com",
      icon: "mail",
      color: "warning"
    },
  ]


];

export interface UserDetailsInfoTypeIPERX {
  id: number;
  title: string;
  value: string;
  icon: string;
  color: string;
}

export const userDetailsInfoDataIPERX: UserDetailsInfoTypeIPERX[][] = [
  [
    {
      id: 1,
      title: "Nom du contact",
      value: "Jackson Pollock",
      icon: "user",
      color: "success"
    }
    ,
    {
      id: 2,
      title: "Rôle",
      value: "Directeur",
      icon: "user",
      color: "info"
    }
    ,
    {
      id: 3,
      title: "Fonction",
      value: "Technique",
      icon: "user",
      color: "info"
    },
  ],
  [
    {
      id: 4,
      title: "Numéro de téléphone mobile",
      value: "05 54 56 32 97",
      icon: "phone",
      color: "primary"
    },
    {
      id: 5,
      title: "Numéro de téléphone fixe",
      value: "05 54 56 32 97",
      icon: "phone",
      color: "primary"
    },
    {
      id: 6,
      title: "Email",
      value: "jacksonpol@email.com",
      icon: "mail",
      color: "warning"
    },
  ]


];

export interface UserDetailsInfoTypeIPBX {
  id: number;
  title: string;
  value: string;
  icon: string;
  color: string;
}

export const userDetailsInfoDataIPBX: UserDetailsInfoTypeIPBX[][] = [
  [
    {
      id: 1,
      title: "Nom du contact",
      value: "Jackson Pollock",
      icon: "user",
      color: "success"
    }
    ,
    {
      id: 2,
      title: "Rôle",
      value: "Directeur",
      icon: "user",
      color: "info"
    }
    ,
    {
      id: 3,
      title: "Fonction",
      value: "Technique",
      icon: "user",
      color: "info"
    },
    {
      id: 4,
      title: 'Téléphone',
      value: windows,
      icon: 'phone',
      color: 'primary'
    },
    
  ],
  [
    {
      id: 5,
      title: "Numéro de téléphone mobile",
      value: "05 54 56 32 97",
      icon: "phone",
      color: "primary"
    },
    {
      id: 6,
      title: "Numéro de téléphone fixe",
      value: "05 54 56 32 97",
      icon: "phone",
      color: "primary"
    },
    {
      id: 7,
      title: "Email",
      value: "jacksonpol@email.com",
      icon: "mail",
      color: "warning"
    },
    {
      id: 8,
      title: 'Softphone',
      value: mac,
      icon: 'phone',
      color: 'primary'
    }
  ]


];


















export interface Stat {
  id: number;
  title: string;
  value: string;
  icon: string;
  color: string;
}

export const stats: Stat[] = [
  {
    id: 1,
    title: "Contact",
    value: "$12,000.00",
    icon: "dollar-sign",
    color: "success"
  },
  {
    id: 2,
    title: "Appareils SIP",
    value: "PHO1234",
    icon: "code",
    color: "info"
  },
  {
    id: 3,
    title: "Appareils IAX2",
    value: "New Business",
    icon: "layout",
    color: "primary"
  },
  {
    id: 4,
    title: "Appel actif",
    value: "New Business",
    icon: "layout",
    color: "primary"
  }
];
