import {
  faArrowUpFromBracket,
  faCalendarCheck,
  faCirclePlus,
  faDownload,
  faFileExport,
  faFolderTree,
  faPlus,
  faRefresh,
  faX
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchBox from "components/common/SearchBox";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { ChangeEvent } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import BackupsTable, { backupsTableColumns } from "../../../components/tables/ipbx/BackupsTable";
import { backupsTableData } from "../../../data/iper-x/ipbx/backups";

const BackupsList = () => {
  const table = useAdvanceTable({
    data: backupsTableData,
    columns: backupsTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };
  return (
    <div>
      {/*<PageBreadcrumb items={editorUserBreadcrumbItems} />*/}
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">Sauvegardes</span>
          </h2>
          <Link className="btn btn-phoenix-secondary d-flex flex-center " to="/">
            <FontAwesomeIcon icon={faFolderTree} className="me-2" />
            Emplacement
          </Link>
          <Link className="btn btn-white border border-2 px-2" to="/">
            <FontAwesomeIcon icon={faCirclePlus} className="me-2" />
            Sauvegarder
          </Link>
          <Link className="btn btn-white border border-2 px-2" to="/">
            <FontAwesomeIcon icon={faArrowUpFromBracket} className="me-2" />
            Restaurer
          </Link>
          <Link className="btn btn-white border border-2 px-2" to="/">
            <FontAwesomeIcon icon={faDownload} className="me-2" />
            Télécharger
          </Link>
          <Link className="btn btn-white border border-2 px-2" to="/">
            <FontAwesomeIcon icon={faX} className="me-2" />
            Supprimer
          </Link>
          <Link className="btn btn-white border border-2 px-2" to="/">
            <FontAwesomeIcon icon={faCalendarCheck} className="me-2" />
            Planifier la sauvegarde
          </Link>

          <Link className="btn btn-white border border-2 px-2" to="/">
            <FontAwesomeIcon icon={faCalendarCheck} className="me-2" />
            Planifier la restauration
          </Link>

          <Link className="btn btn-white border border-2 px-2" to="/">
            <FontAwesomeIcon icon={faRefresh} className="me-2" />
            Failover
          </Link>


        </div>
        <div className="mb-4">
          <Row className="g-3">
            <Col xs="auto">
              <SearchBox className="w-300"
                         placeholder="Rechercher"
                         onChange={handleSearchInputChange}
              />
            </Col>
            <Col
              xs="auto"
              className="scrollbar overflow-hidden-y flex-grow-1"
            ></Col>
            <Col xs="auto">
              <Button variant="link" className="text-900 me-4 px-0">
                <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                Export
              </Button>
              <Button variant="primary">
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Planifier une sauvgarde
              </Button>
            </Col>
          </Row>
        </div>
        <div
          className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <BackupsTable />
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default BackupsList;

