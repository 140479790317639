import React, { useState } from "react";
import { Row, Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import IperxParamTAonline from "./IperxParamTAonline";
import IperxParamTAoffline from "./IperxParamTAoffline";
import IperxParamTAdoNotDisturb from "./IperxParamTAdoNotDisturb";
import IperxParamTAlunch from "./IperxParamTAlunch";
import IperxParamTAbusinessTrip from "./IperxParamTAbusinessTrip";
import IperxParamTAchangeStatus from "./IperxParamTAchangeStatus";
import IperxParamTAexceptions from "./IperxParamTAexceptions";


const IperxParamTAmenu = () => {
    const [selectedComponent, setSelectedComponent] = useState<string>("general");

    const handleItemClick = (component: string) => setSelectedComponent(component);

    const selectedMenuStyle: React.CSSProperties = {
        color: "#F72585",
    };

    return (
        <div>
            <Nav
                variant="underline"
                className="mb-2 mb-md-5 pb-3 pt-2 w-100 w-sm-75 w-md-100 mx-auto bg-soft"
            >
                <button onClick={() => handleItemClick("general")} className="nav-link fw-normal me-3 fs-8 pe-2 text-center" style={selectedComponent === "general" ? selectedMenuStyle : {}}>
                    Disponible
                </button>
                <button onClick={() => handleItemClick("offline")} className="nav-link fw-normal me-3 fs-8 pe-2 text-center" style={selectedComponent === "offline" ? selectedMenuStyle : {}}>
                 Absent
                </button>
                <button onClick={() => handleItemClick("do-not-disturb")} className="nav-link fw-normal me-3 fs-8 pe-2 text-center" style={selectedComponent === "do-not-disturb" ? selectedMenuStyle : {}}>
                   Ne pas déranger
                </button>
                <button onClick={() => handleItemClick("lunch")} className="nav-link fw-normal me-3 fs-8 pe-2 text-center" style={selectedComponent === "lunch" ? selectedMenuStyle : {}}>
                    Lunch
                </button>
                <button onClick={() => handleItemClick("business-trip")} className="nav-link fw-normal me-3 fs-8 pe-2 text-center" style={selectedComponent === "business-trip" ? selectedMenuStyle : {}}>
                    Business Trip
                </button>
                <button onClick={() => handleItemClick("exception")} className="nav-link fw-normal me-3 fs-8 pe-2 text-center" style={selectedComponent === "exception" ? selectedMenuStyle : {}}>
                    Exceptions
                </button>
                <button onClick={() => handleItemClick("change-status")} className="nav-link fw-normal me-3 fs-8 pe-2 text-center" style={selectedComponent === "change-status" ? selectedMenuStyle : {}}>
                    Modifier le statut
                </button>
            </Nav>

            {selectedComponent === "general" && <IperxParamTAonline />}
            {selectedComponent === "offline" && <IperxParamTAoffline />}
            {selectedComponent === "do-not-disturb" && <IperxParamTAdoNotDisturb />}
            {selectedComponent === "lunch" && <IperxParamTAlunch />}
            {selectedComponent === "business-trip" && <IperxParamTAbusinessTrip />}
            {selectedComponent === "exception" && <IperxParamTAexceptions />}
            {selectedComponent === "change-status" && <IperxParamTAchangeStatus />}
        </div>
    );
};

export default IperxParamTAmenu;
