import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Button, Spinner } from 'react-bootstrap';

// Helper function to fetch user details
const fetchUserDetails = async (userId: number) => {
  try {
    const response = await fetch(`https://erp.ipercom.io/api/index.php/users/${userId}?includepermissions=1`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'DOLAPIKEY': 'UX8gfqaOcVv8692NNw11fkvE6Sh3DJ5M',
      },
    });
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching user details", error);
    return null;
  }
};

// Helper function to fetch contact details using contact_id
const fetchContactDetails = async (contactId: number) => {
  try {
    const response = await fetch(`https://erp.ipercom.io/api/index.php/contacts/${contactId}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'DOLAPIKEY': 'UX8gfqaOcVv8692NNw11fkvE6Sh3DJ5M',
      },
    });
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching contact details", error);
    return null;
  }
};

// Helper function to fetch company (society) details using socid
const fetchCompanyDetails = async (societyId: number) => {
  try {
    const response = await fetch(`https://erp.ipercom.io/api/index.php/thirdparties/${societyId}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'DOLAPIKEY': 'UX8gfqaOcVv8692NNw11fkvE6Sh3DJ5M',
      },
    });
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching company details", error);
    return null;
  }
};

const convertUnixTimestamp = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  return date.toLocaleString(); // e.g., "9/18/2024, 12:30:57 PM"
};

const UserDetails = () => {
  const { id } = useParams<{ id: string }>(); // Get user ID from URL params
  const [userData, setUserData] = useState<any>(null);
  const [contactData, setContactData] = useState<any>(null);
  const [companyData, setCompanyData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const loadUserAndContactAndCompanyDetails = async () => {
      const user = await fetchUserDetails(Number(id));
      setUserData(user);

      // If user has a contact_id, fetch contact details
      if (user?.contact_id) {
        const contact = await fetchContactDetails(Number(user.contact_id));
        setContactData(contact);
      }

      // If user has a socid, fetch company details
      if (user?.socid) {
        const company = await fetchCompanyDetails(Number(user.socid));
        setCompanyData(company);
      }

      setLoading(false);
    };

    loadUserAndContactAndCompanyDetails();
  }, [id]);

  if (loading) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (!userData) {
    return <p>Aucune donnée trouvée pour cet utilisateur.</p>;
  }

  return (
    <div className="container mt-5">
      <Card className="mb-4">
        <Card.Header>
          <h3>Détails de l'utilisateur</h3>
        </Card.Header>
        <Card.Body>
          <p><strong>Nom:</strong> {userData.firstname} {userData.lastname}</p>
          <p><strong>Email:</strong> {userData.email}</p>
          <p><strong>Téléphone:</strong> {userData.office_phone}</p>
          <p><strong>Mobile:</strong> {userData.user_mobile}</p>
          <p><strong>Login:</strong> {userData.login}</p>
          <p><strong>Administrateur:</strong> {userData.admin === "1" ? "Oui" : "Non"}</p>
          <p><strong>ID de l'utilisateur:</strong> {userData.id}</p>
          <p><strong>Société ID:</strong> {userData.socid}</p>
          <p><strong>Dernière connexion:</strong> {userData.datelastlogin ? convertUnixTimestamp(userData.datelastlogin) : "Jamais"}</p>
        </Card.Body>
      </Card>

      {contactData && (
        <Card className="mb-4">
          <Card.Header>
            <h3>Détails du Contact</h3>
          </Card.Header>
          <Card.Body>
            <p><strong>Nom:</strong> {contactData.firstname}</p>
            <p><strong>Prénom:</strong> {contactData.lastname}</p>
            <p><strong>Email:</strong> {contactData.email}</p>
            <p><strong>Téléphone mobile:</strong> {contactData.phone_mobile}</p>
            <p><strong>Téléphone pro:</strong> {contactData.phone_pro}</p>
            <p><strong>Adresse:</strong> {contactData.address}</p>
            <p><strong>Ville:</strong> {contactData.town}</p>
            <p><strong>Code Postal:</strong> {contactData.zip}</p>
            <p><strong>Date de Création:</strong> {convertUnixTimestamp(contactData.date_creation)}</p>
            <p><strong>Date de Modification:</strong> {convertUnixTimestamp(contactData.date_modification)}</p>
          </Card.Body>
        </Card>
      )}

      {!contactData && <p>Aucun contact associé trouvé.</p>}

      {companyData && (
        <Card className="mb-4">
          <Card.Header>
            <h3>Détails de la Société</h3>
          </Card.Header>
          <Card.Body>
            <p><strong>Nom de la société:</strong> {companyData.name}</p>
            <p><strong>ID de la société:</strong> {companyData.id}</p>
            <p><strong>Adresse:</strong> {companyData.address}</p>
            <p><strong>Code Postal:</strong> {companyData.zip}</p>
            <p><strong>Ville:</strong> {companyData.town}</p>
            <p><strong>TVA Intracommunautaire:</strong> {companyData.tva_intra}</p>
            <p><strong>Forme Juridique:</strong> {companyData.forme_juridique}</p>
            <p><strong>Numéro de SIRET:</strong> {companyData.idprof2}</p>
            <p><strong>Date de Création:</strong> {convertUnixTimestamp(companyData.date_creation)}</p>
          </Card.Body>
        </Card>
      )}

      {!companyData && <p>Aucune société associée trouvée.</p>}
    </div>
  );
};

export default UserDetails;
