import { GroupData } from 'hooks/api/useGroup';
import React, { useState, ChangeEvent } from 'react';
import { Form, Button } from 'react-bootstrap';

type GroupFormProps = {
  tableData: GroupData[];
  setTableData: React.Dispatch<React.SetStateAction<GroupData[]>>;
  onClose: () => void;
};

const AddGroupForm: React.FC<GroupFormProps> = ({ tableData, setTableData, onClose }) => {
  const [newGroup, setNewGroup] = useState<GroupData>({
    id: 0,
    id_user: 0,
    nameGroup: "",
    ringStrategy: "",
    internalUsers: "",
    externalNumber: "",
    trunkExternalNumber: "",
    priorityExternalInternal: "",
    ringDuration: 0,
    state: false
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewGroup(prevGroup => ({
      ...prevGroup,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/group', {
        method: 'POST',
        headers: {
          'accept': 'text/plain',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newGroup)
      });

      if (response.ok) {
        window.location.reload();
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: GroupData = await response.json();
      setTableData([...tableData, data]);
      onClose();
    } catch (error) {
      console.error("Erreur lors de l'ajout d'un groupe d'appels", error);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="nameGroup">
        <Form.Label>Nom du groupe</Form.Label>
        <Form.Control
          type="text"
          name="nameGroup"
          value={newGroup.nameGroup}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="ringStrategy">
        <Form.Label>Stratégie de sonnerie</Form.Label>
        <Form.Control
          as="select"
          className="form-select"
          name="ringStrategy"
          value={newGroup.ringStrategy}
          onChange={handleChange}
          required
        >
          <option value="priority">Priorité</option>
          <option value="all">Tous</option>
        </Form.Control>
      </Form.Group>

      <Form.Group controlId="internalUsers">
        <Form.Label>Utilisateurs internes</Form.Label>
        <Form.Control
          type="text"
          name="internalUsers"
          value={newGroup.internalUsers}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="externalNumber">
        <Form.Label>Numéro externe</Form.Label>
        <Form.Control
          type="text"
          name="externalNumber"
          value={newGroup.externalNumber}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="trunkExternalNumber">
        <Form.Label>Trunk numéro externe</Form.Label>
        <Form.Control
          type="text"
          name="trunkExternalNumber"
          value={newGroup.trunkExternalNumber}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="priorityExternalInternal">
        <Form.Label>Priorité externe/interne</Form.Label>
        <Form.Control
          type="text"
          name="priorityExternalInternal"
          value={newGroup.priorityExternalInternal}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="ringDuration">
        <Form.Label>Durée de sonnerie</Form.Label>
        <Form.Control
          type="number"
          name="ringDuration"
          value={newGroup.ringDuration}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="state">
        <Form.Label>État</Form.Label>
        <Form.Check
          type="checkbox"
          name="state"
          checked={newGroup.state}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setNewGroup({
              ...newGroup,
              state: e.target.checked
            })
          }
        />
      </Form.Group>

      <Button variant="primary" type="submit" className="mt-2">
        Ajouter
      </Button>
    </Form>
  );
};

export default AddGroupForm;
