import React, { useState, ChangeEvent } from 'react';
import { Form, Button } from 'react-bootstrap';
import { RetailerClientDataType } from 'data/iper-x/retailer/retailerClientsTableData';

type RetailerClientFormProps = {
  tableData: RetailerClientDataType[];
  setTableData: React.Dispatch<React.SetStateAction<RetailerClientDataType[]>>;
  onClose: () => void;
};

const RetailerClientForm: React.FC<RetailerClientFormProps> = ({ tableData, setTableData, onClose }) => {
  const [newClient, setNewClient] = useState<RetailerClientDataType>({
    id: 0,
    company: { name: '', status: { label: '', type: 'secondary' } }, // Par défaut à 'secondary'
    product: { name: '' },
    responsible: { name: '', functionName: '' },
    licence: '',
    type: { name: '', status: '' }, // Assure-toi que 'status' a une valeur par défaut appropriée
    date: '',
    expiration: { date: '', status: '' }
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setNewClient(prevClient => ({
      ...prevClient,
      [name]: value
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setTableData([...tableData, newClient]);
    onClose();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="companyName">
        <Form.Label>SOCIÉTÉ</Form.Label>
        <Form.Control
          type="text"
          name="companyName"
          value={newClient.company.name}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setNewClient({
              ...newClient,
              company: { ...newClient.company, name: e.target.value }
            })
          }
          required
        />
      </Form.Group>

      <Form.Group controlId="productName">
        <Form.Label>PRODUIT</Form.Label>
        <Form.Control
          type="text"
          name="productName"
          value={newClient.product.name}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setNewClient({
              ...newClient,
              product: { ...newClient.product, name: e.target.value }
            })
          }
          required
        />
      </Form.Group>

      <Form.Group controlId="responsibleName">
        <Form.Label>RESPONSABLE NUMÉRO DE LICENCE</Form.Label>
        <Form.Control
          type="text"
          name="responsibleName"
          value={newClient.responsible.name}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setNewClient({
              ...newClient,
              responsible: { ...newClient.responsible, name: e.target.value }
            })
          }
          required
        />
      </Form.Group>

      {/* <Form.Group controlId="type">
        <Form.Label>TYPE</Form.Label>
        <Form.Control
          as="select"
          name="type"
          value={newClient.type.name}
          // onChange={(e: ChangeEvent<HTMLSelectElement>) =>
          //   setNewClient({
          //     ...newClient,
          //     type: { ...newClient.type, name: e.target.value }
          //   })
          // }
          required
        >
          <option value="">Sélectionner un type</option>
          <option value="Hébergé">Hébergé</option>
          <option value="Non hébergé">Non hébergé</option>
        </Form.Control>
      </Form.Group> */}

      <Form.Group controlId="date">
        <Form.Label>DATE DE CRÉATION</Form.Label>
        <Form.Control
          type="date"
          name="date"
          value={newClient.date}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setNewClient({ ...newClient, date: e.target.value })
          }
          required
        />
      </Form.Group>

      <Button variant="primary" type="submit" className="mt-2">
        Ajouter
      </Button>
    </Form>
  );
};

export default RetailerClientForm;
