import { QueueData } from 'hooks/api/useQueue';
import React, { useState, ChangeEvent } from 'react';
import { Form, Button } from 'react-bootstrap';

type QueueFormProps = {
  tableData: QueueData[];
  setTableData: React.Dispatch<React.SetStateAction<QueueData[]>>;
  onClose: () => void;
};

const AddQueueForm: React.FC<QueueFormProps> = ({ tableData, setTableData, onClose }) => {
  const [newQueue, setNewQueue] = useState<QueueData>({
    id: 0,
    id_user: 0,
    name: "",
    timeout: 0,
    strategy: "",
    state: false
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewQueue(prevQueue => ({
      ...prevQueue,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/queue', {
        method: 'POST',
        headers: {
          'accept': 'text/plain',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newQueue)
      });

      if (response.ok) {
        window.location.reload();
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: QueueData = await response.json();
      setTableData([...tableData, data]);
      onClose();
    } catch (error) {
      console.error("Erreur lors de l'ajout d'une file d'attente", error);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="name">
        <Form.Label>Nom</Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={newQueue.name}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="timeout">
        <Form.Label>Timeout(s)</Form.Label>
        <Form.Control
          type="number"
          name="timeout"
          value={newQueue.timeout}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="strategy">
                <Form.Label>Stratégie</Form.Label>
                <Form.Control
                  as="select"
                  className="form-select"
                  name="strategy"
                  value={newQueue.strategy}
                  onChange={handleChange}
                  required
                >
                <option value="ringall">Sonner tous</option>
                <option value="leastrecent">Le moins récent</option>
                <option value="fewestcalls">Le moins d'appels</option>
                <option value="random">Aléatoire</option>
                <option value="rrmemory">Mémoire</option>
                <option value="linear">Linéaire</option>
                <option value="wrandom">Aléatoire pondéré</option>
                <option value="rrordered">Ordonné</option>
                </Form.Control>
              </Form.Group>

      <Form.Group controlId="state">
        <Form.Label>État</Form.Label>
        <Form.Check
          type="checkbox"
          name="state"
          checked={newQueue.state}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setNewQueue({
              ...newQueue,
              state: e.target.checked
            })
          }
        />
      </Form.Group>

      <Button variant="primary" type="submit" className="mt-2">
        Ajouter
      </Button>
    </Form>
  );
};

export default AddQueueForm;
