import React, { useState } from 'react';
import classNames from 'classnames';
import Button from 'components/base/Button';
import LeadInfoItem from 'components/info-items/LeadInfoItem';
import { Card, Modal, Form } from 'react-bootstrap';
import {
  UilEstate,
  UilLocationArrow,
  UilLocationPinAlt,
  UilMap,
  UilMapPin,
  UilMapPinAlt,
  UilUserLocation,
  UilWindsock
} from "@iconscout/react-unicons";

const LeadAddressCard = ({ className }: { className?: string }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Card className={classNames(className)}>
      <Card.Body>
        <div className="d-flex align-items-center mb-5">
          <h3>Adresse</h3>
          <Button variant="link" className="px-3" onClick={handleShow}>
            Modifier
          </Button>
        </div>
        <LeadInfoItem
          className="mb-4"
          label="Adresse"
          icon={UilEstate}
          value="38/2 Penelope street"
        />
        <LeadInfoItem
          className="mb-4"
          label="Code postal"
          icon={UilMapPinAlt}
          value="1425"
        />
        <LeadInfoItem
          className="mb-4"
          label="Ville"
          icon={UilLocationPinAlt}
          value="Pompignac"
        />
        <LeadInfoItem
          className="mb-4"
          label="Région"
          icon={UilMap}
          value="Nouvelle-Aquitaint"
        />
        <LeadInfoItem
          label="Pays"
          icon={UilWindsock}
          value="FRANCE"
        />
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Modifier l'adresse du lead</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Replace this with your form for editing lead address information */}
            <Form>
              <Form.Group className="mb-3" controlId="address">
                <Form.Label>Adresse</Form.Label>
                <Form.Control type="text" defaultValue="38/2 Penelope street" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="postalCode">
                <Form.Label>Code postal</Form.Label>
                <Form.Control type="text" defaultValue="1425" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="city">
                <Form.Label>Ville</Form.Label>
                <Form.Control type="text" defaultValue="Pompignac" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="region">
                <Form.Label>Région</Form.Label>
                <Form.Control type="text" defaultValue="Nouvelle-Aquitaine" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="country">
                <Form.Label>Pays</Form.Label>
                <Form.Control type="text" defaultValue="FRANCE" />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Annuler
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Sauvegarder
            </Button>
          </Modal.Footer>
        </Modal>
      </Card.Body>
    </Card>
  );
};

export default LeadAddressCard;
