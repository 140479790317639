import { Nav } from 'react-bootstrap';

import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import PageBreadcrumb, {
  PageBreadcrumbItem
} from 'components/common/PageBreadcrumb';
import ParamVideo from '../../system/video/ParamVideo';
import ParamAudio from '../../system/audio/ParamAudio';
import UserSyncConfig from "./UserSyncConfig";
import SignInConfig from "./SignInConfig";
import ContactSyncConfig from "./ContactSyncConfig";
import CalendarSyncConfig from "./CalendarSyncConfig";
import TeamsRoutingConfig from "./TeamsRoutingConfig";

export const conferenceBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Paramètre',
    url: '/apps/ipbx/parametres'
  },
  {
    label: 'Intégration',
    url: '/apps/ipbx/parametres?selected=integration'
  },
  {
    label: 'Microsoft 365',
    url: '/apps/ipbx/parametres/microsoft-integration'
  }
];

const Microsoft365IntegrationConfig = () => {
  const [selectedComponent, setSelectedComponent] = useState<string>('user-sync');
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const selectedComponentParam = searchParams.get('selected');
    if (selectedComponentParam) {
      setSelectedComponent(selectedComponentParam);
    }
  }, [location]);

  const handleItemClick = (component: string) => {
    setSelectedComponent(component);
    // Update the URL with the selected component
    const searchParams = new URLSearchParams({ selected: component });
    window.history.pushState(
      {},
      '',
      `${location.pathname}?${searchParams.toString()}`
    );
  };

  return (
    <>
      <PageBreadcrumb items={conferenceBreadcrumbItems} />
      <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
        <h2 className="mb-0">
          <span className="me-3">Intégration Microsoft 365</span>
        </h2>
      </div>

      <div>
        <Nav
          variant="underline"
          className="mb-2 mb-3 md-5 pb-3 pt-2 w-100 w-sm-75 w-md-100 mx-auto bg-soft"
        >
          <Nav.Item>
            <Nav.Link
              className={`fw-semi-bold me-3 fs-8 pe-2 text-center ${selectedComponent === 'user-sync' ? 'active' : ''}`}
              onClick={() => handleItemClick('user-sync')}
            >
              Synchronisation utilisateur
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={`fw-semi-bold me-3 fs-8 pe-2 text-center ${selectedComponent === 'sign-in' ? 'active' : ''}`}
              onClick={() => handleItemClick('sign-in')}
            >
              Sign In
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={`fw-semi-bold me-3 fs-8 pe-2 text-center ${selectedComponent === 'contact-sync' ? 'active' : ''}`}
              onClick={() => handleItemClick('contact-sync')}
            >
              Synchronisation contacts
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={`fw-semi-bold me-3 fs-8 pe-2 text-center ${selectedComponent === 'calendar-sync' ? 'active' : ''}`}
              onClick={() => handleItemClick('calendar-sync')}
            >
              Synchronisation calendrier
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={`fw-semi-bold me-3 fs-8 pe-2 text-center ${selectedComponent === 'teams-routing' ? 'active' : ''}`}
              onClick={() => handleItemClick('teams-routing')}
            >
              Routage direct Teams (Alpha)
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <hr />

        {selectedComponent === 'user-sync' && <UserSyncConfig />}
        {selectedComponent === 'sign-in' && <SignInConfig />}
        {selectedComponent === 'contact-sync' && <ContactSyncConfig />}
        {selectedComponent === 'calendar-sync' && <CalendarSyncConfig />}
        {selectedComponent === 'teams-routing' && <TeamsRoutingConfig />}
      </div>
    </>
  );
};

export default Microsoft365IntegrationConfig;
