import React, { useState, ChangeEvent } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faFilter } from '@fortawesome/free-solid-svg-icons';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import SearchBox from 'components/common/SearchBox';
import LeadsFilterModal from 'components/modals/LeadsFilterModal';
import LeadsTable from 'components/tables/old/LeadsTable';
import useRetailerClients from 'hooks/api/useRetailerClients';
import RetailerClientForm from './RetailerClientForm';
import { retailerClientsTableColumns } from '../../../../components/tables/iper-x/retailer/RetailerClientsTable';
import useAdvanceTable from '../../../../hooks/useAdvanceTable';

const RetailerClients = () => {
  const { tableData, setTableData, fetchData, defaultTableData, loading, error } =
    useRetailerClients();
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [showAddClientModal, setShowAddClientModal] = useState(false);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value.toLowerCase();
    const filteredData = defaultTableData.filter(client =>
      client.company.name.toLowerCase().includes(searchText)
    );
    setTableData(filteredData);
  };

  const table = useAdvanceTable({
    data: tableData,
    columns: retailerClientsTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false,
    initialState: {
      columnVisibility: {
        status: false,
        designation: false
      }
    }
  });

  return (
    <AdvanceTableProvider {...table}>
      <h2 className="mb-4">Vos clients</h2>

      {loading && <p>Chargement des données...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {!loading && (
        <>
          <Row className="g-3 justify-content-between mb-4">
            <Col xs="auto">
              <SearchBox
                placeholder="Rechercher par nom"
                className="me-2"
                onChange={handleSearchInputChange}
              />
            </Col>
            <Col xs="auto">
              <div className="d-flex">
                <Button
                  variant="primary"
                  onClick={() => setShowAddClientModal(true)}
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Ajouter un IperX
                </Button>
                <Button
                  variant="phoenix-secondary"
                  className="px-3 ms-2"
                  onClick={() => setOpenFilterModal(true)}
                >
                  <FontAwesomeIcon
                    icon={faFilter}
                    transform="down-3"
                    className="text-primary"
                  />
                </Button>
              </div>
              <LeadsFilterModal
                show={openFilterModal}
                handleClose={() => setOpenFilterModal(false)}
              />
            </Col>
          </Row>
          <LeadsTable />
        </>
      )}

      <Modal
        show={showAddClientModal}
        onHide={() => setShowAddClientModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un nouveau client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RetailerClientForm
            tableData={tableData}
            setTableData={setTableData}
            onClose={() => setShowAddClientModal(false)}
          />
        </Modal.Body>
      </Modal>
    </AdvanceTableProvider>
  );
};

export default RetailerClients;
