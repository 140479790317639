import React from 'react';
import { Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";

const GoogleConfig = () => {
  return (
    <>
      <Card className="mb-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">Configurer</h3>
              <ol>
                <li>
                  Ouvrez la console Google avec le compte de votre organisation
                  ou votre compte personnel.
                </li>
                <li>
                  Cliquez sur "CRÉER UN PROJET" et complétez les informations du
                  projet:
                  <ul>
                    <li>
                      "Nom du projet" - entrez le nom du projet, par exemple
                      "pbx-intégration".
                    </li>
                    <li>
                      "Organisation" - sélectionnez la bonne organisation (le
                      cas échéant).
                    </li>
                    <li>« Emplacement » - sélectionnez le bon emplacement.</li>
                  </ul>
                </li>
                <li>Cliquez sur « Créer » pour créer le projet.</li>
                <li>
                  Allez sur « API et services » {'>'} « Écran de consentement
                  OAuth ».
                </li>
                <li>
                  Sélectionnez le « type d’utilisateur » approprié:
                  <ul>
                    <li>
                      « Interne » - L'application sera accessible par les
                      comptes Google d'entreprise.
                    </li>
                    <li>
                      "Externe" - Accessible au public pour tout utilisateur
                      disposant d’un compte Google.
                    </li>
                  </ul>
                </li>
                <li>
                  Complétez les informations "Écran de consentement OAuth":
                  <ul>
                    <li>
                      « Informations de l’application » - « Nom de l’application
                      » - entrez le nom de l’application, par exemple
                      Intégration PBX.
                    </li>
                    <li>
                      « Informations de l'application » - « Email de support
                      utilisateur » - entrez l'email pour obtenir de
                      l'assistance.
                    </li>
                    <li>
                      « Coordonnées du développeur » - « Adresses email » -
                      entrez l’email pour le support de développement.
                    </li>
                  </ul>
                </li>
                <li>Cliquez sur « ENREGISTRER ET CONTINUER ».</li>
                <li>
                  Sur « Périmètres », cliquez sur « ENREGISTRER ET CONTINUER ».
                </li>
                <li>
                  Ouvrez « Identifiants » et cliquez sur « + CRÉER DES
                  IDENTIFIANTS » {'>'} « ID client OAuth ».
                </li>
                <li>
                  Dans « Type d'application », sélectionnez « Application Web »:
                  <ul>
                    <li>
                      « Nom » - entrez un nom pour le client OAuth, par exemple
                      « Intégration PBX ».
                    </li>
                    <li>
                      « URI de redirection autorisés » - cliquez sur « + AJOUTER
                      UN URI » et copiez la valeur ci-dessous :
                      <a href="https://frenchburgers.oniperx.fr/oauth2google">
                        https://frenchburgers.oniperx.fr/oauth2google
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Copiez « Votre identifiant client » et collez-le ci-dessous.
                </li>
                <li>
                  Copiez « Votre clé secrète client » et collez-la ci-dessous.
                </li>
              </ol>

              <Form.Group controlId="formNum" className="mt-3 mb-3">
                <Form.Label>
                  11. Copiez « Votre identifiant client » et collez-le ci-dessous :
                </Form.Label>
                <FloatingLabel controlId="floatingInputAuthMax" label="key">
                  <Form.Control type="text" value="" />
                </FloatingLabel>
              </Form.Group>

              <Form.Group controlId="formNum" className="mt-3 mb-3">
                <Form.Label>
                  12. Copiez « Votre clé secrète client » et collez-la ci-dessous :
                </Form.Label>
                <FloatingLabel controlId="floatingInputAuthMax" label="key">
                  <Form.Control type="text" value="" />
                </FloatingLabel>
              </Form.Group>


            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default GoogleConfig;
