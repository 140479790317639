import {
  faCircleChevronDown,
  faCirclePlus,
  faFileExport, faFileImport, faFilter,
  faGear,
  faGears,
  faPencil,
  faPlus,
  faRefresh,
  faTerminal,
  faUser,
  faX
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchBox from "components/common/SearchBox";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import React, { ChangeEvent, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import UsersTable from "../../../components/tables/ipbx/UsersTable";
import DevicesTable, { devicesTableColumns } from "../../../components/tables/ipbx/DevicesTable";
import { devicesTableData } from "../../../data/iper-x/ipbx/devices";
import LeadsFilterModal from "../../../components/modals/LeadsFilterModal";

const DevicesList = () => {
  const table = useAdvanceTable({
    data: devicesTableData,
    columns: devicesTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };
  return (
    <div>
      {/*<PageBreadcrumb items={editorUserBreadcrumbItems} />*/}
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">Téléphones</span>
          </h2>



        </div>
        <div className="mb-4">
          <Row className="g-3">
            <Col xs="auto">
              <SearchBox className="w-300"
                         placeholder="Rechercher"
                         onChange={handleSearchInputChange}
              />
            </Col>
            <Col
              xs="auto"
              className="scrollbar overflow-hidden-y flex-grow-1"
            ></Col>
            <Col xs="auto">
          <Button variant="link" className="text-900 me-4 px-0">
               <FontAwesomeIcon icon={faFileImport} className="fs-9 me-2" />
               Importer
             </Button>
             <Button variant="link" className="text-900 me-4 px-0">
               <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
               Exporter
             </Button>
              <Button variant="primary" href="/ipbx/téléphones/ajouter">
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Ajouter un téléphone
              </Button>
             <Button
                variant="phoenix-secondary"
                className="px-3 ms-2"
                onClick={() => setOpenFilterModal(true)}
              >
                <FontAwesomeIcon
                  icon={faFilter}
                  transform="down-3"
                  className="text-primary"
                />
              </Button>
              <LeadsFilterModal
                show={openFilterModal}
                handleClose={() => setOpenFilterModal(false)}
              />
            </Col>
          </Row>
        </div>
        <div
          className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <DevicesTable />
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default DevicesList;

