import { Button, Card, Col, Form, Row } from "react-bootstrap";

const RootCredentialsConfig = () => {

  return (
    <>
      <Card className="">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">
                Identifiants racine
              </h3>
              <Form>
                <Form.Group className="mb-3" controlId="formGroupUsername">
                  <Form.Label>Nom d'utilisateur racine</Form.Label>
                  <Form.Control type="text" value="admin" readOnly />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGroupEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" value="support@pigma.fr" readOnly />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGroupOldPassword">
                  <Form.Label>Ancien mot de passe</Form.Label>
                  <Form.Control type="password" placeholder="Ancien mot de passe" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGroupNewPassword">
                  <Form.Label>Nouveau mot de passe</Form.Label>
                  <Form.Control type="password" placeholder="Nouveau mot de passe" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGroupConfirmPassword">
                  <Form.Label>Confirmez le mot de passe</Form.Label>
                  <Form.Control type="password" placeholder="Confirmez le mot de passe" />
                </Form.Group>

                <Button variant="primary" type="submit">
                  Modifier
                </Button>
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default RootCredentialsConfig;
