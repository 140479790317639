import React from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { BarChart } from 'echarts/charts';
import {
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { useAppContext } from 'providers/AppProvider';
import dayjs from 'dayjs';
import { getPastDates } from 'helpers/utils';
import { tooltipFormatterDefault } from 'helpers/echart-utils';

echarts.use([
  TooltipComponent,
  BarChart,
  CanvasRenderer,
  GridComponent,
  LegendComponent
]);

const dates = getPastDates(12);

const data1 = [
  24000, 24000, 24000, 14000, 30000, 24000, 32000, 32000, 18000, 12000, 32000,
  30000
];
const data2 = [
  36000, 36000, 36000, 28000, 36000, 39000, 54000, 38000, 22000, 34000, 52000,
  42000
];

const getDefaultOptions = (getThemeColor: (color: string) => string) => ({
  color: [getThemeColor('primary'), getThemeColor('gray-300')],
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    backgroundColor: getThemeColor('gray-100'),
    borderColor: getThemeColor('gray-300'),
    textStyle: { color: getThemeColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    axisPointer: {
      type: 'none'
    },
    formatter: (params: any[]) => tooltipFormatterDefault(params, 'MMM DD', 'color')
  },
  legend: {
    data: ['Objectif', 'Chiffre'],
    right: 'right',
    width: '100%',
    itemWidth: 16,
    itemHeight: 8,
    itemGap: 20,
    top: 3,
    inactiveColor: getThemeColor('gray-500'),
    textStyle: {
      color: getThemeColor('gray-900'),
      fontWeight: 600,
      fontFamily: 'Nunito Sans'
    }
  },
  xAxis: {
    type: 'category',
    data: dates,
    axisLabel: {
      color: getThemeColor('gray-800'),
      formatter: (value: string) => dayjs(value).format('MMM DD'),
      interval: 1,
      fontFamily: 'Nunito Sans',
      fontWeight: 600,
      fontSize: 12.8
    },
    axisLine: {
      lineStyle: {
        color: getThemeColor('gray-300')
      }
    },
    axisTick: { show: false }
  },
  yAxis: {
    type: 'value',
    axisPointer: { type: 'none' },
    axisTick: { show: false },
    splitLine: {
      interval: 5,
      lineStyle: {
        color: getThemeColor('gray-200')
      }
    },
    axisLine: { show: false },
    axisLabel: {
      fontFamily: 'Nunito Sans',
      fontWeight: 600,
      fontSize: 12.8,
      color: getThemeColor('gray-800'),
      margin: 20,
      verticalAlign: 'bottom',
      formatter: (value: number) => `${value.toLocaleString()}€`
    }
  },
  series: [
    {
      name: 'Objectif',
      type: 'bar',
      barWidth: '6px',
      data: data2,
      barGap: '30%',
      itemStyle: {
        borderRadius: [2, 2, 0, 0],
        color: getThemeColor('primary')
      }
    },
    {
      name: 'Chiffre',
      type: 'bar',
      data: data1,
      barWidth: '6px',
      barGap: '30%',
      itemStyle: {
        borderRadius: [2, 2, 0, 0],
        color: getThemeColor('info-100')
      }
    }
  ],
  grid: {
    right: 0,
    left: 3,
    bottom: 0,
    top: '15%',
    containLabel: true
  },
  animation: false
});

interface ChartProps {
  height: string | number;
  width: string | number;
}

const EcomProjectionVsActualChart: React.FC<ChartProps> = ({ height, width }) => {
  const { getThemeColor } = useAppContext();

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getDefaultOptions(getThemeColor)}
      style={{ height, width }}
    />
  );
};

export default EcomProjectionVsActualChart;
