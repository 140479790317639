import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import { Link } from "react-router-dom";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Avatar from "components/base/Avatar";

import Badge from "components/base/Badge";
import { UsersIperx } from "../../../data/iper-x/ipbx/usersIperx";
import RevealDropdown, { RevealDropdownTrigger } from "components/base/RevealDropdown";
import ActionDropdownItems from "components/common/ActionDropdownItems";
import { faClock, faEllipsis, faMessage, faSquarePhone, faStar, faVideoCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Dropdown } from "react-bootstrap";

export const usersIperxTableColumns: ColumnDef<UsersIperx>[] = [

  {
    id: "user",
    accessorFn: ({ user }) => user.name,
    header: "Nom d’utilisateur",
    cell: ({ row: { original } }) => {
      const { name, avatar, status} = original.user;
      return (
        <Link
          to={`/iperx/utilisateurs/details/${name}`}
          className="d-flex align-items-center"
        >
          <Avatar src={avatar} size="m" className="me-3" status={status} />
          <p className="mb-0 ms-3 text-1100 fw-bold">{name}</p>
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: "20%"},
        className: "ps-8"
      },
      cellProps: { className: "ps-8 py-0 white-space-nowrap" }
    }
  },
  {
    accessorKey: 'numero',
    header: 'Numéro de poste',
    cell: ({ row: { original } }) => original.numberPoste,
    meta: {
      headerProps: {
        style: { width: '14%' },
        className: 'pe-6'
      },
      cellProps: {
        className: 'white-space-nowrap text-start fw-bold text-700 py-2 pe-6'
      }
    }
  },
  {
    accessorKey: "email",
    header: "Email",
    meta: {
      headerProps: { style: { width: "14%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "mobile",
    header: "Mobile",
    meta: {
      headerProps: { style: { width: "14%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "calling_id",
    header: "ID d’appelant",
    meta: {
      headerProps: { style: { width: "14%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "favoris",
    header: "Favoris",
    cell: ({ row: { original } }) => {
      return (
        <div className="d-flex align-items-center ms-3">
          <FontAwesomeIcon
            icon={faStar}
            style={{ fontSize: "25px" }}
            transform="shrink-2 up-1" 
          />
        </div>
      );
    },
    meta: {
      headerProps: { style: { width: "10%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "option",
    header: "",
    cell: ({ row: { original } }) => {
      const {
        user: { name }
      } = original;
      return (
        <div className="d-flex align-items-center justify-content-center flex-1">
          <Link to={`/iperx/utilisateurs/appel-waiting/${name}`} className="me-3">
            <FontAwesomeIcon
              icon={faSquarePhone}
              style={{ fontSize: "25px" }}
              transform="shrink-2 up-1" 
            />
          </Link>
          <Link to={`/iperx/utilisateurs/appel-waiting/${name}`} className="me-3">
            <FontAwesomeIcon
              icon={faVideoCamera}
              style={{ fontSize: "28px" }}
              transform="shrink-2 up-1" 
            />
          </Link>
          {/* a garder pour plus tard , quand on assignera la discurssion en fonction de l'id et non du name */}
          {/* <Link to={`/iperx/chat/${name}/conversation`} className="me-3"> */}
          <Link to={`/iperx/chat`} className="me-3">
            <FontAwesomeIcon
              icon={faMessage}
              style={{ fontSize: "22px" }}
              transform="shrink-2 up-1" 
            />
          </Link>
          <RevealDropdownTrigger>
      <RevealDropdown>
        <Link to={`/iperx/utilisateurs/details/${name}`} className="d-flex align-items-center text-1000 m-1">
         Modifier
        </Link>
      </RevealDropdown>
    </RevealDropdownTrigger>
        </div>
      );
    },
    meta: {
      headerProps: { style: { width: '5%' }, className: 'text-end' },
    }
  },
];

const IperxUsersTable = () => {
  
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9" }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default IperxUsersTable;
