import { Button, Card, Col, FloatingLabel, Form, InputGroup, Row } from "react-bootstrap";
import PageBreadcrumb, {
  PageBreadcrumbItem
} from '../../../../../components/common/PageBreadcrumb';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";

export const optionsBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Paramètre',
    url: '/apps/ipbx/parametres'
  },
  {
    label: 'Avancé',
    url: '/apps/ipbx/parametres?selected=advance'
  },
  {
    label: 'Mises à jour',
    url: '/apps/ipbx/parametres/update'
  }
];

const UpdateConfig = () => {
  return (
    <>
      <PageBreadcrumb items={optionsBreadcrumbItems} />
      <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
        <h2 className="mb-0">
          <span className="me-3">Mises à jour</span>
        </h2>
      </div>

      <Card className="mb-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">
                Version 20 (inclut la mise à jour de l'OS de Debian 10 à 12)
              </h3>

              <span className="me-3">
                La version 20 est maintenant disponible. Avant d'effectuer la
                mise à jour, faites une sauvegarde complète de IPER-X et
                sauvegardez-la hors de l'instance. Cette mise à jour mettra
                aussi à jour Debian de 10 à 12.
              </span>
              <br />
              <Button variant="btn btn-primary px-2">
                Mise à jour
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mb-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">Fuseau horaire</h3>
              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Choisir le fuseau horaire</Form.Label>
                <FloatingLabel controlId="floatingSelect" label="Region">
                  <Form.Select aria-label="Floating label select example">
                    <option>+1:00 Spain (Madrid, Palma)</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mb-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">
                Configurer les heures de bureau
              </h3>
              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>
                  Configurer les heures de bureau et de pause par défaut. Les
                  appels seront routés en fonction.
                </Form.Label>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>


      <Card className="mb-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <Form.Check type="checkbox" id="sameAsShipping" className="4">
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  <h3 className="fw-bolder mb-2 line-clamp-1">
                    Mettre à jour automatiquement iperx
                  </h3>
                </Form.Check.Label>
              </Form.Check>

              <span className="me-3">
           iperx contrôlera et téléchargera automatiquement les mises à jour disponibles et pertinentes à la date/heure sélectionnée. Les mises à jour incluant les firmwares supportés par iperx pour vos téléphones IP, les fichiers de langue, les certificats PUSH et les modèles de trunks SIP seront effectuées automatiquement
Les mises à jour iperx "RELEASE" seront aussi téléchargées et installées automatiquement. Si la mise à jour est une version "Alpha/Beta (instable)", elle ne sera pas installée automatiquement et devra être installée manuellement si nécessaire. Les mises à jour Alpha/Beta sont utilisées pour évaluer une prochaine version avant qu'elle sorte officiellement, et devraient être utilisées à des fins de tests et d'évaluation. N'installez pas de mise à jour Alpha/Beta sur un système en production.
Quand les mises à jour sont effectuées, tous les services iperx redémarreront plusieurs fois. Il est donc important que les mises à jour soient prévues en dehors des heures de travail.
Sur les systèmes Linux, cette option installe aussi automatiquement les mises à jour de sécurité Debian.
              </span>


              <Form.Group
                controlId="formNum"
                className="mt-3 mb-3 d-flex align-items-center"
              >
                <FloatingLabel controlId="floatingSelect" label="Region"  className="ms-5">
                  <Form.Select aria-label="Floating label select example">
                    <option>Toutes les semaines le</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>

                <FloatingLabel controlId="floatingSelect" label="Region" className="ms-5">
                  <Form.Select aria-label="Floating label select example">
                    <option>03:00 - 04:00</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>

                <Form.Check.Label className="fs-8 fw-normal me-2 ms-5">
                 Les
                </Form.Check.Label>

                <FloatingLabel controlId="floatingSelect" label="Region" className="ms-5">
                  <Form.Select aria-label="Floating label select example">
                    <option>Samedi</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>


            </Col>
          </Row>
        </Card.Body>
      </Card>

    </>
  );
};

export default UpdateConfig;
