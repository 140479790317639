import { useState, useEffect, useCallback } from 'react';

export interface TrunkData {
  id: number,
  id_user: number,
  name: string,
  username: string,
  password: string,
  server: string,
  clientid: string,
  nameInstance: string,
  subdomainInstance: string,
  caps: number,
  currentCall: number,
  displayNumber: string,
  prefixe: string,
  ringDuration: number,
  withRegistration: boolean,
  sdaRegistration: string,
  nbLiveCalls: number,
  state: boolean
}
const trunkTableData: TrunkData[] = [
  {
    id: 0,
    id_user: 0,
    name: "Damien",
    username: "Fasquelle",
    password: "root",
    server: "hhtps:023",
    clientid: "1",
    nameInstance: "Test",
    subdomainInstance: "Test2",
    caps: 0,
    currentCall: 0,
    displayNumber: "2",
    prefixe: "02",
    ringDuration: 0,
    withRegistration: true,
    sdaRegistration: "2020",
    nbLiveCalls: 0,
    state: true
  }
]

const useTrunk = () => {
  const [trunkData, setTrunkData] = useState(trunkTableData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchUsers = useCallback(async () => {
    try {
      const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/trunk', {
        method: 'GET',
        headers: {
          'accept': 'text/plain',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      // console.log("Fetched data: ", data);
      setTrunkData(data);
      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données de l'API", error);
      setError('Impossible de récupérer les données.');
      setTrunkData(trunkTableData);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return {
    trunkData,
    setTrunkData,
    loading,
    error
  };
};

export default useTrunk;
