import { useState, useCallback, useEffect } from "react";

export interface GroupData {
  id: number,
  id_user: number,
  nameGroup: string,
  ringStrategy: string,
  internalUsers: string,
  externalNumber: string,
  trunkExternalNumber: string,
  priorityExternalInternal: string,
  ringDuration: number,
  state: boolean
}

const groupTableData: GroupData[] = [
  {
    id: 1,
    id_user: 9,
    nameGroup: "Au lit",
    ringStrategy: "obligé",
    internalUsers: "1579,5458",
    externalNumber: "789654123",
    trunkExternalNumber: "Jean",
    priorityExternalInternal: "interne",
    ringDuration: 90,
    state: false
  }
];

const useGroup = () => {
  const [groupData, setGroupData] = useState(groupTableData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    console.log("Fetching new API data...");
    try {
      const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/group', {
        method: 'GET',
        headers: {
          'accept': 'text/plain',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      // console.log("Fetched data: ", data);
      setGroupData(data);
      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données de l'API", error);
      setError('Impossible de récupérer les données.');
      setGroupData(groupTableData);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    groupData,
    setGroupData,
    loading,
    error
  };
};

export default useGroup;
