import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import RevealDropdown, {
  RevealDropdownTrigger
} from '../../base/RevealDropdown';
import ActionDropdownItems from '../../common/ActionDropdownItems';
import { Microsoft365Integration } from '../../../data/iper-x/ipbx/microsoft365Integrations';

export const microsoft365IntegrationsInstalledTableColumns: ColumnDef<Microsoft365Integration>[] =
  [
    {
      accessorKey: 'name',
      header: 'Nom',
      meta: {
        headerProps: { style: { width: '30%' } },
        cellProps: { className: 'text-900 fs-9' }
      }
    },
    {
      accessorKey: 'mainName',
      header: 'Nom Principal',
      meta: {
        headerProps: { style: { width: '30%' } },
        cellProps: { className: 'text-900 fs-9' }
      }
    },
    {
      accessorKey: 'email',
      header: 'Email',
      meta: {
        headerProps: { style: { width: '40%' } },
        cellProps: { className: 'text-900 fs-9' }
      }
    },
    {
      id: 'leadDropdown',
      header: 'Gérer',
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-900 fs-9' }
      },
      cell: () => {
        return (
          <RevealDropdownTrigger>
            <RevealDropdown>
              <ActionDropdownItems />
            </RevealDropdown>
          </RevealDropdownTrigger>
        );
      }
    }
  ];

const Microsoft365IntegrationsTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default Microsoft365IntegrationsTable;
