import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Badge from "components/base/Badge";
import { VoicemailData } from "hooks/api/useVoicemail";
import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Modal, Form } from "react-bootstrap";

export const voicemailAPITableColumns: ColumnDef<VoicemailData>[] = [
  {
    accessorKey: "nameUser",
    header: "Nom d'utilisateur",
    meta: {
      headerProps: { style: { width: "20%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "emailUser",
    header: "Email",
    meta: {
      headerProps: { style: { width: "30%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "password",
    header: "Mot de passe",
    cell: ({ row: { original } }) => {
      const password = original.password || "";
      return (
        <span>
          {"•".repeat(password.length)} 
        </span>
      );
    },
    meta: {
      headerProps: { style: { width: "20%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" },
    }
  },
  {
    accessorKey: "voicemailNumber",
    header: "Numéro de répondeur",
    meta: {
      headerProps: { style: { width: "20%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "state",
    header: "État",
    cell: ({ row: { original } }) => {
      const { state } = original;
      return (
        <Badge
          bg={state ? "success" : "secondary"}
          variant="phoenix"
          iconPosition="end"
          className="fs-10"
        >
          {state ? "Actif" : "Inactif"}
        </Badge>
      );
    },
    meta: {
      headerProps: { style: { width: "10%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    id: 'leadDropdown',
    header: "Gérer",
    meta: {
      headerProps: { style: { fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    },
    cell: ({ row: { original } }) => {
      const [dropdownOpen, setDropdownOpen] = useState(false);
      const [showDeleteModal, setShowDeleteModal] = useState(false);
      const [showEditModal, setShowEditModal] = useState(false);
      const [selectedVoicemail, setSelectedVoicemail] = useState<VoicemailData | null>(null);
      const [formData, setFormData] = useState<VoicemailData | null>(null);

      const toggle = () => setDropdownOpen(!dropdownOpen);

      const handleDeleteClick = (voicemail: VoicemailData) => {
        setSelectedVoicemail(voicemail);
        setShowDeleteModal(true);
      };

      const handleEditClick = (voicemail: VoicemailData) => {
        setSelectedVoicemail(voicemail);
        setFormData(voicemail);
        setShowEditModal(true);
      };

      const handleDeleteConfirm = async () => {
        if (selectedVoicemail) {
          await handleDelete(selectedVoicemail.id);
          setShowDeleteModal(false);
        }
      };

      const handleEditConfirm = async () => {
        if (formData) {
          await handleEdit(formData);
          setShowEditModal(false);
        }
      };

      const handleDelete = async (id: number) => {
        try {
          const response = await fetch(`https://rct-backend.ipercom.io/api/Ipbx/voicemail/${id}`, {
            method: 'DELETE',
            headers: {
              'accept': 'text/plain',
            },
          });

          if (response.ok) {
            window.location.reload();
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        } catch (error) {
          console.error("Erreur lors de la suppression du répondeur numérique", error);
        }
      };

      const handleEdit = async (data: VoicemailData) => {
        try {
          const { id, ...dataWithoutId } = data;
          const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/voicemail', {
            method: 'PUT',
            headers: {
              'accept': 'text/plain',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          });

          if (response.ok) {
            window.location.reload();
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        } catch (error) {
          console.error("Erreur lors de la mise à jour du répondeur numérique", error);
        }
      };

      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => prevData ? {
          ...prevData,
          [name]: type === 'checkbox' ? checked : value
        } : null);
      };

      return (
        <>
          <Dropdown show={dropdownOpen} onToggle={toggle} style={{ display: 'flex', justifyContent: 'center' }}>
            <DropdownToggle as="div" onClick={toggle} style={{ cursor: 'pointer' }}>
              Actions
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => handleEditClick(original)}>Modifier</DropdownItem>
              <DropdownItem onClick={() => handleDeleteClick(original)}>Supprimer</DropdownItem>
            </DropdownMenu>
          </Dropdown>

          <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Confirmation de suppression</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Êtes-vous sûr de vouloir supprimer ce répondeur numérique ?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                Annuler
              </Button>
              <Button variant="danger" onClick={handleDeleteConfirm}>
                Supprimer
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Modifier le répondeur numérique</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="nameUser">
                  <Form.Label>Nom d'utilisateur</Form.Label>
                  <Form.Control
                    type="text"
                    name="nameUser"
                    value={formData?.nameUser || ""}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="emailUser">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="emailUser"
                    value={formData?.emailUser || ""}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="password">
                  <Form.Label>Mot de passe</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={formData?.password || ""}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="voicemailNumber">
                  <Form.Label>Numéro de répondeur</Form.Label>
                  <Form.Control
                    type="text"
                    name="voicemailNumber"
                    value={formData?.voicemailNumber || ""}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="state">
                  <Form.Label>État</Form.Label>
                  <Form.Check
                    type="checkbox"
                    name="state"
                    checked={formData?.state}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Button variant="primary" type="submit" className="mt-2">
                  Ajouter
                </Button>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                Annuler
              </Button>
              <Button variant="primary" onClick={handleEditConfirm}>
                Enregistrer
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    }
  }
];

const VoicemailTableAPI = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9 text-center" }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default VoicemailTableAPI;
