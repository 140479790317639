import { faCircle, faClock, faPlusCircle, faTimes, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Row, Col, Form, Button } from "react-bootstrap";

const IperxParamIntegration = () => {

  return (
    <>
     <h2 className="fs-6 mb-3">Intégration</h2>
      <Card className="mb-4">
      <Card.Body>
          <h2 className="fs-6 mb-3">Intégration CRM</h2>
        <Row>
        <Col className="ms-4 mt-4">
          <Form.Check
            type='radio'
            id='item1Radio'
            label='Éteint'
          />
          <Form.Check
            type='radio'
            id='item2Radio'
            label='Ouvrir l’URL de contact en utilisant l’intégration CRM côté serveur'
          />
          <Form.Check
            type='radio'
            id='item2Radio'
            label='Ouvrir le contact dans un CRM supporté'
          />
          <Form.Check
            type='radio'
            id='item2Radio'
            label='Ourvrir le contact dans un CRM personnalisé'
          />
        </Col>
        </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default IperxParamIntegration;
