import { Card, Col, FloatingLabel, Form, Nav, Row } from 'react-bootstrap';
import PageBreadcrumb, {
  PageBreadcrumbItem
} from '../../../../../components/common/PageBreadcrumb';
import React, { useEffect, useState } from 'react';
import ParamApps from '../apps/ParamApps';
import ParamSystem from './ParamSystem';
import ParamIntegration from '../integration/ParamIntegration';
import ParamAdvance from '../advance/ParamAdvance';
import ParamVideo from './video/ParamVideo';
import ParamAudio from './audio/ParamAudio';
import { useLocation } from 'react-router-dom';
import ParamMailServerConfig from './email/ParamMailServerConfig';
import ParamNotificationsConfig from "./email/ParamNotificationsConfig";
import ParamEmailTemplatesConfig from "./email/ParamEmailTemplatesConfig";
// import { useLocation } from "react-router-dom";

export const emailBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Paramètre',
    url: '/apps/ipbx/parametres'
  },
  {
    label: 'Systéme',
    url: '/apps/ipbx/parametres?selected=system'
  },
  {
    label: 'Email',
    url: '/apps/ipbx/parametres/email'
  }
];

const EmailConfig = () => {
  const [selectedComponent, setSelectedComponent] = useState<string>('mails-server');
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const selectedComponentParam = searchParams.get('selected');
    if (selectedComponentParam) {
      setSelectedComponent(selectedComponentParam);
    }
  }, [location]);

  const handleItemClick = (component: string) => {
    setSelectedComponent(component);
    // Update the URL with the selected component
    const searchParams = new URLSearchParams({ selected: component });
    window.history.pushState(
      {},
      '',
      `${location.pathname}?${searchParams.toString()}`
    );
  };

  return (
    <>
      <PageBreadcrumb items={emailBreadcrumbItems} />
      <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
        <h2 className="mb-0">
          <span className="me-3">Paramètres email</span>
        </h2>
      </div>

      <div>
        <Nav
          variant="underline"
          className="mb-2 mb-3 md-5 pb-3 pt-2 w-100 w-sm-75 w-md-100 mx-auto bg-soft"
        >
          <Nav.Item>
            <Nav.Link
              className={`fw-semi-bold me-3 fs-8 pe-2 text-center ${selectedComponent === 'mails-server' ? 'active' : ''}`}
              onClick={() => handleItemClick('mails-server')}
            >
              Serveur mail
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={`fw-semi-bold me-3 fs-8 pe-2 text-center ${selectedComponent === 'notifications' ? 'active' : ''}`}
              onClick={() => handleItemClick('notifications')}
            >
              Notifications
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={`fw-semi-bold me-3 fs-8 pe-2 text-center ${selectedComponent === 'email-templates' ? 'active' : ''}`}
              onClick={() => handleItemClick('email-templates')}
            >
              Modèles d’email
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <hr />

        {selectedComponent === 'mails-server' && <ParamMailServerConfig />}
        {selectedComponent === 'notifications' && <ParamNotificationsConfig />}
        {selectedComponent === 'email-templates' && <ParamEmailTemplatesConfig />}
      </div>
    </>
  );
};

export default EmailConfig;
