import React from 'react';
import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  InputGroup,
  Row
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faCog, faSave } from '@fortawesome/free-solid-svg-icons';

const GeneralHoldMusicConfig = () => {
  return (
    <>
      <Card className="">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">
                Fichiers de musiques d'attentes : Format WAV (PCM, 8 kHz, 16
                bit, Mono, taille max : 100MB)
              </h3>

              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Fichier musical 0</Form.Label>
                <InputGroup>
                  <FloatingLabel controlId="floatingSelect" label="Region">
                    <Form.Select aria-label="Floating label select example">
                      <option>onhold.wav</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </FloatingLabel>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faCirclePlus} className="me-2" />
                    Ajouter
                  </Button>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faSave} className="me-2" />
                    Enregistrer
                  </Button>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faCog} className="me-2" />
                    Administrer
                  </Button>
                </InputGroup>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Fichier musical 1</Form.Label>
                <InputGroup>
                  <FloatingLabel controlId="floatingSelect" label="Region">
                    <Form.Select aria-label="Floating label select example">
                      <option>onhold.wav</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </FloatingLabel>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faCirclePlus} className="me-2" />
                    Ajouter
                  </Button>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faSave} className="me-2" />
                    Enregistrer
                  </Button>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faCog} className="me-2" />
                    Administrer
                  </Button>
                </InputGroup>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Fichier musical 2</Form.Label>
                <InputGroup>
                  <FloatingLabel controlId="floatingSelect" label="Region">
                    <Form.Select aria-label="Floating label select example">
                      <option>onhold.wav</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </FloatingLabel>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faCirclePlus} className="me-2" />
                    Ajouter
                  </Button>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faSave} className="me-2" />
                    Enregistrer
                  </Button>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faCog} className="me-2" />
                    Administrer
                  </Button>
                </InputGroup>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Fichier musical 3</Form.Label>
                <InputGroup>
                  <FloatingLabel controlId="floatingSelect" label="Region">
                    <Form.Select aria-label="Floating label select example">
                      <option>onhold.wav</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </FloatingLabel>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faCirclePlus} className="me-2" />
                    Ajouter
                  </Button>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faSave} className="me-2" />
                    Enregistrer
                  </Button>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faCog} className="me-2" />
                    Administrer
                  </Button>
                </InputGroup>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Fichier musical 4</Form.Label>
                <InputGroup>
                  <FloatingLabel controlId="floatingSelect" label="Region">
                    <Form.Select aria-label="Floating label select example">
                      <option>onhold.wav</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </FloatingLabel>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faCirclePlus} className="me-2" />
                    Ajouter
                  </Button>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faSave} className="me-2" />
                    Enregistrer
                  </Button>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faCog} className="me-2" />
                    Administrer
                  </Button>
                </InputGroup>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Fichier musical 5</Form.Label>
                <InputGroup>
                  <FloatingLabel controlId="floatingSelect" label="Region">
                    <Form.Select aria-label="Floating label select example">
                      <option>onhold.wav</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </FloatingLabel>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faCirclePlus} className="me-2" />
                    Ajouter
                  </Button>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faSave} className="me-2" />
                    Enregistrer
                  </Button>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faCog} className="me-2" />
                    Administrer
                  </Button>
                </InputGroup>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Fichier musical 6</Form.Label>
                <InputGroup>
                  <FloatingLabel controlId="floatingSelect" label="Region">
                    <Form.Select aria-label="Floating label select example">
                      <option>onhold.wav</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </FloatingLabel>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faCirclePlus} className="me-2" />
                    Ajouter
                  </Button>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faSave} className="me-2" />
                    Enregistrer
                  </Button>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faCog} className="me-2" />
                    Administrer
                  </Button>
                </InputGroup>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Fichier musical 7</Form.Label>
                <InputGroup>
                  <FloatingLabel controlId="floatingSelect" label="Region">
                    <Form.Select aria-label="Floating label select example">
                      <option>onhold.wav</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </FloatingLabel>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faCirclePlus} className="me-2" />
                    Ajouter
                  </Button>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faSave} className="me-2" />
                    Enregistrer
                  </Button>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faCog} className="me-2" />
                    Administrer
                  </Button>
                </InputGroup>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Fichier musical 8</Form.Label>
                <InputGroup>
                  <FloatingLabel controlId="floatingSelect" label="Region">
                    <Form.Select aria-label="Floating label select example">
                      <option>onhold.wav</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </FloatingLabel>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faCirclePlus} className="me-2" />
                    Ajouter
                  </Button>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faSave} className="me-2" />
                    Enregistrer
                  </Button>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faCog} className="me-2" />
                    Administrer
                  </Button>
                </InputGroup>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Fichier musical 9</Form.Label>
                <InputGroup>
                  <FloatingLabel controlId="floatingSelect" label="Region">
                    <Form.Select aria-label="Floating label select example">
                      <option>onhold.wav</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </FloatingLabel>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faCirclePlus} className="me-2" />
                    Ajouter
                  </Button>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faSave} className="me-2" />
                    Enregistrer
                  </Button>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faCog} className="me-2" />
                    Administrer
                  </Button>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mt-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <Form.Check type="checkbox" id="sameAsShipping" className="4">
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  <h3 className="fw-bolder mb-2 line-clamp-1">
                    Sélection aléatoire
                  </h3>
                </Form.Check.Label>
              </Form.Check>

              <Form.Group className="mb-1" controlId="personalAccount">
                <Form.Check
                  inline
                  type="radio"
                  name="accountType"
                  id="personalAccount"
                  className="me-2"
                  defaultChecked
                />
                <Form.Label className="mb-0">
                  Une musique différente par appel
                </Form.Label>
              </Form.Group>

              <Form.Group className="mb-1" controlId="personalAccount">
                <Form.Check
                  inline
                  type="radio"
                  name="accountType"
                  id="personalAccount"
                  className="me-2"
                  defaultChecked
                />
                <Form.Label className="mb-0">
                  Une musique différente par jour
                </Form.Label>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default GeneralHoldMusicConfig;
