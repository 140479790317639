import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import Button from 'components/base/Button';

interface ExportModalProps {
  show: boolean;
  handleClose: () => void;
  handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleExport: () => void;
  exportOptions: {
    includeInvoice: boolean;
    includeOrder: boolean;
  };
}

const ExportModal: React.FC<ExportModalProps> = ({ show, handleClose, handleCheckboxChange, handleExport, exportOptions }) => (
  <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Exporter commande et facture</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form>
        <Form.Check
          type="checkbox"
          id="includeInvoiceOrder"
          name="includeInvoiceOrder"
          label="Facture et commande"
          checked={exportOptions.includeInvoice && exportOptions.includeOrder}
          onChange={handleCheckboxChange}
        />
        <Form.Check
          type="checkbox"
          id="includeInvoice"
          name="includeInvoice"
          label="Facture"
          checked={exportOptions.includeInvoice}
          onChange={handleCheckboxChange}
        />
        <Form.Check
          type="checkbox"
          id="includeOrder"
          name="includeOrder"
          label="Commande"
          checked={exportOptions.includeOrder}
          onChange={handleCheckboxChange}
        />
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Annuler
      </Button>
      <Button variant="primary" onClick={handleExport}>
        Exporter
      </Button>
    </Modal.Footer>
  </Modal>
);

export default ExportModal;
