import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Table, Container, Alert, Row, Col, Button } from 'react-bootstrap';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css'; // Import PDF viewer styles
import { Document, Page, pdfjs } from 'react-pdf';
import '@react-pdf-viewer/core/lib/styles/index.css';

// Set the workerSrc to use the correct path to the worker
pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.js`;

interface Order {
  id: string;
  ref: string;
  date: string;
  total_ht: string;
  total_tva: string;
  total_ttc: string;
  socid: string;
  lines: OrderLine[];
}

interface OrderLine {
  id: string;
  product_ref: string;
  product_label: string;
  qty: string;
  subprice: string;
  tva_tx: string;
  total_ht: string;
  total_ttc: string;
  array_options: {
    options_licences?: string[];
  };
}

const OrderDetails: React.FC = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const [order, setOrder] = useState<Order | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [pdfData, setPdfData] = useState<string | null>(null); // PDF data as base64
  const DOLAPIKEY = localStorage.getItem('DOLAPIKEY');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const isNumeric = /^\d+$/.test(orderId);
        const endpoint = isNumeric
          ? `https://erp.ipercom.io/api/index.php/orders/${orderId}`
          : `https://erp.ipercom.io/api/index.php/orders/ref/${orderId}?contact_list=1`;

        const response = await fetch(endpoint, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'DOLAPIKEY': DOLAPIKEY || '',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch order details.');
        }

        const data = await response.json();
        setOrder(data);
      } catch (error) {
        setError('Failed to load order details.');
      }
    };

    fetchOrderDetails();
  }, [orderId]);

  const handleOrderAction = async (action: string, successMessage: string) => {
    try {
      const response = await fetch(`https://erp.ipercom.io/api/index.php/orders/${orderId}/${action}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'DOLAPIKEY': DOLAPIKEY || '',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      });

      if (!response.ok) {
        throw new Error('Failed to perform order action.');
      }

      setSuccess(successMessage);
      setError(null);
    } catch (error) {
      setError(`Error: ${error.message}`);
      setSuccess(null);
    }
  };

  const generateDocument = async () => {
    try {
      const documentEndpoint = 'https://erp.ipercom.io/api/index.php/documents/builddoc';
      const body = {
        modulepart: 'order',
        original_file: `${order?.ref}/${order?.ref}.pdf`,
        doctemplate: 'eratosthene',
        langcode: 'fr_FR',
      };

      const response = await fetch(documentEndpoint, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          DOLAPIKEY: DOLAPIKEY || '',
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error('Failed to generate document.');
      }

      const fileResponse = await fetch(
        `https://erp.ipercom.io/api/index.php/documents/download?modulepart=order&original_file=${order?.ref}%2F${order?.ref}.pdf`,
        {
          headers: {
            'DOLAPIKEY': DOLAPIKEY || '',
            Accept: 'application/json',
          },
        }
      );

      if (!fileResponse.ok) {
        throw new Error('Failed to download the document.');
      }

      const fileData = await fileResponse.json();
      setPdfData(fileData.content); // Set base64 PDF data for rendering

      setSuccess('Document generated and loaded successfully.');
      setError(null);
    } catch (error) {
      setError(`Error: ${error.message}`);
      setSuccess(null);
    }
  };

  const checkAndDisplayPDF = async () => {
    try {
      const fileResponse = await fetch(
        `https://erp.ipercom.io/api/index.php/documents/download?modulepart=order&original_file=${order?.ref}%2F${order?.ref}.pdf`,
        {
          headers: {
            'DOLAPIKEY': DOLAPIKEY || '',
            Accept: 'application/json',
          },
        }
      );

      if (!fileResponse.ok) {
        throw new Error('PDF file does not exist.');
      }

      const fileData = await fileResponse.json();
      setPdfData(fileData.content);
      setError(null);
    } catch (error) {
      // setError('Error: Could not find the PDF file.');
      setPdfData(null);
    }
  };

  useEffect(() => {
    if (order) {
      checkAndDisplayPDF();
    }
  }, [order]);

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  if (!order) {
    return <div>Loading order details...</div>;
  }

  return (
    <Container className="mt-5">
      <Button variant="primary" onClick={() => navigate(-1)} className="mb-3">
        ← Retour
      </Button>
      <Card>
        <Card.Header>
          <h4>Order Details - {order.ref}</h4>
          <p>Date: {new Date(order.date).toLocaleDateString()}</p>
          <a
            href={`https://erp.ipercom.io/document.php?modulepart=commande&attachment=0&file=${order.ref}/${order.ref}.pdf&&entity=1`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {order.ref}
          </a>
          <Button variant="primary" onClick={generateDocument}>
            Générer le récapitulatif de la commande
          </Button>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <h5>Order Information</h5>
              <p><strong>Total HT:</strong> {order.total_ht} EUR</p>
              <p><strong>Total TVA:</strong> {order.total_tva} EUR</p>
              <p><strong>Total TTC:</strong> {order.total_ttc} EUR</p>
            </Col>
            <Col>
              <h5>Customer Information</h5>
              <p><strong>Customer ID:</strong> {order.socid}</p>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {pdfData ? (
        <Card className="mt-3">
          <Card.Header>
            <h5>Generated Document</h5>
          </Card.Header>
          <Card.Body>
            <div style={{ height: '500px' }}>
              {/*<Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js`}>*/}
              <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js`}>

              <Viewer fileUrl={`data:application/pdf;base64,${pdfData}`} />
              </Worker>
            </div>
          </Card.Body>
        </Card>
      ) : (
        <Alert variant="info">No PDF document found for this order.</Alert>
      )}

      <Card className="mt-3">
        <Card.Header>
          <h5>Order Lines</h5>
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
            <tr>
              <th>Product Reference</th>
              <th>Product Name</th>
              <th>Quantity</th>
              <th>Price HT</th>
              <th>TVA (%)</th>
              <th>Total HT</th>
              <th>Total TTC</th>
              <th>Licenses</th>
            </tr>
            </thead>
            <tbody>
            {order.lines.map((line) => (
              <tr key={line.id}>
                <td>{line.product_ref}</td>
                <td>{line.product_label}</td>
                <td>{line.qty}</td>
                <td>{line.subprice} EUR</td>
                <td>{line.tva_tx}</td>
                <td>{line.total_ht} EUR</td>
                <td>{line.total_ttc} EUR</td>
                <td>
                  {line.array_options?.options_licences
                    ? String(line.array_options.options_licences)
                    : 'No additional options available'}
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Card className="mt-3">
        <Card.Header>
          <h5>Order Actions</h5>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <Button
                variant="primary"
                onClick={() => handleOrderAction('validate', 'Order validated successfully')}
              >
                Validate Order
              </Button>
            </Col>
            <Col>
              <Button
                variant="secondary"
                onClick={() => handleOrderAction('setinvoiced', 'Order set as invoiced successfully')}
              >
                Set as Invoiced
              </Button>
            </Col>
            <Col>
              <Button
                variant="warning"
                onClick={() => handleOrderAction('reopen', 'Order reopened successfully')}
              >
                Reopen Order
              </Button>
            </Col>
            <Col>
              <Button
                variant="danger"
                onClick={() => handleOrderAction('close', 'Order closed successfully')}
              >
                Close Order
              </Button>
            </Col>
            <Col>
              <Button
                variant="info"
                onClick={() => handleOrderAction('settodraft', 'Order set to draft successfully')}
              >
                Set to Draft
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default OrderDetails;
