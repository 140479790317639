import React, { useState} from "react";
import {
  faCircle,
  faClose,
  faFileExport,
  faFileImport,
  faPencil,
  faPeopleGroup,
  faPlus,
  faRefresh
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchBox from "components/common/SearchBox";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { ChangeEvent } from "react";
import { Button, Col, Row, Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import ContactsTable, { contactsTableColumns } from "../../../components/tables/ipbx/ContactsTable";
import { contactsTableData } from "../../../data/iper-x/ipbx/contacts";

const ContactsList = () => {
  const table = useAdvanceTable({
    data: contactsTableData,
    columns: contactsTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true
  });

  const [openAddModal, setOpenAddModal] = useState(false);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const handleAddRuleClick = () => {
    setOpenAddModal(true);
  };

  const handleCloseModal = () => {
    setOpenAddModal(false);
  };

  return (
    <div>
      {/*<PageBreadcrumb items={editorUserBreadcrumbItems} />*/}
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">Contacts</span>
          </h2>
        </div>
        <div className="mb-4">
          <Row className="g-3">
            <Col xs="auto">
              <SearchBox className="w-300"
                         placeholder="Rechercher"
                         onChange={handleSearchInputChange}
              />
            </Col>
            <Col
              xs="auto"
              className="scrollbar overflow-hidden-y flex-grow-1"
            ></Col>
            <Col xs="auto">
              <Button variant="link" className="text-900 me-4 px-0">
                <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                Export
              </Button>
              <Button variant="primary" onClick={handleAddRuleClick}>
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Ajouter un contact
              </Button>
            </Col>
          </Row>
        </div>
        <div
          className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <ContactsTable />
        </div>
      </AdvanceTableProvider>

      <Modal show={openAddModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
        <Modal.Title>Ajouter un contact</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group controlId="username">
                    <Form.Label>NOM D'UTILISATEUR</Form.Label>
                    <Form.Control type="text" name="username" required />
                  </Form.Group>

                  <Form.Group controlId="email">
                    <Form.Label>EMAIL</Form.Label>
                    <Form.Control type="text" name="email" required />
                  </Form.Group>

                  <Form.Group controlId="mobile">
                    <Form.Label>MOBILE</Form.Label>
                    <Form.Control type="text" name="mobile" required />
                  </Form.Group>

                  <Form.Group controlId="fixed">
                    <Form.Label>FIX</Form.Label>
                    <Form.Control type="text" name="fixed" required />
                  </Form.Group>

                  <Button variant="primary" type="submit" className='mt-4'>
                    Ajouter
                  </Button>
                </Form>
              </Modal.Body>
      </Modal>
    </div>
  );
};

export default ContactsList;

