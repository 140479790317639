export interface Voicemail {
  extension: string;  // Ext.
  name: string;      // Nom
  type: string;      // Type
  directive: string; // Directive
  noInputAction: string; // Pas de saisie
  // You can remove or keep other fields based on your requirement
}

export const voicemailTableData: Voicemail[] = [
  {
    extension: "HOL",             // Example value for Ext.
    name: "Out of office IVR",    // Example value for Nom
    type: "Standard",             // Example value for Type
    directive: "OfficeClosed.wav",// Example value for Directive
    noInputAction: "End Call"     // Example value for Pas de saisie
  },
  {
    extension: "HOL",             // Example value for Ext.
    name: "Out of office IVR",    // Example value for Nom
    type: "Standard",             // Example value for Type
    directive: "OfficeClosed.wav",// Example value for Directive
    noInputAction: "End Call"     // Example value for Pas de saisie
  },
  {
    extension: "HOL",             // Example value for Ext.
    name: "Out of office IVR",    // Example value for Nom
    type: "Standard",             // Example value for Type
    directive: "OfficeClosed.wav",// Example value for Directive
    noInputAction: "End Call"     // Example value for Pas de saisie
  },
  {
    extension: "HOL",             // Example value for Ext.
    name: "Out of office IVR",    // Example value for Nom
    type: "Standard",             // Example value for Type
    directive: "OfficeClosed.wav",// Example value for Directive
    noInputAction: "End Call"     // Example value for Pas de saisie
  },
  {
    extension: "HOL",             // Example value for Ext.
    name: "Out of office IVR",    // Example value for Nom
    type: "Standard",             // Example value for Type
    directive: "OfficeClosed.wav",// Example value for Directive
    noInputAction: "End Call"     // Example value for Pas de saisie
  },
  {
    extension: "HOL",             // Example value for Ext.
    name: "Out of office IVR",    // Example value for Nom
    type: "Standard",             // Example value for Type
    directive: "OfficeClosed.wav",// Example value for Directive
    noInputAction: "End Call"     // Example value for Pas de saisie
  },
  {
    extension: "HOL",             // Example value for Ext.
    name: "Out of office IVR",    // Example value for Nom
    type: "Standard",             // Example value for Type
    directive: "OfficeClosed.wav",// Example value for Directive
    noInputAction: "End Call"     // Example value for Pas de saisie
  },
  {
    extension: "HOL",             // Example value for Ext.
    name: "Out of office IVR",    // Example value for Nom
    type: "Standard",             // Example value for Type
    directive: "OfficeClosed.wav",// Example value for Directive
    noInputAction: "End Call"     // Example value for Pas de saisie
  },
  {
    extension: "HOL",             // Example value for Ext.
    name: "Out of office IVR",    // Example value for Nom
    type: "Standard",             // Example value for Type
    directive: "OfficeClosed.wav",// Example value for Directive
    noInputAction: "End Call"     // Example value for Pas de saisie
  },
  {
    extension: "HOL",             // Example value for Ext.
    name: "Out of office IVR",    // Example value for Nom
    type: "Standard",             // Example value for Type
    directive: "OfficeClosed.wav",// Example value for Directive
    noInputAction: "End Call"     // Example value for Pas de saisie
  },
  {
    extension: "HOL",             // Example value for Ext.
    name: "Out of office IVR",    // Example value for Nom
    type: "Standard",             // Example value for Type
    directive: "OfficeClosed.wav",// Example value for Directive
    noInputAction: "End Call"     // Example value for Pas de saisie
  },
  {
    extension: "HOL",             // Example value for Ext.
    name: "Out of office IVR",    // Example value for Nom
    type: "Standard",             // Example value for Type
    directive: "OfficeClosed.wav",// Example value for Directive
    noInputAction: "End Call"     // Example value for Pas de saisie
  },
  {
    extension: "HOL",             // Example value for Ext.
    name: "Out of office IVR",    // Example value for Nom
    type: "Standard",             // Example value for Type
    directive: "OfficeClosed.wav",// Example value for Directive
    noInputAction: "End Call"     // Example value for Pas de saisie
  },



];
