import { LeadData } from 'hooks/api/useLead';
import React, { useState, ChangeEvent } from 'react';
import { Form, Button } from 'react-bootstrap';

type LeadFormProps = {
  tableData: LeadData[];
  setTableData: React.Dispatch<React.SetStateAction<LeadData[]>>;
  onClose: () => void;
};

const AddLeadForm: React.FC<LeadFormProps> = ({ tableData, setTableData, onClose }) => {
  const [newLead, setNewLead] = useState<LeadData>({
    idLeads: 0,
    name: "",
    ceoName: "",
    siret: "",
    address: "",
    mail: "",
    website: "",
    phone: "",
    numbersEmployees: 0,
    annualIncome: 0,
    nafCode: "",
    sourceLead: { id: 0, name: "", slug: "" },
    companyClientType: { id: 0, name: "", slug: "" },
    commonCity: { id: 0, name: "", slug: "", zipCode: "" },
    devicesNumber: 0,
    accessNumber: 0,
    progressMin: 0,
    progressMax: 0,
    statusLabel: "",
    statusType: "",
    userAssignedRetailer: { id: 0, firstname: "", lastname: "", avatar: "" }
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewLead(prevLead => ({
      ...prevLead,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await fetch('https://rct-backend.ipercom.io/api/Lead', {
        method: 'POST',
        headers: {
          'accept': 'text/plain',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newLead)
      });

      if (response.ok) {
        window.location.reload();
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: LeadData = await response.json();
      setTableData([...tableData, data]);
      onClose();
    } catch (error) {
      console.error("Erreur lors de l'ajout d'un lead", error);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="name">
        <Form.Label>Nom</Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={newLead.name}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="ceoName">
        <Form.Label>Nom du PDG</Form.Label>
        <Form.Control
          type="text"
          name="ceoName"
          value={newLead.ceoName}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="siret">
        <Form.Label>SIRET</Form.Label>
        <Form.Control
          type="text"
          name="siret"
          value={newLead.siret}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="address">
        <Form.Label>Adresse</Form.Label>
        <Form.Control
          type="text"
          name="address"
          value={newLead.address}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="mail">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          name="mail"
          value={newLead.mail}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="website">
        <Form.Label>Site Web</Form.Label>
        <Form.Control
          type="text"
          name="website"
          value={newLead.website}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="phone">
        <Form.Label>Téléphone</Form.Label>
        <Form.Control
          type="text"
          name="phone"
          value={newLead.phone}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="numbersEmployees">
        <Form.Label>Nombre d'employés</Form.Label>
        <Form.Control
          type="number"
          name="numbersEmployees"
          value={newLead.numbersEmployees}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="annualIncome">
        <Form.Label>Revenu annuel</Form.Label>
        <Form.Control
          type="number"
          name="annualIncome"
          value={newLead.annualIncome}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="nafCode">
        <Form.Label>Code NAF</Form.Label>
        <Form.Control
          type="text"
          name="nafCode"
          value={newLead.nafCode}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="sourceLead">
        <Form.Label>Source Lead</Form.Label>
        <Form.Control
          type="text"
          name="sourceLead"
          value={newLead.sourceLead.name}
          onChange={(e) => setNewLead({
            ...newLead,
            sourceLead: {
              ...newLead.sourceLead,
              name: e.target.value
            }
          } as LeadData)}
          required
        />
      </Form.Group>

      <Form.Group controlId="companyClientType">
        <Form.Label>Type de Client</Form.Label>
        <Form.Control
          type="text"
          name="companyClientType"
          value={newLead.companyClientType.name}
          onChange={(e) => setNewLead({
            ...newLead,
            companyClientType: {
              ...newLead.companyClientType,
              name: e.target.value
            }
          } as LeadData)}
          required
        />
      </Form.Group>

      <Form.Group controlId="commonCity">
        <Form.Label>Ville</Form.Label>
        <Form.Control
          type="text"
          name="commonCity"
          value={newLead.commonCity.name}
          onChange={(e) => setNewLead({
            ...newLead,
            commonCity: {
              ...newLead.commonCity,
              name: e.target.value
            }
          } as LeadData)}
          required
        />
      </Form.Group>

      <Form.Group controlId="devicesNumber">
        <Form.Label>Nombre de dispositifs</Form.Label>
        <Form.Control
          type="number"
          name="devicesNumber"
          value={newLead.devicesNumber}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="accessNumber">
        <Form.Label>Nombre d'accès</Form.Label>
        <Form.Control
          type="number"
          name="accessNumber"
          value={newLead.accessNumber}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="progressMin">
        <Form.Label>Progression Min</Form.Label>
        <Form.Control
          type="number"
          name="progressMin"
          value={newLead.progressMin}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="progressMax">
        <Form.Label>Progression Max</Form.Label>
        <Form.Control
          type="number"
          name="progressMax"
          value={newLead.progressMax}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="statusLabel">
        <Form.Label>Statut</Form.Label>
        <Form.Control
          type="text"
          name="statusLabel"
          value={newLead.statusLabel}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="statusType">
        <Form.Label>Type de Statut</Form.Label>
        <Form.Control
          type="text"
          name="statusType"
          value={newLead.statusType}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="userAssignedRetailer">
        <Form.Label>Assigné à</Form.Label>
        <Form.Control
          type="text"
          name="userAssignedRetailer"
          value={newLead.userAssignedRetailer.firstname + " " + newLead.userAssignedRetailer.lastname}
          onChange={(e) => setNewLead({
            ...newLead,
            userAssignedRetailer: {
              ...newLead.userAssignedRetailer,
              firstname: e.target.value.split(" ")[0],
              lastname: e.target.value.split(" ")[1]
            }
          } as LeadData)}
          required
        />
      </Form.Group>
      <Button variant="primary" type="submit" className="mt-2">
        Ajouter
      </Button>
    </Form>
  );
};

export default AddLeadForm;
