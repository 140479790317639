import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";

import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import { ReportsDownload } from "../../../data/iper-x/ipbx/reportsDownload";

export const reprtsDownloadTableColumns: ColumnDef<ReportsDownload>[] = [

  {
    id: "name",
    header: "Nom",
    accessorFn: ({ name }) => name,
    meta: {
      headerProps: { style: { width: "40%" }, className: "pe-3" }
    }
  },
  {
    id: "date",
    header: "Date",
    accessorFn: ({ date }) => date,
    meta: {
      headerProps: { style: { width: "40%" }, className: "pe-3" }
    }
  },
  {
    id: "url_report",
    header: "Url rapport",
    accessorFn: ({ url_report }) => url_report,
    meta: {
      headerProps: { style: { width: "40%" }, className: "pe-3" }
    }
  }

];

const ReportsDownloadTable = () => {
  return (
    <div>
      <AdvanceTable
        tableProps={{ className: "phoenix-table fs-9" }}
      />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default ReportsDownloadTable;
