import Button from 'components/base/Button';
import accessDeniedIllustration from 'assets/img/spot-illustrations/dark_chat.webp';
import darkAccessDeniedIllustrations from 'assets/img/spot-illustrations/dark_500-illustration.png';
import accessDenied from 'assets/img/spot-illustrations/dark_17.png';
import darkAccessDenied from 'assets/img/spot-illustrations/dark_13.png';
import { Col, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import useSettingsMountEffect from 'hooks/useSettingsMountEffect';

const AccessDenied = () => {
  useSettingsMountEffect({
    disableNavigationType: true,
    disableHorizontalNavbarAppearance: true,
    disableVerticalNavbarAppearance: true,
    disableHorizontalNavbarShape: true
  });

  const navigate = useNavigate();

  return (
    <div>
      <div className="px-3">
        <Row className="min-vh-100 flex-center p-5">
          <Col xs={12} xl={10} xxl={8}>
            <Row className="justify-content-center align-items-center g-5">
              <Col xs={12} lg={6} className="text-center order-lg-1">
                <img
                  src={accessDeniedIllustration}
                  alt=""
                  width={400}
                  className="img-fluid w-lg-100 d-dark-none"
                />
                <img
                  src={darkAccessDeniedIllustrations}
                  alt=""
                  width={540}
                  className="img-fluid w-md-50 w-lg-100 d-light-none"
                />
              </Col>
              <Col xs={12} lg={6} className="text-center text-lg-start">
                <h2 className="text-800 fw-bolder mb-3">Accès Refusé !</h2>
                <p className="text-900 mb-5">
                  Vous n'avez pas les permissions nécessaires pour accéder à cette page.
                  <br className="d-none d-sm-block" />
                  Veuillez contacter votre administrateur si vous pensez que c'est une erreur.
                </p>
                <Button variant="primary" size="lg" onClick={() => navigate(-1)}>
                  Retour
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AccessDenied;
