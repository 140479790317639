import { AppModule } from 'hooks/api/useAppModules';
import useAppModules from 'hooks/api/useAppModules';
import useModuleFeatures, { ModuleFeature } from 'hooks/api/useModuleFeatures';
import { UserData } from 'hooks/api/useEditorUser';
import React, { useState, ChangeEvent, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';

type UserFormProps = {
  tableData: UserData[];
  setTableData: React.Dispatch<React.SetStateAction<UserData[]>>;
  onClose: () => void;
};

const AddUserForm: React.FC<UserFormProps> = ({ tableData, setTableData, onClose }) => {
  const [newUser, setNewUser] = useState<UserData>({
    idUsers: 0,
    firstname: "",
    lastname: "",
    mail: "",
    phone: "",
    mobile: "",
    avatar: "",
    userRole: { id: 0, name: "", slug: "" },
    idErpCompanies: 0,
    company: { id: 0, name: "", ceoName: "" },
  });

  const [selectedAppId, setSelectedAppId] = useState<number | null>(null);
  const { appModules, loading: loadingModules, error: errorModules } = useAppModules();
  const { moduleFeatures, loading: loadingFeatures, error: errorFeatures } = useModuleFeatures();
  const [filteredModules, setFilteredModules] = useState<AppModule[]>([]);
  const [modulePermissions, setModulePermissions] = useState<{ [key: number]: { read: boolean, create: boolean, update: boolean, delete: boolean } }>({});
  const [featurePermissions, setFeaturePermissions] = useState<{ [key: number]: boolean }>({});

  useEffect(() => {
    if (selectedAppId !== null) {
      setFilteredModules(appModules.filter(module => module.idApps === selectedAppId));
    }
  }, [selectedAppId, appModules]);

  const handleAppChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const appId = Number(e.target.value);
    setSelectedAppId(appId);
  };

  const handleModulePermissionChange = (moduleId: number, permission: 'read' | 'create' | 'update' | 'delete') => (e: ChangeEvent<HTMLInputElement>) => {
    setModulePermissions(prevPermissions => ({
      ...prevPermissions,
      [moduleId]: {
        ...prevPermissions[moduleId],
        [permission]: e.target.checked
      }
    }));
  };

  const handleFeaturePermissionChange = (featureId: number) => (e: ChangeEvent<HTMLInputElement>) => {
    setFeaturePermissions(prevPermissions => ({
      ...prevPermissions,
      [featureId]: e.target.checked
    }));
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewUser(prevUser => ({
      ...prevUser,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await fetch('https://rct-backend.ipercom.io/api/User', {
        method: 'POST',
        headers: {
          'accept': 'text/plain',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newUser)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: UserData = await response.json();
      setTableData([...tableData, data]);
      onClose();
    } catch (error) {
      console.error("Erreur lors de l'ajout d'un utilisateur", error);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col md={6}>
          <Form.Group controlId="firstname">
            <Form.Label>Prénom</Form.Label>
            <Form.Control
              type="text"
              name="firstname"
              value={newUser.firstname}
              onChange={handleChange}
              required
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="lastname">
            <Form.Label>Nom</Form.Label>
            <Form.Control
              type="text"
              name="lastname"
              value={newUser.lastname}
              onChange={handleChange}
              required
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Form.Group controlId="mail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="mail"
              value={newUser.mail}
              onChange={handleChange}
              required
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="phone">
            <Form.Label>Téléphone</Form.Label>
            <Form.Control
              type="text"
              name="phone"
              value={newUser.phone}
              onChange={handleChange}
              required
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Form.Group controlId="mobile">
            <Form.Label>Mobile</Form.Label>
            <Form.Control
              type="text"
              name="mobile"
              value={newUser.mobile}
              onChange={handleChange}
              required
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="userRole">
            <Form.Label>Rôle</Form.Label>
            <Form.Control
              type="text"
              name="userRole"
              value={newUser.userRole.name}
              onChange={(e) => setNewUser({
                ...newUser,
                userRole: {
                  ...newUser.userRole,
                  name: e.target.value
                }
              } as UserData)}
              required
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>

        <Col md={6}>
          <Form.Group controlId="company">
            <Form.Label>Société</Form.Label>
            <Form.Control
              type="text"
              name="company"
              value={newUser.company.name}
              onChange={(e) => setNewUser({
                ...newUser,
                company: {
                  ...newUser.company,
                  name: e.target.value
                }
              } as UserData)}
              required
            />
          </Form.Group>
        </Col>
      </Row>

      <Form.Group controlId="appId">
        <Form.Label>Application</Form.Label>
        <Form.Control as="select" onChange={handleAppChange}>
          <option value="">Sélectionnez une application</option>
          <option value="1">Revendeur</option>
          <option value="2">Éditeur</option>
          <option value="3">IPBX</option>
          <option value="4">WebRTC</option>
        </Form.Control>
      </Form.Group>
   

      {selectedAppId !== null && (
        <Row>
          <h4 className='pt-3 pb-3'>Modules</h4>
          {filteredModules.map(module => (
            <Col md={4} key={module.idModule} className='border-1'>
              <h5 className="border-bottom pb-2">{module.name}</h5>
              <Form.Check
                type="checkbox"
                label="Lire"
                checked={modulePermissions[module.idModule]?.read || false}
                onChange={handleModulePermissionChange(module.idModule, 'read')}
              />
              <Form.Check
                type="checkbox"
                label="Créer"
                checked={modulePermissions[module.idModule]?.create || false}
                onChange={handleModulePermissionChange(module.idModule, 'create')}
              />
              <Form.Check
                type="checkbox"
                label="Modifier"
                checked={modulePermissions[module.idModule]?.update || false}
                onChange={handleModulePermissionChange(module.idModule, 'update')}
              />
              <Form.Check
                type="checkbox"
                label="Supprimer"
                checked={modulePermissions[module.idModule]?.delete || false}
                onChange={handleModulePermissionChange(module.idModule, 'delete')}
              />

              {moduleFeatures.filter(feature => feature.idModule === module.idModule).length > 0 && (
                <div>
                  <h5 className='fs-9'>Fonctionnalités</h5>
                  {moduleFeatures.filter(feature => feature.idModule === module.idModule).map(feature => (
                    <Form.Check
                      key={feature.idFeature}
                      type="checkbox"
                      label={feature.name}
                      className='ps-5'
                      checked={featurePermissions[feature.idFeature] || false}
                      onChange={handleFeaturePermissionChange(feature.idFeature)}
                    />
                  ))}
                </div>
              )}
            </Col>
          ))}
        </Row>
      )}

      <Button variant="primary" type="submit" className="mt-2">
        Ajouter
      </Button>
    </Form>
  );
};

export default AddUserForm;
