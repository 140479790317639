import React from 'react';
import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  FormControl,
  InputGroup,
  Row
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCirclePlus,
  faCog,
  faCopy,
  faEyeSlash,
  faGear,
  faPlus,
  faTrash
} from '@fortawesome/free-solid-svg-icons';

const ParamAudio = () => {
  return (
    <>
      <Card className="">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">Conférence audio</h3>

              <Form.Check.Label className="fs-8 fw-normal me-2">
                Extension conférence
              </Form.Check.Label>
              <div className="me-2">
                <FloatingLabel
                  controlId="floatingInputAuthMax"
                  label="extension"
                >
                  <Form.Control type="text" value="70" />
                </FloatingLabel>
              </div>

              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>
                  Directive pour les appelants dans l'attente de l'organisateur
                  : Format WAV (PCM, 8 kHz, 16 bit, Mono, taille max : 100Mo)
                </Form.Label>
                <InputGroup>
                  <FloatingLabel controlId="floatingSelect" label="Region">
                    <Form.Select aria-label="Floating label select example">
                      <option>onhold.wav</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </FloatingLabel>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faCog} className="me-2" />
                    Générer
                  </Button>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faCopy} className="me-2" />
                  </Button>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faEyeSlash} className="me-2" />
                  </Button>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faTrash} className="me-2" />
                  </Button>
                </InputGroup>
              </Form.Group>


              <Button variant="btn btn-white border border-1 px-2">
                <FontAwesomeIcon icon={faCirclePlus} className="me-2" />
                Ajouter un SDA
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">
                Email de conférences
              </h3>

              <Form.Check type="checkbox" id="sameAsShipping" className="mb-2">
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  L'organisateur doit entrer un code PIN pour créer des
                  conférences depuis un téléphone IP
                </Form.Check.Label>
              </Form.Check>

              <InputGroup>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Password"
                />

                <Button variant="btn btn-white border border-1 px-2">
                  <FontAwesomeIcon icon={faEyeSlash} className="me-2" />
                </Button>
              </InputGroup>

              <Form.Check type="checkbox" id="sameAsShipping" className="mt-2">
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Appeler les participants quand la conférence audio démarre
                </Form.Check.Label>
              </Form.Check>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">Conférence audio</h3>

              <Form.Group className="mb-3" controlId="formGroupEmail">
                <Form.Check.Label className="fs-9 fw-normal me-2">
                  Tapez n'importe quels numéros SDA externes de conférence
                  ci-dessous. Les numéros de téléphone seront indiqués dans
                  l'email envoyé aux participants. Exemple "US : +1 (217)
                  123-1234, ALL : +49 (30) 20179101".
                </Form.Check.Label>
                <div className="me-2">
                  <FloatingLabel
                    controlId="floatingInputAuthMax"
                    label="extension"
                  >
                    <Form.Control type="text" value="70" />
                  </FloatingLabel>
                </div>
              </Form.Group>

              <span className="me-3">
                Personnalisez l'email de conférence qui sera envoyé aux
                participants.
              </span>

              <Button variant="btn btn-white border border-1 px-2">
                <FontAwesomeIcon icon={faCog} className="me-2" />
                Configurer
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>

    </>
  );
};

export default ParamAudio;
