import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import { Records } from "../../../data/iper-x/ipbx/records";

export const recordsTableColumns: ColumnDef<Records>[] = [
  {
    id: "dateTime",
    header: "Date/heure",
    accessorFn: ({ dateTime }) => dateTime,
    meta: {
      headerProps: { style: { width: "20%" }, className: "pe-3" }
    }
  },
  {
    id: "name",
    header: "Nom",
    accessorFn: ({ name }) => name,
    meta: {
      headerProps: { style: { width: "20%" }, className: "pe-3" }
    }
  },
  {
    id: "sda",
    header: "SDA",
    accessorFn: ({ sda }) => sda,
    meta: {
      headerProps: { style: { width: "20%" }, className: "pe-3" }
    }
  },
  {
    id: "from",
    header: "De",
    accessorFn: ({ from }) => from,
    meta: {
      headerProps: { style: { width: "20%" }, className: "pe-3" }
    }
  },
  {
    id: "to",
    header: "A",
    accessorFn: ({ to }) => to,
    meta: {
      headerProps: { style: { width: "20%" }, className: "pe-3" }
    }
  },
  {
    id: "conversation",
    header: "Conversation",
    accessorFn: ({ conversation }) => conversation,
    meta: {
      headerProps: { style: { width: "30%" }, className: "pe-3" }
    }
  }
];

const RecordsTable = () => {
  return (
    <div>
      <AdvanceTable
        tableProps={{ className: "phoenix-table fs-9" }}
      />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default RecordsTable;
