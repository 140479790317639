import { faPlusCircle, faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Button from 'components/base/Button';
import { IperxDropdowData } from 'data/social/IperxDropdowData';
import IperxCallingConferenceModal from 'pages/apps/webrtc/IperxCall/IperxCallingConferenceModal';
import { useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const IperxNavigationCallingPage = ({ className }: { className?: string }) => {

  const [showModal, setShowModal] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const handleFifthButtonClick = () => {
    handleShowModal();
  };

  const handleCallBreak = () => {

    setIsPlaying(prevState => !prevState); 
   
  };

  const playPauseIcon = isPlaying ? faPause : faPlay; 

  const totalItems = IperxDropdowData.slice(0, 6).length;
  const numRows = Math.ceil(totalItems / 3);
  const lastRowFirstIndex = (numRows - 1) * 3;

  return (
    <>
      <IperxCallingConferenceModal show={showModal} handleClose={handleCloseModal} />

      <Row className={classNames(className, 'g-0 justify-content-center m-2')}>
        {IperxDropdowData.slice(0, 6).map((item, index) => (
          <Col
            key={index}
            xs={4}
            className={classNames('py-1', {
              'border-bottom': index < lastRowFirstIndex,
              'border-end': index % 3 !== 2
            })}
          >
            <Button
              as={Link}
              to="#!"
              variant="link"
              className={classNames(
                'fs-8 text-800 hover-primary fw-semi-bold d-flex flex-column d-xxl-inline-block align-items-center align-items-xxl-start btn btn-link',
              )}
              onClick={() => {
                if (index === 0) {
                  handleCallBreak();
                } else if (index === 5) {
                  handleFifthButtonClick();
                } else if (index === 3) {
                  handleFifthButtonClick();
                }
                
              }}
            >
              <div className="d-flex flex-column align-items-center">
                <FontAwesomeIcon
                  icon={index === 0 ? playPauseIcon : item.icon}
                  className={classNames('mb-2', 'small-icon')}
                />
                <span>{item.label}</span>
              </div>
            </Button>

          </Col>
        ))}
      </Row>
    </>
  );
};

export default IperxNavigationCallingPage;
