import { useState, useEffect, useCallback } from 'react';

export class UserData {
  id: string;
  entity: string;
  lastname: string;
  firstname: string;
  contact_id?: string;
  email: string;
  office_phone: string;
  user_mobile: string;
  admin: string;
  login: string;
  socid: string;
  datelastlogin: number;
  datepreviouslogin: number;
  iplastlogin: string;
  ippreviouslogin: string;
  address: string;
  vat_number: string;
  city: string;
  country: string;
  social_security_number: string;

  constructor(data: any) {
    this.id = data.id;
    this.entity = data.entity;
    this.lastname = data.lastname;
    this.firstname = data.firstname;
    this.contact_id = data.contact_id;
    this.email = data.email || '';
    this.office_phone = data.office_phone || '';
    this.user_mobile = data.user_mobile || '';
    this.admin = data.admin;
    this.login = data.login;
    this.socid = data.socid || '';
    this.datelastlogin = data.datelastlogin || 0;
    this.datepreviouslogin = data.datepreviouslogin || 0;
    this.iplastlogin = data.iplastlogin || '';
    this.ippreviouslogin = data.ippreviouslogin || '';
    this.address = data.address || 'N/A';
    this.vat_number = data.vat_number || 'N/A';
    this.city = data.city || 'N/A';
    this.country = data.country || 'N/A';
    this.social_security_number = data.social_security_number || 'N/A';
  }

  getFullName(): string {
    return `${this.firstname} ${this.lastname}`;
  }

  isAdmin(): boolean {
    return this.admin === '1';
  }

  getLastLoginDate(): string {
    return this.datelastlogin ? new Date(this.datelastlogin * 1000).toLocaleString() : 'N/A';
  }

  getPreviousLoginDate(): string {
    return this.datepreviouslogin ? new Date(this.datepreviouslogin * 1000).toLocaleString() : 'N/A';
  }
}

const useRetailerUsers = () => {
  const [usersData, setUsersData] = useState<UserData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchUsers = useCallback(async () => {
    try {
      const response = await fetch(
        'https://erp.ipercom.io/api/index.php/users/groups/5?load_members=1',
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            DOLAPIKEY: 'UX8gfqaOcVv8692NNw11fkvE6Sh3DJ5M',
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      // Vérifiez si 'members' existe et est un objet
      if (!data.members || typeof data.members !== 'object') {
        throw new Error('Données des membres invalides.');
      }

      const membersArray = Object.values(data.members);

      const users = await Promise.all(
        membersArray.map(async (member: any) => {
          if (member.contact_id) {
            // Si contact_id est présent, récupérer les données de contact
            try {
              const contactResponse = await fetch(
                `https://erp.ipercom.io/api/Contact/${member.contact_id}`,
                {
                  method: 'GET',
                  headers: {
                    Accept: 'application/json',
                    DOLAPIKEY: 'UX8gfqaOcVv8692NNw11fkvE6Sh3DJ5M',
                  },
                }
              );

              if (!contactResponse.ok) {
                console.warn(`Erreur lors de la récupération du contact ID: ${member.contact_id}`);
                return new UserData({
                  ...member,
                  address: 'N/A',
                  vat_number: 'N/A',
                  city: 'N/A',
                  country: 'N/A',
                  social_security_number: 'N/A',
                });
              }

              const contactData = await contactResponse.json();

              return new UserData({
                ...member,
                address: contactData.address || 'N/A',
                vat_number: contactData.vat_number || 'N/A',
                city: contactData.city || 'N/A',
                country: contactData.country || 'N/A',
                social_security_number: contactData.social_security_number || 'N/A',
              });
            } catch (contactError) {
              console.error(`Erreur lors de la récupération des données de contact pour l'ID: ${member.contact_id}`, contactError);
              return new UserData({
                ...member,
                address: '',
                vat_number: '',
                city: '',
                country: '',
                social_security_number: '',
              });
            }
          } else {
            // Si contact_id est null, utiliser les valeurs par défaut
            return new UserData({
              ...member,
              address: 'N/A',
              vat_number: 'N/A',
              city: 'N/A',
              country: 'N/A',
              social_security_number: 'N/A',
            });
          }
        })
      );

      setUsersData(users);
      setError(null); // Réinitialiser l'erreur si succès
    } catch (error: any) {
      console.error("Erreur lors de la récupération des données de l'API", error);
      setError('Impossible de récupérer les données.');
    } finally {
      setLoading(false); // Arrêter le chargement
    }
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return {
    usersData,
    loading,
    error,
  };
};

export default useRetailerUsers;
