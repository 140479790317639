import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCircleStop,
  faPause,
  faPlayCircle,
  faPlus,
  faSquarePhone,
  faUsers
} from '@fortawesome/free-solid-svg-icons';

export interface DropdownData {
  label: string;
  icon: IconProp;
}

export const IperxDropdowData: DropdownData[] = [
  {
    label: 'Attente',
    icon: faPause
  },
  {
    label: 'Nouvel appel',
    icon: faPlus
  },
  {
    label: 'Enregistrer',
    icon: faCircleStop
  },
  {
    label: 'Transfert',
    icon: faSquarePhone
  },
  {
    label: 'Transfert sup',
    icon: faSquarePhone
  },
  {
    label: 'Conférence',
    icon: faUsers
  },
  {
    label: 'Pause',
    icon: faPlayCircle
  },
]

export interface DropdownDataCallingTapNumber {
  letter: string; 
  label: string;
}

export const IperxDropdowCallingTaplabels: DropdownDataCallingTapNumber[] = [
  {
    letter: '', 
    label: '1' 
  },
  {
    letter: 'ABC', 
    label: '2' 
  },{
    letter: 'DEF', 
    label: '3' 
  },{
    letter: 'GHI', 
    label: '4' 
  },{
    letter: 'JKL', 
    label: '5' 
  },{
    letter: 'MNO', 
    label: '6' 
  },{
    letter: 'PQRS', 
    label: '7' 
  },{
    letter: 'TUV', 
    label: '8' 
  },{
    letter: 'WXYZ', 
    label: '9' 
  },{
    letter: '', 
    label: '*' 
  },{
    letter: '', 
    label: '0' 
  },{
    letter: '', 
    label: '#' 
  },
];