
export interface CRMIntegration {
  name: string;
  version: string;
}

export const crmIntegrationsInstalledTableData: CRMIntegration[] = [
  {
    name: "Nom du plugin",
    version: "V 1.13.0",
  },
  {
    name: "Nom du plugin",
    version: "V 1.13.0",
  },
  {
    name: "Nom du plugin",
    version: "V 1.13.0",
  },
  {
    name: "Nom du plugin",
    version: "V 1.13.0",
  },
  {
    name: "Nom du plugin",
    version: "V 1.13.0",
  },
  {
    name: "Nom du plugin",
    version: "V 1.13.0",
  },
  {
    name: "Nom du plugin",
    version: "V 1.13.0",
  },
];
