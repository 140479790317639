import { useState, useCallback, useEffect } from "react";

export interface SourceLead {
  id: number,
  name: string,
  slug: string
}

export interface CompanyClientType {
  id: number,
  name: string,
  slug: string
}

export interface CommonCity {
  id: number,
  name: string,
  slug: string,
  zipCode: string
}

export interface UserAssignedRetailer {
  id: number,
  firstname: string,
  lastname: string,
  avatar: string
}

export interface LeadData {
  idLeads: number,
  name: string,
  ceoName: string,
  siret: string,
  address: string,
  mail: string,
  website: string,
  phone: string,
  numbersEmployees: number,
  annualIncome: number,
  nafCode: string,
  sourceLead: SourceLead,
  companyClientType: CompanyClientType,
  commonCity: CommonCity,
  devicesNumber: number,
  accessNumber: number,
  progressMin: number,
  progressMax: number,
  statusLabel: string,
  statusType: string,
  userAssignedRetailer: UserAssignedRetailer
}

const leadTableData: LeadData[] = [
  {
    idLeads: 1,
    name: "Scouty",
    ceoName: "John Doe",
    siret: "2164516106210",
    address: "8 All. Cantillac",
    mail: "ipercom@gmail.com",
    website: "www.ipercom.com",
    phone: "610021245",
    numbersEmployees: 15,
    annualIncome: 2500000,
    nafCode: "6201Z",
    sourceLead: { id: 1, name: "pigma", slug: "" },
    companyClientType: { id: 1, name: "client", slug: "" },
    commonCity: { id: 1, name: "Pompignac", slug: "alle-cantillac", zipCode: "33370" },
    devicesNumber: 36,
    accessNumber: 21,
    progressMin: 100,
    progressMax: 150,
    statusLabel: "completed",
    statusType: "success",
    userAssignedRetailer: { id: 2, firstname: "Helene", lastname: "Degouve", avatar: "" }
  }
];

const useLead = () => {
  const [leadData, setLeadData] = useState(leadTableData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    console.log("Fetching new API data...");
    try {
      const response = await fetch('https://rct-backend.ipercom.io/api/Lead', {
        method: 'GET',
        headers: {
          'accept': 'text/plain',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      // console.log("Fetched data: ", data);
      setLeadData(data);
      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données de l'API", error);
      setError('Impossible de récupérer les données.');
      setLeadData(leadTableData);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    leadData,
    setLeadData,
    loading,
    error
  };
};

export default useLead;
